import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { City, OneCity } from '../UiKit/City';
import { IconList } from '../UiKit/IconList';
import { Preloader, Preloader0 } from '../UiKit/kit';
import { parseDesignInPersonas } from '../utilsDesign';
import { ListAvatars } from './ListAvatars';
import { ListAvatarsLayout } from './ListAvatarsLayout';
import { CityLocate } from '../UiKit/CityLocate';

export const ListCity = React.memo(({
  filterPeople,
  filter
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const [cityId, setCityId] = useState(context.me ? context.me.user.city_id : null);
  console.log('context.myCity = ', context.myCity);
  const [cityObj, setCityObj] = useState(context.me ? context.myCity : {});

  const [expanded, setExpanded] = useState(false);


  const celebs = Object.keys(context.celebs)

  const [more, setMore] = useState(20);

  const [cityPeople, setCityPeople] = useState(context.state.cityPeople);

  useEffect(() => {
    setCityPeople()
    if (
      (cityId && !context.myCity)
      || (cityPeople && cityId !== cityPeople.id)
      || (context.myCity && context.myCity.city_id !== cityId)
    ) {
      context.x.getCityPeople(cityObj, (cityD) => {
        setCityPeople(cityD)
        context.setState.setCityPeople(cityD)
      })
    }

    if (!cityId) {
      context.x.getCityPeople(cityObj, setCityPeople)
      // context.setState.setCityPeople([])
    }
  }, [cityId, context.me])

  useEffect(() => {
    console.log('cityPeople = ', cityPeople);
  }, [cityPeople])


  const handleSaveCity = () => {
    if (cityId) {
      context.x.updateMyCity(cityId, () => context.x.getCityPeople(cityObj, setCityPeople))
    }
  };



  const filterOutCelebs = (arr) => {
    arr = arr.filter(one => !Object.keys(context.celebs).includes(one.user.token))
    return arr
  }



  const handleEmpty = () => {
    setCityId()
    setCityObj({})
    setCityPeople()
    // context.x.fetchAllUsers((people) => setCityPeople(people.filter((one, i) => i < 12)))
    context.x.fetchAllUsers((people) => {
      people = parseDesignInPersonas(people)
      setCityPeople(
      people
        // .filter((one, i) => celebs.includes(one.token))
        .filter((one, i) => i < 100)
      )
    })
    // context.x.fetchAllUsers((people) => setCityPeople(people))
  };



  return (
    <>



      {context.state.peoplePanel && <div
        className={context.state.peoplePanel ? "x_choose_section x_choose_section--v" : "x_choose_section"}
      >
        {context.state.peoplePanel && <div className={context.state.peoplePanel ? "x_choose_section_header0 x_choose_section_header0--v" : "x_choose_section_header0"}>

          <div className={context.state.peoplePanel ? "x_choose_section_title0 x_choose_section_title0--v" : "x_choose_section_title0"}>

            <div className={context.state.peoplePanel ? "x_choose_section_header x_choose_section_header--v" : "x_choose_section_header"}>

              <div className={context.state.peoplePanel ? "x_choose_section_title x_choose_section_title--v mobile_v_no" : "x_choose_section_header mobile_v_no"}>


                <IconList icon={'travel_explore'}
                // q={quantity}
                /> {$ui('people_in')}

                {/* {Boolean(quantity) && <div className="x_choose_menu_counter">
                  {quantity}
                </div>} */}
              </div>

              <div className="_f _fwrap">
                {context.state.peoplePanel && <><div className='x_choose_city'>

                <City
                    //locateMe={true}
                  placeholder={$ui('user_city_placeholder')}
                  city={cityObj}
                  show={['city', 'province', 'country']}
                  className={'x_input--choosecity'}
                  // className={cityId ? 'x_input x_input--choosecity' : 'x_input x_input--choosecity x_input--choosecity--empty'}
                  value={cityId}
                  handleCityId={(id) => { setCityId(id) }}
                  handleCityObj={cityObj => context.x.addCityHistory(cityObj)}
                  handleEmpty={handleEmpty}
                  recents={true}
                  />

                </div>



                {Boolean(cityId) && cityId !== context.me.user.city_id && <>
                    {context.updatingAva !== context.me.user.token && <div className='x_choose_city_my'><div className="x_button x_button--2nd x_button--icon"
                      onClick={() => handleSaveCity()}><div className="t_icon">home</div>
                    {$ui('user_city_set_my')}
                  </div></div>}
                  {context.updatingAva === context.me.user.token && <div className="x_button x_button--inactive x_button--ghost x_button--icon"><div className="t_icon">check</div>{$ui('process_saving')}</div>}
                </>}



              </>}
                <CityLocate
                  handleCityId={(id) => { setCityId(id) }}
                  handleCityObj={cityObj => {
                    context.x.addCityHistory(cityObj)
                    context.x.updateMyCity(cityObj.id, () => context.x.getCityPeople(cityObj, setCityPeople))
                  }}
                  style={{
                    fontSize: '0.8em'
                  }}
                  buttonStyle={{
                    sm: true,
                    ghost: context.me.user.aggr.city ? true : false,
                  }}
                >
                  {!context.me.user.aggr.city && $ui('user_city_locate')}
                </CityLocate>
              </div>

              {!context.state.peoplePanel && <div className='x_choose_section_city'>{context.myCity.city}</div>}

            </div>

            {/* <ListCitiesPresets
              handleCityId={(id) => { setCityId(id) }}
              handleCityObj={(cityObj) => { setCityObj(cityObj) }}
            /> */}

          </div>


        </div>}



        {context.state.peoplePanel && cityPeople && <div
          className={`x_choose_section x_choose_section--city`}
        >
          <ListAvatars
            celeb={true}
            listId='city'
            quantity={filterOutCelebs(cityPeople).length}
            array={filterPeople(filterOutCelebs(cityPeople), filter)}
            actions={['share', 'follow']}
          /></div>}











        {!cityPeople && <>
          <Preloader0 style={{ zIndex: 100, height: '20em', position: 'relative' }}>
            <Preloader width={80} height={80} /> {$ui('loading')}...
          </Preloader0>
        </>}


        {cityPeople && !cityPeople.length && <>
          <div className="x_choose x_choose--empty">
            <div className="x_choose_empty">
              <div className="x_choose_empty_message">
                {/* <div className="t_icon t_icon--40">
                    travel_explore
                  </div> */}
                {/* <div>No people in <City cityId={cityId} inactive={true} show={['city']} /> yet</div> */}
                {$ui('no_people_yet')}
              </div>
            </div>
          </div>
        </>}



      </div>}


      {!context.state.peoplePanel && cityPeople && Boolean(cityPeople.length) && <ListAvatarsLayout
        icon='travel_explore'
        //TODO: fix
        title={`${$ui('people_in')} ${cityObj.city}`}
        listId='world'
        quantity={context.state.showHistory.length}
        className={context.state.peoplePanel ? 'v' : ''}
      >

        {Boolean(cityPeople.length > 6) && <div className="x_choose_section_city_expand" onClick={() => setExpanded(prev => !prev)}>
          <div className="t_icon t_icon--40">{!expanded ? <>expand_more</> : <>expand_less</>}</div>
        </div>}

        {/* {Boolean(cityPeople.length > 6) && !expanded && <div className="x_choose_section_city_grad inact" />} */}




          {cityPeople && <ListAvatars
            listId='city'
          actions={['share', 'follow']}
            quantity={cityPeople.length}
          array={cityPeople}
          />}




      </ListAvatarsLayout>}

    </>
  )
})



export function ListCitiesPresets({
  handleCityId = () => void (0),
  handleCityObj = () => void (0),
  className = ''
}) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };

  return (
    <>

      {Boolean(context.myCity.id) && <div className={`x_input_drop_li ${className}`}
        key={`my_${context.myCity['id']}`}
        // onClick={() => handleCityId(context.myCity['id'])}
        onClick={() => {
          // handleCityId(context.myCity['id'])
          handleCityObj(context.myCity)
        }}
      >
        <OneCity
          key={context.myCity.id}
          icon='home'
          className={className}
          one={context.myCity}
          handleSelect={handleCityObj}
        />


      </div>}

      {/* {context.myCities.map(one => <div className={`x_input_drop_li ${className}`}
        key={one.id}
        onClick={() => {
          handleCityId(one['id'])
          handleCityObj(one)
        }}
      >
        {one['city']}
      </div>)} */}

      {context.myCities.map(one =>
        <div
          className={`x_input_drop_li ${className}`}
          key={`city_${one.city}_${one.country}_${one.id}`}
        >
          <OneCity
            key={one.id}
            icon='history'
            className={className}
            one={one}
            handleSelect={handleCityObj}
          />
        </div>
      )}

    </>
  )
}






export default ListCity
