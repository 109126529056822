import React, { useState } from 'react';

const tokens = [
  '2eacc32420f89154dd3e',
  '5d2726d417d9ea95a55a',
];

export const TestPentaAvatars = () => {
  const [selectedToken, setSelectedToken] = useState(tokens[0]);

  const handleChange = (e) => {
    setSelectedToken(e.target.value);
  };

  const handleSubmit = async () => {
    const url = `http://localhost/api/penta/avatars/${selectedToken}/`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        // If you need to send a body with the POST request, include it here
        // body: JSON.stringify({ key: 'value' }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      // Handle success, e.g., showing a success message
    } catch (error) {
      console.error('Error:', error.message);
      // Handle error, e.g., showing an error message
    }
  };

  return (
    <div>
      <h3>/api/penta/avatars/</h3>
      <input tepe='text' value={selectedToken} onChange={handleChange} placeholder='token'></input>
      {/* <select value={selectedToken} onChange={handleChange}>
        {tokens.map((token) => (
          <option key={token} value={token}>{token}</option>
        ))}
      </select>  */}
      <button onClick={handleSubmit}>Penta</button>
    </div>
  );
};


