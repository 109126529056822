import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { DesignPages } from './Edu/DesignPages';
import { AvatarPanel } from './UiKit/AvatarPanel';
import { Guide } from './UiKit/Guide';
import { PreloaderHD } from './UiKit/PreloaderHD';
import { getLocal, setLocal } from './utilsSocial';
import { WithRectification } from './WithRectification';
import { WithURLparams } from './WithURLparams';



export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}






export const Start = memo(({
  publ,
  user,
  avatar,
  penta,
  followings = [],
  followers = [],
  loading,
  children,
  isMe,
  refetch
}) => {


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;


  // console.log('avatar.design = ', avatar.design);


  // const narrative0 = context.narratives['empty'] ? context.narratives['empty'] : defaultNarrative;
  // const beginner = context.narratives['beginner'] ? context.narratives['beginner'] : defaultNarrative;
  // const pro = context.narratives['pro'] ? context.narratives['pro'] : defaultNarrative;



  const narrativeFromLocal = getLocal('narrative');

  useEffect(() => {
    // Check for the hash "n" on hash change
    const handleHashChange = () => {
      const hashN = window.location.hash.split('n=')[1];
      if (hashN && Object.keys(context.narratives).includes(hashN)) {
        context.setNarrativeCurrent(hashN); // Set the current narrative if valid
      } else {
        context.setNarrativeCurrent('simple');
      }
    };

    // Initial check on component mount
    handleHashChange();

    // Add event listener for hash change
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [context.narratives]);

  // useEffect(() => {
  //   if (avatar && avatar.design['id'] === 'dummy') {
  //     context.setNarrativeCurrent('default');
  //   } else if (avatar) {
  //     context.setNarrativeCurrent('default');
  //   }
  // }, [context.narratives, avatar]);
  // useEffect(() => {
  //   if (
  //     avatar
  //     && avatar.design['id'] !== 'dummy'
  //     && context.narratives['pro']
  //   ) {
  //     context.setNarrativeCurrent('pro');
  //   } else if (avatar) {
  //     context.setNarrativeCurrent('default');
  //   }
  // }, [context.narratives, avatar]);

  // Update the hash whenever the narrative changes
  useEffect(() => {
    if (context.narrativeCurrent) {
      window.location.hash = `n=${context.narrativeCurrent}`; // Set the hash to the current narrative
    }
  }, [context.narrativeCurrent]);


  useEffect(() => {
    // console.log('narrativeCurrent = ', context.narrativeCurrent);
    // console.log('narrativeCurrentN = ', context.narratives[context.narrativeCurrent]);
  }, [context.narrativeCurrent])


  const [scrollTo, setScrollTo] = useState(-1);

  const scrollToSection = (id) => {
    // setActiveLink(id)
    const section = document.getElementById(id);
    section && section.scrollIntoView({ behavior: "smooth" });
  };





  const navigate = useNavigate();
  const location = useLocation();
  const handleCloseInfoPop = () => {
    document.body.classList.remove('noscroll');
    context.setState.setHighlighted()
    context.setState.setSearch([])

    const removeStudySegments = (pathname) => {
      const segments = pathname.split('/study')
      return segments.length > 1 ? segments[0] : null
    };
    const currentPath = location.pathname;
    const newPath = removeStudySegments(currentPath);
    if (newPath) navigate(newPath);
  };




  return (<>






    {avatar && <AvatarPanel
      publ={publ}
      isMe={isMe}
      user={user}
      avatar={avatar}
      followings={followings}
      followers={followers}
      refetch={refetch}
    />}

    {children}

    {/* {loading && <>
      <Preloader0 style={{ zIndex: 1, position: 'fixed', pointerEvents: 'none' }}>
        <Preloader width={80} height={80} />
      </Preloader0>
    </>} */}
    {loading && <> <PreloaderHD /></>}


    <div
      style={{
        opacity: loading ? 0.2 : 1
        // marginRight: '-2em', paddingLeft: '2em'
      }}
      // className={"x_edu"}
      className={context.state.calcShown ? "x_edu x_edu--calc" : "x_edu"}

    // style={{
    // opacity: datePickerShown ? 0.3 : 1,
    // overflow: datePickerShown ? 'hidden' : 'auto',
    // height: datePickerShown ? '100svh' : 'auto',
    // pointerEvents: datePickerShown ? 'none' : 'inherit',
    // }}
    >

      <div className={`x_edu_content00`}
      // onClick={() => handleCloseInfoPop()}
      >

        {
          !penta
          && avatar
          && <WithURLparams>
          <WithRectification avatar={avatar}>
            <DesignPages
              user={user}
              avatar={avatar}
                  narrative={
                    avatar.design['id'] !== 'dummy' ?
                      context.narratives[context.narrativeCurrent]
                      : context.narratives['default']
                  }
            //TODO: public date change
            // publ={publ}
            />
          </WithRectification>
        </WithURLparams>}

        {penta &&
          <DesignPages
            penta={penta}
          narrative={context.narratives['basic']}
          //TODO: public date change
          // publ={publ}
          />
        }

      </div>

    </div>




    {avatar
      && context.narratives[context.narrativeCurrent]
      && avatar.design['id'] !== 'dummy'
      && <Guide
      design={avatar.design}
      narrative={
        avatar.design['id'] !== 'dummy' ?
          context.narratives[context.narrativeCurrent]
          : context.narratives['default']
      }
      // activeLink={activeLink}
      scrollToSection={scrollToSection}
    />
    }

  </>
  )
})





export function NarrativesList() {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $narr = context.$narr;


  const def = 'simple';
  useEffect(() => {
    if (context.state.version !== 'β' && context.getNarrative(context.narrativeCurrent).beta) {
      context.setNarrativeCurrent(def);
    }
  }, [context.state.version, context.narrativeCurrent])

  return (
    <>

          <div className="x_edu_narrative_choose">

            {context.narratives && Object.keys(context.narratives)
              .filter(ky => !context.getNarrative(ky).beta || context.state.version === 'β')
              .filter(ky => context.narratives[ky].user_choose)
          .sort((a, b) => context.narratives[b].order - context.narratives[a].order)
              .map(ky => <div
                onClick={() => {
                  window.location.hash = `n=${ky}`;
                  // context.setNarrativeCurrent(ky);
                  // setLocal('narrative', ky)
                }}
                key={`${ky}`} className={context.narrativeCurrent === ky ? "x_edu_narrative1 active" : "x_edu_narrative1"}>
                {$narr(ky, 'trigger')}
              </div>)}


      </div >
    </>
  )
}