import React, { useContext } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Ad1 } from './Ad1';
import { ChatsAsPage } from './Chats/ChatsAsPage';
import { WithChats } from './Chats/WithChats';
import { WithDOB } from './Design';
import { PentaView } from './Edu/PentaView';
import { GroupMembers } from './Group';
import { Import } from './Import';
import { Layout } from './Layout';
import { WithPenta } from './Penta/WithPenta';
import { WithPublicData } from './Public';
import { TestMain } from './Test/TestMain';
import { GroupLayout } from './UiKit/GroupLayout';
import Page404 from './UiKit/Page404';
import { PreloaderHD } from './UiKit/PreloaderHD';
import { Thumbnail } from './UiKit/Thumbnail';
import { VideoPop } from './VideoPop';
import { WithGroup } from './WithGroup';
import { AdminTexts } from './Admin/AdminTexts';
import { AdminVideos } from './Admin/AdminVideos';
import { AdminUsers } from './Admin/AdminUsers';
import { AdminSkynet } from './Admin/AdminSkynet';
import { Celebs } from './Lists/Celebs';
import { CelebsLayout } from './CelebsLayout';

// import { Rectificate } from './Rectification/Rectificate';

export function RoutesMain(props) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext) };

  // define a function that sets min-height of my-element to window.innerHeight:



  return (
    <>







      <Router>
        <Routes>


          <Route
            exact path={'/ppp'}
            element={
              <>
                <PreloaderHD />
              </>
            }
          />
          <Route
            exact path={'/'}
            element={
              <>
                {!context.me && <Ad1 />}
                {context.me && context.avatars && context.pentas && context.followings && context.followers && <Navigate to={`/u/${context.me.user.token}`} />}
                {context.summary === 0 ? <Ad1 /> : null} {/* Adjusted to use a ternary operator */}
              </>
            }
          />

          <Route
            exact path={'/intro'}
            element={
              <>
                {/* {context.me && context.avatars && context.pentas && context.followings && context.followers && <Navigate to={`/u/${context.me.user.token}`} />} */}
                {/* {context.summary === 0 ? <Ad1 /> : null} Repeated adjustment for consistency */}
                <Ad1 />
              </>
            }
          />


          <Route
            exact path={'/test'}
            element={<>

              <TestMain />

            </>}
          />



          <Route
            exact path={'/import'}
            element={<>

              <Import />

            </>}
          />

          <Route
            exact path={'/admin'}
            element={<>
              <AdminUsers />
            </>}
          />

          <Route
            exact path={'/admin/skynet'}
            element={<>
              <AdminSkynet />
            </>}
          />

          <Route
            exact path={'/admin/texts'}
            element={<>
              <AdminTexts />
            </>}
          />

          <Route
            exact path={'/admin/videos'}
            element={<>
              <AdminVideos />
            </>}
          />



          <Route path='/p/:pentaId/*'
            element={context.summary && <Navigate to={`/intro`}></Navigate>}
          >
            <Route
              index
              element={<>
                <WithChats>
                  <Layout>
                    <WithPenta>
                      <PentaView />
                    </WithPenta>
                  </Layout>
                </WithChats>
              </>}
            />

            <Route path='study/:what' element={<WithChats>
              <Layout>
                <WithPenta>
                  <PentaView />
                </WithPenta>
              </Layout>
            </WithChats>} />
          </Route>


          <Route path='/g/:groupId/*'
            element={context.summary && <Navigate to={`/intro`}></Navigate>}
          >
            <Route
              index
              element={<>
                <WithChats>
                  <WithGroup>
                    <GroupLayout>
                      <GroupMembers />
                    </GroupLayout>
                  </WithGroup>
                </WithChats>
              </>}
            />
            {/* <Route path='study/:what' element={<WithChats>
              <Layout>
                <WithPenta>
                  <PentaView />
                </WithPenta>
              </Layout>
            </WithChats>} /> */}
          </Route>


          <Route path='/faces/:typeId?'
            element={context.summary && <Navigate to={`/intro`}></Navigate>}
          >
            <Route
              index
              element={<>
                <WithChats>
                  <CelebsLayout>
                    <Celebs fullSrceen={true} />
                  </CelebsLayout>
                </WithChats>
              </>}
            />
          </Route>



          <Route
            exact path={'/login'}
            element={<>
              <Ad1 />
              {/* <Login>
                <a href='/auth0/login' className="x_ad_cta">
                  Enter
                </a>
              </Login> */}
            </>}
          />



          <Route path="/chats/:chatId/*" element={
            context.me ? (<WithChats>
              <ChatsAsPage />
            </WithChats>) : (
                <Navigate to="/login" />
            )
          } />

          <Route path="/chats/" element={
            context.me ? (
              <ChatsAsPage />
            ) : (
                <Navigate to="/login" />
            )
          } />

          {/* <Route path="/chats/choose" element={
            context.me ? (
                <Chats />
            ) : (
              <Navigate to="/login" />
            )
          } /> */}

          {/* <Route
            exact path={'/rectificate'}
            element={<>
              <Rectificate />
            </>}
          /> */}

          {/* <Route
            path={'/u/:useravatarId/[?followers,?following]'}
            element={<>
              <Layout peopleShown={false}>
                <WithDOB />
              </Layout>

            </>}
          /> */}

          <Route path='/u/:useravatarId/*'
            element={context.summary && <Navigate to={`/intro`}></Navigate>}
          >
            {/* {context.me && context.avatars && context.pentas && context.followings && context.followers && */}
            <>
              <Route
                index
                element={<WithChats><Layout><WithDOB /></Layout></WithChats>}
              />
              <Route path='video' element={<VideoPop />} />
              <Route path='followers' element={<WithChats><Layout><WithDOB /></Layout></WithChats>} />
              <Route path='following' element={<WithChats><Layout><WithDOB /></Layout></WithChats>} />
              <Route path='info' element={<WithChats><Layout><WithDOB /></Layout></WithChats>} />
              <Route path='settings' element={<WithChats><Layout><WithDOB /></Layout></WithChats>} />
              <Route path='study/:what' element={<WithChats><Layout><WithDOB /></Layout></WithChats>} />
            </>
            {/* } */}

            {/* {context.summary === 0 && !context.me && <Route element={<Ad1 />} />} */}

          </Route>

          <Route path='/i/:useravatarId/*'
            element={context.summary && <Navigate to={`/intro`}></Navigate>}
          >
            {context.me && context.avatars && context.pentas && context.followings && context.followers &&
              <>
                <Route
                  index
                  element={<Thumbnail />} />
                <Route path='followers' element={<WithDOB />} />
                <Route path='following' element={<WithDOB />} />
                <Route path='info' element={<WithDOB />} />
              {/* <Route path='study' element={<WithDOB />} /> */}
              <Route path='study/:what' element={<WithDOB />} />
              </>}

            {context.summary === 0 && !context.me && <Route element={<Ad1 />} />}

          </Route>



          <Route
            exact path={'/public/'}
            element={<>

              {/* <WithURLparams> */}
              <WithChats>
              <WithPublicData />
              </WithChats>
              {/* </WithURLparams> */}

            </>}
          >
            <Route path='study/:what' element={<WithChats>
              <Layout>
                <WithPenta>
                  <PentaView />
                </WithPenta>
              </Layout>
            </WithChats>} />
          </Route>


          <Route
            exact path={'/404'}
            element={<><Page404 /></>}
          />





        </Routes>
      </Router>

    </>
  )
}