import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';

export function AvatarCity({ avatar = {} }) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  return (
    <>

      {context.state.hideNames ? context.getDefaultTitle(avatar.design, 'type') :
        <>{avatar.aggr && avatar.aggr.city ? `${avatar.aggr.city.city}, ${avatar.aggr.city.country}` : ''}</>
      }

    </>
  )
}