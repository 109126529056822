import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { getMessageDate, getMessageTime } from '../Chats/ChatMessageRead';
import { _notificationsMarkAllRead, _notificationsMarkRead, parseNewDesign } from '../utilsDesign';
import { Avatar } from './Avatar';
import { DropDown } from './DropDown';

export const Notifications = React.memo(({ listId, actions = [], avatar = {}, user = {}, penta = {} }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  const navigate = useNavigate();

  const [notiCount, setNotiCount] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [notis, setNotis] = useState([]);

  const fetchNotis = () => {
    context.x.fetchDataAsync(`notifications/list`)
      .then(data => {
        if (data) {
          setNotifications(data)
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  const fetchNotiCount = () => {
    context.x.fetchDataAsync(`notifications/count`)
      .then(data => {
        if (data) {
          setNotiCount(data)
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    if (notifications.length) {
      setNotis([{
        id: 'div1',
        divider: true,
      },
      ...processNoti(notifications)])
    } else {
      setNotis([])
    }
  }, [notifications])

  const markAllRead = () => {
    setNotiCount({
      ...notiCount,
      new: 0
    })
    _notificationsMarkAllRead();
    setNotifications(notifications.map(one => ({
      ...one,
      read: true
    })))
  }

  const markRead = (_id) => {
    setNotiCount({
      ...notiCount,
      new: notiCount.new > 0 ? notiCount.new - 1 : 0
    })

    _notificationsMarkRead(_id)
  }



  useEffect(() => {
    fetchNotis()
    fetchNotiCount()
  }, [])


  const processNoti = (notis) => {
    return notis.map(one => ({
      id: one._id,
      // active: context.language === 'ru',
      icon: 'person_add',
      className: `x_menu_li x_menu_li--noti ${!one.read ? 'active' : ''} ${one.read ? 'x_menu_li--noti--inactive' : ''}`,
      component: <>
        <div className="_f _f100">

          <div className="_f _fgrow">
            <Avatar
              style={{
                fontSize: '0.8em'
              }}
              avatar={{
                ...one.payload.by.avatar,
                design: parseNewDesign(one.payload.by.avatar.design)
              }}
            />
            {one.payload.by.avatar.name} {$ui(`noti_${one.type}`)}
          </div>

          <div className="_f _fwrap _fgap0 _fright _t08">
            <span className='tnowrap'>
              {getMessageDate({ timestamp: one.timestamp })}
            </span>
            <span className='tnowrap'>
              {getMessageTime({ timestamp: one.timestamp })}
            </span>
          </div>

        </div>
      </>,
      handle: () => {
        markRead(one._id);
        // navigate(`/u/${one.userToken}`);
        navigate(`/u/${one.payload.by.user.token}`);
      }
    }))
  }


  return (


    <DropDown
      id='noti'
      options={[
        // { component: <LangSwitcher /> },
        {
          id: 'allread',
          // active: context.language === 'en',
          icon: 'check',
          // title: 'English',
          handle: () => markAllRead(),
          className: `x_menu_li x_menu_markallread ${Boolean(notifications.filter(one => !one.read).length) ? '' : 'x_menu_li--noti x_menu_li--noti--inactive'}`,
          component: <> {Boolean(notiCount.new)
            && <div className='_fcc _f100'>
              <div className="x_button x_button--ghost">
                <div className="t_icon">check</div> Mark all as read</div></div>} {!Boolean(notiCount.new)
                  && <div className='_fcc _f100'>No notifications yet</div>}</>
        },

        ...notis

      ]}

    >
      <div className="x_user0">

        {Boolean(notiCount.new) && <div className="x_noti0 x_noti0--number">
          <div className="x_noti0_number">{notiCount.new}</div>
        </div>}

        <div className="t_icon t_icon--60" style={{ opacity: Boolean(notiCount.new) ? 1 : 0.4 }}>
          notifications
        </div>
      </div>
    </DropDown>

  )
})