import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Topbar } from './UiKit/Topbar';

export function CelebsLayout(props) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;
  const { children } = { ...props };



  return (
    <>

      <div className="_fv _fcc _f100">

        <Topbar
          user={context.me.user}
          avatar={context.me.avatar}
          mode2={true}
        />

        <div className="_fv _fgap2 _fcc _f100"
          style={{ marginTop: '2em', padding: '4em' }}
        >






            {children}




        </div>
      </div>
    </>
  )
}