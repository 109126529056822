import React, { useContext, useEffect } from 'react';
import { StateContext } from '../../contexts/stateContext';

export function Pop({ handleClose, children, title, icon, type = '', addContent }) {
  const context = { ...useContext(StateContext) };

  useEffect(() => {
    document.body.classList.add('noscroll');
    return () => {
      document.body.classList.remove('noscroll');
    };
  }, []);

  return (
    <>

      <div className={`m0 ${Boolean(type) ? `m0--${type}` : ''}`}>
        <div

          className="m_z"
          onClick={handleClose}
        />
        <div className={`m_b m_b--${type}`}>

          <div className={`m_b_c m_b_c--${type}`}>
            <div className={`m_b_h m_b_h--${type}`}>
            <div className="m_b_h_icon">
              <div className="t_icon t_icon--20">
                {icon}
              </div>
            </div>
              <div className="m_b_h_title">
                {title}
            </div>
              <div className="x_pop_right">
              <div className="x_action"
                onClick={handleClose}
              >
                <div className="t_icon t_icon--20">
                  <div>close</div>
                </div>
              </div>
            </div>
          </div>


            {children}

          </div>
          {/* <div className="m_b_b" /> */}
        </div>

        {addContent}

      </div>

    </>
  )
}
