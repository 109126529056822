import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Design } from './Design';
import { Study } from './Edu/Study';
import { PublicSocial } from './PublicSocial';
import { Social } from './Social';
import { Background } from './UiKit/Background';
import { Topbar } from './UiKit/Topbar';
import { VideoPop } from './VideoPop';
import {
  _getNewDesign,
  convertGMTtoDateTime
} from './utilsDesign';
import { _getCity } from './utilsGeo';


export const WithPublicData = ({ children }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  const navigate = useNavigate();
  const location = useLocation();

  let urlParams = new URLSearchParams(window.location.search);
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString().replace('%3A', ':').replace('%2F', '/');

  // const lang = urlParams.get('ln') && context.languages.includes(urlParams.get('ln')) ? urlParams.get('ln') : context.languages[0];

  const [date, setDate] = useState(urlParams.get('date') ? urlParams.get('date') : `1990-01-01`);
  const [time, setTime] = useState(urlParams.get('time') ? urlParams.get('time') : '12:00');
  const [offset, setOffset] = useState(urlParams.get('offset') ? urlParams.get('offset') : '0');
  const [timezone, setTimezone] = useState(urlParams.get('timezone') ? urlParams.get('timezone') : urlParams.get('city_id') ? '' : 'GMT');
  const [dob, setDOB] = useState(urlParams.get('dob') ? urlParams.get('dob') : urlParams.get('dob') ? '' : '');
  const [title, setTitle] = useState(urlParams.get('title'));
  const [cityId, setCityId] = useState(urlParams.get('city_id'));

  const friendToken = urlParams.get('invited_by');
  if (friendToken && !context.me) localStorage.setItem('invited_by', JSON.stringify(friendToken))

  const allowCopy = urlParams.get('copy');
  if (allowCopy && dob && cityId && !context.me) localStorage.setItem('import_avatar', JSON.stringify({ dob: dob, city_id: cityId }))


  // if (lang) {
  //   context.setLanguage(lang)
  // }

  const removeLanguageParam = () => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    params.delete('ln');
    const newHash = params.toString();
    window.history.replaceState(null, '', `${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
  };

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    const languageParam = params.get('ln');
    if (languageParam !== context.language && context.languages.includes(languageParam)) {
      context.setLanguage(languageParam)
    }
    removeLanguageParam()
  }, [location]);




  const [cityData, setCityData] = useState();


  const [cachedDesign, setCachedDesign] = useState();
  const [avatar, setAvatar] = useState({});

  useEffect(() => {
    if (cityId && !cityData) {
      _getCity(cityId, setCityData)
    }
  }, [cityId])
  useEffect(() => {
    if (cityData && !timezone) {
      setTimezone(cityData.timezone)
    }
  }, [cityData])




  const cacheDesign = (dob, tz) => {

    const callback = (data) => {
      setCachedDesign(data)
    }

    _getNewDesign(dob, tz, callback)

    // _getDesignArray([dob], (data = []) => {
    //   // let des = {}
    //   // des[data['id']] = data;
    //   // setCachedDesign((prev = {}) => ({ ...prev, ...des }))
    //   setCachedDesign(data)
    // })
  }

  useEffect(() => {

    let GMT = dob;
    // let GMT = adjustUTCByOffset(`${date}T${time}`, offset)
    const { date, time } = convertGMTtoDateTime(GMT)
    // if (convertedDate && convertedTime) {
    //   cacheDesign(`${convertedDate}|${convertedTime}|${timezone}`)
    // }


    if (timezone) {
      cacheDesign(`${date}T${time}:00`, timezone)
    }
  }, [dob, timezone])



  const [link, setLink] = useState('');





  useEffect(() => {
    console.log('cachedDesign = ', cachedDesign);
    const publicUser = {
      name: title,
      unsaved: true,
      dob: dob, //GMT
      city_id: cityId, //GMT
      design: cachedDesign
    };
    setAvatar(publicUser)

    const link = location.pathname + location.search + location.hash;
    console.log('useLocation = ', link);

    context.x.setPublicUser({
      link: link,
      user: null,
      avatar: publicUser
    })
  }, [cachedDesign])



  const loading = false;

  // if (avatar.design && React.isValidElement(children)) {
  //   return <>
  //     <Topbar user={false} />
  //     {React.cloneElement(children, { avatar, loading })}
  //     {avatar && <Background
  //       style={{ zIndex: -1 }}
  //       design={avatar.design}
  //     ></Background>}
  //   </>
  // }


  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position > 40) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollable = useRef(null);



  {
    if (avatar.design) return (<>


      <Study
        user={null}
        avatar={avatar}
        handleClose={() => navigate('../')}
      />

      <VideoPop />


      <Topbar
        user={false}
        avatar={avatar}
        // mode2={scrolled}
      />

      {context.me && <Social />}
      {!context.me && <PublicSocial />}

      <Design
        publ={true}
        avatar={avatar}
        loading={false}
      >
      </Design>

      {avatar && <Background
        style={{ zIndex: -1, position: 'fixed', bottom: 0 }}
        design={avatar.design}
        aura={false}
      ></Background>}
    </>)
  }

  // if ((!cachedDesign)) {
  //   return <><Preloader0><Preloader width={80} height={80} /> Loading your bodygraph...</Preloader0></>
  // }

  return children;
};
