import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { default_design } from '../../static/default_design';
import { AVATAR_TOKEN_LENGTH, USER_TOKEN_LENGTH } from '../utilsDesign';
import { Thumb } from './Thumb';


export const Thumbnail = React.memo(({ children }) => {
  const { useravatarId } = useParams();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState({});
  const [followings, setFollowings] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [scrolled, setScrolled] = useState(false);

  const [newUser, setNewUser] = useState();

  // Create a ref to store the current useravatarId
  const currentIdRef = useRef(useravatarId);


  const scrollable = useRef(null);


  const fetchCallback = (newUser, refetch) => {
    // Check if useravatarId has changed since the request was made
    if (currentIdRef.current !== useravatarId) {
      // If it has changed, abort the callback execution
      // console.log('Aborting fetchCallback due to useravatarId change');
      return;
    }

    if (!newUser) {
      navigate('/404');
      return;
    }

    setNewUser(newUser)
  };

  useEffect(() => {
    if (newUser && useravatarId === newUser.user.token) {
      updateUser(newUser)
    }
  }, [newUser, useravatarId])


  const updateUser = (newUser) => {
    setUser(newUser.user);
    let ava = newUser.avatar;
    if (!ava) {
      navigate('../');
      return;
    }
    if (!ava.design || !ava.design['id']) {
      ava.design = default_design;
    }
    // if (!refetch || (refetch === newUser.avatar.token || refetch === newUser.user.token)) {
    setAvatar(ava);
    setFollowings(newUser.followings ? newUser.followings : []);
    setFollowers(newUser.followers ? newUser.followers : []);
    // }
  }





  const getUserAvatar = (useravatarId, refetch = false) => {


    if (useravatarId.length === USER_TOKEN_LENGTH) {
      currentIdRef.current = useravatarId;
      context.x.fetchUser(useravatarId, fetchCallback, refetch);

    } else if (useravatarId.length === AVATAR_TOKEN_LENGTH) {
      setUser()
      let ava = context.x.getAvatar(useravatarId)
      if (!ava) {
        navigate('../');
        return;
      }
      console.log('ava = ', ava);
      if (!ava.design['id']) {
        ava.design = default_design;
      }
      console.log('ava = ', ava);
      setAvatar(ava)
    }




  };



  useEffect(() => {
    getUserAvatar(useravatarId);
  }, [useravatarId, context.avatars, context.updatingAva, context.followings, context.cachedUsers]);

  useEffect(() => {
    getUserAvatar(useravatarId, true);
  }, [context.me]);




  const isInfo = location.pathname.includes('info');
  const isFollowersPage = location.pathname.includes('followers');
  const isFollowingPage = location.pathname.includes('following');




  // useEffect(() => {
  //   const handleScroll = () => {
  //     const position = window.scrollY;
  //     if (position > 40) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };

  //   // Attach the scroll event listener to the window
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);



  return (
    <>



      {avatar.design && (
        <>
          <ThumbnailSvg avatar={avatar} />
        </>
      )}
    </>
  );
});









export function ThumbnailSvg(props) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext) };
  const {
    publ,
    user,
    avatar,
    isMe,
    useravatarId,
    loading
  } = { ...props };


  // const design =
  //   cachedDesign ? cachedDesign
  //   : avatar ? context.x.getDesign(avatar) : null;
  // const design = avatar ? context.x.getDesign(avatar) : null;

  //if array
  // const design = (avatar && avatar.design && avatar.design[0]) ? avatar.design[0] : default_design;

  const design = (avatar && avatar.design && avatar.design['id'] !== 'dummy') ? avatar.design : default_design;


  const [designObject, setDesignObject] = useState(design)

  useEffect(() => {
    console.log('context.avatar.design = ', context.avatar ? context.avatar.design : null);
  }, [context.avatar])


  // const design = avatar && avatar.design ? avatar.design : null;

  useEffect(() => {
    avatar && context.setState.setCalcShown(!avatar.dob ? true : false)
  }, [avatar])

  useEffect(() => {
    console.log('design 🧩 = ', design);
  }, [design])

  return (
    <>

      <>
        <Thumb
          design={avatar.design}
          type='og'
        // type='email'
        />
        <Thumb
          design={avatar.design}
          // type='og'
          type='thumbnail'
        />
      </>
    </>
  )
}


