import React, { useContext, useEffect, useState } from 'react';
import { SocialContext } from '../contexts/socialContext';
import { ListAvatars } from './Lists/ListAvatars';
import { ListAvatarsLayout } from './Lists/ListAvatarsLayout';

export function FollowList(props) {
  const context = { ...useContext(SocialContext) };

  const { token = '', count = 0, list = 'followings', children } = { ...props };

  const [listInner, setListInner] = useState([]);

  useEffect(() => {
    context.x.fetchUser(token, (userData) => {
      setListInner(userData[list] ? userData[list] : [])
    }, true)
  }, [token])

  return (
    <>

      {Boolean(children) && <div
        className={`x_choose--v ${list ? `x_choose--${list}` : ''}`}
        style={{ width: '100%' }}
      >
        {children}
      </div>}

      <ListAvatarsLayout icon=''
        className='followers'
      >


          <ListAvatars
            listId='followers'
            quantity={count}
            array={listInner}
          // fullList={true}
          />
      </ListAvatarsLayout>



    </>
  )
}