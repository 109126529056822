import React from 'react';
import { QRCode } from '../../../node_modules/antd/es';

export function QR({ url }) {

  // const qrCodeData = `http://hd.cafe/public/?${url}`; // Data to be encoded in the QR code
  const qrCodeData = url; // Data to be encoded in the QR code

  const options = {
    imageSettings: {
      src: '../../public/logo192.png',
      x: 50, // Adjust the x-coordinate to center the logo
      y: 50, // Adjust the y-coordinate to center the logo
      height: 100,
      width: 100,
    },
  }

  return (
    <>
      <QRCode value={qrCodeData} size={200}
      // imageSettings={{
      //   // src: 'https://lh3.googleusercontent.com/a/AGNmyxbdGgZzaIV41l3LAEhkvRWsxy3wxvWCK04qafeHFUs=s96-c',
      //   x: 64, // Adjust the x-coordinate to center the logo
      //   y: 64, // Adjust the y-coordinate to center the logo
      //   height: 32,
      //   width: 32,
      // }}

      />
    </>
  );
}