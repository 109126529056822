
const ln = 'en';

const typesDescription = {
  en: {
    MG: 'Manifesting Generators are here to respond to life and initiate their own creative projects.',
    G: 'Generators are here to respond to life and find work that is satisfying and fulfilling for them.',
    P: 'Projectors are here to guide and direct others, using their natural wisdom and insight to help people find their way.',
    M: 'Manifestors are here to initiate and get things started in the world.',
    R: 'They are highly sensitive to the energy of their environment and are here to reflect the health of their community.',
  }
}
const typesDescriptionLong = {
  en: {
    MG: 'As a Manifesting Generator, you have a unique superpower of being able to multitask and handle multiple projects at once, while still having the energy to pursue your passions and respond to opportunities in life with speed and efficiency.',
    G: 'As a Pure Generator, you have a powerful life force that is designed to create. Your energy is magnetic and draws opportunities towards you. It\'s important for you to wait and respond to life rather than initiating action on your own.When you respond to life, you create a powerful energetic field that attracts even more abundance and opportunities towards you.',
    P: 'As a Projector, your natural gift is your wisdom and insight, which you can use to guide and direct others in finding their way. Remember to wait for invitations before offering your guidance so that you can be sure that you are being recognized and valued for your unique abilities.',
    M: 'As a Manifestor, you have a natural gift for initiating and making things happen in the world. Your impact on others is often significant and can be felt without you even realizing it. Remember to inform those around you before taking action to avoid resistance and create a smoother path forward.',
    R: 'As a Reflector, you have a unique ability to reflect the energy of your environment, which makes you highly intuitive and perceptive. Your open centers allow you to experience a wide range of emotions and perspectives, making you a natural empath and able to see things from many different angles. Remember to take your time before making big decisions, giving yourself the space to reflect and gather information over a full lunar cycle.',
  }
}






export const typeColors = {
  MG: { bg: '#fef1e8', border: '#ffc850', },
  G: { bg: '#fef1e8', border: '#ffc850', },
  P: { bg: '#fff6fe', border: '#bad4ff', },
  M: { bg: '#ffe4e5', border: '#ffbcbc', },
  R: { bg: '#e2fbf6', border: '#b0ebff', },
}

const typeTitles = {
  en: {
    MG: 'Manifesting Generator',
    G: 'Generator',
    P: 'Projector',
    M: 'Manifestor',
    R: 'Reflector',
  }
}
const typeTitlesFull = {
  en: {
    MG: 'Manifesting Generator',
    G: 'Pure Generator',
    P: 'Projector',
    M: 'Manifestor',
    R: 'Reflector',
  }
}
const typeTitlesPlural = {
  en: {
    MG: 'Manifesting Generators',
    G: 'Generators',
    P: 'Projectors',
    M: 'Manifestors',
    R: 'Reflectors',
  }
}
const typeTitlesShort = {
  en: {
    MG: 'Mani-Gen',
    G: 'Gen',
    P: 'Prj',
    M: 'Mfs',
    R: 'Rfl',
  }
}
const typeTitlesCompact = {
  en: {
    MG: 'Mani-Gen',
    G: 'Generator',
    P: 'Projector',
    M: 'Manifestor',
    R: 'Reflector',
  }
}
const typeTitlesCompactPlural = {
  en: {
    MG: 'Mani-Gens',
    G: 'Generators',
    P: 'Projectors',
    M: 'Manifestors',
    R: 'Reflectors',
  }
}

const typesPercentage = {
  MG: 32,
  G: 37,
  P: 21,
  M: 9,
  R: 1,
}



export const types_dict = {
  MG: { bgColor: typeColors['MG']['bg'], typeId: 'MG', title: typeTitles[ln]['MG'], titleFull: typeTitlesFull[ln]['MG'], titleShort: typeTitlesShort[ln]['MG'], titleCompact: typeTitlesCompact[ln]['MG'], titleCompactPlural: typeTitlesCompactPlural[ln]['MG'], typesPercentage: typesPercentage['MG'], titlePlural: typeTitlesPlural[ln]['MG'], descriptionShort: typesDescription[ln]['MG'], descriptionLong: typesDescriptionLong[ln]['MG'] },
  G: { bgColor: typeColors['G']['bg'], typeId: 'G', title: typeTitles[ln]['G'], titleFull: typeTitlesFull[ln]['G'], titleShort: typeTitlesShort[ln]['G'], titleCompact: typeTitlesCompact[ln]['G'], titleCompactPlural: typeTitlesCompactPlural[ln]['G'], typesPercentage: typesPercentage['G'], titlePlural: typeTitlesPlural[ln]['G'], descriptionShort: typesDescription[ln]['G'], descriptionLong: typesDescriptionLong[ln]['G'] },
  P: { bgColor: typeColors['P']['bg'], typeId: 'P', title: typeTitles[ln]['P'], titleFull: typeTitlesFull[ln]['P'], titleShort: typeTitlesShort[ln]['P'], titleCompact: typeTitlesCompact[ln]['P'], titleCompactPlural: typeTitlesCompactPlural[ln]['P'], typesPercentage: typesPercentage['P'], titlePlural: typeTitlesPlural[ln]['P'], descriptionShort: typesDescription[ln]['P'], descriptionLong: typesDescriptionLong[ln]['P'] },
  M: { bgColor: typeColors['M']['bg'], typeId: 'M', title: typeTitles[ln]['M'], titleFull: typeTitlesFull[ln]['M'], titleShort: typeTitlesShort[ln]['M'], titleCompact: typeTitlesCompact[ln]['M'], titleCompactPlural: typeTitlesCompactPlural[ln]['M'], typesPercentage: typesPercentage['M'], titlePlural: typeTitlesPlural[ln]['M'], descriptionShort: typesDescription[ln]['M'], descriptionLong: typesDescriptionLong[ln]['M'] },
  R: { bgColor: typeColors['R']['bg'], typeId: 'R', title: typeTitles[ln]['R'], titleFull: typeTitlesFull[ln]['R'], titleShort: typeTitlesShort[ln]['R'], titleCompact: typeTitlesCompact[ln]['R'], titleCompactPlural: typeTitlesCompactPlural[ln]['R'], typesPercentage: typesPercentage['R'], titlePlural: typeTitlesPlural[ln]['R'], descriptionShort: typesDescription[ln]['R'], descriptionLong: typesDescriptionLong[ln]['R'] },
}