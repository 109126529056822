import React, { useState } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

const tokens = [
  '5aef3edf90ee5bb9',
  '5aef3edf90ee5bb8',
];

export const TestIsMe = () => {
  const [selectedToken, setSelectedToken] = useState(tokens[0]);

  const handleChange = (e) => {
    setSelectedToken(e.target.value);
  };

  const handleSubmit = async () => {
    const url = `${API_URL}/api/avatar/is-me/`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include any other headers you need, such as authorization
        },
        // If you need to send a body with the POST request, include it here
        body: JSON.stringify({ token: `${selectedToken}` }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      // Handle success, e.g., showing a success message
    } catch (error) {
      console.error('Error:', error.message);
      // Handle error, e.g., showing an error message
    }
  };

  return (
    <div>
      <h3><span style={{ color: 'red' }}>POST</span> /api/avatar/is-me/</h3>

      <input tepe='text' value={selectedToken} onChange={handleChange} placeholder='token'></input>
      {/* or select <select value={selectedToken} onChange={handleChange}>
        {tokens.map((token) => (
          <option key={token} value={token}>{token}</option>
        ))}
      </select>  */}
      <button onClick={handleSubmit}>Set Is Me</button>
    </div>
  );
};


