import React, { useContext, useEffect, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Button } from '../UiKit/Button';


export const EditName = ({ name, desription, placeholder = '', handleUpdate = () => void (0) }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [title, setTitle] = useState(name ? name : '');
  const [desriptionI, setDescription] = useState(desription ? desription : '');
  const inputName = useRef(null);

  useEffect(() => {
    if (name !== title) {
      setTitle(name)
    }
  }, [name])

  useEffect(() => {
    if (desription !== desriptionI) {
      setDescription(desription)
    }
  }, [desription])

  const handleSave = () => {
    if ((title !== name || desriptionI !== desription)) {
      handleUpdate(title, desriptionI)
    }
  };



  return (<>


    <div className="_fv">

      <div className="_f">
      <div className="x_calc_form0 _f100 _fstretch">

          <input
            ref={inputName}
            placeholder={placeholder ? placeholder : $ui('enter_name')}
            type="text"
            className="x_input x_input--bordered"
            onFocus={(e) => e.target.select()}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />

          {/* <div className="x_input_right">
  <div className="x_input_action" onClick={() => {
    setTitle(data.name)
    inputName.current.focus()
  }}>
    <div className="t_icon t_icon--40">
      edit
    </div>
  </div>
</div> */}

        </div>
        <div className="_fr _fstretch">
          <Button
            className='_fstretch'
            icon={!context.updatingAva ? 'check' : 'pending'}
            disabled={!title || context.updatingAva || (title === name && desriptionI === desription)}
            inactive={title === name && desriptionI === desription}
            onClick={() =>
              !(context.updatingAva || (title === name && desriptionI === desription)) && handleSave()
            }
          >
            {/* {!context.updatingAva && <div>
{$ui('process_save')}
</div>} */}
            {/* {context.updatingAva && <div>{$ui('process_saving')}...</div>} */}
          </Button>
        </div>


      </div>

      {desription && <textarea
        rows={3}
        cols={50}
        ref={inputName}
        placeholder={''}
        type="text"
        className="x_input x_input--bordered"
        onFocus={(e) => e.target.select()}
        onChange={(e) => setDescription(e.target.value)}
        value={desriptionI}
      />}


    </div>
  </>)
}