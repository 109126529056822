import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { prepareGates } from '../utilsDesign';
import GateNumber from './GateNumber';
import { Txt } from './Txt';
import { Videos } from './Videos';



export const GateInfo = ({
  hexagram,
  number,
  design = {},
  gateId = '',
  title = true,
  desctiption = true,
  videos = false
}) => {

  const context = { ...useContext(LangContext), ...useContext(SocialContext) };
  const $ui = context.$ui;
  const $gate = context.$gate;

  const designGates = design ? design['gates'] : [];


  let prsDes;
  const prsD = prepareGates(design.gates, 'des')[gateId]
  const prsP = prepareGates(design.gates, 'prs')[gateId]

  if (prsD && prsP) prsDes = 'prsdes'
  if (prsD && !prsP) prsDes = 'des'
  if (!prsD && prsP) prsDes = 'prs'



  return (<>
    {context.languageReady &&

      <div className="_fv _fgap04">

      {(title || hexagram || number) && <div className="x_edu_channel_title">

        {number && <>
            <GateNumber design={design} gateId={gateId} />
        </>}


        <span className="x_edu_channel_h1 x_edu_channel_h1--2">
          {hexagram && <span className="x_gate_title_hex">
              {$gate(gateId, 'hexagram')} </span>}
            {title && <><span className="x_edu_channel_of _tnowrap">{$ui('gate_of')}</span>  {$gate(gateId, 'title')}</>}
        </span>

      </div>}

      {desctiption && <div className="x_edu_channel_desc">
        <Txt>{$gate(gateId, 'description')}</Txt>
      </div>}

      {videos && <Videos
        title={`${$gate(gateId, 'id')}. ${$gate(gateId, 'title')}`}
        size='sm'
        relType='gates'
        relValue={gateId.toString()} />}
      </div>




    }

  </>)
}