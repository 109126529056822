export const RadioButtonGroup = ({ options, selectedValue, onChange }) => {
  return (
    <div className="x_settings_ul expanded">
      {options.map((option) => (
        <div className="x_settings_li" key={option.value}>
          <div
            className={`x_settings_action ${selectedValue === option.value ? 'active' : ''}`}
            onClick={() => onChange(option.value)}
          >
            <div className="t_icon">
              <div>{option.icon}</div>
            </div>
            <div>
              <div className="x_choose_filter_title">{option.label}</div>
            </div>
            <div className="x_settings_right">
              <div className={`x_settings_check ${selectedValue === option.value ? 'active' : ''}`}>
                <div className="t_icon t_icon--20">
                  {selectedValue === option.value ? 'radio_button_checked' : 'radio_button_unchecked'}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};