import React, { useContext, useEffect, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { default_design } from '../../static/default_design';


export function Guide({
  person = default_design,
  narrative,
  // activeLink,
  scrollToSection
}) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;

  const [activeLink, setActiveLink] = useState('calculate');
  const [guideShown, setGuideShown] = useState(true);
  const guideRef = useRef(null);

  const [scrollTo, setScrollTo] = useState(-1);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (guideRef.current && !guideRef.current.contains(event.target)) {
        setGuideShown(false);
      }
    };
    // document.addEventListener('mouseover', handleClickOutside);
    return () => {
      // document.removeEventListener('mouseover', handleClickOutside);
    };
  }, [guideRef]);



  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries.find((entry) => entry.isIntersecting);
        if (entry) {
          setActiveLink(entry.target.id);
          // context.handles.setActivePage(entry.target.id);
        }
      },
      { rootMargin: "-80% 0px -80% 0px" } // Change this to adjust when the links get highlighted
    );

    const sections = document.querySelectorAll(".x_edu_content0");

    sections.forEach((section, index) => {
      observer.observe(section);
      // if (index === 0) {
      //   context.handles.setFirstPage('page--first')
      // }
      // else {
      //   context.handles.setFirstPage('')
      // }
    });

    const targetSection = sections[scrollTo]; // Zero-based index

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [narrative]);








  return (
    <>

      <div className="x_guide00">

        {/* <div className="x_gude_z" /> */}

          <div className="x_guide0" ref={guideRef}>



            {!guideShown && <div className="x_guide_trigger0"
              onClick={() => setGuideShown(!guideShown)}
            >
              <div className="x_guide_trigger">
                <div className="t_icon t_icon--20">
                  <div>menu</div>
                </div>
              </div>
            </div>}


            <div className={guideShown ? "x_guide active" : "x_guide"}>

              {/* <Switch
                preLabel={'Pro'}
                label={'Beginner'}
                active={context.state.narrativeBeta}
                handle={() => context.setState.setNarrativeBeta(!context.state['narrativeBeta'])}
              /> */}



              <div className="x_theory_1 active"
              >
                {/* <div className="x_theo_h1">
                <div>Fundamentals</div>
              </div> */}

                <div className="x_theo_ul">

                {narrative && narrative.pages &&
                  narrative.pages
                    .filter(one => $('title', one))
                    .map(one => {
                  let oneId = one.id;
                    return (<div key={`nav_${oneId}_${1}`}>

                      {one['component'] && $('title', one) &&
                        <div className="x_theo_li active"

                          onClick={() => oneId ? scrollToSection(oneId) : void (0)}
                        >
                          {$('title', one)}

                          {activeLink === oneId && <div className="x_theo_active">
                            {/* <div className="t_icon-2 t_icon--28">
                            <div>chevron_right</div>
                          </div> */}
                          </div>}

                        </div>}

                      {!one['component'] &&
                        <div className="x_theo_li "
                          onClick={() => oneId ? scrollToSection(oneId) : void (0)}
                        >
                          {$('title', one)}

                          {activeLink === oneId && <div className="x_theo_active">
                            {/* <div className="t_icon-2 t_icon--28">
                            <div>chevron_right</div>
                          </div> */}
                          </div>}

                        </div>}

                    </div>)
                  })}



                  {/*
                <div className="x_theo_li blocked">
                  <div>Gates, Channels, Circuits</div>
                </div>
                <div className="x_theo_li blocked">
                  <div>Personality &amp;&nbsp;Body</div>
                </div>
                <div className="x_theo_li blocked">
                  <div>Mind / brain modality</div>
                </div>
                <div className="x_theo_li blocked">
                  <div>Ideal environment &amp;&nbsp;Nutrition</div>
                </div> */}
                </div>


              </div>

              {/* <div className="x_theory_1">
              <div className="x_theo_h1">
                <div>Live your design</div>
              </div>
              <div className="x_theo_ul">
                <div className="x_theo_li">
                  <div>Hear your authority</div>
                </div>
                <div className="x_theo_li">
                  <div>realize your hi-level life narrative (incarnarion cross)</div>
                </div>
                <div className="x_theo_li">
                  <div>Watch your definition</div>
                </div>
                <div className="x_theo_li">
                  <div>Planetary transits</div>
                </div>
              </div>
            </div> */}

              {/* <div className="x_theory_1">
              <div className="x_theo_h1">
                <div>Interaction &amp;&nbsp;Conditioning</div>
              </div>
              <div className="x_theo_ul">
                <div className="x_theo_li">
                  <div>
                    <div>Parental programs</div>
                  </div>
                </div>
                <div className="x_theo_li">
                  <div>Romantic relationships</div>
                </div>
                <div className="x_theo_li">
                  <div>Friendly relationships</div>
                </div>
                <div className="x_theo_li">
                  <div>Work relationships</div>
                </div>
              </div>
            </div> */}



            </div>

          </div>









      </div>
    </>
  )
}