import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { TooltipH } from './TooltipH';

export function IconColor({
  title = '',
  color = '',
}) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $vars = context.$vars;
  return (
    <TooltipH title={title}>
      <div className="x_phs_col">
        <div className="x_phs_col_ico w-embed">
          <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 26.3043C20.2432 26.3043 25.3043 21.2432 25.3043 15C25.3043 8.75678 20.2432 3.69565 14 3.69565C7.75678 3.69565 2.69565 8.75678 2.69565 15C2.69565 21.2432 7.75678 26.3043 14 26.3043ZM14 28C21.1797 28 27 22.1797 27 15C27 7.8203 21.1797 2 14 2C6.8203 2 1 7.8203 1 15C1 22.1797 6.8203 28 14 28Z" fill="currentColor" />
          </svg>
        </div>
        <div>{color}</div>
      </div>
    </TooltipH>
  )
}

export function IconTone({
  title = '',
  tone = '',
}) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $vars = context.$vars;
  return (
    <TooltipH title={title}>
      <div className="x_phs_col">
        <div className="x_phs_col_ico w-embed">
          <svg width={30} height={29} viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2387_145037)">
              <path fillRule="evenodd" clipRule="evenodd" d="M3.36896 25.981C1.51463 25.981 0.372403 23.9545 1.33258 22.3681L13.0225 2.48446C13.9602 0.935309 16.2156 0.958888 17.1206 2.5273L28.9964 22.4109C29.9121 23.9978 28.7668 25.981 26.9347 25.981L3.36896 25.981ZM2.69017 23.1898C2.37011 23.7186 2.75085 24.3941 3.36896 24.3941L26.9347 24.3941C27.5454 24.3941 27.9272 23.733 27.6219 23.2041L15.7462 3.32044C15.4445 2.79764 14.6927 2.78977 14.3801 3.30616L2.69017 23.1898Z" fill="currentColor" />
            </g>
            <defs>
              <clipPath id="clip0_2387_145037">
                <rect width="28.3294" height="28.3294" fill="currentColor" transform="translate(0.835449 0.670654)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>{tone}</div>
      </div>
    </TooltipH>
  )
}

export function IconBase({
  title = '',
  base = '',
}) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $vars = context.$vars;
  return (
    <TooltipH title={title}>
      <div className="x_phs_col"><div className="x_phs_col_ico w-embed">
        <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2387_145041)">
            <path fillRule="evenodd" clipRule="evenodd" d="M25.4999 12.0001C25.7601 12.2161 25.8591 12.5707 25.7485 12.8902L21.2671 24.3972C21.1539 24.7242 20.846 24.9435 20.5 24.9435L10 25.0575C9.64127 25.0575 9.32507 24.822 9.22228 24.4783L4.5573 13.0179C4.46536 12.7105 4.58421 12.3716 4.82789 12.1628L14.8298 2.68305C15.1301 2.42572 15.5721 2.42228 15.8764 2.6749L25.4999 12.0001ZM27.2827 13.4213C27.6145 12.4627 27.3174 11.3989 26.5369 10.751L16.9135 1.42577C16.4449 1.0368 15.8675 0.85018 15.2946 0.86504C14.7513 0.879133 14.212 1.07444 13.7734 1.45022L3.74556 10.9136C3.01451 11.54 2.72383 12.5777 2.99967 13.5L7.66685 24.9435C7.97521 25.9745 8.9238 26.681 10 26.681L20.5 26.567C21.5379 26.567 22.4618 25.9091 22.8013 24.9283L27.2827 13.4213Z" fill="currentColor" />
          </g>
          <defs>
            <clipPath id="clip0_2387_145041">
              <rect width="28.3294" height="28.3294" fill="currentColor" transform="translate(0.670898 0.670654)" />
            </clipPath>
          </defs>
        </svg>
      </div>
        <div>{base}</div>
      </div>
    </TooltipH>
  )
}

