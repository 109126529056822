import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/stateContext';
import { types_dict } from '../../static/types_dictionary';
import { drawProfile, drawType } from '../utilsDesign';
import { getPentaDesign } from '../utilsPenta';
import { Preloader } from './kit';
import { TooltipH } from './TooltipH';


export function Avatar(props) {

  const {
    user,
    avatar = {},
    penta = {},
    // type = 'calc',
    type,
    composite,
    style = {},
    className,
    active,
    onClick,
    onClickOpen = true,
    tooltip = { 'name': true, 'type': true, 'profile': true },
    noFace
  } = { ...props }

  const context = { ...useContext(StateContext), ...useContext(StateContext) };

  // console.log('avatar = ', avatar);
  const design = avatar.design ? avatar.design : penta ? getPentaDesign(penta) : {};
  // design = design || avatar;
  // if (!design) design = {}

  const getTypeClass = (design) => {
    if (!design) return ''
    return design['type'] ? design['type'].toLowerCase() : ''
  }
  const getTypeColor = (design) => {
    if (!design) return ''
    return design['type'] ? types_dict[design['type']]['bgColor'] : ''
  }

  return (
    <>
      {tooltip
        && avatar.design
        && <TooltipH title={<>{tooltip.name && <b>{avatar.name}</b>} {(tooltip.type || tooltip.profile) && <br />} {tooltip.type && drawType(avatar.design.type, 'compact')} {tooltip.profile && drawProfile(design['profile'])}</>}>
          <div className=""
            style={{
              cursor: (onClickOpen || onClick) ? 'pointer' : 'inherit',
            }}
          >
            <AvatarCircle {...props} />
          </div>
        </TooltipH>
      }
      {!tooltip && <AvatarCircle {...props} />}
    </>
  )
}


export function AvatarCircle({
  user,
  avatar = {},
  penta = {},
  type,
  // type = 'calc',
  composite,
  style = {},
  className,
  active,
  onClick,
  onClickOpen,
  noFace
}) {
  const context = { ...useContext(StateContext), ...useContext(StateContext) };

  // console.log('avatar = ', avatar);
  const design = avatar.design ? avatar.design : penta ? getPentaDesign(penta) : {};
  // design = design || avatar;
  // if (!design) design = {}

  const getTypeClass = (design) => {
    if (!design) return ''
    return design['type'] ? design['type'].toLowerCase() : ''
  }
  const getTypeColor = (design) => {
    if (!design) return ''
    return design['type'] ? types_dict[design['type']]['bgColor'] : ''
  }
  const navigate = useNavigate();

  return (
    <>
      {/* <div className={`x_user_ava ${active ? 'active' : ''}   ${getTypeClass(design)} ${Boolean(className) ? className : ''}`} */}
      <div className={`x_user_ava ${active ? 'active' : ''}  x_user_ava--${type} ${getTypeClass(design)} ${Boolean(className) ? className : ''}`}
        style={{
          backgroundColor: getTypeColor(design),
          borderWidth: '0.07em',
          ...style
        }}
        onClick={() => {
          onClick && onClick();
          onClickOpen && navigate(`/u/${user ? user.token : avatar.token}`);
        }}
      >
        {!noFace && !context.state.hideNames && Boolean(avatar.image) && <div className="x_user_ava_img"
          style={{
            backgroundImage: `url(${avatar.image})`,
            // backgroundImage: `url(${avatar.image}?t=${new Date().getTime()})`,
            ...style,
          }}
        />}


        {!avatar.design && !penta && <Preloader />}

        {avatar.design && <div className="x_user_ava_type"
          style={{ textDecoration: 'none' }}
        >{drawProfile(design.profile)}</div>}

        {penta.members && <div className="t_icon"
          style={{ textDecoration: 'none' }}
        >group</div>}
      </div>
    </>
  )
}