import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { prepareGates } from '../utilsDesign';

function GateNumber(props) {

  const {
    design,
    gateId,
    style = {},
    className = '',
    onClick = () => void (0),
    onMouseEnter = () => void (0),
    onMouseOut = () => void (0),
  } = { ...props }

  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;
  const $gate = context.$gate;

  let prsDes;
  const prsD = prepareGates(design.gates, 'des')[gateId]
  const prsP = prepareGates(design.gates, 'prs')[gateId]

  if (prsD && prsP) prsDes = 'prsdes'
  if (prsD && !prsP) prsDes = 'des'
  if (!prsD && prsP) prsDes = 'prs'

  return (
    <span
      style={{ ...style }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      className={`x_gate_title_num0 ${className}`}
    >
      <span className="x_edu_channel_nums">
      <span className={`x_gate_title_num x_gate_title_num--${prsDes}`}>
        {prsDes === 'prsdes' && <>
          <span className="x_gate_title_num_prs" />
          <span className="x_gate_title_num_des" />
        </>}
        {$gate(gateId, 'id')}
      </span>
      </span>
    </span>
  )
}

export default GateNumber
