import React from 'react';

export function Hr({ nogap, gap = 0, transparent, style = {} }) {
  const st = {}
  if (transparent) st.borderBottomColor = 'transparent';
  return (
    <>
      <div className="hr"
        style={{
          ...st,
          width: '100%', margin: nogap || gap ? `${gap}em` : '1em 0',
          ...style,
        }}
      />
    </>
  )
}