import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';

// Define keyframes for the animation
const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  50% {
    opacity: 0.8;
    transform: scale(2, 2);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
`;

// Create a styled component for the div
const StyledDiv = styled.div`
  opacity: 0;
  ${props => props.triggerAnimation && css`
    animation: ${fadeInOut} 1200ms forwards;
  `}
`;
const StyledDiv0 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function X({ value, children }) {
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const prevValueRef = useRef(null); // Ref to store the previous value

  useEffect(() => {
    // Check if the previous value is not null and the current value is different
    if (prevValueRef.current !== null && value !== null && prevValueRef.current !== value) {
      setTriggerAnimation(true);
      const timer = setTimeout(() => {
        setTriggerAnimation(false);
      }, 300); // Reset after animation duration

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }

    // Update the previous value ref
    prevValueRef.current = value;
  }, [value]);

  return (
    <StyledDiv0>
      {children}
      <StyledDiv triggerAnimation={triggerAnimation} className="x_x" />
    </StyledDiv0>
  );
}
