import React, { useContext, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { default_design } from '../../static/default_design';
import { PentaBody } from '../Penta/PentaBody';
import { PentaStats } from '../Penta/PentaStats';
import { Background } from '../UiKit/Background';
import { PentaPanel } from '../UiKit/PentaPanel';
import { Preview } from '../UiKit/Preview';
import { getPentaDesign } from '../utilsPenta';

export const PentaFirst = React.memo((props) => {
  const { penta, id, user, design, avatar, children, loading } = { ...props };

  // const [preview, setPreview] = useState(0);
  const [preview, setPreview] = useState('comp');


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $type = context.$type;
  const $profile = context.$profile;


  const bodyContainer = useRef(null);
  const parentContainer1 = useRef(null);
  const parentContainer2 = useRef(null);






  // const design1 = penta.members[0]
  //   && penta.members[0].avatar
  //   && penta.members[0].avatar.design.type
  //   ? penta.members[0].avatar.design : parseNewDesign(default_design);

  // const design2 = penta.members[1]
  //   && penta.members[1].avatar
  //   && penta.members[1].avatar.design.type
  //   ? penta.members[1].avatar.design : parseNewDesign(default_design);

  const design1 = penta.members[0] ? penta.members[0].avatar.design : default_design;
  // console.log('design1 = ', design1);
  const design2 = penta.members[1] ? penta.members[1].avatar.design : default_design;
  // console.log('design2 = ', design2);


  const profileId1 = design1.profile && design1.profile.length ? `${design1['profile'][0]}${design1['profile'][1]}` : '';
  const profileId2 = design2.profile && design2.profile.length ? `${design2['profile'][0]}${design2['profile'][1]}` : '';


  const pentaDesign = getPentaDesign(penta);

  return (
    <>
      <div className="x_edu_content0" >
        <div className="x_edu_content">

          <div className="_fv _fgap2 _f100">


            {penta.members.length > 0 && <div className="x_edu_penta0">



            <div className="x_edu_ava1">
                {penta.members[0] && <PentaPanel member={penta.members[0]} />}
              <div className="x_edu_ava1_body">
                <div className={"x_click x_click--penta"} style={{ zIndex: 4 }}
                  onClick={() => setPreview((prev) => prev === 0 ? 'comp' : 0)}
                  onMouseEnter={() => setPreview(0)}
                  onMouseOut={() => setPreview('comp')}
                />
                <div className="x_edu_ava1_bodygraph" ref={parentContainer1}>
                  <Preview
                    parentContainer={parentContainer1}
                    design={design1}
                    aura={false}
                  />
                </div>
                <Background design={design1}></Background>
              </div>

              <div className="x_penta_typeprofile">
                  {$type(design1['type'], 'title_compact')} {$profile(profileId1, 'profile')}<br />"{$profile(profileId1, 'title')}"
              </div>
            </div>


            <div className="x_edu_penta_preview" >
              <div className="x_body_content" ref={bodyContainer}>
                <PentaBody
                  planets={true}
                  preview={preview}
                  setPreview={setPreview}
                  numbers={true}
                    penta={penta}
                  parentContainer={bodyContainer}
                  aura={false}
                />
              </div>
            </div>



            {penta.members[1] && <div className="x_edu_ava1">
                <PentaPanel member={penta.members[1]} />
              <div className="x_edu_ava1_body">


                <div className={"x_click x_click--penta"} style={{ zIndex: 4 }}
                  onClick={() => setPreview((prev) => prev === 1 ? 'comp' : 1)}
                  onMouseEnter={() => setPreview(1)}
                  onMouseOut={() => setPreview('comp')}
                />
                <div className="x_edu_ava1_bodygraph" ref={parentContainer2}>
                  <Preview
                    // aura={false}
                    // width={180}
                    // height={200}
                    parentContainer={parentContainer2}
                    design={design2}
                    aura={false}
                  />
                </div>
                <Background design={design2}></Background>
              </div>
              <div className="x_penta_typeprofile">
                  {$type(design2['type'], 'title_compact')} {$profile(profileId2, 'profile')}<br />"{$profile(profileId2, 'title')}"
              </div>
            </div>}




            {!penta.members[1] && <div className="x_edu_ava1">
              <PentaPanel />
              <div className="x_edu_ava1_body">
                <div className="x_penta_slot x_penta_slot--main x_penta_slot--empty"
                  onClick={() => context.setState.showPeoplePanel(['pentas'])}
                ><div className="t_icon t_icon--40"><div>add</div></div>
                </div>
              </div>

              <div className="x_penta_typeprofile">

                {/* {$type(design2['type'], 'title')} {$profile(profileId2, 'profile')}  "{$profile(profileId2, 'title')}" */}
              </div>
            </div>}


          </div>}


          <div className="_f _fv _fm">
            <div className="x_edu_content_h x_edu_content_h--3" style={{ textAlign: 'center' }}>
              {$ui('penta_composite')}: "{$type(pentaDesign['type'], 'title')}"
            </div>
            <div className="x_edu_content_h x_edu_content_h--3">
                <PentaStats penta={penta} size={30} />
            </div>
          </div>

          </div>

        </div>

        {penta && <Background
          design={getPentaDesign(penta)}
        />}

      </div>





    </>
  )
})




