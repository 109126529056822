import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { MessageContext } from '../contexts/messageContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Avatar } from './UiKit/Avatar';
import { AvatarName } from './UiKit/AvatarName';
import { Button } from './UiKit/Button';
import { EditName } from './UiKit/EditName';
import { PopoverH, TooltipH } from './UiKit/TooltipH';
import { renderLogo } from './UiKit/Topbar';

export function GroupInfo(props) {

  const {
    group,
    updateGroup = () => void (0),
    invited = [],
    members = [],
    admins = [],
    myRequests = [],
    setGroupBrowser,
    requestToJoin = () => void (0),
    fetchMembers,
    fetchMyRequests
  } = { ...props }

  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;

  const imAdmin = Boolean(admins.filter(u => u.user.token === context.me.user.token).length);

  const imThere = members.filter(one => one.user.token === context.me.user.token).length
    ? true : false;
  const iRequested = myRequests.filter(one => one.token === group.token).length
    ? true : false;
  console.log('myRequests = ', myRequests);
  console.log('iRequested = ', iRequested);



  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <>





      <div className="x_group_top">
        <div className="x_group_title0">
          <div className="_fm _fwrap">

          <div className="x_group_title">
            {group.name}
          </div>

            {
              imThere
              && !iRequested
              && <PopoverH
                open={open}
                onOpenChange={handleOpenChange}
                title={<>
                  <GroupDropDown
                    imAdmin={imAdmin}
                    iRequested={iRequested}
                    hide={hide}
                    {...props}
                  />
                </>}>
                <div >
                    <Button ghost iconAfter='more_vert' sm>{imAdmin && $ui('group_admin_title')}</Button>
                </div>
              </PopoverH>}

            {
              !imThere
              && iRequested
              && <PopoverH
                open={open}
                onOpenChange={handleOpenChange}
                title={<>
                  <GroupDropDown
                    imAdmin={imAdmin}
                    iRequested={iRequested}
                    hide={hide}
                    {...props}
                  />
                </>}>
                <div >
                    <Button ghost iconAfter='more_vert' sm>{$ui('group_requested')}</Button>
                </div>
              </PopoverH>}


            {!imThere && !iRequested && <Button
              onClick={() => requestToJoin(group.token)}
            >{$ui('group_askinto')}</Button>}

          </div>
          <div className="x_group_description">
            {group.description}
          </div>

          <div className="_fm">

            <div className="_fm">
          <div className="x_group_users">
            {members.map(one => <div className="x_group_user1 _fcc" key={one.user.token}>
              <Avatar avatar={one.avatar} style={{ fontSize: '1.2em' }} />
            </div>)}
              </div>


            <div className="x_group_quantity">
                {$ui('group_users', null, group.count)}: {group.count}
            </div>
            </div>


            <div className="x_group_users_right">

              <div className="_f _fm">

                {$ui('group_admin', null, admins.length)}:

                <div className="x_group_users">
                {admins.map(one => <div className="x_group_user1 _fcc" key={one.user.token}>
                  <Avatar avatar={one.avatar} style={{ fontSize: '1.2em' }} />
                </div>)}
                </div>

                <Button
                  icon='mail'
                  ghost
                  onClick={() => messageContext.setCurrentChat(admins[0].user.token)}
                >
                </Button>

              </div>
            </div>
          </div>

        </div>
        <div className="x0_logo x0_logo--group">

            <div className={`x_logo`}
              onClick={() => {
                context.me ? navigate(`/u/${context.me.user.token}`) : navigate('/')
              }}
            >
              HD
              <div className="hd_logo_img w-embed">
                {renderLogo()}
              </div>

              Cafe
            </div>
            {/* <PeopleBar /> */}







        </div>
      </div>
















    </>
  )
}


const GroupDropDown = ({
  group,
  imAdmin,
  invited,
  iRequested,
  setGroupBrowser,
  hide,
  updateGroup,
  fetchMembers,
  fetchMyRequests,
}) => {

  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;

  return (<>

    {!imAdmin && !iRequested && <Button red icon='delete'
      onClick={() => context.x.leaveGroup(group.token, () => {
        fetchMembers();
        fetchMyRequests();
      })}
    >{$ui('group_leave')}
    </Button>}

    {!imAdmin && iRequested && <Button red icon='delete'
      onClick={() => context.x.leaveGroup(group.token, () => {
        fetchMembers();
        fetchMyRequests();
      })}
    >{$ui('group_askinto_cancel')}
    </Button>}

    {imAdmin && <>
        <div className="x_groupadmin0 _f100 _fgap1">
          <div className="_f _f100">
            {/* <div className="x_choose_section_title x_choose_section_title--v">
                {$ui('group_admin_title')}
                </div> */}

            <GroupInvited invited={invited}>
              <Button
                icon='person_add'
              onClick={() => {
                setGroupBrowser(group.token)
                hide()
              }}
              >
                {$ui('group_invite_user')}
              </Button>

            </GroupInvited>


            <div className="_f100 _fr">


              <PopoverH
                title={<EditName
                  name={group.name}
                desription={group.description ? group.description : ' '}
                  // placeholder='Set group name'
                  placement='bottom'
                handleUpdate={(newName, newDescription) => {
                  updateGroup({
                      'token': group.token,
                      'name': newName,
                    'description': newDescription
                    })
                  }}
                />}>
                <div >
                  <Button
                    ghost
                    onClick={() => void (0)}
                  >{$ui('group_edit')}</Button>
                </div>
              </PopoverH>

              <PopoverH
                title={<>
                  <Button red icon='delete'
                  onClick={() => {
                    context.x.deleteGroup(group.token);
                    navigate('/');
                  }}
                  >{$ui('group_delete')}</Button>
                </>}>
                <div >
                  <Button ghost red icon='delete'></Button>
                </div>
              </PopoverH>
            </div>


          </div>


        </div>
      </>}



  </>)
}


const GroupInvited = ({ invited = [], children }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const navigate = useNavigate();
  return (

    <div className="_fv _f100 ">
      <div className="_tnowrap">
        {$ui('group_invited')}:
      </div>
      <div className={`_f _fgap06 _fwrap _fm _f100`} style={{

      }}>
        {invited
          .map(one => {
            return (<TooltipH
              key={one.user.token}
              title={<AvatarName avatar={one.avatar}
              />}>
              <div className="_fv _fm _fgap04 _click _fnogrow"
                style={{ marginBottom: '-0.8em' }}
                key={`${one.user.token}`}
                onClick={() => navigate(`/u/${one.user.token}`)}
              >
                <Avatar avatar={one.avatar} style={{ fontSize: '0.8em' }} />
                <div style={{
                  width: invited.length > 3 ? '4.6em' : '7em',
                  height: '2em',
                  fontSize: '0.8em',
                  fontWeight: '600',
                  marginRight: '-0.4em'
                }} className="ellipsis">
                  <AvatarName avatar={one.avatar} />
                </div>
              </div>
            </TooltipH>)
          })}
        {children}
      </div>

    </div>

  )
}