import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { channels_dictionary } from '../../static/channels_dictionary';
import { findGateInUser, handleChannelClick } from '../utilsDesign';
import { Avatar } from './Avatar';
import { AvatarName } from './AvatarName';
import { GateAllActivations } from './GateAllActivations';
import GateNumber from './GateNumber';
import { Txt } from './Txt';
import { Videos } from './Videos';
import { VideoIco } from './Variants';


export const Channel = React.memo((props) => {


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $channel = context.$channel;

  const {
    uniq = '',
    id,
    label = false,
    centers = [],
    circuit = '',
    type = [],
    description,
    gates = [],
    title = '',
    design = {},
    stream = '',
    activatedWith = {},
    className,
    owner,
    penta,
    showActivations,
    videos = 'inline', //'ico',
    view = 'column' //'line'
  } = { ...props };



  const location = useLocation();
  const navigate = useNavigate();

  const handleChannelClickInner = (channelId) => {
    const centers = channels_dictionary.filter(one => one.id === channelId)[0].centers;
    const gates = channels_dictionary.filter(one => one.id === channelId)[0].gates;
    context.setState.setHighlighted([...centers]);
    context.setState.setSearch([...gates]);
    handleChannelClick(channelId, location, navigate);
  };


  // const gates = design.gates ;

  return (
    <>



      <div className={`x_edu_chan ${className ? className : ''}`}

        onClick={(e) => {
          e.stopPropagation();
          // if (!penta) {
          handleChannelClickInner(id)
          // }
        }}
      >



        <div className="x_edu_channel_title00">




          {label && <><div className="x_edu_content_label">
            {$ui('channel')}
          </div></>}



          <div
            className={location.pathname.includes(`channel:${id}`) ? "x_edu_channel_info active" : "x_edu_channel_info"}
          // className={location.pathname.includes(`channel:${id}`) ? "x_edu_channel_info active" : "x_edu_channel_info"}
          >

            <div className={`x_edu_channel_info_inner _fv _fgap1 ${view === 'line' ? 'x_edu_channel_info_inner--line' : ''}`}>

              {/* <div className="x_edu_channel_more"><div className="t_icon t_icon--60">
              {!shown && <div>arrow_drop_down</div>}
              {shown && <div>arrow_drop_up</div>}
            </div></div> */}


              <div className="x_edu_channel_info_top">

            <div className="x_edu_channel_title0">

              {/* <span className="x_edu_channel_nums">
                <GateNumber design={design} gateId={gates[0]} />
                <GateNumber design={design} gateId={gates[1]} />
              </span> */}


              <div className="x_edu_channel_title">
                <div className="x_edu_channel_nums">
                  <GateNumber design={design} gateId={gates[0]} />
                  <GateNumber design={design} gateId={gates[1]} />
                </div>
                <span className="x_edu_channel_h1 x_edu_channel_of _tnowrap">{$ui('channel_of')}</span> <span className='x_edu_channel_h1 _tnowrap'>{$channel(id, 'title')}</span>

                    {videos === 'ico' && <VideoIco>
                      <Videos
                        title={`${gates[0]}–${gates[1]} ${$channel(id, 'title')}`}
                        size='sm'
                        relType='channels'
                        relValue={id}
                      />
                    </VideoIco>}


              </div>


            </div>


            <div className="x_edu_channel_desc">
              <Txt>{$channel(id, 'description')}</Txt>
            </div>


              </div>



            {/* <div className="x_edu_channel_info_more0"
              onClick={() => setShown(!shown)}
            >
              <div className="t_icon t_icon--20">
                {!shown && <div>expand_more</div>}
                {shown && <div>expand_less</div>}
              </div>
            </div> */}


              {design && design.gates && showActivations && <div className={`x_edu_activatedby ${view === 'line' ? 'x_edu_activatedby--line' : ''} inactive`}>
              <div>
                <GateAllActivations
                  number={true}
                  hexagram={true}
                  design={design}
                  gateId={gates[0]} />
              </div>
              <div>
                <GateAllActivations
                  number={true}
                  hexagram={true}
                  design={design}
                  gateId={gates[1]} />
              </div>
            </div>}

              {videos === 'inline' && <Videos
                title={`${gates[0]}–${gates[1]} ${$channel(id, 'title')}`}
                size='sm'
                relType='channels'
                relValue={id}
              />}

              {penta && <div className={`x_edu_activatedby ${view === 'line' ? 'x_edu_activatedby--line' : ''} inactive`}>

                <div className="_f _fwrap _fgap2v">

                  {penta.members.map(one => <div className="_f _fgrow _fv" key={`channel_${one.avatar.token}`}>

                  {(Boolean(findGateInUser(one.avatar.design.gates, gates[0]).length)
                    || Boolean(findGateInUser(one.avatar.design.gates, gates[1]).length))
                    && <>

                    {(Boolean(findGateInUser(one.avatar.design.gates, gates[0]).length) ||
                      Boolean(findGateInUser(one.avatar.design.gates, gates[1]).length))
                        && <div
                          className={`x_ava_inline_name ellipsis x_ava_inline_name--${one.avatar.design.type.toLowerCase()}`}
                        >
                          {/* <Avatar avatar={one.avatar} style={{ fontSize: '1em' }} /> */}
                        <div className='x_avatar_1line'><AvatarName avatar={one.avatar} /></div>
                      </div>}


                    {gates.map((oneGateId, index) => {
                      if (Boolean(findGateInUser(one.avatar.design.gates, oneGateId).length)) return <div
                        key={`gate_${oneGateId}`}
                        className="_f _fv "
                      >


                        {one.avatar.design && one.avatar.design.gates && <GateAllActivations
                          number={true}
                          hexagram={true}
                          design={one.avatar.design}
                          gateId={oneGateId}
                        />}
                      </div>
                    })}
                    </>
                  }


                </div>)}




              </div></div>}




          </div>
          </div>


        </div>







        {/* <div className={showActivations ? "x_edu_planets active" : "x_edu_planets"}>
          <div className={showActivations ? "x_edu_planets_inner active" : "x_edu_planets_inner"}>


            <div className="x_edu_activations_type">


              <ChannelTypeInfo
                type={type}
                circuit={circuit}
                stream={stream}
              />


              <Videos
                title={`${gates[0]}–${gates[1]} ${$channel(id, 'title')}`}
                size='sm'
                relType='channels'
                relValue={id} />

            </div>






            <div className="x_edu_labels">
            <div className="x_edu_planets_title">
              <div>Center</div>
            </div>
            <div className="x_edu_gate_label">
              <div>Gate</div>
            </div>
            <div className="x_edu_gate_label">
              <div>Defined by</div>
            </div>
            </div>
            {gates[0] && gates[0]['planet'] && <div className="x_edu_pl1">
              <div className="x_edu_planets_title">
                {getCenter([centers[0]], 'title')}
              </div>
              <GateOne gateNumber={gates[0]} design={design} shown={shown} uniq={`${uniq}`} />

            </div>}
            {gates[1] && gates[1]['planet'] && <div className="x_edu_pl1">
              <div className="x_edu_planets_title">
                {getCenter([centers[1]], 'title')}
              </div>
              <GateOne gateNumber={gates[1]} design={design} shown={shown} uniq={`${uniq}`} />
            </div>}
          </div>
        </div> */}
      </div>


    </>
  );
});



export function ChannelTypeInfo({
  type = [],
  circuit = '',
  stream = ''
}) {

  const uniq = ''

  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  return (
    <>
      <div>
        {$ui('type')}: <strong>{type.map((oneType, ind, allTypes) => {
          if (allTypes.length > 1) {
            return ind !== allTypes.length - 1 ? <span
              key={`${oneType}_${ind}_${uniq}`}
            >{$ui(oneType)}, </span> :
              <span
                key={`${oneType}_${ind}_${uniq}`}
              >{$ui(oneType)}</span>;
          } else {
            return <span
              key={`${oneType}_${ind}_${uniq}`}
            >{$ui(oneType)}</span>;
          }
        })}</strong>
      </div>
      <div className=''>{$ui('circuit')}: <strong>{$ui(circuit)}</strong></div>
      <div className=''>{$ui('sub_circuit')}: <strong>{$ui(stream)}</strong></div>
    </>
  )
}

export default Channel
