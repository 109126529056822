import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { _getNewDesign, parseNewDesign } from './utilsDesign';


export function WithRectification(props) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };

  const [avatar, setAvatar] = useState(props.avatar);
  const [fetching, setFetching] = useState(false);


  const [debounceFlag, setDebounceFlag] = useState(false);

  useEffect(() => {

    const applyNewDesign = (newDesign) => {
      let newAva = { ...avatar, dob: context.state.tryDesignDate };
      newAva.design = newDesign
      // newAva = parseAvatar(newAva)
      // console.log('newAva.design = ', newAva.design);
      // if (JSON.stringify(newAva) !== JSON.stringify(avatar)) {
      setAvatar(newAva)
      setDebounceFlag(false);
      // }
    }

    const getCachedDesign = (tryDate, currentAvatar) => {
      // console.log('currentAvatar = ', currentAvatar.token);
      // console.log('tryDate = ', tryDate);
      // console.log('originalDate = ', avatar.dob);
      const originalDate = new Date(currentAvatar.dob);
      // console.log('originalDate2 = ', originalDate);
      // console.log('convertedDate = ', avatar.design.meta.personality);
      const convertedDate = new Date(currentAvatar.design.meta.personality);
      // console.log('convertedDate2 = ', convertedDate);

      // Calculate the offset in milliseconds
      const offset = originalDate - convertedDate;
      // console.log('offset (ms) = ', offset);

      // Method to apply the offset to a given date
      const applyOffset = (date) => {
        const dateWithOffset = new Date(date);
        dateWithOffset.setTime(dateWithOffset.getTime() - offset);
        // console.log('dateWithOffset = ', dateWithOffset);
        return dateWithOffset;
      };

      // Apply the offset to tryDate
      const tryDateWithOffset = applyOffset(tryDate);
      // console.log('tryDateWithOffset = ', tryDateWithOffset);

      // Format the date to match the keys in rectificationDesigns
      const year = tryDateWithOffset.getFullYear();
      const month = String(tryDateWithOffset.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(tryDateWithOffset.getDate()).padStart(2, '0');
      const hours = String(tryDateWithOffset.getHours()).padStart(2, '0');
      const minutes = String(tryDateWithOffset.getMinutes()).padStart(2, '0');
      const seconds = String(tryDateWithOffset.getSeconds()).padStart(2, '0');

      // Construct formattedDate with colons
      const formattedDate = `${year}-${month}-${day}${hours}:${minutes}:00`;
      // console.log('formattedDate = ', formattedDate);

      console.log('cach123CRNTA = ', currentAvatar.token);
      console.log('cach123CACHD = ', context.state.rectificationDesigns);

      // Search in rectificationDesigns using the adjusted tryDate
      if (
        context.state.rectificationDesigns[currentAvatar.token]
        && context.state.rectificationDesigns[currentAvatar.token][formattedDate]
      ) {
        const d = parseNewDesign(context.state.rectificationDesigns[currentAvatar.token][formattedDate]);
        console.log('FOUND = ', d);
        applyNewDesign(d);
        // console.log('cach123FOUND = ', context.state.rectificationDesigns[currentAvatar.token] ? context.state.rectificationDesigns[currentAvatar.token][formattedDate] : 'no');
        // console.log('cach123 —————————— ');
        return true;
      } else if (
        context.state.tryDesignDate === avatar.dob
        // context.state.tryDesignDate.replace('T', ' ') === avatar.dob
      ) {
        return true;
      }
      // console.log('cach123 —————————— ');
      return false;
    };



    async function updateAvatarFetch() {
      if (context.state.tryDesignDate
        && (
          context.state.tryDesignDate
          && avatar.dob
          && context.state.tryDesignDate !== avatar.dob)
      // && context.state.tryDesignDate.replace('T', ' ') !== avatar.dob)
        && !debounceFlag) {
        setFetching(true)
        await _getNewDesign(
          context.state.tryDesignDate,
          avatar.aggr.city.timezone,
          (d) => {
            setFetching(false)
            // console.log('d = ', d);
            if (!debounceFlag) applyNewDesign(d)
          });
      }

    }

    if (context.state.tryDesignDate
      && avatar.design.id !== 'dummy'
      && context.state.tryDesignDate !== avatar.dob
    // && context.state.tryDesignDate.replace('T', ' ') !== avatar.dob
      && !debounceFlag) {
      if (!getCachedDesign(context.state.tryDesignDate, props.avatar)) {
        setDebounceFlag(true);
        updateAvatarFetch();
      }

    }
  }, [
    context.state.tryDesignDate,
    props.avatar,
    context.state.rectificationDesigns
  ]);

  useEffect(() => {
    if (avatar.token !== props.avatar) {
      setAvatar(props.avatar)
    }
  }, [props.avatar])





  return (
    <>
      {React.cloneElement(props.children, { ...props, avatar: avatar, design: avatar.design })}
    </>
  )
}