import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SocialContext } from '../../contexts/socialContext';
import { SelectCity } from '../UiKit/SelectCity';
import { TestCity } from './TestCity';
import { TestIsMe } from './TestIsMe';
import { TestPenta } from './TestPenta';
import { TestPentaAvatars } from './TestPentaAvatars';


const API_URL = process.env.REACT_APP_API_URL;

export function TestMain(props) {
  const socialContext = useContext(SocialContext);
  const { children } = { ...props };
  const [searchParams] = useSearchParams();

  const [state, setState] = useState();


  const navigate = useNavigate();

  const call = (url) => {

    fetch(url)
      .then((res) => {
        if (res.status === 401) {
          navigate('/login')
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        console.log(data);
      });
  }


  const newData = {
    "city_id": 123,
    "dob": "1990-09-24T14:24:00+0000",
    "isMe": false,
    "my": true,
    "name": "Misha",
    "token": "e60fcdb8a759451ac5"
  }

  const _updateAvatar = async (newData = {}, callback = (data) => console.log(data)) => {
    const url = `${API_URL}/api/avatar`;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Include any other headers you need, such as authorization
        },
        // If you need to send a body with the POST request, include it here
        body: JSON.stringify(newData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      callback && callback(data)
      // Handle success, e.g., showing a success message
    } catch (error) {
      console.error('Error:', error.message);
      // Handle error, e.g., showing an error message
    }
  };





  return (
    <>

      <div style={{ padding: '4em', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', rowGap: '2em' }}>

        <div>
          <h3>/api/me</h3>
          <button onClick={() => call(`${API_URL}/api/me`)}>Me</button>
        </div>

        <div>
          <h3>/api/me/friends</h3>
          <button onClick={() => call(`${API_URL}/api/me/friends`)}>My Friends</button>
        </div>

        <div>
          <h3>/api/me/avatars/</h3>
          <button onClick={() => call(`${API_URL}/api/me/avatars/`)}>My Avatars</button>
        </div>

        <div className="">
          <h3>/api/me/groups</h3>
          <button onClick={() => call(`${API_URL}/api/me/groups`)}>Groups</button>
        </div>

        <div>
          <h3>/api/me/pentas</h3>
          <button onClick={() => call(`${API_URL}/api/me/pentas`)}>Pentas</button>
        </div>

        <div>
          <h3>!!!! TEST CORS</h3>
          <button onClick={() => _updateAvatar(newData)}>TEST CORS</button>
        </div>

        <TestCity />

        <SelectCity />

        <TestPenta />

        <TestPentaAvatars />

        <TestIsMe />

        <div>
          <h3>401 test - /api/index/guest</h3>
          <button onClick={() => call(`${API_URL}/api/index/guest`)}>401</button>
        </div>



      </div>
    </>
  )
}