import React from 'react'


export const Iching = React.memo(({ profile = [1, 3], size = 'lg' }) => {
  const a = [1, 2, 3, 4, 5, 6].reverse();
  return (<div
    className={`t_icon t_icon--iching t_icon--iching--${size}`}>
    {a.map(
      (one, ind) => {
        return (<div
          key={`iching_${one}_${ind}`}
          className={`t_icon_iching
          ${one === profile[0] ? 't_icon_iching--black' : ''} ${one === profile[1] ? 't_icon_iching--red' : ''}
        `} />)
      }
    )}
  </div>)
})