import React, { useEffect, useState } from 'react';
import { defaultNarrative } from '../components/Edu/narratives';
import {
  _getPublicUser,
  _updateMe,
  drawProfile,
  drawType,
  parseDesignInPersonas
} from '../components/utilsDesign';
import { getLocal, setLocal } from '../components/utilsSocial';
import profiles_local from '../static/profiles_local.json';
import types_local from '../static/types_local.json';
import ui_local from '../static/ui_local.json';
import celebs_categories from '../static/celebs_categories.json';

var Airtable = require('airtable');

const LangContext = React.createContext();

function LangProvider({ children }) {

  const AIRTABLE_URL = process.env.REACT_APP_AIRTABLE_URL;
  const AIRTABLE_APIKEY = process.env.REACT_APP_AIRTABLE_APIKEY;
  const APP_AIRTABLE_CACHE = process.env.REACT_APP_AIRTABLE_CACHE;
  const celebsTable = process.env.REACT_APP_AIRTABLE_CELEBS;


  var base = new Airtable({ apiKey: AIRTABLE_APIKEY }).base(AIRTABLE_URL);


  const [changes, setChanges] = useState('');

  const [language, setLanguage1] = useState(getLocal('language') ? getLocal('language') : 'en')
  const [amPm, setAmPm] = useState(getLocal('ampm') ? getLocal('ampm') : 'ampm')

  const setLanguage = (l) => {
    setLanguage1(l);
    setLocal('language', l)
    setAmPm(l === 'en' ? 'ampm' : '24')
    _updateMe({ locale: l })
  }
  const processCrosses = (crosses) => {

    const a = [...Object.values(crosses)]
    // .reduce((acc, record) => {
    //   const id = `${record.angle}_${record.id}`;
    //   acc[id] = record;
    //   return acc;
    // }, {});
    const crossesNew = {}
    a.forEach(record => {
      // const id = `${record.angle}_${record.id}`;
      const id = `${record.id}`;
      crossesNew[id] = {
        ...record,
        id
      }
    })
    return crossesNew
  }

  const processVideos = (videos) => {
    const a = [...Object.values(videos)]
      .filter(record => typeof record === 'object')
      .filter(record => record.active)
    return a
  };



  const processNarratives = (narratives, narrativePages) => {

    if (Object.keys(narratives).length && Object.keys(narrativePages).length) {
      Object.keys(narratives).forEach(oneNarrativeId => {
        const oneNarrative = narratives[oneNarrativeId];

        const getPage = (pageId) => {
          const narrativePagesIdsArray = Object.values(narrativePages).filter(one => typeof narrativePages.one !== 'object');
          const narrativePagesNew = {}
          narrativePagesIdsArray.forEach(onePage => {
            narrativePagesNew[onePage._id] = onePage
          })
          return narrativePagesNew[pageId]
        }

        const pagesArray = []

        oneNarrative.pages.forEach(pageId => {
          pagesArray.push(getPage(pageId))
        })
        oneNarrative.pages = pagesArray;
        // return (narratives)
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    }
    return narratives
  }

  const [ui, setUi] = useState({ ...ui_local });
  // const [ui, setUi] = useState(getLocal('lang_ui') ? getLocal('lang_ui') : null);
  // const [ui, setUi] = useState(null);
  // const [ui, setUi] = useState({});

  // const [types, setTypes] = useState({ ...types_local })
  // const [profiles, setProfiles] = useState({ ...profiles_local })
  const [types, setTypes] = useState(getLocal('data') && getLocal('data').types ? getLocal('data').types : {})
  const [profiles, setProfiles] = useState(getLocal('data') && getLocal('data').profiles ? getLocal('data').profiles : {})
  const [lines, setLines] = useState(getLocal('data') && getLocal('data').lines ? getLocal('data').lines : {})
  const [environments, setEnvironments] = useState(getLocal('data') && getLocal('data').environments ? getLocal('data').environments : {})
  const [centers, setCenters] = useState(getLocal('data') && getLocal('data').centers ? getLocal('data').centers : {})
  const [authorities, setAuthorities] = useState(getLocal('data') && getLocal('data').authorities ? getLocal('data').authorities : {})
  const [planets, setPlanets] = useState(getLocal('data') && getLocal('data').planets ? getLocal('data').planets : {})
  // const [gates, setGates] = useState({})
  const [gates, setGates] = useState(getLocal('data') && getLocal('data').gates ? getLocal('data').gates : {})
  const [channels, setChannels] = useState(getLocal('data') && getLocal('data').channels ? getLocal('data').channels : {})
  const [videos, setVideos] = useState(getLocal('data') && getLocal('data').videos ? processVideos(getLocal('data').videos) : [])
  // const [videos, setVideos] = useState([])
  const [crosses, setCrosses] = useState(getLocal('data') && getLocal('data').crosses ? processCrosses(getLocal('data').crosses) : {})
  const [variables, setVariables] = useState(getLocal('data') && getLocal('data').variables ? getLocal('data').variables : {})

  const [celebs, setCelebs] =
    useState(celebsTable !== 'celebs_dev' && getLocal('data') && getLocal('data').celebs ? getLocal('data').celebs
      : {})

  // const [narratives, setNarratives] = useState(
  //   getLocal('narratives')
  //     ? getLocal('narratives')
  //     : { 'default': defaultNarrative }
  // )



  const [narrativePages, setNarrativePages] = useState(
    getLocal('data')
      && getLocal('data').narrative_pages ? getLocal('data').narrative_pages
      : {}
  )

  const [narratives, setNarratives] = useState(
    (
      getLocal('data')
      && getLocal('data').narratives
      && getLocal('data').narrative_pages
    )
      ? processNarratives(getLocal('data').narratives, getLocal('data').narrative_pages)
      : {}
  )




  const [narrativeCurrent, setNarrativeCurrentInner] = useState(
    getLocal('narrative') ? getLocal('narrative') : 'simple'
  )

  const setNarrativeCurrent = (narrative) => {
    setNarrativeCurrentInner(narrative);
    setLocal('narrative', narrative)
  }



  useEffect(() => {
    if (ui && Object.keys(ui).length) {
      setLocal('lang_ui', ui)
    }
  }, [ui])




  const dataKeys = [
    'changes',
    'profiles',
    'types',
    'planets',
    'lines',
    'gates',
    'channels',
    'environments',
    'environments',
    'authorities',
    'centers',
    'videos',
    'crosses',
    'variables',
    'celebs',
    'narratives',
    'narrative_pages'
  ]




  const processData = (data) => {
    const processedData = {};
    data.forEach(item => {
      if (item && item.table) {
        processedData[item.table] = Object.fromEntries(
          Object.entries(item).filter(([key, value]) => typeof value === 'object' && value !== null)
        );
        if (getLocal('data')) {
          const localData = getLocal('data') || {};
          localData[item.table] = processedData[item.table];
          setLocal('data', localData);
        }
      }
      if (item && !item.table) {
        processedData['changes'] = item;
      }
    });
    if (!getLocal('data')) {
      setLocal('data', processedData)
    }
    return processedData; // Return the named object
  };


  const fetchStaticData = async (dataKeys) => {
    try {
      const responses = await Promise.all(['changes', ...dataKeys].map(key => fetch(`${APP_AIRTABLE_CACHE}/${key}.json`)));

      const data = await Promise.all(responses.map(response => response.json()));

      const setTables = (processedData) => {
        if (processedData.changes) setLocal('changes', processedData.changes || []);
        if (processedData.types) setTypes(processedData.types || {});
        if (processedData.profiles) setProfiles(processedData.profiles || {});
        if (processedData.narrative_pages) setNarrativePages(processedData.narrative_pages || {});
        if (processedData.narratives) setNarratives(processNarratives(processedData.narratives, processedData.narrative_pages));
        // setUi(processedData.ui || {});
        if (celebsTable !== 'celebs_dev' && processedData.celebs) setCelebs(processedData.celebs || {});
        if (processedData.planets) setPlanets(processedData.planets || {});
        if (processedData.lines) setLines(processedData.lines || {});
        if (processedData.gates) setGates(processedData.gates || {});
        if (processedData.channels) setChannels(processedData.channels || {});
        if (processedData.environments) setEnvironments(processedData.environments || {});
        if (processedData.authorities) setAuthorities(processedData.authorities || {});
        if (processedData.centers) setCenters(processedData.centers || {});
        if (processedData.videos) setVideos(processVideos(processedData.videos) || {});
        if (processedData.crosses) setCrosses(processCrosses(processedData.crosses || {}));
        if (processedData.variables) setVariables(processedData.variables || {});
      }

      setTables(processData(data));

    } catch (error) {
      console.error('Error fetching static data:', error);
    }
  };




  const fetchChanges = async () => {
    try {
      const response = await fetch(`${APP_AIRTABLE_CACHE}/changes.json`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const changesServer = await response.json();
      return changesServer;
    } catch (error) {
      console.error('Error fetching changes:', error);
      return null; // Return null or handle the error as needed
    }
  };


  useEffect(() => {
    if (getLocal('data')) {
      const changesLocal = getLocal('changes') || {};

      // Fetch changes and handle the promise
      fetchChanges(changesLocal).then(changesServer => {
        const delta = Object.keys(changesServer).filter((tableId) => {
          const tableServerTimeStamp = changesServer[tableId];
          const tableLocalTimeStamp = changesLocal[tableId];
          return tableServerTimeStamp !== tableLocalTimeStamp;
        });

        // console.log('delta = ', delta);

        if (delta.length) {
          setChanges(changesServer);
          setLocal('changes', changesServer);
          fetchStaticData(delta);
        }

      }).catch(error => {
        console.error('Error fetching changes:', error);
      });
    } else {
      fetchStaticData(dataKeys);
    }
  }, []);





  const getNarrative = (id) => {

    if (narratives[id]) { return narratives[id] }
    else { return {} }
  }






  useEffect(() => {
    setLocal('language', language)
  }, [ui])

  useEffect(() => {
    // console.log('narratives12312312312312313123213231232 = ', narratives);
  }, [narratives])







  useEffect(() => {
    if (celebsTable === 'celebs_dev' && !Object.keys(celebs).length) {
      base(celebsTable).select({
        maxRecords: 100,
      }).eachPage(function page(records, fetchNextPage) {
        const results = {};
        records
          .filter(one => one.get('active'))
          .filter(one => one.get('hd_id'))
          .forEach(one => {
            results[one.get('hd_id')] = ({
              'id': one.get('id'),
              'hd_id': one.get('hd_id') ? one.get('hd_id') : '',
              'type': one.get('type') ? one.get('type') : [],
            })
          })
        setCelebs(results)
      }, function done(err) {
        if (err) {
          console.log(err);
          // console.error(err);
          setCelebs({})
          return;
        }
      });
    }
  }, [])


  const [celebsData, setCelebsData] = useState([]);


  useEffect(() => {
    if (Object.keys(celebs).length > 0 && celebsData.length === 0) {
      // Use a Set to keep track of userIds that have been fetched successfully
      const fetchedUserIds = new Set();

      const fetchUser = async (userId) => {
        try {
          // Check if userId has already been fetched successfully
          if (fetchedUserIds.has(userId)) {
            return null; // Return null to skip fetching again
          }

          const userData = await new Promise((resolve) => {
            _getPublicUser(userId, (userData) => {
              resolve(userData);
            }, (error) => {
              // Handle error if needed
              console.log('Error fetching user:', error);
              resolve(null); // Resolve with null if there's an error
            });
          });

          // Add userId to fetchedUserIds if userData is not null
          if (userData !== null) {
            fetchedUserIds.add(userId);
          }

          return userData;
        } catch (error) {
          console.error('Error fetching user:', error);
          return null; // Return null if there's an error
        }
      };


      const fetchDataForCelebs = async () => {
        try {
          const promises = Object.keys(celebs).map(oneId => fetchUser(oneId));
          const userDataArray = await Promise.all(promises);
          setCelebsData(
            parseCelebs(
              parseDesignInPersonas(userDataArray.filter(userData => userData !== null))
            )
          );
        } catch (error) {
          // console.error('Error fetching celebs:', error);
          console.log('Error fetching celebs:', error);
        }
      };

      fetchDataForCelebs();
    }
  }, [celebs]);


  const parseCelebs = (personas) => {

    const parseCeleb = (persona) => {
      persona = {
        ...persona,
        type: getCelebCategory(persona.user.token)
      }
      return persona;
    }


    if (!personas) return []
    personas = personas.map(one => ({
      ...parseCeleb(one)
    }))
    return personas
  }




  const $ui = (str, field, quantity = 1) => {
    if (ui && Object.keys(ui).length) {

      const lang = quantity > 1 && ui[str][`${language}+`] ? `${language}+` : language;
      if (!field) { return ui[str] ? ui[str][lang] : str }
      else { return ui[str][`${field}-${lang}`] ? ui[str][`${field}-${lang}`] : str }
    } else {
      return str
    }
  };

  const $ = (str, obj) => obj[`${str}-${language}`];



  const $type = (id, str) => types[id] ? types[id][`${str}-${language}`] ? types[id][`${str}-${language}`] : types[id][`${str}`] : id;
  const $profile = (id, str) => {
    let a = profiles[id] ? profiles[id][`${str}-${language}`] ? profiles[id][`${str}-${language}`] : profiles[id][`${str}`] : id
    return a
  }
  // const $line = (id, str) => lines[id] ? lines[id][`${str}-${language}`] ? lines[id][`${str}-${language}`] : lines[id][`${str}`] : <span className='x_txt_loading'>{id}</span>;
  const $line = (id, str) => lines[id] ? lines[id][`${str}-${language}`] ? lines[id][`${str}-${language}`] : lines[id][`${str}`] : id;
  const $vars = (id, str = 'title') => variables[id] ? variables[id][`${str}-${language}`] ? variables[id][`${str}-${language}`] : variables[id][`${str}`] : str !== 'title' ? '' : id;
  const $envs = (id, str) => environments[id] ? environments[id][`${str}-${language}`] ? environments[id][`${str}-${language}`] : environments[id][str] : str !== 'title' ? '' : id;
  const $center = (id, str) => centers[id] ? centers[id][`${str}-${language}`] ? centers[id][`${str}-${language}`] : centers[id][str] : str !== 'title' ? '' : id;
  const $auths = (id, str) => authorities[id] ? authorities[id][`${str}-${language}`] ? authorities[id][`${str}-${language}`] : authorities[id][str] : str !== 'title' ? '' : id;
  const $planet = (id, str) => planets[id] ? planets[id][`${str}-${language}`] ? planets[id][`${str}-${language}`] : planets[id][str] : str !== 'title' ? '' : id;
  const $channel = (id, str) => channels[id] ? channels[id][`${str}-${language}`] ? channels[id][`${str}-${language}`] : channels[id][str] : str !== 'title' ? '' : id;
  const $gate = (id, str) => gates[id] ? gates[id][`${str}-${language}`] ? gates[id][`${str}-${language}`] : gates[id][str] : str === 'title' ? '' : id;
  const $narr = (id, str) => {
    return narratives[id] ? narratives[id][`${str}-${language}`] ? narratives[id][`${str}-${language}`] : narratives[id][str] : id
  };

  const $cross = (id, str) => {
    return crosses[id] ? crosses[id][`${str}-${language}`] ? crosses[id][`${str}-${language}`] : crosses[id][str] : id
  };

  const $celebType = (typeId) => celebs_categories[typeId] ? celebs_categories[typeId][language] : '';

  const defaultTitles = {
    en: {
      short: 'Unnamed'
    },
    ru: {
      short: 'Безымянный'
    }
  }

  const getDefaultTitle = (design, show = 'unnamed type') => {
    let ret = 'Unnamed'
    let typ = design ? design['type'] : ''
    let prof = design ? design['profile'] : []
    // if (show = 'type' && typ) ret = `${defaultTitles[language]['short']} ${drawType(typ)}`
    if (show === 'unnamed type' && typ) ret = `${defaultTitles[language]['short']} ${drawType(typ, 'short')}${drawProfile(prof)}`
    if (show === 'type' && typ) ret = `${drawType(typ, 'short')} ${drawProfile(prof)}`
    return ret;
  }



  const getVideoList = (rel, relValue, locale = language) => {

    // console.log('videos = ', videos);
    if (!videos.length) return []

    const varLabel = relValue.split('_')[0];
    const relLabelVideo = `label_${varLabel}`

    const getLabelVideo = videos
      .filter(one => {
        return one.locale.includes(locale)
      })
      .map(one => ({
        ...one,
        comment: one[`comment-${language}`]
      }))
      .filter(one => {
        return one[`rel-${rel}`] ? one[`rel-${rel}`]
          .includes(relLabelVideo) : false
      });

    // console.log('videos = ', videos);
    const videosLocale = videos
        .filter(one => {
          return one.locale.includes(locale)
        })
        .map(one => ({
          ...one,
          comment: one[`comment-${language}`]
        }))
        .filter(one => one[`rel-${rel}`] ? one[`rel-${rel}`].includes(relValue) : false)
    // console.log('videosLocale = ', videosLocale);
    return [...getLabelVideo, ...videosLocale]
  }

  const getVideoById = (youtubeId) => {
    let vid = videos.filter(one => one.youtubeId === youtubeId)[0];
    if (vid) {
      vid['comment'] = vid[`comment-${language}`];
    } else {
      vid = {}
    }
    return vid;
  }

  const getCelebCategory = (hdId) => {
    if (!Object.keys(celebs).length) return [];
    const types = celebs[hdId] ? celebs[hdId].type : [];
    return types;
  }

  const languages = ['en', 'ru']

  const [languageReady, setLanguageReady] = useState()

  useEffect(() => {
    if (
      Object.keys(ui).length &&
      Object.keys(types).length &&
      Object.keys(profiles).length &&
      Object.keys(lines).length &&
      Object.keys(gates).length &&
      Object.keys(variables).length &&
      Object.keys(environments).length &&
      Object.keys(authorities).length &&
      Object.keys(channels).length &&
      Object.keys(planets).length &&
      Object.keys(celebs).length &&
      Object.keys(crosses).length
      // && videos.length
    ) {
      setLanguageReady(true)
    }
  }, [
    ui,
    types,
    profiles,
    lines,
    gates,
    variables,
    environments,
    authorities,
    channels,
    planets,
    celebs,
    crosses,
    videos
  ])


  return (
    <LangContext.Provider
      value={{
        languageReady,
        languages,
        language,
        amPm,
        narratives,
        narrativeCurrent,
        celebs,
        videos,
        celebsData,
        lang: {
          ui,
          types,
          profiles,
          lines,
          gates,
          variables,
          environments,
          authorities,
          channels,
          planets
        },
        setLanguage,
        $ui,
        $,
        $type,
        $profile,
        $line,
        $vars,
        $envs,
        $narr,
        $auths,
        $planet,
        $channel,
        $center,
        $gate,
        $cross,
        $celebType,
        setNarrativeCurrent,
        getDefaultTitle,
        getNarrative,
        getVideoList,
        getVideoById,
        getCelebCategory
      }}
    >
      {
        narratives
        && children}
    </LangContext.Provider>
  )

}

const LangConsumer = LangContext.Consumer;

export { LangConsumer, LangContext, LangProvider };
