import React, { useEffect, useState } from 'react';
import { Preloader } from '../UiKit/kit';






export const ChatMessageRead = ({ children, style = {}, userData, my = false, _id, channel, from, text, read, timestamp, temp }) => {
  return (
    <>



      {!my &&
        <div style={{ ...style }} className={`x_chat_status ${read ? 'x_chat_status--read' : ''}`}>
        {children}
          {/* <div className="t_icon t_icon--20">{read ? `done_all` : `check`}</div> */}
      </div>}


      {my && <div style={{ ...style }} className={`x_chat_status ${read && !temp ? 'x_chat_status--read' : ''}`}>
        {children}
        {!temp && <div className="t_icon t_icon--20">
          {read ? `done_all` : `check`}
        </div>}
        {temp && <div className="t_icon t_icon--20">
          <Preloader width={20} height={20} style={{ opacity: 1, marginRight: '-4px' }} />
        </div>}
      </div>}

    </>
  );
};




export const getMessageTime = ({ timestamp }) => {
  if (!timestamp) return ' '
  const timest = timestamp * 1000; // Convert to milliseconds
  const dateObj = new Date(timest);
  const formattedTime = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return formattedTime;
}
export const getMessageDate = ({ timestamp }) => {
  if (!timestamp) return ' '
  const timest = timestamp * 1000; // Convert to milliseconds
  const dateObj = new Date(timest);
  const formattedDate = dateObj.toLocaleDateString([], { month: 'short', day: 'numeric', year: 'numeric' });
  return formattedDate;
}



export const ChatMessageTime = ({
  show = 'time',
  style = {},
  userData,
  my = false,
  _id,
  channel,
  from,
  text,
  read,
  timestamp
}) => {

  const [time, setTime] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    // Set the state variables
    setTime(getMessageTime({ timestamp }));
    setDate(getMessageDate({ timestamp }));
  }, [timestamp]);


  return (
    <>

      {show === 'time' && time}
      {show === 'date' && date}
      {show === 'datetime' && `${date} ${time}`}

    </>
  );
};
