export const circuit_dictionary = {
  sense: {
    title: "Sensing", titleFull: "Abstract/Sensing"
  }, centering: {
    title: "Centering", titleFull: "Centering"
  },
}



export const channels_dictionary = [
  { "id": "34-57", "type": ["generated"], "circuit": ["individual"], "stream": ["integration"], "gates": [34, 57], "centers": ["SL", "SN"] },
  { "id": "26-44", "type": ["projected"], "circuit": ["tribal"], "stream": ["ego"], "gates": [26, 44], "centers": ["HT", "SN"] },
  { "id": "37-40", "type": ["projected"], "circuit": ["tribal"], "stream": ["ego"], "gates": [37, 40], "centers": ["SP", "HT"] },
  { "id": "21-45", "type": ["manifested"], "circuit": ["tribal"], "stream": ["ego"], "gates": [21, 45], "centers": ["HT", "TT"] },
  { "id": "20-57", "type": ["projected"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [20, 57], "centers": ["TT", "SN"] },
  { "id": "47-64", "type": ["projected"], "circuit": ["collective"], "stream": ["sense"], "gates": [47, 64], "centers": ["AA", "HD"] },
  { "id": "9-52", "type": ["generated"], "circuit": ["collective"], "stream": ["realize"], "gates": [9, 52], "centers": ["SL", "RT"] },
  { "id": "24-61", "type": ["projected"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [24, 61], "centers": ["AA", "HD"] },
  { "id": "35-36", "type": ["manifested"], "circuit": ["collective"], "stream": ["sense"], "gates": [35, 36], "centers": ["TT", "SP"] },
  { "id": "28-38", "type": ["projected"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [28, 38], "centers": ["SN", "RT"] },
  { "id": "6-59", "type": ["generated"], "circuit": ["tribal"], "stream": ["protect"], "gates": [6, 59], "centers": ["SP", "SL"] },
  { "id": "11-56", "type": ["projected"], "circuit": ["collective"], "stream": ["sense"], "gates": [11, 56], "centers": ["AA", "TT"] },
  { "id": "32-54", "type": ["projected"], "circuit": ["tribal"], "stream": ["ego"], "gates": [32, 54], "centers": ["SN", "RT"] },
  { "id": "1-8", "type": ["projected"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [1, 8], "centers": ["GC", "TT"] },
  { "id": "12-22", "type": ["manifested"], "circuit": ["individual"], "stream": ["protect"], "gates": [12, 22], "centers": ["TT", "SP"] },
  { "id": "4-63", "type": ["projected"], "circuit": ["collective"], "stream": ["realize"], "gates": [4, 63], "centers": ["AA", "HD"] },
  { "id": "23-43", "type": ["projected"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [23, 43], "centers": ["TT", "AA"] },
  { "id": "30-41", "type": ["projected"], "circuit": ["collective"], "stream": ["sense"], "gates": [30, 41], "centers": ["SP", "RT"] },
  { "id": "13-33", "type": ["projected"], "circuit": ["collective"], "stream": ["sense"], "gates": [13, 33], "centers": ["GC", "TT"] },
  { "id": "10-34", "type": ["generated"], "circuit": ["individual"], "stream": ["integration"], "gates": [10, 34], "centers": ["GC", "SL"] },
  { "id": "19-49", "type": ["projected"], "circuit": ["tribal"], "stream": ["ego"], "gates": [19, 49], "centers": ["RT", "SP"] },
  { "id": "2-14", "type": ["generated"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [2, 14], "centers": ["GC", "SL"] },
  { "id": "29-46", "type": ["generated"], "circuit": ["collective"], "stream": ["sense"], "gates": [29, 46], "centers": ["SL", "GC"] },
  { "id": "42-53", "type": ["generated"], "circuit": ["collective"], "stream": ["sense"], "gates": [42, 53], "centers": ["SL", "RT"] },
  { "id": "20-34", "type": ["manifested", "generated"], "circuit": ["individual"], "stream": ["integration"], "gates": [20, 34], "centers": ["TT", "SL"] },
  { "id": "27-50", "type": ["generated"], "circuit": ["tribal"], "stream": ["protect"], "gates": [27, 50], "centers": ["SL", "SN"] },
  { "id": "10-20", "type": ["generated"], "circuit": ["individual"], "stream": ["integration"], "gates": [10, 20], "centers": ["GC", "TT"] },
  { "id": "5-15", "type": ["generated"], "circuit": ["collective"], "stream": ["realize"], "gates": [5, 15], "centers": ["SL", "GC"] },
  { "id": "7-31", "type": ["projected"], "circuit": ["collective"], "stream": ["realize"], "gates": [7, 31], "centers": ["GC", "TT"] },
  { "id": "3-60", "type": ["generated"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [3, 60], "centers": ["SL", "RT"] },
  { "id": "17-62", "type": ["projected"], "circuit": ["collective"], "stream": ["realize"], "gates": [17, 62], "centers": ["AA", "TT"] },
  { "id": "25-51", "type": ["projected"], "circuit": ["individual"], "stream": ["center"], "gates": [25, 51], "centers": ["GC", "HT"] },
  { "id": "39-55", "type": ["projected"], "circuit": ["individual"], "stream": ["knowledge"], "gates": [39, 55], "centers": ["RT", "SP"] },
  { "id": "18-58", "type": ["projected"], "circuit": ["collective"], "stream": ["realize"], "gates": [18, 58], "centers": ["SN", "RT"] },
  { "id": "16-48", "type": ["projected"], "circuit": ["collective"], "stream": ["realize"], "gates": [16, 48], "centers": ["TT", "SN"] },
  { "id": "10-57", "type": ["projected"], "circuit": ["individual"], "stream": ["integration"], "gates": [10, 57], "centers": ["GC", "SN"] }
]


export const channels_dictionaryOld = [
  {
    gates: [64, 47], centers: ["HD", "AA"], title: "Abstraction", description: "A Design of mental activity and clarity", stream: ["sense"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [61, 24], centers: ["HD", "AA"], title: "Awareness", description: "A Design of a thinker", stream: ["knowledge"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [63, 4], centers: ["HD", "AA"], title: "Logic", description: "A Design of a mental muse mixed with doubt", stream: ["realize"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [17, 62], centers: ["AA", "TT"], title: "Acceptance", description: "A Design of an organizational being", stream: ["realize"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [43, 23], centers: ["AA", "TT"], title: "Structuring", description: "A Design of individuality", stream: ["knowledge"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [11, 56], centers: ["AA", "TT"], title: "Curiosity", description: "A Design of a searcher", stream: ["sense"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [16, 48], centers: ["TT", "SN"], title: "The Wave Length", description: "A Design of a talent", stream: ["realize"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [20, 57], centers: ["TT", "SN"], title: "The Brain Wave", description: "A Design of penetrating awareness", stream: ["knowledge"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [20, 34], centers: ["TT", "SL"], title: "Charisma", description: "A Design where thoughts must become deeds", stream: ["integration"], circuit: ["individual"], type: ["manifested", "generated"]
  }, {
    gates: [32, 54], centers: ["SN", "RT"], title: "Transformation", description: "A Design of being driven", stream: ["ego"], circuit: ["tribal"], type: ["projected"]
  }, {
    gates: [28, 38], centers: ["SN", "RT"], title: "Struggle", description: "A Design of stubbornness", stream: ["knowledge"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [18, 58], centers: ["SN", "RT"], title: "Judgment", description: "A Design of insatiability", stream: ["realize"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [20, 10], centers: ["TT", "GC"], title: "Awakening", description: "A Design of commitment to higher principles", stream: ["integration"], circuit: ["individual"], type: ["generated"]
  }, {
    gates: [31, 7], centers: ["TT", "GC"], title: "The Alpha", description: "For 'good' or 'bad',	a Design of leadership", stream: ["realize"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [8, 1], centers: ["TT", "GC"], title: "Inspiration", description: "A Design of a creative role model", stream: ["knowledge"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [33, 13], centers: ["TT", "GC"], title: "The Prodigal", description: "A Design of witness", stream: ["sense"], circuit: ["collective"], type: ["projected"]
  }, {
    gates: [10, 34], centers: ["GC", "SL"], title: "Exploration", description: "A Design of following one's convictions", stream: ["integration"], circuit: ["individual"], type: ["generated"]
  }, {
    gates: [15, 5], centers: ["GC", "SL"], title: "Rhythm", description: "A Design of being in the flow", stream: ["realize"], circuit: ["collective"], type: ["generated"]
  }, {
    gates: [2, 14], centers: ["GC", "SL"], title: "The Beat", description: "A Design of being the keeper of keys", stream: ["knowledge"], circuit: ["individual"], type: ["generated"]
  }, {
    gates: [46, 29], centers: ["GC", "SL"], title: "Discovery", description: "A Design of succeeding where others fail", stream: ["sense"], circuit: ["collective"], type: ["generated"]
  }, {
    gates: [10, 57], centers: ["GC", "SN"], title: "Perfected Form", description: "A Design of survival", stream: ["integration"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [57, 34], centers: ["SN", "SL"], title: "Power", description: "A Design of an archetype", stream: ["integration"], circuit: ["individual"], type: ["generated"]
  }, {
    gates: [50, 27], centers: ["SN", "SL"], title: "Preservation", description: "A Design of custodianship", stream: ["protect"], circuit: ["tribal"], type: ["generated"]
  }, {
    gates: [45, 21], centers: ["TT", "HT"], title: "Money", description: "A Design of a materialist", stream: ["ego"], circuit: ["tribal"], type: ["manifested"]
  }, {
    gates: [59, 6], centers: ["SL", "SP"], title: "Mating", description: "A Design focused on reproduction", stream: ["protect"], circuit: ["tribal"], type: ["generated"]
  }, {
    gates: [42, 53], centers: ["SL", "RT"], title: "Maturation", description: "A Design of balanced development,	cyclic", stream: ["sense"], circuit: ["collective"], type: ["generated"]
  }, {
    gates: [3, 60], centers: ["SL", "RT"], title: "Mutation", description: "A Design of energy which fluctuates and initiates,	pulse", stream: ["knowledge"], circuit: ["individual"], type: ["generated"]
  }, {
    gates: [9, 52], centers: ["SL", "RT"], title: "Concentration", description: "A Design of determination,	focused", stream: ["realize"], circuit: ["collective"], type: ["generated"]
  }, {
    gates: [26, 44], centers: ["HT", "SN"], title: "Surrender", description: "A Design of a transmitter", stream: ["ego"], circuit: ["tribal"], type: ["projected"]
  }, {
    gates: [25, 51], centers: ["GC", "HT"], title: "Initiation", description: "A Design of needing to be first", stream: ["Center"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [40, 37], centers: ["HT", "SP"], title: "Community", description: "A Design of being part,	seeking a whole", stream: ["ego"], circuit: ["tribal"], type: ["projected"]
  }, {
    gates: [35, 36], centers: ["TT", "SP"], title: "Transitoriness", description: "A Design of a 'Jack of all Trades'", stream: ["sense"], circuit: ["collective"], type: ["manifested"]
  }, {
    gates: [12, 22], centers: ["TT", "SP"], title: "Openness", description: "A Design of acceptance", stream: ["protect"], circuit: ["individual"], type: ["manifested"]
  }, {
    gates: [49, 19], centers: ["SP", "RT"], title: "Synthesis", description: "A Design of being sensitive", stream: ["ego"], circuit: ["tribal"], type: ["projected"]
  }, {
    gates: [55, 39], centers: ["SP", "RT"], title: "Emoting", description: "A Design of moodiness", stream: ["knowledge"], circuit: ["individual"], type: ["projected"]
  }, {
    gates: [30, 41], centers: ["SP", "RT"], title: "Recognition", description: "A Design of focused energy", stream: ["sense"], circuit: ["collective"], type: ["projected"]
  }
];