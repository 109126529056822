import React, { useEffect, useState } from 'react';
import { Button } from '../UiKit/Button';
import { AdminMenu } from './AdminMenu';
import TextArea from 'antd/es/input/TextArea';
import { Preloader, Preloader0 } from '../UiKit/kit';


const API_URL = process.env.REACT_APP_API_URL;
const APP_AIRTABLE_CACHE = process.env.REACT_APP_AIRTABLE_CACHE;

export function AdminTexts() {
  const [changes, setChanges] = useState('');
  const [profiles, setProfiles] = useState('');
  const [types, setTypes] = useState('');
  const [planets, setPlanets] = useState('');
  const [lines, setLines] = useState('');
  const [gates, setGates] = useState('');
  const [channels, setChannels] = useState('');
  const [environments, setEnvironments] = useState('');
  const [authorities, setAuthorities] = useState('');
  const [centers, setCenters] = useState('');
  const [narratives, setNarratives] = useState('');
  const [narrativePages, setNarrativePages] = useState('');
  const [ui, setUi] = useState('');
  const [videos, setVideos] = useState('');
  const [celebs, setCelebs] = useState('');
  const [crosses, setCrosses] = useState('');
  const [variables, setVariables] = useState('');

  const fetchStaticData = async (table) => {
    console.log('table = ', table);
    try {
      const responses = table
        ? await Promise.all([
          fetch(`${APP_AIRTABLE_CACHE}/${table}.json`),
        ])
        : await Promise.all([
          fetch(`${APP_AIRTABLE_CACHE}/changes.json`),
          fetch(`${APP_AIRTABLE_CACHE}/profiles.json`),
          fetch(`${APP_AIRTABLE_CACHE}/types.json`),
          fetch(`${APP_AIRTABLE_CACHE}/planets.json`),
          fetch(`${APP_AIRTABLE_CACHE}/lines.json`),
          fetch(`${APP_AIRTABLE_CACHE}/gates.json`),
          fetch(`${APP_AIRTABLE_CACHE}/channels.json`),
          fetch(`${APP_AIRTABLE_CACHE}/environments.json`),
          fetch(`${APP_AIRTABLE_CACHE}/environments.json`),
          fetch(`${APP_AIRTABLE_CACHE}/authorities.json`),
          fetch(`${APP_AIRTABLE_CACHE}/centers.json`),
          fetch(`${APP_AIRTABLE_CACHE}/videos.json`),
          fetch(`${APP_AIRTABLE_CACHE}/crosses.json`),
          fetch(`${APP_AIRTABLE_CACHE}/variables.json`),
          fetch(`${APP_AIRTABLE_CACHE}/celebs.json`),
          fetch(`${APP_AIRTABLE_CACHE}/narratives.json`),
          fetch(`${APP_AIRTABLE_CACHE}/narrative_pages.json`),
          fetch(`${APP_AIRTABLE_CACHE}/ui.json`),
        ]);

      const data = await Promise.all(responses.map(response => response.json()));
      console.log('data = ', data);

      setTables(processData(data), table);

    } catch (error) {
      console.error('Error fetching static data:', error);
    }
  };

  const processData = (data) => {
    const processedData = {};
    data.forEach(item => {
      if (item && item.table) {
        processedData[item.table] = item;
      }
      if (item && !item.table) {
        processedData['changes'] = item;
      }
    });
    return processedData; // Return the named object
  };

  const handleSync = async () => {
    try {
      setTables({});
      setChanges('Syncing...');
      // const response = await fetch(`${API_URL}/admin/api/utils/load-air-tables`, {
      const response = await fetch(`https://hd.cafe/admin/api/utils/load-air-tables`, {
        method: 'POST',
      });
      const syncResponse = await response.json(); // Assuming the response is JSON

      if (syncResponse && typeof syncResponse === 'object' && syncResponse.status !== 401) {
        fetchStaticData();
        // const processedData = processData(data); // Call the processing function
        // setTables(processedData);
      } else if (!response.ok) {
        setChanges(`${response.status}: ${response.statusText}`);
        console.error('Sync failed');
      } else {
        const err = {}
        setChanges('Sync failed');
        console.error('Sync failed');
      }
    } catch (error) {
      console.error('Error during sync:', error);
    }
  };



  const setTable = (table, processedData) => {
    switch (table) {
      case 'changes':
        setChanges(processedData);
        break;
      case 'profiles':
        setProfiles(processedData);
        break;
      case 'types':
        setTypes(processedData);
        break;
      case 'planets':
        setPlanets(processedData);
        break;
      case 'lines':
        setLines(processedData);
        break;
      case 'gates':
        setGates(processedData);
        break;
      case 'channels':
        setChannels(processedData);
        break;
      case 'environments':
        setEnvironments(processedData);
        break;
      case 'authorities':
        setAuthorities(processedData);
        break;
      case 'centers':
        setCenters(processedData);
        break;
      case 'narratives':
        setNarratives(processedData);
        break;
      case 'narrative_pages':
        setNarrativePages(processedData);
        break;
      case 'ui':
        setUi(processedData);
        break;
      case 'videos':
        setVideos(processedData);
        break;
      case 'celebs':
        setCelebs(processedData);
        break;
      case 'crosses':
        setCrosses(processedData);
        break;
      case 'variables':
        setVariables(processedData);
        break;
      default:
        console.warn(`No state variable to nullify for table: ${table}`);
        break;
    }
  }



  const setTables = (processedData, table) => {
    console.log('table = ', table);
    if (table) {
      handleSyncIndividual(table, processedData[table]);
    } else {
      setChanges(processedData.changes || []);
      setProfiles(processedData.profiles || {});
      setTypes(processedData.types || {});
      setNarrativePages(processedData.narrative_pages || {});
      setNarratives(processedData.narratives || {});
      setUi(processedData.ui || {});
      setCelebs(processedData.celebs || {});
      setPlanets(processedData.planets || {});
      setLines(processedData.lines || {});
      setGates(processedData.gates || {});
      setChannels(processedData.channels || {});
      setEnvironments(processedData.environments || {});
      setAuthorities(processedData.authorities || {});
      setCenters(processedData.centers || {});
      setVideos(processedData.videos || {});
      setCrosses(processedData.crosses || {});
      setVariables(processedData.variables || {});
    }
  }




  const handleSyncIndividual = async (table) => {
    setTable(table, 'Syncing...');
    try {
      const response = await fetch(`${APP_AIRTABLE_CACHE}/${table}.json`, {
        method: 'POST',
      });
      if (response.ok) {
        console.log('table = ', table);
        fetchStaticData(table)
        console.log('Sync successful');
      } else if (!response.ok) {
        setTable(table, `${response.status}: ${response.statusText}`);
        // console.error('Sync failed');
      } else {
        const err = {}
        setTable('Sync failed', table);
        console.error('Sync failed');
      }
    } catch (error) {
      console.error('Error during sync:', error);
    }
  }


  useEffect(() => {
    fetchStaticData();
  }, []);

  return (<>
    <AdminMenu active="texts" />
    <div className="b" style={{ position: 'relative', minHeight: '100vh', padding: '2em 2em 12em 2em', background: '#efefef' }}>
      <div className='_fv _fgap2'>
        <h1>💬 Texts</h1>


        <div className="_f _fgap2 _rel _ftop">

          <div className="_fv _rad1 _rel"
            style={{
              width: '34em',
              background: "#d4d4d4",
              padding: "1em",
              position: 'sticky',
              top: '1em'
            }}>
            {/* {!changes && <Preloader0 style={{ height: '100%', background: '#e6e6e68a' }}><Preloader /></Preloader0>} */}
            <h2>Changes</h2>
            <TextArea value={JSON.stringify(changes, null, 2)} readOnly rows={20} cols={50} />
            <div className="_f _fwrap _fgap2">
              <Button onClick={handleSync}>Sync all</Button>
            </div>
          </div>


          <div className="_f _fwrap _fgap2 _f100 ">
            <DataSection title="Videos" value={videos} onSync={() => handleSyncIndividual('videos')} />
            <DataSection title="Narratives" value={narratives} onSync={() => handleSyncIndividual('narratives')} />
            <DataSection title="Narrative Pages" value={narrativePages} onSync={() => handleSyncIndividual('narrative_pages')} />
            <DataSection title="Celebs" value={celebs} onSync={() => handleSyncIndividual('celebs')} />
            <DataSection title="Profiles" value={profiles} onSync={() => handleSyncIndividual('profiles')} />
            <DataSection title="Types" value={types} onSync={() => handleSyncIndividual('types')} />
            <DataSection title="Planets" value={planets} onSync={() => handleSyncIndividual('planets')} />
            <DataSection title="Lines" value={lines} onSync={() => handleSyncIndividual('lines')} />
            <DataSection title="Gates" value={gates} onSync={() => handleSyncIndividual('gates')} />
            <DataSection title="Channels" value={channels} onSync={() => handleSyncIndividual('channels')} />
            <DataSection title="Environments" value={environments} onSync={() => handleSyncIndividual('environments')} />
            <DataSection title="Authorities" value={authorities} onSync={() => handleSyncIndividual('authorities')} />
            <DataSection title="Centers" value={centers} onSync={() => handleSyncIndividual('centers')} />
            <DataSection title="Crosses" value={crosses} onSync={() => handleSyncIndividual('crosses')} />
            <DataSection title="Variables" value={variables} onSync={() => handleSyncIndividual('variables')} />
            <DataSection title="UI" value={ui} onSync={() => handleSyncIndividual('ui')} />
          </div>
        </div>
      </div>
    </div>
  </>
  );
}



const DataSection = ({ title, value, onSync }) => {
  return (
    <div className="_fv _rad1 _rel "
      style={{
        background: "#e6e6e6",
        width: '26em'
      }}>
      {!value && <Preloader0 style={{ height: '100%', background: '#e6e6e68a' }}><Preloader /></Preloader0>}
      <div className="_fv _rad1 _rel " style={{ padding: "1em" }}>


        <h2>{title}</h2>
        <TextArea value={JSON.stringify(value, null, 2)} readOnly rows={12} cols={30} />

        <div className="_f">{Boolean(value) && <>Changed: <b>{value.timestamp2}</b></>}</div>

        <div className="_f">
          <Button ghost onClick={onSync}>Sync</Button>
        </div>
      </div>
    </div>
  );
};