import React, { useContext, useState } from 'react';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { PentasBrowser } from './Penta/PentasBrowser';
import { _groupInvite } from './utilsGroup';


export function GroupSelector({
  exclude = [],
  group = {},
  expanded,
  setExpanded = () => void (0)
}) {
  console.log('exclude = ', exclude);


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [currentPenta, setCurrentPenta] = useState(expanded);

  // useEffect(() => {
  //   if (expanded !== currentPenta) setCurrentPenta(expanded);
  // }, [expanded]);



  const customHandlerInner = (userAvatar = { user: {} }) => {
    // context.x.addToPenta(currentPenta, userAvatar.avatar.token);
    // setCurrentPenta(false);
    const callback = (data) => {
      console.log('data = ', data)
    };
    _groupInvite(expanded, userAvatar.user.token, () => setExpanded())
  };


  return (
    <>

      <div className={expanded ? "x_pentas_panel x_pentas_panel--expanded" : "x_pentas_panel"}
        style={{ zIndex: 10000 }}
        onClick={!expanded ? () => setExpanded(false) : () => void (0)}
      >

        {context.state.peoplePanel && <div
          onClick={() => setExpanded(false)}
          style={{ opacity: expanded ? 1 : 0 }} className="x_pentas_panel_z"
        />}

        {context.state.peoplePanel && <PentasBrowser
          title={`${$ui('group_add_title')} ${group.name}`}
          includeMe={false}
          exclude={exclude}
          scope={['people']}
          customHandler={customHandlerInner}
          pentaId={expanded}
          expanded={expanded}
          handleClose={() => {
            setExpanded(false);
            // setCurrentPenta(false);
          }} />}


      </div>
    </>
  );
}
