import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { gates_dictionary } from '../../static/gates_dictionary';
import { handleGateClick } from '../utilsDesign';
import { GateAllActivations } from './GateAllActivations';
import { GateInfo } from './GateInfo';
import { Videos } from './Videos';


export const Gate = React.memo(({
  abel = true,
  gateNumber,
  design,
  label,
  advanced,
  expert,
  showActivations,
  videos = 'inline'
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $gate = context.$gate;

  const [shown, setShown] = useState(false);
  const [expanded, setExpanded] = useState(false);



  // const gateInfo = findGateInUser(design['gates'], gateNumber)
  const designGates = design ? design['gates'] : [];

  const {
    gate, title, type, circuit, stream, description,
  } = gates_dictionary[gateNumber.toString()];


  const location = useLocation();
  const navigate = useNavigate();


  const handleGateClickInner = (gateNumber) => {
    context.setState.setSearch([gateNumber]);
    handleGateClick(gateNumber, location, navigate);
  };


  return (

    <div className={shown ? "x_edu_chan active" : "x_edu_chan"}
      // onClick={() => setShown(!shown)}
      onClick={(e) => {
        e.stopPropagation();
        handleGateClickInner(gateNumber, location, navigate);
      }}

    >
      <div className="x_edu_channel_title00">
        {/* {gateInfo[0]['label'] === 'prs' && <div className="x_edu_content_label">Gate in Personality</div>} */}
        {/* {gateInfo[0]['label'] === 'des' && <div className="x_edu_content_label">Gate in Design</div>} */}
        {label && <div className="x_edu_content_label">{$ui('gate')}</div>}



        <div className={location.pathname.split(':')[1] && location.pathname.split(':')[1] === gateNumber.toString() ? "x_edu_channel_info active" : "x_edu_channel_info"}>

          <div className="x_edu_channel_more"><div className="t_icon t_icon">
            {/* {!shown && <div>arrow_drop_down</div>} */}
            {/* {shown && <div>arrow_drop_up</div>} */}
            <div>info</div>
          </div></div>


          <div className="x_edu_channel_title0">

            {/* <div className="x_edu_channel_title0">
              <div className="x_edu_channel_nums">
                <div className='x_edu_h3'>{$gate(gate, 'id')}</div>
              </div>
              <div className="x_edu_channel_title">
                <div className='x_edu_h3'>
                  {$gate(gate, 'title')}
                </div>
              </div>
            </div> */}



            <GateInfo
              design={design}
              number={true}
              hexagram={false}
              title={true}
              description={true}
              gateId={gateNumber}
            />

            {videos === 'inline' && <Videos
              title={`${$gate(gate, 'id')}. ${$gate(gate, 'title')}`}
              size='sm'
              relType='gates'
              relValue={gate.toString()} />}

          </div>


          {/* <div className="x_edu_channel_desc">
            {$gate(gate, 'description')}
          </div> */}











          {/* <div className="x_edu_channel_desc w-richtext">
              <p>{$gate(gate, 'description')}</p>
            </div> */}





          {/* <div className="x_edu_channel_info_more0"
              onClick={() => setShown(!shown)}
            >
              <div className="t_icon t_icon--20">
                {!shown && <div>expand_more</div>}
                {shown && <div>expand_less</div>}
              </div>
            </div> */}


          {advanced && design && design.gates && <div className='x_edu_activatedby'
        >
          <GateAllActivations
              gateId={gate}
            design={design}
              hexagram={true}
              number={false}
              inLine={false}
              planetGlyph={true}
            // showAdvancedForSunEart={true}
            // showAdvancedForNode={true}
            />
          </div>}



        </div>





      </div>

      <div className={expanded ? "x_edu_planets active" : "x_edu_planets"}>
        <div className={expanded ? "x_edu_planets_inner active" : "x_edu_planets_inner"}>

          <div className="x_edu_activations_type">
            <div className=''>{$ui('type')}: <strong>{type.map((oneType, ind, allTypes) => {
              if (allTypes.length > 1) {
                return ind !== allTypes.length - 1 ? <span key={oneType}>{$ui(oneType)}, </span> : <span key={oneType}>{$ui(oneType)}</span>;
              } else {
                return <span key={oneType}>{$ui(oneType)}</span>;
              }
            })}</strong></div>
            <div className=''>{$ui('circuit')}: <strong>{$ui(circuit)}</strong>
            </div>
            <div className=''>{$ui('sub_circuit')}: <strong>{stream.map((oneCircuit, ind, arr) => {
              if (ind !== arr.length - 1) {
                return <span key={`${oneCircuit}_${ind}`}>{$ui(oneCircuit)}, </span>;
              } else {
                return <span key={`${oneCircuit}_${ind}`}>{$ui(oneCircuit)}</span>;
              }
            })}</strong>
            </div>
          </div>

          <div className="x_edu_pl1">


            {/* <GateAllActivations
              gateId={gate}
              design={design}
              hexagram={false}
              number={false}
              inLine={true}
              planetGlyph={true}
              planetName={true}
              line={true}
              inColor={true}
            /> */}

          </div>

          {/* <div className="x_edu_pl1">
              {findGateInUser(designGates, gateNumber).map((oneGate, ind) => {
                return (
                  <GatePlanet
                    key={`gate_${oneGate['gate']}_${ind}`}
                    shown={shown}
                    gate={oneGate}
                    design={design}
                  />

                )
              })}
            </div> */}



        </div>




      </div>
    </div>

  );
});
