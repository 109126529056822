import React from 'react';
import { getListIcon } from '../utilsSocial';

export function IconList(props) {
  const { icon = '', q = 1 } = { ...props };
  return (
    <>
      <span className="t_icon t_icon--40" >
        {icon ? icon : getListIcon(q)}
      </span>
    </>
  )
}