import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { getGodFaceByGate } from '../../static/crosses_dictionary';
import { Txt } from '../UiKit/Txt';
import { getCrossType, handleGateClick } from '../utilsDesign';


export const Cross = React.memo(({ id, design }) => {


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $cross = context.$cross;






  const getCross = (what) => {
    const leftRightJuxt = getCrossType(design.profile)

    const crossId = `${leftRightJuxt}_${design.inc_cross.toString()}`;
    return $cross(crossId, what)
    // if (profile[0] === 4 && profile[1] === 1) {
    //   if (cr[0]['title'].includes('The Juxtaposition')) return cr[0];
    // } else {
    //   if (cr[0]['title'].includes('The Juxtaposition')) return cr[1];
    // }
  }






  const getGateEmoji = (index) => {
    const emo = [
      '🌞', '🌎', '🌞', '🌎'
    ]
    return emo[index]
  }

  const location = useLocation();
  const navigate = useNavigate();
  const handleGateClickInner = (gateNumber) => {
    context.setState.setSearch([gateNumber]);
    handleGateClick(gateNumber, location, navigate);
  };


  return (
    <>







        <div className="x_edu_white0">
          <div className="x_edu_1st">
            <div className="x_edu_content_h x_edu_content_h--3 _fgap04">{$ui(`cross_${getCrossType(design['profile'])}`)}
              {/* <TooltipH title={<Txt>{$ui('cross_right', 'rich')}</Txt>}>
                <div className="">
                  <Icon className={'_o04'}>info</Icon>
                </div>
              </TooltipH> */}

            </div>



          <h1 className="x_edu_content_h x_edu_content_h--type">
            {getCross('title')}
          </h1>
            <br />
            <div className='x_edu_channel_desc'>
              <Txt>
              {getCross('description')}
              </Txt>
            </div>
            {/* <p className='x_edu_content_desc'>
              {getCross('description')}
            </p> */}
            <br />
            <div className="x_edu_cross_faces0">


              {design['inc_cross'].map((gateNumber, index) => {
                return <div
                  className={index > 1 ? "x_edu_cross_face x_edu_cross_face--des" : "x_edu_cross_face"}
                  style={{ position: 'relative' }}
                  key={`${index}_${getGateEmoji(index)}`}
                >
                  <div className="x_click x_click_pop"
                    onClick={() => handleGateClickInner(gateNumber, location, navigate)}
                  />
                    <div className="x_edu_cross_face_sun">
                      {getGateEmoji(index)}
                    </div>
                    <div className="x_edu_cross_face_gate">
                    {gateNumber}
                    </div>
                    <div className="x_edu_cross_face_name">
                    {getGodFaceByGate(gateNumber)['face']}
                    </div>
                    <div>
                      <div style={{
                        fontSize: '3em',
                        overflow: 'hidden',
                        height: '0.24em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      {getGodFaceByGate(gateNumber)['hexagram']}
                      </div>
                    </div>
                  </div >

              })}





            </div>




          </div>

          <br />
          <br />
          <div className="">
            {getCrossType(design['profile']) === 'right' && <Txt>{$ui('cross_right', 'rich')}</Txt>}
            {getCrossType(design['profile']) === 'left' && <Txt>{$ui('cross_left', 'rich')}</Txt>}
          </div>

        </div>









    </>
  )
})