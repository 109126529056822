import React from 'react';
import { TooltipH } from './TooltipH';
import { Preloader, Preloader0 } from './kit';

export function Button(props) {

  const {
    onClick = () => void (0),
    children,
    icon = '',
    iconAfter = '',
    loading,
    ghost,
    red,
    flat,
    sm,
    inactive,
    disabled,
    tooltip,
    tooltipPlacement,
    className,
    style = {},
    onMouseEnter = () => void (0),
    onMouseOut = () => void (0),
  } = { ...props }

  if (!tooltip) return (
    <>
      <div
        style={{ ...style }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        className={`x_button ${sm ? 'x_button--sm' : ''} ${ghost ? 'x_button--ghost' : ''}  ${red && !disabled ? 'x_button--red' : ''} ${flat ? 'x_button--flat' : ''} ${inactive ? 'x_button--inactive' : ''} ${disabled ? 'x_button--locked' : ''} ${className ? `${className}` : ''}`}
      >
        {Boolean(icon) && !loading &&
          <div className="x_button_icon">
            <div className="t_icon">{icon}
            </div>
          </div>}
        {loading &&
          <div className="x_button_icon">
            <Preloader0 invert style={{ width: 36, height: 36, position: 'relative', margin: '-0.8em', marginLeft: '-0.7em', marginRight: '-0.9em' }}><Preloader invert width={36} height={36} /></Preloader0>
          </div>}
        {children}
        {Boolean(iconAfter) &&
          <div className="x_button_icon">
            <div className="t_icon">{iconAfter}
            </div>
          </div>}
      </div>
    </>
  )
  if (tooltip) return (
    <>
      <TooltipH
        placement={tooltipPlacement}
        title={<>{tooltip}</>}
      >
        <div className={`x_button ${sm ? 'x_button--sm' : ''} ${ghost ? 'x_button--ghost' : ''}  ${red ? 'x_button--red' : ''} ${flat ? 'x_button--flat' : ''} ${inactive ? 'x_button--inactive' : ''} ${disabled ? 'x_button--locked' : ''}`} onClick={onClick}>
        {Boolean(icon) &&
          <div className="x_button_icon">
            <div className="t_icon">{icon}
            </div>
          </div>}
        {children}
      </div>
      </TooltipH >
    </>
  )
}