import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { replacements } from './sanitize';


export const ChatSend = ({ handler }) => {
  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  const [message, setMessage] = useState('');

  const sendMessage = () => {
    handler(sanitize(message));
    setMessage('');
    setDrafts(prev => {
      const current = JSON.parse(JSON.stringify(prev))
      current[chatId] = null;
      return ({ ...current })
    });
  };

  function sanitize(input) {
    const replaceSpaces = (text) => text.replace(/\s+/g, ' ');
    // input = replaceSpaces(input)
    const tagRegex = /<[^>]*>/g;

    return input.replace(tagRegex, (match) => {
      return replacements[match] || ''; // Replace with the mapped entity or empty string
    });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action of the Enter key
      if (message.trim()) {
        sendMessage();
      }
    } else if ((e.ctrlKey || e.metaKey) && e.key === 'b') {
      e.preventDefault(); // Prevents the default action of the `ctrl+b` or `cmd+b`
      applyBoldFormatting();
    }
  };

  const applyBoldFormatting = () => {
    const textarea = send.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (start !== end) { // Ensure some text is selected
      const selectedText = message.substring(start, end);
      const newText = `**${selectedText}**`;
      const updatedMessage = message.substring(0, start) + newText + message.substring(end);

      setMess(updatedMessage)

      // Maintain the selection after formatting
      setTimeout(() => {
        textarea.setSelectionRange(start + 2, end + 2); // Adjust the selection to account for the added `**`
      }, 0);
    }
  };


  const setMess = (newMess) => {
    setMessage(newMess);
    setDrafts(prev => {
      const current = JSON.parse(JSON.stringify(prev))
      current[chatId] = newMess;
      return ({ ...current })
    });
  }


  const { chatId } = useParams();
  const send = useRef(null);

  const [drafts, setDrafts] = useState({})

  useEffect(() => {
    if (send.current) {
      send.current.focus();
    }
    if (drafts[chatId]) {
      setMessage(drafts[chatId])
    } else {
      setMessage('')
    }
  }, [chatId]);

  return (
    <div className="x_mess_conv_input0">
      <textarea
        placeholder={`${$ui('chat_placeholder')}`}
        ref={send}
        className={send.current && send.current.focused ? "x_mess_conv_input active" : "x_mess_conv_input"}
        value={message}
        onChange={(e) => {
          setMess(e.target.value);
        }}
        rows={Math.max(1, Math.ceil((message.match(/\n/g)?.length || 0) + (message.length / 42)))}
        onKeyDown={handleKeyDown}
      >
      </textarea>

      <div className="x_mess_conv_send" onClick={handler && message.trim() ? sendMessage : () => void (0)}>
        <div className={handler && message.trim() ? "x_mess_conv_send_button" : "x_mess_conv_send_button locked"}>
          <div className="t_icon t_icon--60">arrow_upward</div>
        </div>
      </div>
    </div>
  );
};
