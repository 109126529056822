import { ChannelsTable } from './ChannelsTable';
import { Cross } from './Cross';
import { Disclaimer } from './Disclaimer';
import { Full } from './Full';
import { Gates } from './Gates';
import { LinesTable } from './LinesTable';
import { PHS } from './PHS';
import { Tags } from './Tags';
import { Variables } from './Variables';
import { VariablesAdv } from './VariablesAdv';



export const narrativesComponents = {
  default: <Full //No bodygraph
    numbers={false}
    planets={false}
    advanced={false}
    hoverable={true}
    noSettings={false}
    pentaSettings={{
      noSettings: true,
      hoverable: true,
      noSearch: true,
      noHints: true,
      hints: false,
    }}
  />,
  calculate: <Full
    hints={false}
    numbers={false}
    planets={false}
    advanced={false}
    hoverable={true}
    noSettings={false}
    pentaSettings={{
      noSettings: true,
      hoverable: true,
      noSearch: true,
      noHints: true,
      hints: true,
    }}
  />,
  full: <Full
    hints={false}
    noHints={true}
    numbers={true}
    planets={true}
    advanced={true}
    hoverable={true}
    noSettings={false}
    pentaSettings={{
      noSettings: true,
      hoverable: true,
      noSearch: true,
      noHints: true,
      hints: true,
    }}
  />,
  variables: <Variables />,
  variables_adv: <VariablesAdv />,
  phs: <PHS />,
  linesAdv: <LinesTable />,
  channelsAdv: <ChannelsTable />,
  gates: <Gates />,
  gatesAdv: <Gates advanced={true} />,
  cross: <Cross />,
  disclaimer: <Disclaimer />,
  tags: <Tags />,
}







export const getNarrative = (id, narrative) => {
  let ret = narrative.filter(one => one['id'] === id)
  return (ret.length) ? ret[0]['body'] : narrative[0]['body']
}

const defaultPage = {
  id: 'default',
  'title-en': '',
  'title-ru': '',
  'pages': [{
    'id': 'default',
    'title': 'Bodygraph',
    // 'className': 'calculator',
    'bodygraph': true,
    'component': narrativesComponents.full
  },

    // {
    //   'id': 'channels',
    //   'title': 'Channels',
    //   // 'className': 'calculator',
    //   // 'bodygraph': true,
    //   'component': narrativesComponents.channelsAdv
    // }


  ]
}

export const defaultNarrative = defaultPage