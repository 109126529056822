import React from 'react';
import { types_dict } from '../../static/types_dictionary';
import { drawProfile } from '../utilsDesign';

export function ProfileCircle({ design, type = 'calc', typeShow = true, profileShow = true, composite }) {
  const getTypeClass = (design, type = true) => {
    if (!design) return ''
    return design['type'] ? design['type'].toLowerCase() : ''
  }
  const getTypeColor = (design) => {
    if (!design) return ''
    return design['type'] ? types_dict[design['type']]['bgColor'] : ''
  }

  return (
    <>
      <div className={`x_choose_one_ava x_choose_one_ava--${getTypeClass(design)}`}
        style={{
          // backgroundColor: getTypeColor(design)
          background: 'none'
        }}
      >

        {design && <div className={`x_user_ava_type x_user_ava_type--${getTypeClass(design)}`}
          style={{ textDecoration: 'none' }}
        >{`${typeShow ? `${design['type']} ` : ''}${profileShow ? drawProfile(design['profile']) : ''}`} </div>}
      </div>
    </>
  )
}