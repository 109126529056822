import React, { useContext, useEffect, useState } from 'react';
import uniqid from "uniqid";
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';



const Divider = () => {
  return <div style={{ width: '100%', height: 1, background: '#ccc' }}></div>
}

export const DropDown = React.memo(({
  options = [],
  children,
  component,
  addElements,
  customDrop,
  addElementsCloseOnClick,
  addElementsFirst,
  divider,
  id
}) => {


  const context = { ...useContext(SocialContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  id = id || uniqid();

  const [shown, setShown] = useState(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`.x__dropdown_${id}`)) {
        setShown(false);
      }
    };

    const handleMouseMove = (event) => {
      if (!event.target.closest(`.x__dropdown_${id}`)) {
        setShown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const renderComponent = () => {
    return component
  }


  const addClassName = (child, className) => {
    // Get existing className from the child and add the new className
    const existingClassName = child.props.className || '';
    const newClassName = `${existingClassName} ${className}`.trim();

    return React.cloneElement(child, {
      className: newClassName,
      onClick: (e) => {
        e.stopPropagation();
        setShown(!shown);
      },
    });
  };


  return (
    <>

      <div className={shown ? "x_dropdown_menu_z active" : "x_dropdown_menu_z inact"}
        onClick={() => {
          setShown(false);
        }}
      />

      <div className={`x__dropdown_${id}`}
        style={{ position: 'relative' }}
      >
        {/* <div style={{ cursor: 'pointer', zIndex: 1, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          onClick={(e) => {
            e.stopPropagation()
            setShown(!shown)
          }}
        /> */}


        {Boolean(children) && React.isValidElement(children) &&
          addClassName(children, shown ? 'active' : '')
        }



        <div className={shown ? `x_dropdown_menu0 x_dropdown_menu0--${id} active` : `x_dropdown_menu0 x_dropdown_menu0--${id}`}>
          <div className={`x_user_menu_ul x_user_menu_ul--${id}`}>



            {options.map((one = { active: false, title: 'Item 1', icon: '', handle: () => void (0), divider: false }, ind) => {

              if (one.divider) return <Divider key={uniqid()} />

              if (!one.divider) return (

                (Boolean(one.component) && React.isValidElement(one.component)) ? <div
                  className={one.className ? one.className : `x_menu_li x_menu_li--${id} ${one.active ? 'active' : ''}`}
                  key={`menu_${id}_${ind}`}
                  onClick={() => {
                    console.log(id)
                    one.handle();
                  }}
                >{React.cloneElement(one.component)}</div>
                  : <div
                    className={`x_menu_li x_menu_li--${id} ${one.active ? 'active' : ''}`}
                    key={`menu_${id}_${ind}`}
                    onClick={() => {
                      one.handle();
                      setShown(false);
                    }}
                  >
                    {Boolean(one.icon) && <div className="t_icon t_icon--40">{one.icon}</div>} {one.title}
                  </div>)
            })}




            {Boolean(addElements) && addElementsCloseOnClick && <div
              style={{ order: addElementsFirst ? -1 : 'auto' }}
              onClick={() => {
                setShown(false);
              }}
            >{addElements}</div>}

            {Boolean(addElements) && !addElementsCloseOnClick && <div
              style={{ order: addElementsFirst ? -1 : 'auto' }}>{addElements}</div>}

          </div>

          {customDrop && customDrop(() => {
            setShown(false);
          })}
        </div>

      </div>

    </>
  )
})