import React from 'react'
import { Link } from 'react-router-dom'

export default function Page404() {
  return (<>
    <div style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <h1>404</h1>
      <Link to='/'>Go to mainpage</Link>
    </div>
  </>
  )
}
