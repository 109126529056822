import { parseDesignInPersonas } from "./utilsDesign";

const API_URL = process.env.REACT_APP_API_URL;

export const _getGroups = async (callback = () => void (0)) => {
  const url = `${API_URL}/api/me/groups`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};

export const _getInvitations = async (callback = () => void (0)) => {
  const url = `${API_URL}/api/me/invitations`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};
export const _getGroupAdmins = async (groupToken) => {
  const url = `${API_URL}/api/group/admins/${groupToken}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    let members = await response.json();
    members = parseDesignInPersonas(members)
    return members;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;  // Re-throw the error to handle it in the calling function if needed
  }
};


export const _getGroup = async (groupToken) => {
  const url = `${API_URL}/api/group/${groupToken}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    let data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;  // Re-throw the error to handle it in the calling function if needed
  }
};


export const _requestToJoin = async (
  groupToken = '',
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/group/request-to-join/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "group": groupToken
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};



export const _getMyRequests = async () => {
  const url = `${API_URL}/api/me/requests-to-join`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    let data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;  // Re-throw the error to handle it in the calling function if needed
  }
};


export const _getGroupMembers = async (groupToken) => {
  const url = `${API_URL}/api/group/members/${groupToken}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    let members = await response.json();
    members = parseDesignInPersonas(members)
    return members;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;  // Re-throw the error to handle it in the calling function if needed
  }
};

export const _requestIntoGroup = async (token, callback = () => void (0)) => {
  const url = `${API_URL}/api/group/request-to-join/`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "group": token
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};


export const _seeGroupInvitations = async (groupToken = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/group/invitations/${groupToken}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    let members = await response.json();
    members = parseDesignInPersonas(members)
    callback(members);
  } catch (error) {
    console.error('Error:', error.message);
  }
};




export const _groupInvite = async (
  groupToken = '',
  userToken = '',
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/group/invite/`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "group": groupToken,
        "user": userToken,
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};


export const _groupCreate = async (
  groupData = {
    groupName: '',
    groupDescription: '',
  },
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/group/create`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...groupData
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};


export const _groupUpdate = async (
  groupData = {
    groupName: '',
    groupDescription: '',
    token: ''
  },
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/group/update`;
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...groupData
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};



export const _groupDelete = async (
  token,
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/group/delete`;
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "token": token
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};

export const _groupLeave = async (
  token,
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/group/leave`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "group": token
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};

