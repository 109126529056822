import React, { Component } from 'react';

import './components/hd.css';
import './components/hd_add.css';

import { SocialProvider } from './contexts/socialContext';
import { StateProvider } from './contexts/stateContext';

import { RoutesMain } from './components/RoutesMain';
import { LangProvider } from './contexts/langContext';
import { MessageProvider } from './contexts/messageContext';

class App extends Component {



    render() {
        return (
          <>
            <SocialProvider>
              <StateProvider>
                <LangProvider>
                  <MessageProvider>
                    <RoutesMain />
                  </MessageProvider>
                </LangProvider>
              </StateProvider>
            </SocialProvider>
          </>
        );
    }
}

export default App;