import React, { useContext, useEffect, useState } from 'react';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { PentasBrowser } from './PentasBrowser';


export function PentasChoose({
  expanded, setExpanded = () => void (0)
}) {



  const [currentPenta, setCurrentPenta] = useState(expanded);

  useEffect(() => {
    if (expanded !== currentPenta) setCurrentPenta(expanded);
  }, [expanded]);

  const context = { ...useContext(SocialContext), ...useContext(StateContext) };


  const customHandlerInner = (userAvatar) => {
    context.x.addToPenta(currentPenta, userAvatar.avatar.token);
    setCurrentPenta(false);
    setExpanded(false);
  };


  return (
    <>

      <div className={currentPenta ? "x_pentas_panel x_pentas_panel--expanded" : "x_pentas_panel"}
        onClick={!currentPenta ? () => setExpanded(false) : () => void (0)}
      >

        {context.state.peoplePanel && <div
          onClick={() => setExpanded(false)}
          style={{ opacity: currentPenta ? 1 : 0 }} className="x_pentas_panel_z"
        />}

        {context.state.peoplePanel && <PentasBrowser
          scope={['people', 'bodygraphs', 'celebs']}
          customHandler={customHandlerInner}
          pentaId={currentPenta}
          expanded={expanded}
          handleClose={() => {
            setExpanded(false);
            setCurrentPenta(false);
          }} />}


      </div>
    </>
  );
}
