const percentGlobal = {
  "1/3": 14.52,
  "1/4": 2.31,
  "2/4": 14.32,
  "2/5": 2.28,
  "3/5": 14.19,
  "3/6": 2.25,
  "4/6": 14.28,
  "4/1": 2.38,
  "5/1": 14.37,
  "5/2": 2.29,
  "6/2": 14.43,
  "6/3": 2.38
}
const lineStats = {
  "1": 14.52,
  "2": 14.32,
  "3": 14.19,
  "4": 14.28,
  "5": 14.37,
  "6": 14.43,
}

export const profilesValid = [
  '13',
  '14',
  '24',
  '25',
  '35',
  '36',
  '46',
  '41',
  '51',
  '52',
  '62',
  '63',
]


export const lines_dictionary = {
  "1": {
    'percentGlobal': lineStats['1'
    ],
    "line": "1",
    "title": "Investigator",
    "description": "An investigative and skeptical mind, always seeking deeper understanding and knowledge."
  },
  "2": {
    'percentGlobal': lineStats['2'
    ],
    "line": "2",
    "title": "Hermit",
    "description": "A solitary and introspective nature, preferring to observe and reflect rather than engage with the outside world."
  },
  "3": {
    'percentGlobal': lineStats['3'
    ],
    "line": "3",
    "title": "Martyr",
    "description": "A willingness to experience and sacrifice for the sake of deeper learning and evolution."
  },
  "4": {
    'percentGlobal': lineStats['4'
    ],
    "line": "4",
    "title": "Opportunist",
    "description": "A natural ability to recognize and seize opportunities, adapting and improvising in the moment."
  },
  "5": {
    'percentGlobal': lineStats['5'
    ],
    "line": "5",
    "title": "Heretic",
    "description": "A tendency to question and challenge conventional wisdom and authority, seeking to explore beyond established boundaries."
  },
  "6": {
    'percentGlobal': lineStats['6'
    ],
    "line": "6",
    "title": "Role Model",
    "description": "A capacity to provide stability, guidance, and inspiration to others, grounded in a deep understanding of tradition and responsibility."
  }
}



export const profiles_dictionary = {
  "1/3": {
    "percentGlobal": percentGlobal[
      "1/3"
    ],
    "lines": [
      1,
      3
    ],
    "profile": "1/3",
    "title": "Investigator/Martyr",
    "description": "You are naturally very skeptical and always catch people who are trying to lie to you. You need to investigate and study, but don’t be too cautious—allow yourself to experiment. Let life be your teacher so that you can find out what truth really means for you."
  },
  "1/4": {
    "percentGlobal": percentGlobal[
      "1/4"
    ],
    "lines": [
      1,
      4
    ],
    "profile": "1/4",
    "title": "Investigator/Opportunist",
    "description": "You have a strong focus on your own internal process throughout life. The 4th line can bring a focus on networking and being part of a larger community. You are a natural networker, able to connect with people from different backgrounds and bring them together."
  },
  "2/4": {
    "percentGlobal": percentGlobal[
      "2/4"
    ],
    "lines": [
      2,
      4
    ],
    "profile": "2/4",
    "title": "Hermit/Opportunist",
    "description": "You have a natural ability to connect with others and build relationships, but also a strong need for solitude and introspection. Your challenge is to balance these two sides of your personality, using your insights and connections to contribute to the world in a positive way."
  },
  "2/5": {
    "percentGlobal": percentGlobal[
      "2/5"
    ],
    "profile": "2/5",
    "lines": [
      2,
      5
    ],
    "title": "Hermit/Heretic",
    "description": "You are naturally inclined towards introspection and contemplation. You have a gift for seeing the big picture and making connections that others may miss. You are here to question authority and challenge the status quo, but you also need to be careful not to become too isolated. You thrive in environments that allow you to learn and grow, but you also need to take time to rest and integrate your experiences."
  },
  "3/5": {
    "percentGlobal": percentGlobal[
      "3/5"
    ],
    "profile": "3/5",
    "lines": [
      3,
      5
    ],
    "title": "Martyr/Heretic",
    "description": "You are here to question authority and challenge the status quo. You are a natural experimenter and enjoy trying new things, but you also need time to reflect on your experiences and integrate what you learn."
  },
  "3/6": {
    "percentGlobal": percentGlobal[
      "3/6"
    ],
    "profile": "3/6",
    "lines": [
      3,
      6
    ],
    "title": "Martyr/Role Model",
    "description": "You are here to be a role model and inspire others. You are naturally charismatic and have a gift for connecting with people. "
  },
  "4/6": {
    "percentGlobal": percentGlobal[
      "4/6"
    ],
    "profile": "4/6",
    "lines": [
      4,
      6
    ],
    "title": "Opportunist/Role Model",
    "description": "You are here to be a role model and inspire others. You have a natural talent for making connections and bringing people together.  "
  },
  "4/1": {
    "percentGlobal": percentGlobal[
      "4/1"
    ],
    "profile": "4/1",
    "lines": [
      4,
      1
    ],
    "title": "Opportunist/Investigator",
    "description": "You are here to experiment and try new things. You have a natural talent for seeing the potential in people and situations and enjoy taking risks. You also have a skeptical side and are always on the lookout for the truth. Learning to balance your desire for freedom and experimentation with your need for stability and security is key to your growth and development."
  },
  "5/1": {
    "percentGlobal": percentGlobal[
      "5/1"
    ],
    "profile": "5/1",
    "lines": [
      5,
      1
    ],
    "title": "Heretic/Investigator",
    "description": "You have the energy of investigation and skepticism in the conscious part of your life, while the unconscious side of your life has the energy of chaos, unrest, and revolution.  ",
  },
  "5/2": {
    "percentGlobal": percentGlobal[
      "5/2"
    ],
    "profile": "5/2",
    "lines": [
      5,
      2
    ],
    "title": "Heretic/Hermit",
    "description": "You have the energy of skepticism and investigation in the conscious side of your life, and the energy of introspection and withdrawal in the unconscious part of your life. You are a natural investigator and skeptic, and you always seek to uncover the truth. However, you need to balance this with your need for alone time and introspection. You are here to discover your own truth and to share it with others in a way that is compassionate and respectful.",
  },
  "6/2": {
    "percentGlobal": percentGlobal[
      "6/2"
    ],
    "profile": "6/2",
    "lines": [
      6,
      2
    ],
    "title": "Role Model/Hermit",
    "description": "You have the energy of leadership and guidance in the conscious part of your life, while the unconscious side of your life has the energy of introspection and withdrawal. You are a natural leader and role model, but you also need time alone to reflect and recharge. You are here to guide others towards their own truth and to inspire them to be their best selves.",
  },
  "6/3": {
    "percentGlobal": percentGlobal[
      "6/3"
    ],
    "profile": "6/3",
    "lines": [
      6,
      3
    ],
    "title": "Role Model/Martyr",
    "description": "You have the energy of leadership and guidance in the conscious part of your life, while the unconscious side of your life has the energy of sacrifice and martyrdom. You are a natural leader and role model, but you also have a tendency to take on too much and sacrifice yourself for others. You are here to guide others towards their own truth and to inspire them to be their best selves, but you must also learn to take care of yourself and set healthy boundaries.",
  }
}