import React, { memo, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Celebs } from './Lists/Celebs';
import { PublicMe } from './UiKit/PublicMe';

export const PublicSocial = memo((props) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const navigate = useNavigate();

  const {
    children,
    peopleShown = false,
    hidePreviews
  } = { ...props };
  const [searchParams] = useSearchParams();






  const [penta, setPenta] = useState();
  const [pentaId2, setPentaId2] = useState();
  const [pentaAvatars, setPentaAvatars] = useState();




  const [socialView, setSocialViewInner] = useState(['geo', 'people', 'followers', 'bodygraphs'])

  const setSocialView = (what) => {
    if (socialView.includes(what)) {
      setSocialViewInner(prev => prev.filter(one => one !== what))
    } else {
      setSocialViewInner(prev => [...prev, what])
    }
  }


  const filterMeOut = (array) => array.filter(one => {
    // return one
    // console.log('one.avatar.token = ', one.avatar.token);
    return one.avatar.token !== context.me.avatar.token
  })

  const panelExpanded = context.state.peoplePanel;

  let urlParams = new URLSearchParams(window.location.search);

  return (
    <>



      <div className={panelExpanded ? "x_people0 active" : "x_people0 x_people0--public"}>




        {/* {!panelExpanded && <div className="x_choose_open0"
          onClick={() => context.setState.showPeoplePanel(['people'])}
        >
          <div className="x_choose_open"><div className="t_icon t_icon--60"><div>people</div></div></div>
        </div>} */}




        <div className={panelExpanded ? "x_choose_panel active" : "x_choose_panel"}>
          <div className={panelExpanded ? "x_choose0 active" : "x_choose0"}>



            <div className={panelExpanded ? "x_people_panel active" : "x_people_panel"}>


              <div className={panelExpanded ? "x_panel_h x_panel_h--people active" : "x_panel_h x_panel_h--people"}>


                {!panelExpanded && <div className="x_action" onClick={() => context.setState.showPeoplePanel(['people'])}>
                  <div className="t_icon t_icon--20">
                    <div>chevron_right</div>
                  </div>
                </div>}






                {/* {context.me && panelExpanded && <div className="x_panel_friends_title">
                  <div className="x_panel_icon">
                    <div className="t_icon t_icon--20">
                      <div>people</div>
                    </div>
                  </div>
                  <div className="x_panel_title active">
                    {$ui('people_header')}
                  </div>
                </div>} */}









              </div>



              <div
                className={!panelExpanded ? "x_choose_left" : "x_choose_left x_choose_left--open"}
              // className={"x_choose_left"}
              >






                <div className={panelExpanded ? "x_choose_container x_choose_container--v" : "x_choose_container"}>

                  {Boolean(urlParams.get('dob')) && <div className="x_choose_contents">

                    {/* <ListCity user={context.me.user} /> */}
                    <PublicMe />

                  </div>}

                  {!hidePreviews && <div className="x_choose_contents">
                    {/* <ListCity user={context.me.user} /> */}
                    <Celebs />
                  </div>}


                </div>



              </div>


            </div>
            {panelExpanded && <div className="x_choose_bg">
              <div className="x_edu_bg g" />
            </div>}






          </div>
        </div>

        {panelExpanded && <div
          className="x_choose_z"
          onClick={(prev) => context.setState.showPeoplePanel()}
        ></div>}


      </div>






      {/* <ListPersonas title='Pentas' quantity={context.pentas.length}>
        {context.pentas.map((one, ind) => <div key={`penta_${ind}`}>
          <Persona
            name={one.name}
          />
        </div>)}
      </ListPersonas> */}



      {/* <ListPersonas title='Groups' quantity={context.groups.length}>
        {context.groups.map((one, ind) => <div key={`penta_${ind}`}>
          <Persona
            name={one.name}
          />
        </div>)}
      </ListPersonas> */}


    </>
  )
})