import React, { useContext } from 'react';
import { MessageContext } from '../contexts/messageContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Faq } from './Faq';
import { WithVersion } from './UiKit/VersionToggler';




export const Layout = React.memo((props) => {
  const {
    children
  } = { ...props };


  const context = { ...useContext(SocialContext), ...useContext(StateContext) };
  const messageContext = { ...useContext(MessageContext) }

  return (
    <>





      <WithVersion>
        <div className="x_beta inact"><div>experimental</div></div>
      </WithVersion>

              {children}


      {Boolean(context.me) && !messageContext.unauthorized && !context.state.citySearchActive && <Faq />}

    </>
  )
})