import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Avatar } from './Avatar';
import { AvatarName } from './AvatarName';


export function MobMenu({ avatar = {}, penta = {} }) {
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;

  const [active, setActive] = useState(context.state.peoplePanel ? context.state.peoplePanel : []);
  useEffect(() => {
    if (context.state.peoplePanel && context.state.peoplePanel[0] !== active[0]) {
      setActive(context.state.peoplePanel)
    }
    if (!context.state.peoplePanel && active.length) {
      setActive([])
    }
  }, [context])


  const pentaName = (penta) => {
    if (penta.members.length) {
      let letter1 = penta.members[0].avatar.name;
      letter1 = letter1.slice(0, 1)
      let letter2 = ''
      if (penta.members[1]) {
        letter2 = penta.members[1].avatar.name;
        letter2 = letter2.slice(0, 1);
      }
      if (letter2) {
        return `${letter1} + ${letter2}`
      } else {
        return `${letter1}`
      }
    }
  }



  // HASH
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    const panel = params.get('p');
  }, [location]);



  const closePanel = (newPanel) => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    params.delete('p');
    const newHash = params.toString();
    window.history.replaceState(null, '', `${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
    context.setState.showPeoplePanel()
  };


  const setPeoplePanel = (newPanel, group) => {
    const hash = location.hash.substring(1); // Remove the leading '#'
    const params = new URLSearchParams(hash);
    if (newPanel) {
      params.set('p', newPanel); // Set the new panel parameter
      context.setState.showPeoplePanel(newPanel);
    } else {
      params.delete('p');
      context.setState.showPeoplePanel();
    }
    if (group) { params.set('g', group); }
    const newHash = params.toString();
    navigate(`${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
  };





  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop > 120) {
        setIsScrolledDown(scrollTop > lastScrollTop);
        setLastScrollTop(scrollTop);
      }

      if (scrollTop + windowHeight >= documentHeight - 10) {
        setIsAtBottom(true);
        setIsScrolledDown()
      } else {
        setIsAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);



  const goTO = (to) => {
    setPeoplePanel(to)
    context.setState.setCalcShown(false);
  }

  // console.log('context.state.citySearchActive = ', context.state.citySearchActive);

  return (
    <>
      <div className={isScrolledDown ? 'x_0_mob_menu0 scrolled' : `x_0_mob_menu0 ${context.state.citySearchActive ? 'scrolled' : ''}`}
        style={{
          marginBottom: context.state.calcShown ? '-8em' : '0'
        }}
      >
        <div className="x_panel_h x_panel_h--mainmenu active">
          <div className="x_panel_sub0">

            <div
              className={active.includes('world') ? "x_mobmenu_item active" : "x_mobmenu_item"}
              onClick={() => goTO(['world'])}
            >
              <div className="x_mobmenu_icon">
                <div className="t_icon">
                  <div>travel_explore</div>
                </div>
              </div>
              <div className="x_panel_sub_text">{$ui('people_city')}</div>
            </div>

            <div
              className={active.includes('people') ? "x_mobmenu_item active" : "x_mobmenu_item"}
              onClick={() => goTO(['people', 'followers'])}
            >
              <div className="x_mobmenu_icon">
                <div className="t_icon">
                  <div>group</div>
                </div>
              </div>
              <div className="x_panel_sub_text">{$ui('people_people')}</div>
            </div>


            {/* {context.state.peoplePanel && <div
              className={active.includes('history') ? "x_mobmenu_item active" : "x_mobmenu_item"}

              onClick={() => context.setState.showPeoplePanel(false)}
            >
              <div className="x_mobmenu_icon">
                <div className="t_icon">
                  <div>history</div>
                </div>
              </div>
              <div className="x_panel_sub_text">Recents</div>
            </div>} */}

            {Boolean(avatar.token) && context.state.peoplePanel && <div
              // className={active.includes('history') ? "x_mobmenu_item active" : "x_mobmenu_item"}
              className={"x_mobmenu_item"}
              onClick={() => goTO()}
            >
              <div className="x_logo_mob_user_ava0">
                <div className="x_logo_mob_user_ava">
                  <Avatar
                    style={{
                      fontSize: '0.8em'
                    }}
                    avatar={avatar} />
                </div>
              </div>
              <div className="x_panel_sub_text">
                {/* {avatar.name ? avatar.name : context.getDefaultTitle(avatar.design, 'type', context.language)} */}
                <AvatarName avatar={avatar} />
              </div>
            </div>}

            {Boolean(avatar.token) && !context.state.peoplePanel && <div
              className={!context.state.peoplePanel ? "x_mobmenu_item active" : "x_mobmenu_item"}
              onClick={() => goTO(['history'])}
            >
              <div className="x_logo_mob_user_ava0">
                <div className="x_logo_mob_user_ava">
                  <Avatar
                    active={true}
                    style={{
                      fontSize: '0.8em'
                    }}
                    avatar={avatar} />
                </div>
              </div>
              <div className="x_panel_sub_text">{avatar.name ? avatar.name : context.getDefaultTitle(avatar.design, 'type')}</div>
            </div>}


            {Boolean(penta.token) && !context.state.peoplePanel && <div
              className={!context.state.peoplePanel ? "x_mobmenu_item active" : "x_mobmenu_item"}
              onClick={() => goTO(['history'])}
            >
              <div className="x_mobmenu_icon">
                <div className="t_icon">
                  <div>group</div>
                </div>
              </div>
              <div className="x_panel_sub_text">{pentaName(penta)}</div>
            </div>}

            {Boolean(penta.token) && context.state.peoplePanel && <div
              className={!context.state.peoplePanel ? "x_mobmenu_item active" : "x_mobmenu_item"}
              onClick={() => goTO()}
            >
              <div className="x_mobmenu_icon">
                <div className="t_icon">
                  <div>group</div>
                </div>
              </div>
              <div className="x_panel_sub_text">{pentaName(penta)}</div>
            </div>}


            <div
              className={active.includes('bodygraphs') ? "x_mobmenu_item active" : "x_mobmenu_item"}
              onClick={() => goTO(['bodygraphs'])}
            >
              <div className="x_mobmenu_icon">
                <div className="t_icon">
                  <div>person_search</div>
                </div>
              </div>
              <div className="x_panel_sub_text">{$ui('people_bodygraphs')}</div>
            </div>

            <div
              className={active.includes('pentas') ? "x_mobmenu_item active" : "x_mobmenu_item"}
              onClick={() => goTO(['pentas'])}
            >
              <div className="x_mobmenu_icon">
                <div className="t_icon">
                  <div>diversity_3</div>
                </div>
              </div>
              <div className="x_panel_sub_text">{$ui('people_synergy')}</div>
            </div>

          </div>
        </div>
      </div>


    </>
  )
}