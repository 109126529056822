import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ui_local from '../../static/ui_local.json';
import { getLocal } from '../utilsSocial';
import { renderLogo } from './Topbar';
import hd_preloader from './hd_preloader.svg';
import { Preloader, Preloader0 } from './kit';


export function PreloaderHD({ children, nologo, style = {} }) {

  const [language, setLanguage1] = useState(getLocal('language') ? getLocal('language') : 'en')
  const [ui, setUi] = useState({ ...ui_local });

  const [v, setV] = useState(true)
  useEffect(() => {
    if (!v) setTimeout(() => {
      setV(true)
    }, 200);
  }, [])

  const $ui = (str, field) => {
    if (ui && Object.keys(ui).length) {
      if (!field) { return ui[str] ? ui[str][language] : str }
      else { return ui[str][`${field}-${language}`] ? ui[str][`${field}-${language}`] : str }
    } else {
      return str
    }
  };


  return (
    <Preloader0 style={{ zIndex: 100, position: 'absolute', ...style }}>
      {!nologo && <div className="x0_logo">
        <div className="x_logo_container">
          <div className="x_logo">
            HD
            <div className="hd_logo_img w-embed">
              {renderLogo()}
            </div>
            Cafe
          </div>
        </div>
      </div>}
      <Preloader width={80} height={80} />
      <div className="_t12">
        {Boolean(children) ? children : $ui('loading')}
      </div>
    </Preloader0>
  );
}
export function PreloaderHDnew(props) {

  const [v, setV] = useState(true)
  useEffect(() => {
    if (!v) setTimeout(() => {
      setV(true)
    }, 200);
  }, [])

  return (<>
    {/* <PreloaderMain> */}
      <PreloaderSkeleton className='x_bodygraph_svg x_bodygraph_svg--main' style={{ opacity: v ? 1 : 0 }} />
      <PreloaderDesign className='x_bodygraph_svg x_bodygraph_svg--main' />
      <PreloaderContent>
        <div className="x0_logo">
          <div className="x_logo_container">
            <div className="x_logo">
              HD
              <div className="hd_logo_img w-embed">
                {renderLogo()}
              </div>
              Cafe
            </div>
          </div>
        </div>
      </PreloaderContent>
    {/* </PreloaderMain> */}
  </>
  );
}

const PreloaderDesign = styled.div`
  background-image: url(${hd_preloader});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  /* height: 80vh; */
  /* aspect-ratio: 3/4; */
  z-index: 1;
`;

const PreloaderSkeleton = styled.div`
  transition: opacity .2s;
  /* height: 78vh; */
  /* aspect-ratio: 3/4; */
  position: absolute;
  box-shadow: inset 0 0 0 4px #fff;


  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, hsl(201.17647058823522, 12.230215827338126%, 72.74509803921568%), hsl(200, 20%, 95%));
    background-size: 200% 100%;
    animation: skeleton-loading 1s linear infinite alternate;
    z-index: -1;
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 0%;
    }
  }
`;

const PreloaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure it is over the skeleton */
  z-index: 2; /* Ensure it is above the skeleton */
`;

const PreloaderMain = styled.div`
  position: ${({ height }) => (height ? 'relative' : 'absolute')};
  width: 100%;
  height: ${({ height }) => (height ? height : '100dvh')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 100;
  font-size: 1.4em;
`;

