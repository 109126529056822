export const crosses_types = [
  { "id": "right", "profiles": [[1, 3], [1, 4], [2, 4], [2, 5], [3, 5], [3, 6], [4, 6]] },
  { "id": "left", "profiles": [[5, 1], [5, 2], [6, 2], [6, 3]] },
  { "id": "juxtaposition", "profiles": [[4, 1]] },
]

export const crosses_dictionary = [
  { "title": "Cross of the Sphinx", "gates": [1, 2, 7, 13], "description": "You are here to follow your own lead and be in the moment. You are transfixed by the now and your way of doing things. This self absorption is your individual contribution to the world. While it is not your intention to give others direction, you do. By following your heart and doing your own expressive thing, others can find direction through your example and will follow. Do your own thing and make sure it is your passion to your core." },
  { "title": "Cross of the Sphinx", "gates": [1, 2, 7, 13], "description": "You are here to follow your own lead and be in the moment. You are transfixed by the now and your way of doing things. This self absorption is your individual contribution to the world. While it is not your intention to give others direction, you do. By following your heart and doing your own expressive thing, others can find direction through your example and will follow. Do your own thing and make sure it is your passion to your core." },
  { "title": "The Juxtaposition Cross of Self-Expression", "gates": [1, 2, 4, 49], "description": "Your Cross has the energy of self expression. You are here to be different and do your own thing. It is through individual expression that the rest of us can look on and adapt to the new ideas that we feel resonance with. Understand though that individualism is not always adaptive and some self expression will drive others away. It is the nature of the energy and it is not personal." },
  { "title": "Cross of Defiance", "gates": [1, 2, 4, 49], "description": "Your Cross brings the energy that stands up to controlling influences. You are here to embody self expression. There will be people that attempt to impose rules of just how you can do that. Your energy is to defy those rules because how can anyone put bounds on self expression? You are here to make sure your expression has a voice and no one can box that in." },
  { "title": "Cross of the Sphinx", "gates": [2, 1, 13, 7], "description": "The energy of this Cross is to see and be open to the possibilities. You are here to guide us by showing us the many possible directions and ways to create a beautiful world. You point out the many possible roads we may choose to take or give suggestions for alternate routes. However, you may have difficulty explaining why to take that suggested route." },
  { "title": "The Juxtaposition Cross of the Driver", "gates": [2, 1, 49, 4], "description": "The energy of your Cross will give you direction in your life. That direction is going to be tied to discovering the truth -- your truth, the truth of those around you and perhaps universal truths. This energy will give you consistent direction in your life and you will try to pull those around you forward toward that destiny of knowing the Truth." },
  { "title": "Cross of Defiance", "gates": [2, 1, 49, 4], "description": "Whether conscious of this energy or not, you are here to step out of line and to represent the ''other'' way of doing things. Your perceived defiance may be small or great but your goal here is to bring about variations from the norm. Most of the time this will result in slight variances coming into acceptance. This occurs not from your action but rather from the perception of your action by others. It is in this way that you lend your design to society, tweaking those things that are mired and need change to be better." },
  { "title": "Cross of Laws", "gates": [3, 50, 60, 56], "description": "You have the energy that drives you to have laws. As a young child with this Cross if there were not rules and laws in your house, you may have found the scenario disturbing. This Cross is about living out the laws of the tribe. The lead energy has mutative qualities so there is the drive to make the laws better, but this is not revolutionary energy. The lead energy has more of a gradual change, as the law has limitations. If these limitations were to change drastically, it would feel like chaos." },
  { "title": "The Juxtaposition Cross of Mutation", "gates": [3, 50, 41, 31], "description": "This Cross carries the energy to cause mutation or change. These changes are generally associated with rules or laws. There may be some moral judgment as any time you try to change law you may be perceived as a dissident. If you make the change from the position of power you may be perceived as being corrupt. But you are here to be a mutative force in changing the tribal rules and laws." },
  { "title": "Cross of Wishes", "gates": [3, 50, 41, 31], "description": "Your Cross has the energy to provide leadership for change in the law and the way things operate. This is always going to create tension as there will always be those who wish for things to stay the same and those wishing for change. This Cross is about wishing and hoping for a better future. You are here to make that wish for the future and provide some leadership to make it happen." },
  { "title": "Cross of Explanation", "gates": [4, 49, 23, 43], "description": "Your Cross is driven by the energy of the 4th gate which is ''the answer'', but this answer is really just a theory that has to be expressed and then verified. You are here to explain your theories and this is a bit of a challenge. The explanation you give will be individualistic and for a logical theory to be accepted, it needs to appeal to the collective. Your mission is to give the rest of us an uncommon explanation, one that most of us wouldn't think of. It is this energy that gets the collective out of a wrong belief  or difficult situation . Then it is up to society to decide if it is brilliant or bizarre. Don't take it personally if your idea is viewed as bizarre, this is just the dance between collective and individual energy." },
  { "title": "The Juxtaposition Cross of Formulization", "gates": [4, 49, 8, 14], "description": "You are here to express your theories about patterns and formulas that explain things. You are not here to back them up, but to lay them out for the rest of us to puzzle over. The energy of your Cross provides the mental ability to see these formulas and your main job is to express them and then let them go." },
  { "title": "Cross of Revolution", "gates": [4, 49, 8, 14], "description": "Your Cross is about revolution that is practical. If the pattern of the revolution fits and is supported by facts, then it is a change that you are willing to invest in. If on the other hand it is a good theory but there is nothing to stand on, then you will let it go. You are here to make practical change." },
  { "title": "Cross of Consciousness", "gates": [5, 35, 64, 63], "description": "You are here to be in the flow of life. All of life is made up of patterns that dance in the river of time. Your energy is the basis of these patterns. You have an inherent gift to understand that it is all about the patterns in life, in nature, and in the universe. If we go with the flow, then our energy is reserved for experiencing the things that move in and out of our lives and is not spent trying to fight the current. You are here to be wise about this and share your enlightenment with others." },
  { "title": "The Juxtaposition Cross of Habits", "gates": [5, 35, 47, 22], "description": "Your Cross brings the energy of patterns and habits and you are here to have a strong influence on those patterns. In your case it will be with respect to your own patterns, which are like ritual to you. For example, you may have patterns about how many times you brush each tooth, which bus you catch to work, how long you take for lunch. At times you may be very rigid about these things, but what you offer the rest of the world is that by having patterns, you can find individual rhythm. Without patterns, life would be chaotic. You help demonstrate the power of rhythm in life." },
  { "title": "Cross of Separation", "gates": [5, 35, 47, 22], "description": "Your Cross has the energy that wants to march to its own beat. This is energy that is looking for patterns and rhythms that work for you and not necessarily anyone else. This Cross is about separation because on your journey you will find that other people's patterns don't work for you. The world needs people that move in their own rhythm. For example, there is the mainstream work force and then there is the need for the others to fill in the void around it, this might be an off-shift or temp position or simply doing your own thing. Your pattern is important to you and it is your sense of individuality that you offer the world." },
  { "title": "Cross of Eden", "gates": [6, 36, 12, 11], "description": "So you were thrown out of Eden, or at least that may be what birth felt like to you. Many who carry this Cross enter the world reluctantly as the womb provides everything that is needed. Over time, you carry the energy to go out and explore the world and to live life. Ultimately your energy is about exploration, not to make your way back to Eden but to move forward and find a slice of Eden here on earth and then share it with those around you." },
  { "title": "The Juxtaposition Cross of Conflict", "gates": [6, 36, 15, 10], "description": "The energy you bring to this life is about finding your opportunity through the relationships you establish. The conflict that you have to endure is finding balance between feeling tossed out of the Garden of Eden and finding joy living on the earthly plane. Part of you will struggle for Eden lost and the other will revel in the joy of life. Use your relationships to find the opportunity to bring joy to your life." },
  { "title": "Cross of the Plane", "gates": [6, 36, 15, 10], "description": "You are here to be an earthly guide. The energy of your Cross is about life on the earthly plane. You are here to teach and be an example that by aligning ourselves with our Human Design, we will get what we need. Not perhaps what our mind thinks we need, but what our body and soul need. You are here to be the guardian of living successfully on the earth in this material world." },
  { "title": "Cross of the Sphinx", "gates": [7, 13, 2, 1], "description": "As the Cross of the sphinx you bring leadership to guide and direct as your energy supports vision for the future. This is probably the most active Cross of seeking leadership, but the drive for leadership in you will depend on your profile and the rest of your chart's composition. Fundamentally, you have the design to look at past patterns and project where they are moving to. This is your greatest asset to bring forth in your leadership." },
  { "title": "The Juxtaposition Cross of Interaction", "gates": [7, 13, 23, 43], "description": "Your Cross is driven to be involved in leadership. This is not necessarily taking the lead but may manifest as a individual contributor to something bigger. You will feel compelled to have a foot in the door in politics or other organizations that provide leadership. Your Cross carries gates of leadership to support you in this endeavor and your 4th line profile can contribute to provide opportunity through your social network. Is this always a 4th line profile?" },
  { "title": "Cross of Masks", "gates": [7, 13, 23, 43], "description": "Your Cross is expected to lead when there is a dire need for someone to step forward and provide a solution. With this energy, there will be an expectation for you to come through and save the day. Preparation is key for you as the expectations are large. Make sure you are ready for the task at hand or turn it down as the consequences of failure may be great." },
  { "title": "Cross of Contagion", "gates": [8, 14, 30, 29], "description": "The energy of your Cross is to be a contributor through your individual effort. Your passion and expression of excitement is contagious and inspires others to follow your lead This energy means it is unlikely you will be the follower of others, at least not all the way. At some point, you will step out on your own to break new ground. When you do, it creates the energy for others to latch on to your ideas and catch the wave." },
  { "title": "The Juxtaposition Cross of Contribution", "gates": [8, 14, 55, 59], "description": "The energy of this Cross is to make your contribution to society through demonstration. For example, in the movie ''It's a Wonderful Life,'' there is an undeniable drive between George and Mary to get married, buy the old house and spend their life continuously fixing it up. You too have a drive to adopt some project and take it under your wing by fixing it and making it better. This is your contribution to society." },
  { "title": "Cross of Uncertainty", "gates": [8, 14, 55, 59], "description": "The energy of your Cross provides you with the desire to get the right house, the right plot of land and all sorts of nice things. The underlying uncertainty of this Cross is in the question, ''Do I have the right (house, car, land, purse, shoes, etc.).'' All people with Cross of Uncertainty have the 55th gate, which represents melancholy and the wave from joy to sadness and back. The trick of living this Cross is realizing that the sadness to joy is a swing and always will be. Know that when you are feeling melancholy, the joy is coming. It is through this awareness and focus that you bring happiness to your life. As your decisions feel right over time, know that you have made the right choice." },
  { "title": "Cross of Planning", "gates": [9, 16, 40, 37], "description": "You are here to contribute planning for your family, group or community. You have the energy to look at the details and focus on what needs to be done. You are not about determining where the energy or financial resources will come from, because that is someone else's responsibility. Your ability to plan is grounded in the priorities of the group you are working with. Your focus is on the ends not the means." },
  { "title": "The Juxtaposition Cross of Focus", "gates": [9, 16, 64, 63], "description": "You are driven with the energy to find focus in things, especially around planning. Like a travel agent, you can race around the internet trying to find all the logical choices. However, that is not always the most productive way this energy works. Your design will more likely find focus by relaxing into the task and allowing the focus to come into view with time. So using the travel agent example, instead of racing around logically working things out, take a more Zen-like approach and allow the pieces to naturally come together. This relaxed method is how you will find focus most successfully and make your contribution to all that you do." },
  { "title": "Cross of Identification", "gates": [9, 16, 64, 63], "description": "Your Cross carries the energy to have great focus and contribution. Your ability to focus and be a strong contributor is directly linked to the rewards and security you are given. Your design is not well suited for a speculative career where instead of income you receive shares or a potential future bonus. You are not a person for Google in the early days as you need the stability in hand. With that stability, you can bring enormous focus to whatever you do and make a big contribution." },
  { "title": "Cross of Vessel of Love", "gates": [10, 15, 46, 25], "description": "The energy of your Cross is based in love. Love of spirit, love of the body, love of humanity and love of the self. You are the embodiment of love. Your love is driven for you to find self-love. Your Cross is looking for a way to express itself as an individual example of love for all to see." },
  { "title": "The Juxtaposition Cross of Behavior", "gates": [10, 15, 18, 17], "description": "Your Cross has the energy to guide and correct the behavior of others. This energy includes opinions to evaluate the logical process and correction to enhance how it is done. The purpose is to get at a better way of doing things and bring greater joy to live. realize, however, those people that you are correcting and guiding are not always going to like your suggestions. Be sure to follow your Human Design type and strategy when engaging in your corrective process." },
  { "title": "Cross of Prevention", "gates": [10, 15, 18, 17], "description": "Your Cross carries the energy to examine behavior. You analyze behavior and then make commentary to correct it. The driving force here is to prevent hardship or injury by correcting the behavior and cutting it off before potential disaster happens. This energy can be a bit judgmental and dictatorial at times. Be aware of your delivery, as it will be more effective if you soften it some of the time. You are here to help others avoid potentially harmful behavior by correcting what seems risky and applauding what appears good." },
  { "title": "Cross of Eden", "gates": [11, 12, 6, 36], "description": "We come into body from the ethereal world that has a lightness and a lack of density. This world is dense and has a heaviness to it. You are here to educate and pass on the importance of the body experience. You come in on the Cross of Eden and are here to express this philosophy while being in body. Some can tend to be weighted down by the heaviness and others can move to express the importance of being in body and how to live it out. You may experience the emotional oscillation between these two points of view." },
  { "title": "The Juxtaposition Cross of Ideas", "gates": [11, 12, 46, 25], "description": "You are apt to be driven to express your ideas. The energy of your Cross supports philosophizing and organizing thoughts, especially about being in body. This is about the physical experience of being human. You have the energy to teach or be a prophet and speak to the world." },
  { "title": "Cross of Education", "gates": [11, 12, 46, 25], "description": "You have the Cross of Education and the energy that supports the value of education. You are designed to be a proponent of education because it is a vehicle for us to gain a better understanding of who we are. As we understand who we are, we are given a chance to evolve. Education is the basis of that evolution and you are here to be involved and help make it available to all." },
  { "title": "Cross of Eden", "gates": [12, 11, 36, 6], "description": "Your Cross has the energy and drive to go and explore the world -- but it really isn't just about you. This is the energy that compels you to bring others along on your exploration. This is the force that led us out of the Garden of Eden. This energy also has ties to art and bringing expression into form. This Cross can go through a period of sadness as there is a lot in the world that is not Eden-like. But fear not the drive to lead or share. Your sense of exploration will come through because that is what you are here to do." },
  { "title": "The Juxtaposition Cross of Articulation", "gates": [12, 11, 25, 46], "description": "Your Cross carries a special energy to be able to spread the word. Your energy is not just about the articulation of the words, but also the energy of love within the message. You have a gift to be able to infect people around you with what you say. Use your gift to make a change for the better in all that you do." },
  { "title": "Cross of Education", "gates": [12, 11, 25, 46], "description": "You are here to educate by way of the lecture. Your style is not really about sharing but more about expounding to the masses who are ready to hear your message. When living within your type and strategy you will find an audience who is open and receptive to hear what you have to say. You are here to deliver the word in whatever field that you happen to have a passion for." },
  { "title": "Cross of the Sphinx", "gates": [13, 7, 1, 2], "description": "You are here to be intuitive about the past. You are here to provide guidance in reconciling the past by assimilating history to draw out the nugget of advice from what has already occurred." },
  { "title": "The Juxtaposition Cross of Listening", "gates": [13, 7, 43, 23], "description": "You are here to listen and collect the history of events and episodes in this life. However, this configuration is not conducive to expressing this history. It is a history, event or secret that very few will ever know. You have great skills as a listener and there are many ways to use this talent. Lawyers, doctors and psychologists all draw on this skill to help their clients while keeping their information confidential." },
  { "title": "Cross of Masks", "gates": [13, 7, 43, 23], "description": "As Cross of Masks, you are here to look at the past and give direction through your own mutative process. You wear the masks because there is pressure on you to give direction. Sometimes that expectation can make you experience melancholy as your Cross expresses individual energy that is looking for the higher love with the another. The twist is that your individual energy is about individual expression which can sometimes struggle when trying to love others simply because then it is no longer just individual. Remember that to find love you have to start the journey alone, or you would already have love. This is your mutative process that you demonstrate for others." },
  { "title": "Cross of Contagion", "gates": [14, 8, 29, 30], "description": "Your Cross has the energy of being well provided for. You also have the energy of saying ''yes'' and making commitments. Your energetic makeup will always draw the necessary resources to you, however your desire to say ''yes'' can get you overextended and lead you to burn out. You need to follow your Human Design type and strategy and make sure your commitments involve things you are passionate about. People will be drawn to you to experience your abundant resources. Make sure you are spending your energies on things that have meaning on your soul level." },
  { "title": "The Juxtaposition Cross of Empowering", "gates": [14, 8, 59, 55], "description": "You have the energetic design to get what you want in this life and a lot of that is going to depend on your security, both financially and romantically. You are only going to be successful at these goals over the long haul, if you follow your Human Design type and strategy because that is just the way the energy flows. If you follow these guidelines, then romance is going to be a key to your happiness. It is your underlying desire to be happy and secure as your personal means to empowerment." },
  { "title": "Cross of Uncertainty", "gates": [14, 8, 59, 55], "description": "The energy of your Cross is to provide the materials and security to reduce the uncertainty. In relationship we are talking about home, shelter, food etc. In work we are talking about product, ideas or direction. There is an energetic void that you fill. There are these people be it family, work or community that have this uncertainty and you have the energy to provide stability and material goods to quell the uncertainty." },
  { "title": "Cross of the Vessel of Love", "gates": [15, 10, 25, 46], "description": "Born as the Vessel of Love, you are here to bring people into the flow in a loving way. All four of the gates of your Cross are connected to the G\/Identity center and, on a deeper level, connection to the Soul. Love of spirit, love of the flesh, love of the self and love of humanity are the four energies that make up this Cross. With the energy of this Cross, humanity leads the way for you. This is about getting people into the flow of love for themselves, each other and love of humanity." },
  { "title": "The Juxtaposition Cross of Extremes", "gates": [15, 10, 17, 18], "description": "The energy of this Cross is about finding rhythm within extremes. You are driven to find rhythm in all aspects of your life but it may be an extreme rhythm. This might look like staying up late and then getting up early, working the night shift, or working out every day of a month and then doing nothing for two weeks. These are all examples of having rhythm but with an extreme twist. It's ok to have these extremes, as that is who you are." },
  { "title": "Cross of Prevention", "gates": [15, 10, 17, 18], "description": "As the Cross of Prevention you speak from a place of love and caution guiding others to prevent following patterns or paths where they might get hurt. Though your goal is to lovingly help others to avoid a negative experience, your guidance will not always be welcome. Make sure you follow your Human Design type and strategy and, most of the time, this energy will be fully accepted by the recipient." },
  { "title": "Cross of Planning", "gates": [16, 9, 37, 40], "description": "You bring the energy of enthusiasm for finding a better way to do things for the collective. This logical energy is amplified when you identify with, or are passionate about, what you wish to improve. You tend to do this work by taking ideas and deeply analyzing them to make life better. Your key role is to bring together the spirit, the ''cheerleader'' and the talent while looking for the depth of the solution. Your challenge will be finding the resources to make what you need happen, because you need to find that outside of yourself." },
  { "title": "The Juxtaposition Cross of Experimentation", "gates": [16, 9, 63, 64], "description": "You have a tendency to become very fixated on something that intently interests you. When you truly identify with something, it will get under your skin and you will be bound and determined to make it happen. You will have a hard time letting go of what you can't seem to manifest. You are here to be determined and by following your Human Design type and strategy, you make that which you identify with come to life." },
  { "title": "Cross of Identification", "gates": [16, 9, 63, 64], "description": "You carry the energy to draw people in to identify with your cause. Whether you are raising money for charity, selling houses or promoting a book, you have the design to get people to identify with the picture you are painting. At the core, it is the foundation of logic, combined with charisma and enthusiasm, that hook people to your cause. You are here to collect people who identify with what you believe in." },
  { "title": "Cross of Service", "gates": [17, 18, 58, 52], "description": "Your Cross brings four logical energies together. The lead energy is opinion. With opinion energy, there is a great drive to correct and organize things so you can live a healthy, longer and more joyful life. You serve people through the logical energies of guidance, organization and correction. There maybe a drive to feel as if you are a healer, but it is important to understand that you are the guide and each person must take their own steps to truly heal." },
  { "title": "The Juxtaposition Cross of Opinions", "gates": [17, 18, 38, 39], "description": "You are here to give opinions. Not all of your opinions are going to be popular and you are not always going to be right. But, your opinions are important to help correct behavior and implement logical process to bring more joy to life. Because being opinionated is how you were designed, it is important for you to remember that in relationship you will need to be with someone who is comfortable with how freely you express your opinions." },
  { "title": "Cross of Upheaval", "gates": [17, 18, 38, 39], "description": "With your Cross, your purpose is to get your hands dirty, muck about and stir things up. You bring the energy to poke at stagnate issues and inspire evaluation, correction and refinement. This energy can create some tension as not everyone wishes for their issues to be stirred up." },
  { "title": "Cross of Service", "gates": [18, 17, 52, 58], "description": "You bring energy that says ''get your stuff together''. The lead energy here is correction and this correction is about making life better and more joyful. This is not always going to be an easy message to bring to the world because not everyone is ready for correction. It may feel personal, but realize that it is not. At times, you may need to soften the delivery of your message and make sure your correction is invited by the recipient, or greater conflict will arise. You are here to shape up this world to make it a better place." },
  { "title": "The Juxtaposition Cross of Correction", "gates": [18, 17, 39, 38], "description": "You are here to correct patterns and discover a more joyful life. You are here to do this for others, however, realize that correction is often not welcome. In order to be successful and maintain healthy relationships, you need to stick to your Human Design type and strategy with your communication. You need to verbalize your correction with tact and care. When you do, you can be truly wise about correction and harmony. If you don't, you may often be alone." },
  { "title": "Cross of Upheaval", "gates": [18, 17, 39, 38], "description": "You are here to stir things up. Your Cross brings the energy to turn things over in an effort to make things better. This is practical energy that understands that if nothing is going to change, it's not worth the effort. You are like the meek kid suddenly rising up to take on the playground bully to make a change for everyone's benefit. That is what you are here to do." },
  { "title": "Cross of the Four Ways", "gates": [19, 33, 44, 24], "description": "Your Cross is driven by the energy to make sure everyone has what they need. The most fundamental need being food. For you this may just be a drive to make sure your family is fed, but for most with this Cross, it is greater than that and can extend to the community, the country or the world. In addition, your Cross has a deep spiritual connection that is intertwined with the drive to feed." },
  { "title": "The Juxtaposition Cross of Need", "gates": [19, 33, 1, 2], "description": "Your Cross has the need to be expressive. This energy is very creative, but there is a strong desire for this creativity to be done in private. You have a need to have your own space where you can let your creative juices flow. You are here to be creative in your own way and in your own space." },
  { "title": "Cross of Refinement", "gates": [19, 33, 1, 2], "description": "The energy of your Cross provides direction about what is needed. This is not about what is needed today, but rather further down the road. Currently this is the voice of the organic movement. ''Sure through use of commercial fertilization and pesticides we are producing a lot of food, but the soil will wear out and the ecosystem is unbalanced and it the long run we are going to have big problems. Sustainable farming is the solution for the long run.'' You are here to be the voice that points us in the longer term direction, especially with respect to food and things we require for survival." },
  { "title": "Cross of the Sleeping Phoenix", "gates": [20, 34, 55, 59], "description": "The energy you carry is about being busy. It doesn't matter what you are doing, you just know that being busy is what you need to be. There is a strong degree of individualism in this energy and from that you can derive strength or aloneness, joy or melancholy. You can be busy and be happy or be busy with your sadness. Finding the happiness is up to you." },
  { "title": "The Juxtaposition Cross of the Now", "gates": [20, 34, 37, 40], "description": "This Cross carries the energy of being in the present moment. It also carries the energy of family and community. The challenge for you is that you can become so lost in the now that you lose sight of your friends, family and community. Ultimately, if you can find the balance to maintain your being in the now and your connectedness to your community, you can be a model for being present." },
  { "title": "Cross of Duality", "gates": [20, 34, 37, 40], "description": "You are here to be the mutative force in the tribe. You carry very industrious energy to do things and get them done. The individual aspects you introduce may be attractive for your community to adapt into their practice. However, not all individual changes are adaptive for two reasons. One, you may wish to keep it as your own thing and not share it. Two, the group or community may not see the value in your new ways and reject it. So there is a dance that you will do in this life, bringing change to the group or community and seeing if it is adopted or not." },
  { "title": "Cross of Tension", "gates": [21, 48, 38, 39], "description": "Your Cross carries the energy of tension that is necessary to maintain order in a tribal environment. This may play out in family, work or community, but the tension is a necessary provocation to keep the group aligned and in order. The tension also helps to provoke response which provides opportunity for more effective leadership." },
  { "title": "The Juxtaposition Cross of Control", "gates": [21, 48, 54, 53], "description": "Your Cross has the energy of needing control. It is through this control that you can take something and recreate it as something new. Everyone has this ability, but not with the strength that you do. With this energy, you can take control of the situation, start anew and make it what it is really supposed to be. Through your drive to take control you bring about innovation, which is why you are truly here." },
  { "title": "Cross of Endeavour", "gates": [21, 48, 54, 53], "description": "The energy in your Cross is about coming together in a very controlled way to make something bigger happen. You endeavor to make something grand and complex happen from bringing together smaller forces. Tight control is a must, for otherwise you have chaos, which is not what you or this energy is about." },
  { "title": "Cross of Rulership", "gates": [22, 47, 26, 45], "description": "Fueled by the energies of listening, educating and figuring things out, you have the energy to rule your world. Whether it manifests as your own individual world, your family, your town or your country, you have the capacity to rule with grace. This is rulership that comes your way -- you do not need to seek it out." },
  { "title": "The Juxtaposition Cross of Grace", "gates": [22, 47, 11, 12], "description": "You have the design of being a listener, and you love to listen. You will fall in love with listening to certain things in you life, just to be able to listen longer. Because of this, you have a gift to work with strangers as they will instinctively know that you are attentive to what they have to say." },
  { "title": "Cross of Informing", "gates": [22, 47, 11, 12], "description": "You have the great gift of the energy to listen and the energy to communicate. You carry the energy to be attentive to others as they speak and then are able to eloquently communicate what has been told. You are the gatherer and distributor of information. Every family, community, government needs someone like you." },
  { "title": "Cross of Explanation", "gates": [23, 43, 49, 4], "description": "You are here to vocalize individual expression. Because it is individual expression, it is new to the collective and your audience may act as if what you are saying is strange or bizarre. You therefore have built-in energy to repeat what you have said or done over and over again. It is through this process of repetition that the rest of humanity  begin to become familiar with what you are expressing. An illustration of this energy is popular fads. Someone with individual energy does something new -- wears a new type of shoe or sports a new hairdo. At first society's reaction is, ''That's just weird.'' But after seeing it 5, 10, 20 times, other people start to do it and it catches on. You are here to introduce us to the new ideas." },
  { "title": "The Juxtaposition Cross of Assimilation", "gates": [23, 43, 30, 29], "description": "You have the energy that pushes you to bring forward individual ideas to create change. individual ideas can at first seem bizarre or foreign to others because they are new and different. The challenge of your Cross is to bring these forward without scaring others off. You will do a dance of holding this expression in until you get to know people, and only then letting your true expression out. Expressing that inner you is your contribution. It is from these individual ideas that new things are born and can be accepted. Just remember, not all new ideas take flight. Some do, and others drop by the wayside." },
  { "title": "Cross of Dedication", "gates": [23, 43, 30, 29], "description": "There is a drive in your Cross to provide the same explanation over and over again. This Cross is a great basis for teaching. Through teaching you can fulfill the burning desire to repeat the same thing over and over again, perhaps over time clarifying your message through its retelling. The dedication you have to explanation is the gift you bring to this lifetime." },
  { "title": "Cross of the Four Ways", "gates": [24, 44, 19, 33], "description": "You are driven to know and have a mental understanding of all that you encounter in life. This lead force will drive you to go over and over things until you have a solid understanding. This force will give you purpose, like the ocean waves polishing the stone on the beach you will return to things, to experiences to memories to go over and over looking for that deeper understanding of how it all works and fits together. This is an individual drive so it is more likely you will use your gift as an example or an individual contributor as most of the rest of us do not have the patience to go over things so many times." },
  { "title": "The Juxtaposition Cross of Rationalization", "gates": [24, 44, 13, 7], "description": "You carry the energy that allows you to be incredibly gifted with grasping concepts and truly understanding them. This energy can make you among the brightest of people on the planet. The challenge for you is to translate your understanding into that of the collective. Not only does the concept have to be explained in the moment, but it also has to be rationalized about how it ties to the past and the future." },
  { "title": "Cross of Incarnation", "gates": [24, 44, 13, 7], "description": "You carry the energy of the process of incarnation. At some level you have a deep understanding that we are always coming and going. The cells in our bodies have a limited life span. As cells die off, they are replaced with new ones. Your energy has a recognition for this pattern. You are here to give direction to all that you encounter. In whatever process something is in its life, you have seen what leads up to this and where it goes. Without even knowing on a conscious level, you provide guidance about where their process will lead them." },
  { "title": "Cross of the Vessel of Love", "gates": [25, 46, 10, 15], "description": "At the very center of all your energies is love. You have the love of the body, the love of humanity, the love of behavior and universal love. You are here to be universally loving to all things. You may be met with shock at some point in your life, and your challenge is to overcome it." },
  { "title": "The Juxtaposition Cross of Innocence", "gates": [25, 46, 58, 52], "description": "You are here to be an influence on the world about bringing forward the love of life and living. This is not necessarily about finding happiness, although that is the ideal situation. This is about finding that effervescent joy of being in body and going though this human existence." },
  { "title": "Cross of Healing", "gates": [25, 46, 58, 52], "description": "The energy of your Cross is to communicate the value of being healthy and its necessity for a joyful life. All the other things -- food, money, love -- pale in comparison if you do not have your health and the ability to enjoy it. As a Cross of the healer, there is a tendency to go through periods of sickness yourself so you can preach to the world about the joy and benefit of healing and being healthy." },
  { "title": "Cross of Rulership", "gates": [26, 45, 47, 22], "description": "You have the energy to promote yourself as a leader. Your skills in spinning your image will sell your ability to lead. This is not a fact-based enterprise. Your ability to lead is based on the marketing energy that you possess. You are here to be a leader and sell yourself as leader. Just make sure you have the goods to get it done." },
  { "title": "The Juxtaposition Cross of the Trickster", "gates": [26, 45, 6, 36], "description": "Your Cross has the energy of ''slight of hand''. You have the ability to sell and make people believe in what you're selling. Whether you are selling cars or a new idea you have the marketing ability and trustworthiness to get the commitment. The energy of your Cross is very important because while it can be used in deceitful ways, it can also help rally people around important ideas where there is not enough empirical evidence yet for support." },
  { "title": "Cross of Confrontation", "gates": [26, 45, 6, 36], "description": "You have the energy to confront rulership and impose rulership of your own. Your energy is designed to have a bit of a hard edge, because you are here to take on people of power who ought to be questioned. Additionally, your energy is designed to be provocative because from provocation comes justification. With the justification comes information to get a deeper understanding of what people are about and what they stand for. Your energetic demeanor is confrontational and has the purpose of helping us get to the bottom of things." },
  { "title": "Cross of the Unexpected", "gates": [27, 28, 41, 31], "description": "As a tribal Cross, you are here to support and stand for your tribe whether that be friends, family, your town, your school, your team or your country. You are here to ensure that your tribe is nurtured and cared for even if it requires bending the law. You will also find that you bring about unexpected things. Whether this is your doing or just the coincidence of the energy around you, the unexpected is something you can expect in your life both for good or not so good." },
  { "title": "The Juxtaposition Cross of Caring", "gates": [27, 28, 19, 33], "description": "You are here to bring a sense of caring for all things and all people. This can be a blessing or, at times, a burden. Caring gives you purpose, and part of that drive is to make sure you are cared for too. This is the energy that speaks of ''Love thy neighbor,'' and it is your process to find purpose for that in your life." },
  { "title": "Cross of Alignment", "gates": [27, 28, 19, 33], "description": "You carry the energy to handle the unexpected in amazingly smooth ways. Due to the geometry of how energy works you attract unexpected things into your life. Your purpose is to move around or through these for yourself and for others. You would be good on the front lines, whether it be military, firefighter, EMT or teacher or any career where balancing the unexpected and moving through smoothly is crucial." },
  { "title": "Cross of the Unexpected", "gates": [28, 27, 31, 41], "description": "You have the energy to struggle with things in this lifetime. If you find your passion, then your struggle will feel worthwhile and productive. If you cannot find your passion, then your struggle will feel depressing. Find your passion and embrace your struggle as it is meant to bring about a deeper and more personal meaning of life. With this Cross, you have the destiny of unexpected things coming up in your life. Enjoy the ride, it does have a purpose." },
  { "title": "The Juxtaposition Cross of Risks", "gates": [28, 27, 33, 19], "description": "The energy of your Cross is that of a gambler. You are willing and driven to take risks for the thrill of it. Through your risks, you are seeking purpose as well as the rush. Follow your Human Design type and strategy and be sure the things you bet heavily on are what you are passionate about. This strategy is how your success is guaranteed! Deleted comment about blind luck." },
  { "title": "Cross of Alignment", "gates": [28, 27, 33, 19], "description": "You are here with the energy to make a shift from the old, traditional paradigm and align with the new. You are here to show the way and be able to do just that. There may be some risk in making the adjustment, but if you follow your Human Design type and strategy, then the risks you take will be justified. There is some inherent danger in making this change because you can be seen as a deserter. However, you are here to align with the new and you understand that nothing lasts forever." },
  { "title": "Cross of Contagion", "gates": [29, 30, 8, 14], "description": "Your Cross carries the energy to say ''yes''. It also carries an enthusiasm for life and is driven to make things grand by marching to the beat. You are here to spread excitement and spread the word about whatever it is that you are passionate about. Your commitment to things is unbounded and fuels the enthusiasm. However, you are here on earth in a physical body and do have some constraints. You need to set some limits to the number of things you say ''yes'' to. Make certain you agree only to what you are absolutely passionate about. If you not, you will surely burn out." },
  { "title": "The Juxtaposition Cross of Commitment", "gates": [29, 30, 20, 34], "description": "You are here to say ''yes'' and be committed to many things. You have a burning desire to agree to everything and unfortunately this will likely burn you out. You have the capacity to have extreme devotion and commitment to a cause and your level of commitment can inspire those around you. Make sure you are committing to something that is truly your passion and follow your Human Design type and strategy." },
  { "title": "Cross of Industry", "gates": [29, 30, 20, 34], "description": "You are here to do things and can find yourself busy all the time. You can be very dedicated to what you do. It is extremely important that you follow your Human Design type and strategy. It is also just as important that you make sure that what keeps you busy is something that you are passionate about. This passion is not just a vague interest, but a passion that flows from the core of your soul. If you can find your passion and put it in motion, you will be among the most industrious, productive people on the planet." },
  { "title": "Cross of Contagion", "gates": [30, 29, 14, 8], "description": "Your Cross carries the energy of desire and to experience what you desire. You are the one who tries new things out. It is your impulse. The rest of us benefit by the results of your experience for good or bad. You also carry the energy that wants to say ''yes''. You may need to manage this tendency, or you will be overwhelmed by all your commitments." },
  { "title": "The Juxtaposition Cross of Fates", "gates": [30, 29, 34, 20], "description": "You carry the energy of drive and obsession. You are driven by a passion that resonates deeply within you and pushes you forward. You will work on your passion endlessly and continuously until you burn out. Burning out is the nature of the rhythm you bring in this life. There have been many great discoveries made by people with this Cross. Is there burn out and then recovery?" },
  { "title": "Cross of Industry", "gates": [30, 29, 34, 20], "description": "The energy of this Cross is to be industrious. There is a strong desire to be busy and see that others are busy. The drive for this business is the goal to bring people together. Your belief is that doing this work, we will all come together. The trick for you is to make sure you follow your strategy and use your business in response instead of trying to outright manifest." },
  { "title": "Cross of the Unexpected", "gates": [31, 41, 27, 28], "description": "You carry the energy that is likely to land you in a place of leadership rather unexpectedly. It will seem to come from nowhere - suddenly you are in the limelight, or at least holding things up. Be aware that you have this energy and at some point will be thrust into a lead role. Get ready to take on the responsibility for this is why you are here." },
  { "title": "The Juxtaposition Cross of Influence", "gates": [31, 41, 24, 44], "description": "You have the energy to be very influential with others because you have a energy to be persistent. This is the design of an agent, advisor or architect, because you will go over the processes repeatedly until you reach your goals. Your ability to influence combines your interpersonal skills with a quality of leadership. You will wish to lead and influence others to follow your way." },
  { "title": "Cross of the Alpha", "gates": [31, 41, 24, 44], "description": "This is the Cross of a leader. You have the alpha energy to be the leader of the pack and make sure the pack's survival needs are taken care of. You have the mental agility to plan and the presence that assures all that they are safe. Safety is an important act of your leadership as the group you lead wants to alleviate the fear based in survival. Your energy lends naturally to this, whether you lead as a general or patriarch, but make sure you enter leadership in accordance with your Human Design type and strategy. This will ensure strength in your leadership style." },
  { "title": "Cross of Maya", "gates": [32, 42, 62, 61], "description": "This Cross has the energy of survival by looking for value. It can contain a strong strain of survival, of saving and storing things. It is energy that is looking for value in others and in materials. You are here to bring these values together in a way to ensure survival of the self and the species." },
  { "title": "The Juxtaposition Cross of Conservation", "gates": [32, 42, 56, 60], "description": "The energy of your Cross is to conserve and preserve life. This may manifest in many ways including some that are fearful. You may be driven to prepare for disaster, stocking food and water. You may be passionate about the environment and moved to serve or be involved with conservation organizations or movements. You are here to offer your voice to preserve life, just be sure not to get to caught up in the fear. While the fear is an important driving force to conserve, it can become overbearing and detract from the good of the cause. Stay grounded in your core purpose." },
  { "title": "Cross of Limitation", "gates": [32, 42, 56, 60], "description": "Your Cross has the energy of knowing limitation in order to survive or achieve preservation. This is based out of energy that is fearful and at times it can speak in terms that there is no use in trying. Your gift is that you can identify and communicate about the limitations that are needed to be successful. Your liability is that with duality the fearful side can be strong and take over and can lead to depression of yourself or others. Strive to keep your mindset positive!" },
  { "title": "Cross of the Four Ways", "gates": [33, 19, 24, 44], "description": "Your Cross is driven by the lead energy which is looking for privacy. This is a retreating energy which is actually looking for freedom that is only found in having your own space. This Cross is tied to housing in many ways because having your own four walls, where you can go and just be yourself, is important to you. You may well be very social, but there comes a time when you need to be alone. This energy could manifest in many forms, but often is about the right for everyone to have their own space to be themselves." },
  { "title": "The Juxtaposition Cross of Retreat", "gates": [33, 19, 2, 1], "description": "Your energy is about the right for everyone to have there own little place of retreat. This is the start of the peace process. To have peace, everyone must have their own little territory, their own four walls in which they retreat and have their own space. You are here to bring this influence and manifest your passion. This is shown just by the example you set by being yourself, whether you are a designer, a city planner, an architect or a volunteer for Habitat for Humanity. Any of these paths and more could be the way you live out your Cross of retreat. Are these vocations tied to space only?" },
  { "title": "Cross of Refinement", "gates": [33, 19, 2, 1], "description": "You bring the energy of having your own living space and nice things within it. Part of the driving force is about privacy and having your own space, not just for you but for the belief that it is everyone's right to have such a thing. In addition to the shelter a home provides, you desire some beauty within it to add to your life. You are here to ensure all of us have the right to our own private and inspiring space." },
  { "title": "Cross of the Sleeping Phoenix", "gates": [34, 20, 59, 55], "description": "Your energy has a great need to be busy. The channel 34-20 is a main line of energy from the Sacral Center to the Throat Center and is about ''doing it'', whatever ''it'' is. Beware not to get lost in your business and taken away from what's important to your soul. Make sure that you are doing things that are your passion and make your heart sing. Follow your Human Design type and strategy to stay focused and grounded. You are here to be very productive and drawn to secure relationships. Ultimately you accomplish this goal by listening to your soul - not by just doing things." },
  { "title": "The Juxtaposition Cross of Power", "gates": [34, 20, 40, 37], "description": "Your Cross brings a lot of power and energy. You are here to exchange that energy for return. It might be in the form of entertainment or it might be by being productive in other ways. You have power to do things that others are after. You are here to share the power and to get what you want. Make sure what you're after is what you want on the soul level and is not overly influenced by your ego." },
  { "title": "Cross of Duality", "gates": [34, 20, 40, 37], "description": "Your Cross has the energy to thrive both individually and as part of a community. Your challenge is to find the balance between these two different types of energy. You may often feel like being alone out of the control and restriction that being a part of group builds around you. Yet in group situations you thrive. You are here to make your contribution to the family, group or community even if you are not always happy being in that situation. You might find balance as an outside contributor or freelancer." },
  { "title": "Cross of Consciousness", "gates": [35, 5, 63, 64], "description": "You are here to balance the energies of desire to have an experience and be done with it. Your motto is ''been there, done that.'' As situations arise in life, many times you may realize that you have experienced something similar before so you don't need to go through all the effort to do that all again. You may feel enormous pressure to experience all aspects of a situation so you can know that experience fully. Your job is to save the collective from repeating what we know and help us experience what we haven't mastered yet." },
  { "title": "The Juxtaposition Cross of Experience", "gates": [35, 5, 22, 47], "description": "You are driven to have very specific and unique experiences. There is this inner desire to focus on a particular experience and then work toward it until completion. There is enormous expectation placed upon the outcome, and you may be disappointed because there was no way for the experience to match up to such a buildup. It is important that you follow your Human Design type and strategy, so you go forward following the experiences that are right for you." },
  { "title": "Cross of Separation", "gates": [35, 5, 22, 47], "description": "The energy of your Cross is to live life with acceptance of all types. There is a heritage in us as humans that we are tribal by nature, treating foreigners from our tribe as suspicious. But your Cross has evolved beyond that as our cities have grown larger there is a need for not knowing everyone or even recognizing them. Your energy is to be accepting and comfortable with strangers around you. This is probably a key talent that you can utilize in your career." },
  { "title": "Cross of the Eden", "gates": [36, 6, 11, 12], "description": "The energy you carry is about being thrown out of Eden and then rediscovering it. Many people on this Cross will come into life and either feel they have been tossed out of paradise  or have an experience early in life that leaves them feeling Eden was lost. Your energy provides the drive to go seek your own Eden through experience. The experience is really your drive to move forward. Your gift is to find this slice of Eden and show it to the world." },
  { "title": "The Juxtaposition Cross of Crisis", "gates": [36, 6, 10, 15], "description": "Your Cross brings the energy of the love of humanity and the desire for intimacy together. This can create conflict from within as you strive to reconcile the behavior of the self rooted in the love of humanity and the desire to act on the drive for intimacy. Your purpose is to bring these to balance in harmony with the rest of your design." },
  { "title": "Cross of the Plane", "gates": [36, 6, 10, 15], "description": "The energy of your Cross is to find balance in intimacy with people in your life. Your energies are driven to find the personal pleasure you require, but to balance that with your life as it fits within the collective. On one hand, you have this push to grab one of your dreams and run off to the cave to be alone, and on the other hand, to be part of the group around the campfire. Finding and displaying the balance is the key gift that you can bring to this world." },
  { "title": "Cross of Planning", "gates": [37, 40, 9, 16], "description": "The energy in your Cross is to create deals and bargains as part of forming community. The structure of communities and institutions are made up of these bargains or compromises. For example, we pay local taxes so that we can have public schools and transportation, among other community benefits. Your energy strives to contribute to the creation, fortification or continuance of community in whatever shape and form that takes." },
  { "title": "The Juxtaposition Cross of Bargains", "gates": [37, 40, 5, 35], "description": "The energy in your Cross is about making deals and bargains which contribute to yourself and may also contribute to making community. There is the potential to find give-and-take in everything, and the energy you carry will strongly express itself to get something back for each action you take." },
  { "title": "Cross of Migration", "gates": [37, 40, 5, 35], "description": "Your energy is to seek a break from the existing communities and groups that are built. By living in a community, one makes bargains and sacrifices in order to please everyone . At some point the community realizes that it can't please everyone. Your energy is there to be the voice to identify that the compromise has gone too far and we need to move on and begin to build again. Your voice contributes to the community building process." },
  { "title": "Cross of Tension", "gates": [38, 39, 48, 21], "description": "Your Cross is based on energy that is looking for a purpose and ready to struggle to make it happen. Because of this quality, it is paramount for you to find purpose in your life. Living your Human Design type and strategy is key to finding that purpose, because that is just how the energy works. If you don't find purpose you will likely end up struggling with lack of purpose and direction. Follow your strategy and listen to your inner voice. Put that energy to fight-through-it-all to good use." },
  { "title": "The Juxtaposition Cross of Opposition", "gates": [38, 39, 57, 51], "description": "You are here to be an opposing force to almost everything you encounter. It is through this opposition that you will make others justify what they do, what they are talking about or what they are selling. This is an important process both to you and others because it is through the justification process that society can begin to see what ideas are legitimate and what are full of holes. You add value to all involved in the conversation because you ask the right questions and push the buttons to reveal the underlying truth." },
  { "title": "Cross of individualism", "gates": [38, 39, 57, 51], "description": "Your Cross carries energy to provoke others and create tension. Through provocation you create tension in others around you. Situations may become heated at times because people will unload on you. Energetically, however, you are ready for this and it does not seem to bother you. What you are doing is forcing them to take a position and making them justify it. You probably don't even know you are doing this, but your energy gives people a little nudge here or there and then they are suddenly worked up. Through this dance you are here to get people going, to get their emotions flowing and push them to find meaning in their lives." },
  { "title": "Cross of Tension", "gates": [39, 38, 21, 48], "description": "You were born with the gift to push people's buttons. While this can create some negative reactions, the goal of the provocation is to find the right spirit in the reaction. Those who you provoke are given a ''gift,'' as they have an opportunity to work on whatever you have provoked within them. They may not always see it that way though, so be forewarned. Your purpose is to create tension which causes a reaction typically in the form of a tirade or emotional expression. From that reaction come the seeds of information that through analysis can lead to the other finding an emotional path to spirit." },
  { "title": "The Juxtaposition Cross of Provocation", "gates": [39, 38, 51, 57], "description": "You carry a design to be deeply provoking to others around you. The goal of this provocation is to create a reaction and movement around the area that you provoke. If there wasn't something for the other to work on or examine, you would not get such a reaction. realize that not everyone is ready to work on things, so be mindful of your timing or you may get more than you bargain for." },
  { "title": "Cross of individualism", "gates": [39, 38, 51, 57], "description": "You are here to be an individual. The energies in your Cross are all individual in nature. This is not about being social or in community. Not that you can't, but ultimately you are here to march to your own drummer. This is a valuable contribution to society as it gives us a chance to view your expression and adopt parts of it if we are inspired to. However it can annoy others who are more community-minded, as it is vastly different from what they accept, so it may be provoking. That provocation is ultimately what leads to change." },
  { "title": "Cross of Planning", "gates": [40, 37, 16, 9], "description": "The point of your Cross is to evaluate the cost of community projects. This is all about the evaluation of the bargain. ''How much work is going to needed, and what is the return?'' Your Cross has the channel of community, but with the transition from individual to community we have to give things up to fit in. We have to give currency of either money or work to build and be a part of that community. Your Cross is here to say, ''like the idea, but is it worth the cost?''" },
  { "title": "The Juxtaposition Cross of Denial", "gates": [40, 37, 35, 5], "description": "You are here to be the brakes on things when we get carried away. You are here to raise the voice of concern that we have already done that. Your Cross carries energy of resistance and that is important energy to the rest of society. Without it we can get wrapped up in what we are doing and head right off the cliff. You are here to question or deny things so the rest of us snap out of the emotional rhythm and use some energy to justify the present course of action." },
  { "title": "Cross of Migration", "gates": [40, 37, 35, 5], "description": "Your Cross has the energy to move on when there is an imminent threat. This could be literally moving or this can also apply to what you do or what you believe in. Your migration is based on survival and fleeing to survive. You will be in denial up to the point that you are convinced you need to move on. This may manifest in your career where you stick with it no matter what, until you finally believe the writing is on the wall and you flee to another occupation." },
  { "title": "Cross of the Unexpected", "gates": [41, 31, 28, 27], "description": "Your Cross has the energy to bump into the unexpected and bring something forward through this experience. What you bring forward is not known at the time of the experience. Your energy will drive you to have expectations about what will become of this discovery, but these expectations may not be met. There is an old commercial where a man eating chocolate bumps into a woman eating peanut butter. The fantasy reveals the product Reese's Peanut Butter Cups and as you probably know, this seemingly unexpected event rendered a successful outcome although the combination could have easily tasted awful. You're here to make the discovery and bring it forward. Expect the unexpected in your life." },
  { "title": "The Juxtaposition Cross of Fantasy", "gates": [41, 31, 44, 24], "description": "The energy in your Cross is about expectation and fantasy about what is going to happen. This energy is good at spotting trends as it can parlay out what is coming next. The prediction is not always right as it is fantasy, but it does have a basis in the observed pattern. This energy is heavily tied to the anticipation of the emotions derived from fantasy." },
  { "title": "Cross of the Alpha", "gates": [41, 31, 44, 24], "description": "Your Cross has the energy to lead others. Often you will need to wait for the opportunity to take that lead, but when it happens you will be ready. You have the ability to lead because you offer an emotional vision that others are ready to follow. You need to follow your Human Design type and strategy and the opportunity to lead will present itself to you." },
  { "title": "Cross of Maya", "gates": [42, 32, 61, 62], "description": "This is the Cross that is driven by closure. It is important for you to pursue what you are passionate about, with a scope that is achievable, regardless of the challenges you may face. Your drive to find closure is only accomplished when you take into account the environment and all that makes up your reality. Understanding fully how your environment works is really the key to being at peace with this Cross." },
  { "title": "The Juxtaposition Cross of Completion", "gates": [42, 32, 60, 56], "description": "The energy of your Cross is the drive to complete things. Long after others have given up you will trudge on and on until you Cross that finish line. Research is often an occupation or hobby that people with your energy fall into. The trick of your Cross is to enter into what you can complete within a reasonable time frame or with tangible definition. Marriage is a serious commitment for your design as you will be driven to complete it for better or worse." },
  { "title": "Cross of Limitation", "gates": [42, 32, 60, 56], "description": "You are here to impose limitations on others. The limitation may come out abruptly such as ''You can't do this,'' or ''You can't continue with that.'' But the limitation is important. It is what saves us from endlessly wandering without structure. It helps provide clarity as we can focus within the boundaries of the limits. It can be safety-oriented like ''You cannot go outside at night.'' But its abruptness comes from lack of explanation. Your tendency will be to put the limits out there and not include the reason for doing so. This won't always be popular, so strive to explain yourself." },
  { "title": "Cross of Explanation", "gates": [43, 23, 4, 49], "description": "Your Cross has the energy that is driven to provide explanation to the world. This is not always an easy task because you are trying to explain what you already know. This is very individual knowing and as others are trying to understand, you may want to stop and just say, ''I know, because I know!'' More likely, you will continue because you are driven to get this information across and thus you keep explaining. Your mission is to bring this individual thought to light as what you are trying to explain can be a stroke of genius." },
  { "title": "The Juxtaposition Cross of Insight", "gates": [43, 23, 29, 30], "description": "You will spend much of your life saying ''Aha!'' You have the insight which is about pulling together abstract pieces of information and putting them all together. Unfortunately you may have trouble explaining yourself. This process is not logical and explanations that others can typically understand involve logic, but there is no logic. This is just putting the pieces of the puzzle together in an instant, and there you have it - ''Aha!''" },
  { "title": "Cross of Dedication", "gates": [43, 23, 29, 30], "description": "You have the energy to express your ''I know's'' to the world. Your energy is driven for you to explain your insight and then leave it for others to digest. Your insight is individual so it may take a while for it to be adapted by the rest of the group. Sometimes it will be too different for others to absorb at all. You are here to throw out these tidbits and the rest of us will either adopt the understanding, or not." },
  { "title": "Cross of the Four Ways", "gates": [44, 24, 33, 19], "description": "Your Cross has the energy to guide and direct based on the past. This is the energy of managing people to provide for the group or tribe. Based on your instinctive experience, you can guide these resources. Like an old fashioned community or a family business, you are the one who assigns the tasks so that it all comes together and the community has everything it needs. You are here to perform this role for your group." },
  { "title": "The Juxtaposition Cross of Alertness", "gates": [44, 24, 7, 13], "description": "You are here to be a watchdog for the universe, or at least your universe. Your Cross gives you the energy to be alert and notice trouble or a pattern that is detrimental. Because of this you are the first one that will notice. You will see it before anyone else takes heed. If what you notice is important to the group, utilize your Human Design type and strategy to take action and people will heed your warning, otherwise you will be the boy who cried wolf." },
  { "title": "Cross of Incarnation", "gates": [44, 24, 7, 13], "description": "Your Cross has the energy of the cycle. You are constantly reviewing things over and over again to see if they are right and to see if there is enough. Driven from the fear of not having enough you, will turn things over and over again. Your role is to let the others in the group, the family, the community or the company know when something is going wrong and then direct the rejuvenation of doing it right once again." },
  { "title": "Cross of Rulership", "gates": [45, 26, 22, 47], "description": "The energy of your Cross of rulership is about taking power and controlling your piece of land. This may not be a whole country, and in fact it may not be land at all. But you will be driven to have control of something. It might be your apartment or house or a department or section of business at work. Whatever it is, there will be this drive to have rulership over it. Rulership is very important as it brings structure to chaos. Ruling is what you are here to do." },
  { "title": "The Juxtaposition Cross of Possession", "gates": [45, 26, 36, 6], "description": "The energy your Cross brings is to possess people in your life. You have a desire to have control and possession of those with whom you are involved. It is important to follow your Human Design type and strategy, otherwise people you are trying to rule will not be accepting and will become distressed or even angry with your relationship. If you need to be invited, then wait to be invited. If you need to respond, then wait to respond. If you need to inform, then inform. Following your Human Design type and strategy is crucial to making your Cross work for others and most importantly, for you." },
  { "title": "Cross of Confrontation", "gates": [45, 26, 36, 6], "description": "This is energy to come from the outside and take control of what is rightfully yours. In society and business there will always be management or control that gets stagnate over time. This Cross is here to shake things up, usually from the outside. Like a corporate raider who comes in to optimize the pieces of a large business, this is energy to take control and lead to a more streamlined and profitable configuration. This is not just about government or business, this force will take hold in your personal life or community. The real drive here is power and control." },
  { "title": "Cross of the Vessel of Love", "gates": [46, 25, 15, 10], "description": "You bring the energy of the love of life. You are into your body and everything that affects it. You are into experiencing this earthly existence and doing it in a loving way. You are here to be love and the key for you is that it has a sensuousness to it. You are here to show others how to love being alive." },
  { "title": "The Juxtaposition Cross of Serendipity", "gates": [46, 25, 52, 58], "description": "You are here to be in the right place at the right time. Part of that is about being in body and enjoying it, in fact loving it. You have a love for this earthly experience and you share that with the world. You are an opportunist and will share your ability to be serendipitous with others." },
  { "title": "Cross of Healing", "gates": [46, 25, 52, 58], "description": "You are here to be love and heal through love. The energy of your Cross supports you to do this with others. Your energy is focused on love of the body and being in body. You may not always see much in return, but your power of healing through love will make a big difference in the world around you." },
  { "title": "Cross of Rulership", "gates": [47, 22, 45, 26], "description": "You are here to make sense out of the past. Your energy brings forward the events from the past and gives the full picture in the present. You may use this to lead or you may simply use it to rule your world whatever that passion is to you. You will always make more sense and have more of a connection with people in your life when you integrate the past into what you are doing now. It is this continuity that you offer to help others connect and feel in tune." },
  { "title": "The Juxtaposition Cross of Oppression", "gates": [47, 22, 12, 11], "description": "The energy of your Cross is to bring ideas to life which connect the past, present and future. While some of your ideas can feel oppressive, this weightiness puts pressure on the rest of us to analyze them for validity. You are here to throw out these ideas and leave it up to the rest of us to push them forward, or shoot them down." },
  { "title": "Cross of Informing", "gates": [47, 22, 12, 11], "description": "This is a Cross of rulership and carries the energy to be open and caring. You are here to be social and informative. You carry the energy that has concern for the greater whole. Your energy is likely to bring information forth through art, song or other expressionistic medium." },
  { "title": "Cross of Tension", "gates": [48, 21, 39, 38], "description": "Your Cross brings a great deal of depth. You have a drive to know and have understanding. You will feel tension to acquire skills that support being able to do things deeply. Only when you are able to relax into the flow, will you find that by living your Human Design type and strategy, the skills you need will come your way when you need them. Trust that as you allow this process, these skills will have the depth you require to be successful. You are here to bring depth and relax away from the tension that forces you to seek endlessly for talent." },
  { "title": "The Juxtaposition Cross of Depth", "gates": [48, 21, 53, 54], "description": "Your Cross is all about depth, about your ability to do things in a very deep way. You will be noticed for your depth and it is through your social network that you will find opportunity to excel. Your inspiration for depth will motivate others to create a better and more logical way of doing things." },
  { "title": "Cross of Endeavour", "gates": [48, 21, 53, 54], "description": "You bring the energy of depth and are here to contribute your depth to the world. Your Cross is dependent on others to make that deep contribution. You may have a tendency to get fed up waiting for that connection with others to happen. Have patience and remember to relax into the flow and wait for the right encounter where your depth can shine. This depth can take on any field of interest. The drive will be to provide a deeper understanding so the process, product or system can be made better and bring more joy to life." },
  { "title": "Cross of Explanation", "gates": [49, 4, 43, 23], "description": "Your Cross carries revolutionary, mutative energy. This energy has tension and aggression because it is emotional. Providing food plays a large role in this Cross and you have the drive that gets us to take action to provide for our bodies' need to be nourished. The Cross is called explanation because there is pressure to explain what your mutation is all about. Your revolution is individual in nature so others may struggle to follow." },
  { "title": "The Juxtaposition Cross of Principles", "gates": [49, 4, 14, 8], "description": "Your Cross carries the energy of standing on principles. It also carries energy of revolution or change. You have a fundamental drive to stand up for what is right, particularly for human rights. There is a central theme around providing or acquiring food because we must have food to feed ourselves. realize you may be ahead of your time and be passionate before the rest of the collective recognizes the threat or problem you see so clearly." },
  { "title": "Cross of Revolution", "gates": [49, 4, 14, 8], "description": "This is the energy of revolution and to make change - as long as it creates some benefit for the common good. Food is a central theme around revolution and has been for a long time. In a ''Robin Hood'' manner, you will see the need to provide for others who are less fortunate." },
  { "title": "Cross of Laws", "gates": [50, 3, 56, 60], "description": "Your Cross has the energy of making laws for the tribe. In order for a tribe to survive, someone needs to hunt or gather the food, someone needs to cook the food and then there needs to be order around who consumes the food and how that is done. Now many of us are a long way from the campfire days, but the principles still hold. Your gift is to set the rules so the family, community or organization can function in a productive, less chaotic way and achieve greater organization. However, as lawmaker, you need to be open to feedback because the rest of the group has to live with you. Typically your lawmaking standards are inherited and you are less open to outside input. If members of your tribe cannot live with the rules you set out and you cannot be open to negotiation, then one or the other must go." },
  { "title": "The Juxtaposition Cross of Values", "gates": [50, 3, 31, 41], "description": "Your energy is to bring forth rules and laws, but you do it with a lenient style. You are here to help the group or family establish order rules and values. Your energy is to support the process which helps regulate the group from breaking down into chaos. Yours is not a stiff upper hand, but rather one that can see the wisdom of circumstance and can bend and negotiate if necessary." },
  { "title": "Cross of Wishes", "gates": [50, 3, 31, 41], "description": "You are here to look at the systems and structures in place and offer your own view of how things could be different. At times this may be unpopular because people are not always willing to give up something that has governed them for a long time. Regardless, you are hear to make the case that certain things don't fit or make sense. You need to be wise about when you share your dreams and wishes or you may be driven out of the group you call community." },
  { "title": "Cross of Penetration", "gates": [51, 57, 54, 53], "description": "You are here to get to the point with your shocking and penetrating energy. Your Cross is about cutting though all the extras and getting right into the heart of the matter or getting to the answer without a lot of fluff. You will probably do this in a shocking way and with ''gall'' as your lead energy is tied to the gall bladder. In your efforts to be so blunt you may help clear the way for others, although that is secondary for your need to do it and get it done." },
  { "title": "The Juxtaposition Cross of Shock", "gates": [51, 57, 61, 62], "description": "The energy of your Cross is driven to shock people. It doesn't matter to you if what you say or do to shock is actually true because it is all about shock for the sake of shock. From shock comes reaction and can come change from the place of complacency. Movement away from lethargy and complacency is what you are here to do." },
  { "title": "Cross of the Clarion", "gates": [51, 57, 61, 62], "description": "The energy of your Cross is to bring shock and create the potential for change for those who are ready. This does not mean that people on the receiving end won't react with a gasp or ''how could you?'' On a deeper level, they have been waiting for the shock to move them to change. This is just energy at work, and your shock needs a receptor. The energy will naturally guide you to those who at some level are ready for the shock to make change and move on." },
  { "title": "Cross of Service", "gates": [52, 58, 17, 18], "description": "The design of your cross is to help guide and correct others. With your lead energy coming out of the 52nd gate you are going to do your guiding from a point of stillness. Like a guidance counselor sitting in an office people will seek you out to hear your advice and opinions about their situation. It is important to follow your type and strategy when offering your opinions and correction as this will produce more positive results." },
  { "title": "The Juxtaposition Cross of Stillness", "gates": [52, 58, 21, 48], "description": "The energy of your cross is to give sage advice when it is asked for. You have an innate stillness in you that demands to be recognized. It is from this point of stillness that your find clarity about all that is around you. You will offer great ideas and solutions when people come to you for advice. Remember an initiating advisor role is not your nature. Your expertise makes that extra impact when elicited from that point of stillness." },
  { "title": "Cross of Demands", "gates": [52, 58, 21, 48], "description": "Your Cross carries the drive to point out what is not working in society. You are here to demand that somebody take action and rectify the problem or find the solution. This energy is based on collective concern, so this is not about an individual situation. This is about a problem or societal dysfunction that affects the greater collective. You are here to make a stand and make your voice heard. You have limited energy to do this, so you must follow your Human Design type and strategy to be effective. You can also count on others to get involved and make the change." },
  { "title": "Cross of Penetration", "gates": [53, 54, 51, 57], "description": "Your Cross carries the energy of starting or initiating something new. You will always have an ambition to change things or begin anew. With opportunities that come to you, you will much prefer to get in on the ground floor than go with something old and established. You are here to be an initiating force with penetrating energy and be recognized for your contribution. You will want to see that what you are working on is done in a big way. This ability to initiate will be most successful if you follow your Human Design type and strategy. You are here to be involved from the beginning of whatever you are passionate about." },
  { "title": "The Juxtaposition Cross of Beginnings", "gates": [53, 54, 42, 32], "description": "The energy in your Cross brings a unique blend of initiation and transformation. Your design is perfect for project management of all sorts because you have energy to assess the cost, scope, and evaluate the potential resources and their added-value to the project. This can apply to projects big and small. People will seek your talent as you have the energy to get projects started, assess the resources needed and initiate the transformation required to getting them done successfully." },
  { "title": "Cross of Cycles", "gates": [53, 54, 42, 32], "description": "You bring forward the energy of the never-ending cycles. This is the story of evolution. Change is constant and it goes in cycles. Cycles have segments of beginning, transformation, maturation and completion, and then it starts all over again. You bring all of these energies forward. You are here to endure and excel at handling the repetitive and ever-changing cycles of evolution." },
  { "title": "Cross of Penetration", "gates": [54, 53, 57, 51], "description": "Your Cross carries the energy of getting noticed through your ambition. Because of your ambitious approach, people will recognize what you do. You also carry the energy to get to the point. You are not likely to beat around the bush - just get to it and get it done. This can be both positive and negative, depending on other people's expectations. If you are asked to be thorough with an assignment, your tendency to do it as quickly and easily may result in unmet expectations." },
  { "title": "The Juxtaposition Cross of Ambition", "gates": [54, 53, 32, 42], "description": "Your Cross carries the energy to make commitments and see them through to completion. You have the drive to push forward and get the job done. It is important that you live your Human Design type and strategy and find the things in your life that your soul wants to commit to. Listen to that voice deep inside you that says, ''That is exactly what I should do!'' Listen to that voice, live your design and make the commitment. You have the design to stick with it and make it happen." },
  { "title": "Cross of Cycles", "gates": [54, 53, 32, 42], "description": "Your design has very powerful, transformational energy to get things done. Your Cross is tied to the cycles that all life experiences. You understand that everything is born and then dies and is then born again. You have an innate sense about the cyclical process. Things are always changing, becoming better and better through the cyclical process of maturation. Your energy is part of this process, so it is most likely your education and career will cycle too. It is not likely you will stay in the same job for 25 years, unless that job can evolve and change with time. You are here to be a master of the maturation process." },
  { "title": "Cross of the Sleeping Phoenix", "gates": [55, 59, 34, 20], "description": "The Sleeping Phoenix rises from the ashes. The energy of your Cross is pushed forward by the energy channeling straight from the sacral to the throat. This energy can be very productive. Within your Cross, however, is the energy that is moody and oscillates between the glass being half-full and half-empty. Your challenge is to ride this wave and find solace in the half-full glasses along the way. You are learning much about the process and quality of emotions and have the gift to teach this to others." },
  { "title": "The Juxtaposition Cross of Moods", "gates": [55, 59, 9, 16], "description": "Your Cross carries the energy of wanting to know things in great depth and detail. If you can balance the moodiness and particularly the melancholy energy that cycles through in your configuration, then you have the talent to bring things forward in a great way." },
  { "title": "Cross of Spirit", "gates": [55, 59, 9, 16], "description": "The energy in your Cross involves the primal need for good food, love and intimacy in your life. Finding fulfillment of these needs is the fuel for your spirit and is the way you feel good about yourself. Once your foundation is set, then you can let your spirit soar." },
  { "title": "Cross of Laws", "gates": [56, 60, 3, 50], "description": "You are here to speak about ideal laws. Your energy is driven to have rules and regulations and you will talk or dream about how to create them. You are here to promote change through your vision of what is possible and expression of how it will be structured with rules. You will not be motivated to speak to the details of the structure, but you will be inspired to share the dream and how it will make things better." },
  { "title": "The Juxtaposition Cross of Stimulation", "gates": [56, 60, 27, 28], "description": "You have a need for adrenaline stimulation. You may be a thrill seeker and will probably revel in telling of your exploits. You have a great design to be an X-games participant, a white water rafting guide or a novelist writing thrillers. You are here to be turned on by adrenaline stimulation and have a need to tell the stories of the adventure." },
  { "title": "Cross of Distraction", "gates": [56, 60, 27, 28], "description": "You have the ability to draw people's attention away from what they are doing or thinking. This is a great Cross for a comedian or someone trying to help people who are lost. This energy can sometimes be a bit of a problem because it is interruptive and you may receive some negative reactions. It can also be focused inward and result in you distracting yourself from whatever you are doing. Ultimately, you are drawing others to what is stimulates you. Use your Human Design type and strategy when distracting and you will find it more welcome and even successful." },
  { "title": "Cross of Penetration", "gates": [57, 51, 53, 54], "description": "You have the unique ability to read other people and know in the now what is right or wrong for them. Your energy supports being intuitive and to even hear the voices of angels or God or other intelligence not in form. Most of the time you will perceive an instantaneous vibe of right or wrong, as the intuitive energy pulses and fades. You are here to be wise about what to do in the moment for you and for others. This knowledge comes from a place of intuitive knowing. You will be straight to the point in all that you do and won't be inclined to include details." },
  { "title": "The Juxtaposition Cross of Intuition", "gates": [57, 51, 62, 61], "description": "You have an intuitive ability, but by design you will probably not voice this ability often. You have the talent to read others and know what is right and wrong for them. Use your Human Design type and strategy to selectively voice your knowledge. This approach is how you will be heard so that you may lend your gift to the world." },
  { "title": "Cross of the Clarion", "gates": [57, 51, 62, 61], "description": "You carry the energy that makes you wise and intuitive. This is energy that others want and over time will seek you out for. You have knowledge and information that will help guide them. Similar to the Sooth Sayer, you can provide practical solutions to the problems of the day. As you grow older, you will find yourself driven to live out your life guided by your intuitive gift." },
  { "title": "Cross of Service", "gates": [58, 52, 18, 17], "description": "The Cross you bring is an interesting blend of the love of beauty and life and the energy to correct the logical process. This correction is an important process in our world because it helps change patterns for the better and moves us forward to make progress. The finding beauty aspect in life may make some people envious as they want access to that energy to utilize it for more practical correction. Your drive is use the energy for less practical matters and more aesthetic or spiritual things." },
  { "title": "The Juxtaposition Cross of Vitality", "gates": [58, 52, 48, 21], "description": "The energy you bring is vital for the process of logic. In the Human Design system, logic is starved for energy, and you have it . But the nature of your Cross wants control. Therefore, you are going to have situations where you are being asked to contribute, but you are driven to ask for control. When your end goal is similar or in the same direction, you will find harmony in the situation but if it is not then the relationship is going to be at odds. Your gift is to provide energy to the logical process and control it for the mutual benefit of all involved." },
  { "title": "Cross of Demands", "gates": [58, 52, 48, 21], "description": "Your Cross carries energy that is useful to other people, therefore others will seek to connect with you to get at that energy. This Cross also offers correction. You may find that you alienate others if you offer this correction without being asked. At times, you may feel taken advantage of and believe everyone is taking your energy. Over time, you strive to find balance to make sure you are getting something out of the deal. It is in this situation that your demands come into play. You will want to make sure what you are getting back is fair return for the energy you give out." },
  { "title": "Cross of the Sleeping Phoenix", "gates": [59, 55, 20, 34], "description": "Driven by the energies in your Cross, sexual desire and intimate relationships will play a foundational role in your experience this time around. Like the mythical Phoenix these energies will cycle through your lifetime through the phases of taking flight, soaring, bursting into flame to become ashes only to rise again. In your early years, this will be closeness to your parents and siblings. In your sexual prime, this will be more about sex and then creating your own family. In your mid to later years, this will return to more of a caretaker role for the extended family. You are here to promote the regeneration of the human species." },
  { "title": "The Juxtaposition Cross of Strategy", "gates": [59, 55, 16, 9], "description": "The energy of your Cross is to have great strategy about relationships. You could be a wonderful matchmaker as you can see where people come together. People will also volunteer information about their interests to you. You may find your own matchmaking more challenging when someone you are interested in has just told you they are interested in another.." },
  { "title": "Cross of Spirit", "gates": [59, 55, 16, 9], "description": "The energy of your Cross is about finding intimacy and romance in relationship. This can range from purely sexual motivation to the tender and moving romance like in the movie ''Sleepless in Seattle.'' Your Cross is driven to bring focus to the pleasure and pain brought forth by relationship. Since we humans are so focused on relationship, there are many paths you could choose to live out the energy of your Cross." },
  { "title": "Cross of Laws", "gates": [60, 56, 50, 3], "description": "Your Cross has the energy and holds the boundaries of laws. You understand that laws are important because they maintain order and hold our society together. Your energy is bound to the traditional laws or the older customs. In general, lawmaking is an evolutionary process. Rules are established and over time, sometimes taking thousands of years, they change. We cannot just abandon all laws and choose new ones because there needs to be a consistency. You have the energy to provide that stabilizing force and remind us of the rules that help hold us together." },
  { "title": "The Juxtaposition Cross of Limitation", "gates": [60, 56, 28, 27], "description": "Your Cross has the energy of living within the old laws or traditions. You are very fixed in the way you believe things should be done. There are rules and they have great purpose in keeping things from becoming chaotic. You are not comfortable with new rules and tend to prefer the old ones. You are here to offer this heritage because there is a balance in change and tradition that is important to maintain. You help preserve the tradition." },
  { "title": "Cross of Distraction", "gates": [60, 56, 28, 27], "description": "The energy of your Cross is to help put the breaks on change. There is a great balance between tradition and change. Progress is all about change but if it was left unchecked pure progress would create chaos. You are here to insist upon the checks and balance and say, ''Hey, why are you trying to change something that we've been doing the same way for the last 200 years.'' This can be distracting to those that are on the progress side as they just want to move forward, but your voice is important so let it be heard." },
  { "title": "Cross of Maya", "gates": [61, 62, 32, 42], "description": "You carry the energy to bring the unknown into knowing. This is the new and undiscovered knowledge or a new way of looking at things. You bring to the world an inner knowing and by sharing it, you cause the world to reevaluate their position to accommodate the new ideas." },
  { "title": "The Juxtaposition Cross of Thinking", "gates": [61, 62, 50, 3], "description": "Your Cross has the energy that drives knowing and thinking about things. You are looking to understand how things work and move it along. As we evolve, so does our understanding of almost everything. Your energy is driven to work this process and help us evolve our understanding to a new level." },
  { "title": "Cross of Obscuration", "gates": [61, 62, 50, 3], "description": "You carry the energy to bring things into focus by identifying aspects and giving name to them. This could be objects, concepts, or ways of life. You are here to bring foundation to things by naming them so they can be discussed by the collective. This Cross is driven to help bring things, concepts into focus so the logical evaluation\/discussion can be carried out." },
  { "title": "Cross of Maya", "gates": [62, 61, 42, 32], "description": "You have the great ability to discover and remember small details. These details will be important in your life and you will be driven to share them with others. Remember, they may not always want to hear all the agonizing detail. Your gift can be very useful and a great contribution to a profession or organization that requires someone who pays great attention to details." },
  { "title": "The Juxtaposition Cross of Detail", "gates": [62, 61, 3, 50], "description": "This Cross is driven to deliver meaning through the inclusion of detail. There is a bit of fuzzy logic here, as the detail is not always precise and the method of delivery obscures the meaning. This is like a story teller who keeps interrupting themselves to add in odd, detailed facts and on the way loses the momentum of the story. You are here to be a contributor in relationship with others where your contribution will flow and deliver its full punch." },
  { "title": "Cross of Obscuration", "gates": [62, 61, 3, 50], "description": "The energy of your Cross is here to ask questions and examine patterns to find a deeper understanding. Is everything ok or is it working itself out? You are driven to ask the questions that help figure out a logical perspective as to why we are at a particular place. The questions call for the answers to help logically sort it all out." },
  { "title": "Cross of Consciousness", "gates": [63, 64, 5, 35], "description": "The energy of your Cross is here to ask questions and examine patterns to find a deeper understanding. Questions like, ''Is everything ok with this or is it working itself out?'' You are driven to ask the questions from a logical perspective of why we are at a particular place with a certain issue or situation. Your questions call for the answers to help us logically sort it out and make progress." },
  { "title": "The Juxtaposition Cross of Doubts", "gates": [63, 64, 26, 45], "description": "This Cross carries the energy of strong doubt in the logical process. Your first instinct is that it won't work. You are here to be the ''Doubting Thomas''. It is from this doubt that the idea, the product or the process is analyzed to make sure it works without undue risk. You in a sense are the safety engineer or over seer of society." },
  { "title": "Cross of Dominion", "gates": [63, 64, 26, 45], "description": "You have the energy to step in and taken power or authority. This is a transpersonal drive. There is karma involved, perhaps personal or perhaps to protect those being taken advantage of. The irony here is that by taking over the situation, it may make it better but it also has the potential to make it worse." },
  { "title": "Cross of Consciousness", "gates": [64, 63, 35, 5], "description": "This energy is the ability to seize power through the use of your mental prowess. You are here to transform your mental pressure to figure things out and communicate effectively to others, turning them into followers. Not everyone can push forward ideas to lead, but you have the energetic design to do so." },
  { "title": "The Juxtaposition Cross of Confusion", "gates": [64, 63, 45, 26], "description": "Your energy is here to be conscious of our pattern of life and our pattern of thinking. You have the energy to be a narrator or historian. There will be a tendency to manipulate the facts when there is a personal stake. This is due to the fact that some of the Cross energy is connected to the ego and the ego is driven for material gain for self preservation. This is where the confusion surfaces since the facts and reality don't always match. An objective position is your best spot to maintain the accuracy of your documentation or expression of events." },
  { "title": "Cross of Dominion", "gates": [64, 63, 45, 26], "description": "You are here to take power or authority over situations. To do this you will draw on history and assimilate it to your view, perhaps distorting it. You may profit or gain by these actions. This is a karmic Cross, so when considering an authoritative position, you need to weigh the outcome as part of your decision making process." }
]


export const godfaces_dictionary = [
  {
    "face": "Keepers of the Wheel",
    "gates": [19, 41, 60, 61],
    "quarter": "Mutation",
    "hexagram": "䷁",
    "description": "",
    "description": ""
  },
  {
    "face": "Vishnu",
    "gates": [54, 38, 58, 10],
    "quarter": "Mutation",
    "hexagram": "䷋",
    "description": ""
  },
  {
    "face": "Prometheus",
    "gates": [11, 26, 5, 9],
    "quarter": "Mutation",
    "hexagram": "䷊",
    "description": ""
  },
  {
    "face": "Hades",
    "gates": [34, 14, 43, 1],
    "quarter": "Mutation",
    "hexagram": "䷀",
    "description": ""
  },
  {
    "face": "Minerva",
    "gates": [44, 28, 50, 32],
    "quarter": "Duality",
    "hexagram": "䷀",
    "description": ""
  },
  {
    "face": "Christ",
    "gates": [57, 48, 18, 46],
    "quarter": "Duality",
    "hexagram": "䷊",
    "description": ""
  },
  {
    "face": "Harmonia",
    "gates": [6, 47, 64, 40],
    "quarter": "Duality",
    "hexagram": "䷋",
    "description": ""
  },
  {
    "face": "Thoth",
    "gates": [59, 29, 4, 7],
    "quarter": "Duality",
    "hexagram": "䷁",
    "description": ""
  },
  {
    "face": "Maat",
    "gates": [33, 31, 56, 62],
    "quarter": "Civilization",
    "hexagram": "䷋",
    "description": ""
  },
  {
    "face": "Parvati",
    "gates": [53, 39, 52, 15],
    "quarter": "Civilization",
    "hexagram": "䷊",
    "description": ""
  },
  {
    "face": "Lakshmi",
    "gates": [12, 45, 35, 16],
    "quarter": "Civilization",
    "hexagram": "䷋",
    "description": ""
  },
  {
    "face": "Maia",
    "gates": [20, 8, 23, 2],
    "quarter": "Civilization",
    "hexagram": "䷁",
    "description": ""
  },
  {
    "face": "Janus",
    "gates": [24, 27, 3, 42],
    "quarter": "Initiation",
    "hexagram": "䷁",
    "description": ""
  },
  {
    "face": "Michael",
    "gates": [51, 21, 17, 25],
    "quarter": "Initiation",
    "hexagram": "䷋",
    "description": ""
  },
  {
    "face": "Mitra",
    "gates": [36, 22, 63, 37],
    "quarter": "Initiation",
    "hexagram": "䷊",
    "description": ""
  },
  {
    "face": "Kali",
    "gates": [55, 30, 49, 13],
    "quarter": "Initiation",
    "hexagram": "䷀",
    "description": ""
  }
]

export const getGodFaceByGate = (gate) => {
  return godfaces_dictionary.filter(one => one.gates.includes(gate))[0]
}