import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { default_design } from '../../static/default_design';
import { Var } from '../Edu/Variables';
import { Avatar } from '../UiKit/Avatar';
import { AvatarName } from '../UiKit/AvatarName';
import { getPentaDesign } from '../utilsPenta';

export const PentaPHS = React.memo((props) => {

  const {
    videos = false,
    penta
  } = { ...props };

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $type = context.$type;
  const $profile = context.$profile;

  const design1 = penta.members[0] ? penta.members[0].avatar.design : default_design;
  const design2 = penta.members[1] ? penta.members[1].avatar.design : default_design;

  const profileId1 = design1.profile.length ? `${design1['profile'][0]}${design1['profile'][1]}` : '';
  const profileId2 = design2.profile.length ? `${design2['profile'][0]}${design2['profile'][1]}` : '';

  const pentaDesign = {
    ...getPentaDesign(penta),
    // 'gates': prepareChannels(getPentaDesign(penta).gates, true)
  };




  return (
    <>

      <div className="x_edu_content _f  _fgap2 _fgrow _f100">

        <div className="x_edu_content_title">
          <div className='x_edu_content_h x_edu_content_h--2'>
            {$ui('penta_phs_title')}
          </div>
        </div>



      <div className="_fv  _f100">



        <div className="_ftop _fspace _fgap1">
          {penta.members.map((oneMember, ind) => {
            const memberId = oneMember.avatar.token;
            const design = oneMember.avatar.design;
            return (
              <div
                key={`phs_${oneMember.avatar.token}`}
                className='_fv _fgap1'
                style={{ width: `${(100 / penta.members.length - 2)}%` }}
              >
                <div className="x_edu_channels">
                  <div className='x_edu_h2 x_edu_h2--sticky'>
                    {/* {$ui('penta_channels_of')} */}
                    <span className='x_ava_inline0'>
                      <span className='x_ava_inline' style={{ display: 'inline-flex' }}>
                        <Avatar style={{ fontSize: '1em' }} avatar={oneMember.avatar} /></span>
                      <span className={`x_ava_inline_name x_ava_inline_name--${oneMember.avatar.design.type.toLowerCase()}`}>
                        <AvatarName avatar={oneMember.avatar} />
                      </span></span>
                  </div>



                  {/* <Var
                        design={design}
                        variable='diet' addClassName='100'
                        advanced={true}
                      />
                      <Var design={design} variable='env' addClassName='100' advanced={true} />
                      <Var design={design} variable='motivation' addClassName='100' advanced={true} />
                      <Var design={design} variable='perspective' addClassName='100' advanced={true} />

                      <MarsMoon design={design} variable='trauma' addClassName='100' advanced={true} />
                      <MarsMoon design={design} variable='love' addClassName='100' advanced={true} /> */}


                  {/* {!pentaDesign
                        .channels.filter(oneChannel => Object.keys(oneChannel.activatedBy).includes(memberId)).length && <div className="x_edu_channel_no"><div className="x_edu_channel_h1">{$ui("no_channels_0")}</div>
                        </div>} */}

                </div>
              </div>
            )
          })}

        </div>



        <div className="_fv _fgap2 _f100">
          {['diet', 'env', 'motivation', 'perspective', 'trauma', 'love']
            .map(oneVar => {
              return (
                <div
                  key={`phs_${penta.token}_${oneVar}`}
                  className="_f _fspace"
                  style={{ alignItems: 'stretch' }}
                >
                  {penta.members.map((oneMember, ind) => {
                    const memberId = oneMember.avatar.token;
                    const design = oneMember.avatar.design;
                    return (<div
                      className='_fv _fgap1 _t08vh'
                      key={oneMember.avatar.token}
                      style={{ width: `${(100 / penta.members.length - 2)}%` }}
                    >

                      <Var
                        design={design}
                        variable={oneVar}
                        addClassName='100'
                        advanced={true}
                        videos={videos}
                      />
                    </div>)
                  })}
                </div>
              )
            })}
        </div>


      </div>

      </div>

    </>
  )
})




