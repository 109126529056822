const API_URL = process.env.REACT_APP_API_URL;


export const _getSettings = async () => {
  const url = `${API_URL}/api/me/settings`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    let data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;  // Re-throw the error to handle it in the calling function if needed
  }
};

export const _setSettings = async (
  settings = {
    "setting_email_new_follower": true,
    "setting_email_unread_messages": true
  },
  callback = () => void (0)
) => {
  const url = `${API_URL}/api/settings/set`;
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...settings
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};

