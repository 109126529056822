import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { GroupMembers } from '../Group';
import { GroupInfo } from '../GroupInfo';
import { GroupSelector } from '../GroupSelector';

export function GroupLayout(props) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;
  const { children, group = {}, updateGroup, members, admins, invited } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [groupBrowser, setGroupBrowser] = useState();

  const setPeoplePanel = (newPanel, group) => {
    const hash = location.hash.substring(1); // Remove the leading '#'
    const params = new URLSearchParams(hash);
    if (newPanel) {
      params.set('p', newPanel); // Set the new panel parameter
      context.setState.showPeoplePanel(newPanel);
    } else {
      params.delete('p');
      context.setState.showPeoplePanel();
    }
    if (group) { params.set('g', group); }
    const newHash = params.toString();
    navigate(`${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
  };


  useEffect(() => {
    setPeoplePanel(['people', 'followers'], group.token)

  }, [])

  return (
    <>



      <div className="x_people0 active">
        <div className="x_choose_panel active">



          <GroupSelector
            group={group}
            expanded={groupBrowser}
            setExpanded={setGroupBrowser}
          // exclude={[
          //   ...invited.map(one => one.user.token),
          //   ...admins.map(one => one.user.token)
          // ]}
          />


          <div className="x_choose0 active">
            <div className="x_people_panel active">
              <div className="x_choose_left x_choose_left--open">
                <div className="x_choose_container x_choose_container--v">
                  <div className="x_panel_h0" />



                      <div className='_fv _fgap2'>
                        <div>



                      <GroupInfo
                        // updateGroup={updateGroup}
                        // group={group}
                        // members={members}
                        // admins={admins}
                        // invited={invited}
                            groupBrowser={groupBrowser}
                            setGroupBrowser={setGroupBrowser}
                        {...props}
                      />



                        </div>


                        <GroupMembers group={group} members={members} />




                      </div>





                </div>
              </div>

            </div>
            <div className="x_choose_bg"><div className="x_edu_bg g" /></div>


          </div>
        </div>
      </div>

      {/* <div className="x_choose_bg"><div className="x_edu_bg g" /></div> */}


    </>
  )
}