import React, { useContext } from 'react';
import {
  getComposite9,
  getDomChannels,
  getEmChannels,
  getSameChannels,
  penta_comp,
  penta_em,
  penta_same,
  penta_dom
} from '../utilsPenta';

import { LangContext } from '../../contexts/langContext';
import { Svg } from '../UiKit/Svg';
import { TooltipH } from '../UiKit/TooltipH';


export const PentaStats = ({
  penta = {},
  size = 20
}) => {
  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;

  return (<>
    <div className="x_penta_stats">

      <TooltipH title={$ui('penta_composite9')} placement='bottom'>
        <div className="x_penta_stat1">
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_comp}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getComposite9(penta)}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_em_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getEmChannels(penta).length) ? '' : 'x_penta_stat1--0'}`}>
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_em}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getEmChannels(penta).length}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_same_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getSameChannels(penta).length) ? '' : 'x_penta_stat1--0'}`}>
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_same}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getSameChannels(penta).length}
          </div>
        </div>
      </TooltipH>

      <TooltipH title={$ui('penta_dom_channels')} placement='bottom'>
        <div className={`x_penta_stat1 ${Boolean(getDomChannels(penta).length) ? '' : 'x_penta_stat1--0'}`}>
          <div className="x_penta_stat_ico">
            <Svg width={size}>{penta_dom}</Svg>
          </div>
          <div className="x_penta_stat_num">
            {getDomChannels(penta).length}
          </div>
        </div>
      </TooltipH>


    </div>
  </>
  );
};


