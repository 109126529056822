import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { convertGMTtoDateTime, localizeDate } from '../utilsDesign';


export function Date({ children, date, time, utc, localize = true, hideYear, noWrap = true }) {
  const context = { ...useContext(LangContext) }
  const dateString = localizeDate(
    convertGMTtoDateTime(children, null, 'date'),
    context.lang,
    hideYear
  );

  const timeSring =
    convertGMTtoDateTime(children, null, 'time');

  const utcString =
    convertGMTtoDateTime(children, null, 'utc');


  return (
    <>
      {`${date ? dateString : ''}${(date && time) ? ', ' : ''}${time ? timeSring : ''} ${Boolean(utc) ? utcString : ''}`}
    </>
  )
}