import React, { useContext, useEffect, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Persona } from '../Persona';
import { QR } from '../UiKit/QR';
import { convertGMTtoDateTime, localizeDate } from '../utilsDesign';
import { MeChecklist } from '../UiKit/MeChecklist';
import { PreloaderHD } from '../UiKit/PreloaderHD';
import { _getCity } from '../utilsGeo';

export const MeInfo = React.memo(({ user = {}, avatar = {}, handleClose }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [title, setTitle] = useState(avatar.name ? avatar.name : context.getDefaultTitle(avatar.design));
  const [image, setImage] = useState(avatar.image ? avatar.image : '');
  const [cityId, setCityId] = useState(user ? user.city_id : null);

  const [cityData, setCityData] = useState(user.aggr.city);

  useEffect(() => {
    if (cityId && !cityData) {
      _getCity(cityId, setCityData)
    }
  }, [cityId])
  useEffect(() => {
    if (cityData) {
      console.log('cityData = ', cityData);
    }
  }, [cityData])

  const getUrl = () => {
    // let tz = context.me.avatar.aggr.city.timezone;
    // tz = tz.replace('/', '%2F')
    // let url = `https://hd.cafe/public/?timezone=${tz}&dob=${context.me.avatar.dob}&city_id=${context.me.avatar.city_id}&city=${context.me.avatar.aggr.city.city}&title=${context.me.avatar.name}&invited_by=${context.me.user.token}&ln=${context.language}`
    // url = url.replace(' ', '%20')
    const url = `https://hd.cafe/u/${user.token}`
    return url;
  }

  const getPrivateUrl = () => {
    let tz = context.me.avatar.aggr.city.timezone;
    tz = tz.replace('/', '%2F')
    let url = `https://hd.cafe/public/?timezone=${tz}&dob=${context.me.avatar.dob}&city_id=${context.me.avatar.city_id}&city=${context.me.avatar.aggr.city.city}&title=${context.me.avatar.name}&invited_by=${context.me.user.token}&ln=${context.language}`
    url = url.replace(' ', '%20')
    return url;
  }


  const [privateNote, setPrivateNote] = useState(avatar.privateNote ? avatar.privateNote : '');

  const [publicLink, setPublicLink] = useState(true);

  const inputRef = useRef(null);



  const handleCopy = (publicLink) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(getUrl())
        .then(() => {
          console.log("Text copied to clipboard successfully!");
        })
        .catch(err => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Clipboard API not available.");
    }
  };
  const handlePublicCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(getPrivateUrl())
        .then(() => {
          console.log("Text copied to clipboard successfully!");
        })
        .catch(err => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Clipboard API not available.");
    }
  };



  return (
    <>
      {avatar && <div className="m_b_c_scroll">
        <div className="m_b_cols">
          <div className="m_b_form0">
            <div className="m_b_form">


              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('pop_edit_me_header')}</div>
              </div>


              <MeChecklist user={user} avatar={avatar} />



              {/* <div className="x_calc_form_line">
                <div className="x_edu_h3">
                  <div>{$ui('user_set_image_title')}</div>
                </div>
                <div className="x_calc_form0">
                  <input
                    placeholder='https://...'
                    type='text' className="x_input x_input--name"
                    onFocus={(e) => e.target.select()}
                    // defaultValue={image}
                    value={image}
                    onChange={(e) => {
                      setImage(e.target.value)
                    }}
                  >

                  </input>

                  <div className="x_input_right ">
                    {Boolean(image) && <div className="x_input_action"
                      onClick={() => setImage('')}
                    >
                      <div className="t_icon t_icon--40">
                        <div>clear</div>
                      </div>
                    </div>}
                  </div>

                </div>
              </div> */}



              <div className="x_calc_form_line">
                <div className="x_calc_form_info0">
                  <div className="x_calc_label">
                    <div>{$ui('pop_created')}:</div>
                  </div>
                  <div className="x_calc_form_info_value">
                    {avatar && <div>{localizeDate(convertGMTtoDateTime(avatar.created, null, 'date'), context.lang)} {convertGMTtoDateTime(avatar.updated, null, 'time')}</div>}
                  </div>
                </div>
                <div className="x_calc_form_info0">
                  <div className="x_calc_label">
                    <div>{$ui('pop_updated')}:</div>
                  </div>
                  <div className="x_calc_form_info_value">
                    {avatar && <div>{localizeDate(convertGMTtoDateTime(avatar.updated, null, 'date'), context.lang)} {convertGMTtoDateTime(avatar.updated, null, 'time')}</div>}
                  </div>
                </div>
              </div>








            </div>
          </div>
        </div>
        <div className="m_b_cols">
          <div className="m_b_form0">



            <div className="m_b_form">

              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('pop_share')}</div>
              </div>



              <div className="x_info_form_toggles">
                <div className="ui_radiogroup_h">

                  <div className={publicLink ? `ui_radio_h active` : `ui_radio_h`}
                    onClick={() => setPublicLink(true)}
                  >
                    <div className="t_icon t_icon--40 _mv_">
                      <div>person</div>
                    </div>
                    {$ui('pop_share_public')}
                  </div>

                  <div className={publicLink ? `ui_radio_h` : `ui_radio_h active`}
                    onClick={() => setPublicLink(false)}
                  >
                    <div className="t_icon t_icon--40 _mv_">
                      <div>visibility_off</div>
                    </div>
                    {$ui('pop_share_private')}
                  </div>

                </div>
              </div>




              <div className="x_info_form_preview_sections">



                <AvatarInfoVisibility
                  view={publicLink ? 'public' : 'private'}
                  noSocial={!publicLink}
                >
                  {publicLink && <Persona
                    user={user}
                    avatar={avatar}
                    noClick={true}
                    active={false}
                  />}

                  {!publicLink && <Persona
                    avatar={{
                      ...avatar,
                      'image': '',
                      'name': ''
                    }}
                    // noInfo
                    noClick={true}
                    active={false}
                    addLine={'none'}
                  />}
                </AvatarInfoVisibility>

                <div className="m_b_form">

                  <div className="x_calc_form_line">




                    <div className="x_calc_form0">




                      {!Boolean(context.me.avatar.design) && <PreloaderHD />}

                      {!publicLink && <input
                        // disabled
                        type="text"
                        className="x_input x_input--url x_input--disabled"
                        onFocus={(e) => e.target.select()}
                        defaultValue={Boolean(context.me.avatar.design && context.me.avatar.aggr.city) ?
                          getPrivateUrl()
                          : ''}
                        ref={inputRef}
                      />}
                      {publicLink && <input
                        // disabled
                        type="text"
                        className="x_input x_input--url x_input--disabled"
                        onFocus={(e) => e.target.select()}
                        defaultValue={Boolean(context.me.avatar.design && context.me.avatar.aggr.city) ?
                          getUrl()
                          : ''}
                        ref={inputRef}
                      />}

                      <div className="x_input_right">
                        <div className="x_button x_button--sm" onClick={() => !publicLink ? handlePublicCopy() : handleCopy()}>
                          <div className="t_icon">
                            <div>content_copy</div>
                          </div>
                          {$ui('pop_button_copy')}
                        </div>


                      </div>
                    </div>
                  </div>


                  <div className="x_calc_form_line">




                    <div className="x_calc_qr">
                      {Boolean(context.me.avatar.design && context.me.avatar.aggr.city) && <QR
                        url={!publicLink ? getPrivateUrl() : getUrl()}
                      />}

                    </div>
                  </div>

                </div>

              </div>



            </div>
          </div>
        </div>
      </div>}
    </>
  )
})



export function AvatarInfoVisibility({ view = 'public', addPoints, noSocial, children, addComponents }) {
  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;
  const mode = {
    'public': {
      'bodygraph': [
        { 'on': true, 'label': "pop_share_what_they_dob" },
        { 'on': true, 'label': "pop_share_what_they_dob_tz" },
        { 'on': false, 'label': "pop_share_what_they_dob_year" },
        { 'on': false, 'label': "pop_share_what_they_dob_city" }
      ],
      'social': [
        { 'on': true, 'label': "pop_share_what_they_avatar" },
        { 'on': true, 'label': "pop_share_what_they_curcity" },
        { 'on': true, 'label': "pop_share_what_they_can_follow" },
        { 'on': true, 'label': "pop_share_what_they_see_followers" },
        { 'on': true, 'label': "pop_share_what_they_see_followings" },
      ],
    },
    'private': {
      'bodygraph': [
        // { ...addPoints },
        { 'on': true, 'label': "pop_share_what_they_dob" },
        { 'on': true, 'label': "pop_share_what_they_dob_tz" },
        { 'on': true, 'label': "pop_share_what_they_dob_year" },
        { 'on': true, 'label': "pop_share_what_they_dob_city" },
      ],
      'social': [
        { 'on': false, 'label': "pop_share_what_they_avatar" },
        { 'on': false, 'label': "pop_share_what_they_curcity" },
        { 'on': false, 'label': "pop_share_what_they_can_follow" },
        { 'on': false, 'label': "pop_share_what_they_see_followers" },
        { 'on': false, 'label': "pop_share_what_they_see_followings" },
      ],
    }
  }

  if (addPoints) {
    // mode.private.bodygraph = [{ ...addPoints }, ...mode.private.bodygraph]
  }


  return (
    <>
      <div className="x_info_form_preview">

        {addComponents}


        <div className="x_info_form_preview_header">
          {$ui('pop_share_what_they_see')}
        </div>
        <div className="x_info_form_preview_cols">
          <div className="x_info_form_preview_body">

            {children}

          </div>
          <div className="x_info_form_preview_checks0">
            <div className="x_info_form_preview_checks">

              <div className="x_calc_form_line">
                <div className="x_info_form_preview_header">
                  {$ui('pop_share_what_they_title_body')}
                </div>
              </div>


              {mode[view].bodygraph.map(one => <div className="x_calc_form_line" key={one.label}>
                <div className="x_calc_form_info0">
                  <div className="x_info_form_preview_checklabel">
                    {$ui(one.label)}
                  </div>
                  <div className="x_info_form_preview_check">
                    <div className="t_icon t_icon--40">
                      {one.on ? <div>public</div> : <div>lock</div>}
                    </div>
                  </div>
                </div>
              </div>)}




            </div>

            {/* TODO: social */}
            {/* <div className="x_info_form_preview_checks">

              <div className="x_calc_form_line">
                <div className="x_calc_form_line">
                  <div className="x_info_form_preview_header">
                    {$ui('pop_share_what_they_title_social')}
                  </div>
                </div>
              </div>


              {!noSocial && mode[view].social.map(one => <div className="x_calc_form_line" key={one.label}>
                <div className="x_calc_form_info0">
                  <div className="x_info_form_preview_checklabel">
                    {$ui(one.label)}
                  </div>
                  <div className="x_info_form_preview_check">
                    <div className="t_icon t_icon--40">
                      {one.on ? <div>public</div> : <div>lock</div>}
                    </div>
                  </div>
                </div>
              </div>)}

              {noSocial && <div className="x_calc_form_info0">
                <div className="x_info_form_preview_checklabel">
                  {$ui('pop_share_what_they_see_body_only')}
                </div>
              </div>}




            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}