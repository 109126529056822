import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const Txt = ({ children, noP, style }) => {
  const renderers = {
    a: ({ node, ...props }) => <a {...props} className='x_chat_txt_link' target="_blank"
    // rel="noopener noreferrer"
    />
  };

  const textWithLineBreaks = children && typeof children === 'string' ? children.replaceAll(/\n/g, '  \n') : ''; // GitHub flavored markdown: two spaces before newline for line breaks

  return (
    <ReactMarkdown
      children={textWithLineBreaks}
      remarkPlugins={[remarkGfm]}
      unwrapDisallowed={noP}
      components={renderers}
      style={style}
    />
  );
};
