import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';



import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Celebs } from '../Lists/Celebs';
import { ListAvatars } from '../Lists/ListAvatars';
import { ListAvatarsLayout } from '../Lists/ListAvatarsLayout';
import { ListCity } from '../Lists/ListCity';
import { SocialChoose } from '../UiKit/SocialChoose';

export const PentasBrowser = memo((props) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;



  const navigate = useNavigate();

  const {
    title = '',
    includeMe = true,
    pentaId,
    scope = [],
    exclude = [],
    customHandler = () => void (0),
    expanded,
    children,
    peopleShown = false,
    handleClose,
  } = { ...props };
  const [searchParams] = useSearchParams();







  const [penta, setPenta] = useState();
  const [pentaId2, setPentaId2] = useState();
  const [pentaAvatars, setPentaAvatars] = useState();




  const [socialView, setSocialViewInner] = useState(['people', 'followers'])
  // const [socialView, setSocialViewInner] = useState(['world', 'people', 'followers', 'bodygraphs'])

  const setSocialView = (what) => {
    // if (socialView.includes(what)) {
    //   setSocialViewInner(prev => prev.filter(one => one !== what))
    // } else {
    //   setSocialViewInner(prev => [...prev, what])
    // }
    console.log('what = ', what);
    setSocialViewInner(what)
    // context.x.setCurrentPeopleSet(['what'])
  }


  const filterMeOut = (array) => array.filter(one => {
    // return one
    // console.log('one.avatar.token = ', one.avatar.token);
    return one.avatar.token !== context.me.avatar.token
  })


  const peoplePanelRef = useRef(null);

  useEffect(() => {
    if (expanded) {
      // peoplePanelRef.current.scrollTop = 0;
      // setSocialView(context.state.currentPeopleSet)
    } else {
      // setSocialView(context.state.currentPeopleSet)
      if (socialView[0] === 'world') setSocialViewInner(['people', 'followers'])
    }
  }, [expanded]);


  const filterOutFriends = (exclude = []) => {
    const arrayFollowers = context.followers;
    const arrayFollowings = context.followings;
    let friendsTokens = arrayFollowings.length ? arrayFollowings.map(one => one.user.token) : []
    let onlyFollowings = friendsTokens.length ? arrayFollowers.filter(one => {
      return !friendsTokens.includes(one.user.token)
    }) : arrayFollowers
    return onlyFollowings.filter(one => !exclude.includes(one.user.token))
  }

  const options = [
    {
      id: 'celebs',
      icon: 'star',
      handleValue: ['celebs'],
      title: 'people_celebs',
    },
    {
      id: 'people',
      icon: 'group',
      handleValue: ['people', 'followers'],
      title: 'people_people',
    },
    {
      id: 'bodygraphs',
      icon: 'person_search',
      handleValue: ['bodygraphs'],
      title: 'people_bodygraphs',
    },
  ].filter(one => scope.includes(one.id))






  return (
    <>



      <div className={expanded ? "x_choose_panel x_choose_panel--pentas active" : "x_choose_panel x_choose_panel--pentas active"}>
        <div className={expanded ? "x_choose0 active" : "x_choose0"}>



          <div ref={peoplePanelRef} className='x_people_panel active'>






            <div
              className={"x_choose_left x_choose_left--open"}
            // className={"x_choose_left"}
            >



              <div className={"x_choose_container x_choose_container--v"}>

                <div className={"x_panel_h x_panel_h--pentasbroser active"}>


                  {Boolean(title) &&
                    <div className="x_choose_section_title x_choose_section_title--v">
                      <b>{title}</b>
                    </div>}

                  {options.length > 1 && <SocialChoose
                    handle={setSocialView}
                    active={socialView}
                    options={options}
                  />}

                  <div className="x_panel_actions">
                    <div className="x_action" onClick={handleClose}>
                      <div className="t_icon t_icon--60">
                        expand_more
                      </div>
                    </div>
                  </div>

                </div>


                <div className="x_panel_h0" />


                <div className="x_choose_contents">


                  {socialView.includes('world') && expanded && <ListCity user={context.me.user} />}


                  <div className="x_choose_sect0">



                    {socialView.includes('people') && <ListAvatarsLayout
                      icon='group'
                      title={$ui('people_people')}
                      listId='people'
                      quantity={context.followings.length + 1}
                      className={context.state.peoplePanel ? 'v' : ''}
                    >
                      {includeMe && <><ListAvatars
                        customHandler={customHandler}
                        listId='me'
                        quantity={1}
                        array={[context.me]}
                        actions={['edit', 'share']}
                      /><br /></>}
                      <ListAvatars
                        customHandler={customHandler}
                        listId='followings'
                        quantity={context.followings.length}
                        array={context.followings}
                        // actions={['share', 'unfollow']}
                        exclude={exclude}
                      />

                      {/* <CreateNew
                        icon='search'
                        label={$ui('people_find_more')}
                        handle={() => setSocialView('world')}
                      /> */}
                    </ListAvatarsLayout>}



                    {socialView.includes('celebs') && <Celebs
                      customHandler={customHandler}
                    />}



                    {socialView.includes('followers') && <ListAvatarsLayout
                      icon='public'
                      title={$ui('people_followers')}
                      listId='followers'
                      quantity={filterOutFriends(exclude).length}
                      className={context.state.peoplePanel ? 'v' : ''}
                    >
                      <ListAvatars
                        customHandler={customHandler}
                        listId='followers'
                        quantity={filterOutFriends().length}
                        array={filterOutFriends(exclude)}


                      />
                    </ListAvatarsLayout>}







                    {socialView.includes('bodygraphs') && <ListAvatarsLayout
                      title={$ui('people_bodygraphs')}
                      listId='avatars'
                      icon='person_search'
                      quantity={context.avatars.length - 1}
                      className={context.state.peoplePanel ? 'v' : ''}
                    >
                      <ListAvatars
                        customHandler={customHandler}
                        quantity={context.avatars.length - 1}
                        listId='avatars'
                        array={filterMeOut(context.avatars)}
                        exclude={exclude}

                        actions={['edit', 'setMe', 'delete']}

                      />
                      {/* <CreateNew
                        icon='add'
                        label={$ui('button_create_new_bodygraph')}
                        handle={() => !context.creatingAva && context.x.createAvatar(
                        {},
                          (newUrl) => {
                            navigate(`/u/${newUrl}`)
                          }
                        )}
                        loading={context.creatingAva}
                      /> */}
                    </ListAvatarsLayout>}









                  </div>
                </div>



              </div>
              {/* } */}


            </div>


          </div>
          {expanded && <div className="x_choose_bg">
            <div className="x_edu_bg g" />
          </div>}



          {/* <WithVersion>{expanded && <Pentas />}</WithVersion> */}



        </div>
      </div>




    </>
  )
})


