import React, { useEffect, useState } from "react";
import {
  _addToPenta,
  _createAvatar,
  _createPenta,
  _deleteAvatar,
  _deleteFromPenta,
  _deletePenta,
  _follow,
  _getPenta,
  _invitedBy,
  _setIsMe,
  _unfollow,
  _updateAvatar,
  convertGMTtoDateTime,
  parseAvatar,
  parseDesignInPersonas,
  parseNewDesign
} from '../components/utilsDesign';
// import data_bak_17_feb_2024 from '../static/data_bak_17_feb_2024.json';
// import cached_04_mar_2024 from '../static/cached_04_mar_2024.json';
import { PreloaderHD } from '../components/UiKit/PreloaderHD';
import { _getCity, _updateMyCity } from '../components/utilsGeo';
import {
  _getGroups,
  _groupCreate,
  _groupDelete,
  _groupLeave,
  _groupUpdate
} from '../components/utilsGroup';
import { _updatePenta } from '../components/utilsPenta';
import { getLocal } from '../components/utilsSocial';


// const OFFLINE = true;
const OFFLINE = false;


const SocialContext = React.createContext();

const API_URL = process.env.REACT_APP_API_URL;


function SocialProvider({ children }) {

  const [summary, setSummary] = useState(
    // getLocal('summary') ? getLocal('summary') : null
    // OFFLINE ? cached_04_mar_2024 : null
  );
  const [cachedDesigns, setCachedDesigns] = useState(
    // OFFLINE ? cached_04_mar_2024 : null
  );



  const [myCity, setMyCityInner] = useState({});
  const [myCities, setMyCities] = useState([]);

  const setMyCity = (cityObj) => {
    // const cities = myCities.filter(one => one.id !== cityObj.id)
    // setMyCities([cityObj, ...cities])
    setMyCityInner(cityObj)
  }

  const processMe = (me, summary) => {
    let newMe = {
      ...me,
      user: {
        ...me.user,
        isMe: true
      },
      avatar: {
        ...me.avatar,
        isMe: true,
        my: true,
      },
      // avatar: me.avatar.token,
      // avatar: {
      //   ...me.avatar,
      //   isMe: true
      // },
      isMe: true,
    }
    if (summary) {
      newMe.followers = summary.followers;
      newMe.followings = summary.followings;
    }
    if (!myCity.city && me.user.city_id && !me.user.aggr.city) _getCity(me.user.city_id, setMyCity)
    if (!myCity.city && me.user.aggr.city) setMyCity(me.user.aggr.city)
    return newMe;
  }

  const [me, setMe] = useState(
    summary ? processMe(summary.me, summary) : null
  );

  const [avatars, setAvatars] = useState();
  const [groups, setGroups] = useState([]);

  const [friends, setFriends] = useState();
  const [followings, setFollowings] = useState();
  const [followers, setFollowers] = useState();

  const [fetchingSummary, setFetchingSummary] = useState(false);


  const [pentas, setPentas] = useState();
  const [pentasList, setPentasList] = useState();
  const [pentasPersons, setPentasPersons] = useState({});


  const [cachedUsers, setCachedUsers] = useState({});


  const [publicUser, setPublicUser] = useState();



  const addCityHistory = (cityObj) => {
    if (!cityObj || !cityObj.id) return;
    const cities = myCities.filter(one => one.id !== cityObj.id)
    setMyCities([cityObj, ...cities])
  }




  // LOADINGS
  const [updatingAva, setUpdatingAva] = useState();
  const [creatingAva, setCreatingAva] = useState(false);
  const [creatingPenta, setCreatingPenta] = useState(false);
  const [loadingDesign, setLoadingDesign] = useState(false);
  const [deletingAvatar, setDeletingAvatar] = useState([]);
  const [followingUser, setFollowingUser] = useState([]);


  const [cityPeople, setCityPeople] = useState();
  const [geoPeople, setGeoPeople] = useState({});

  const [deletingPenta, setDeletingPenta] = useState([]);



  useEffect(() => {
    if (me && me.user && me.user.aggr.city) {
      fetchData(`geo/users/${me.user.city_id}`, (pep) => {
        pep = parseDesignInPersonas(pep)
        setCityPeople(pep)
      })

      //TODO: сделать людей по координатам
      // const cityObj = me.user.aggr.city;
      // fetchData(`geo/close/?lat=${cityObj.location.lat}&lon=${cityObj.location.lon}&distance=100}`, (cityP) => {
      //   cityP = parseDesignInPersonas(cityP)
      //   if (cityP.length) cacheCityPeople(cityObj.id, cityP)
      //   callback(cityP)
      // })
    }
  }, [me])


  const cacheCityPeople = (cityId, newPeople = []) => {
    let myCity = {}
    newPeople = parseDesignInPersonas(newPeople)
    myCity[cityId] = newPeople;
    setGeoPeople(prev => {
      // console.log('prev = ', prev);
      let newOld = JSON.parse(JSON.stringify(prev))
      function removeItemsByUserToken(data, tokenToRemove) {
        Object.keys(data).forEach(key => {
          data[key] = data[key].filter(item => item.user.token !== tokenToRemove);
        });
        return data;
      }
      newOld = Object.keys(prev).length ? removeItemsByUserToken(newOld, me.user.token) : prev;

      // return ({ ...newOld, ...newPeople })
      if (newPeople.length) newOld[cityId] = newPeople;
      // console.log('newOld = ', newOld);
      return newOld;
    })
  }

  useEffect(() => {
    if (cityPeople && me.user.city_id) {
      cacheCityPeople(me.user.city_id, cityPeople)
    }
  }, [cityPeople, me])

  useEffect(() => {
    // console.log('geoPeople = ', geoPeople);
  }, [geoPeople])


  const getCityPeople = (cityObj, callback = () => void (0)) => {
    if (cityObj && !cityObj.id) {
      callback()
      return
    }
    if (geoPeople[cityObj.id]) {
      callback(geoPeople[cityObj.id])
    } else {
      // fetchData(`geo/users/${cityObj.id}`, (cityP) => {
      //   cityP = parseDesignInPersonas(cityP)
      //   if (cityP.length) cacheCityPeople(cityObj.id, cityP)
      //   callback(cityP)
      // })
      //TODO: сделать людей по координатам
      fetchData(`search/users/?lat=${cityObj.location.lat}&lon=${cityObj.location.lon}&distance=100}`, (cityP) => {
        cityP = parseDesignInPersonas(cityP)
        console.log('cityP = ', cityP);
        if (cityP.length) cacheCityPeople(cityObj.id, cityP)
        callback(cityP)
      })


    }
  }




  useEffect(() => {

    // let up;
    // if (updatingAva) {
    //   let a = getAvatar(updatingAva);
    //   if (a.dob) {
    //     up = convertDobToId(a['dob'])
    //   }
    // } else { up = null }

    // if (
    //   up
    // ) {
    //   setUpdatingAva(false)
    // }
  }, [cachedDesigns])

  useEffect(() => {
    // console.log('summary = ', summary);
  }, [summary])

  useEffect(() => {
    // console.log('pentas = ', pentas);
  }, [pentas])

  useEffect(() => {
    // console.log('me = ', me);
  }, [me])

  // useEffect(() => {
  //   console.log('newPentaPersonas = ', pentasPersons);
  // }, [pentasPersons])

  useEffect(() => {
    // console.log('avatars = ', avatars);
  }, [avatars])

  useEffect(() => {
    // console.log('friends = ', friends);
  }, [friends])

  useEffect(() => {
    // console.log('followings = ', followings);
  }, [followings])

  useEffect(() => {
    // console.log('followers = ', followers);
  }, [followers])




  useEffect(() => {
    console.log('updatingAva = ', updatingAva);
  }, [updatingAva]);



  // useEffect(() => {
  //   if (
  //     typeof friends === 'object'
  //     && typeof avatars === 'object'
  //     && !cachedDesigns
  //   ) {
  //     // console.log('friends = ', friends);
  //     // console.log('avatars = ', avatars);
  //     let allAvatars = []
  //     friends.forEach(one => allAvatars.push(one.avatar))
  //     avatars.forEach(one => allAvatars.push(one.avatar))
  //     // cacheNewDesigns(allAvatars)
  //   }
  // }, [friends, avatars, followings, followers, cachedDesigns])







  useEffect(() => {
    if (
      typeof pentasList === 'object'
      && typeof pentas !== 'object'
    ) {
      fetchPentas(pentasList)
    }
  }, [pentasList, pentas])


  // const fetchPentas = (pentasList = []) => {
  //   pentasList.map(onePenta => fetchData(`penta/avatars/${onePenta.token}`, (pentaData) => processPenta(onePenta.token, pentaData))
  //   )
  // }


  // const processPenta = (pentaId, pentaAvatars = []) => {
  //   const newPentasList = JSON.parse(JSON.stringify(pentasList))
  //   const findIndex = newPentasList.findIndex(one => one.token === pentaId)
  //   if (findIndex > -1) {
  //     newPentasList[findIndex]['avatars'] = pentaAvatars
  //   }
  //   setPentas(newPentasList)
  // }


  const fetchPentas = (pentasList = []) => {
    setPentas(pentasList)
    // Use Promise.all to wait for all fetchData promises to resolve
    Promise.all(pentasList.map(onePenta =>
      fetchDataAsync(`penta/avatars/${onePenta.token}`).then(pentaData =>
        ({ token: onePenta.token, avatars: pentaData })
      )
    ))
      .then(results => {
        // Once all promises are resolved, update the state
        let pentasWithAvatars = JSON.parse(JSON.stringify(pentasList))
        results.forEach(({ token, avatars }) => {
          const findIndex = pentasWithAvatars.findIndex(one => one.token === token);
          if (findIndex > -1) {
            pentasWithAvatars[findIndex]['avatars'] = avatars;
          }
        })
        setPentas(pentasWithAvatars);
      })
      .catch(error => {
        // Handle any errors
        console.error('Error fetching data:', error);
      });
  }


  const updateLocalSummary = (what, newWhat) => {
    const newSummary = JSON.parse(JSON.stringify(summary))
    newSummary[what] = newWhat;
    setSummary(processSummary(newSummary))
  }

  const processAvatars = (pentasWithAvatars) => {
    pentasWithAvatars = pentasWithAvatars.map(one => ({
      user: null,
      avatar: parseAvatar({
        ...one,
        my: true,
        isMe: me.avatar.token === one.token ? true : false
      })
    }))
    // pentasWithAvatars = pentasWithAvatars.filter(one => one.avatar.token !== me.avatar.token)
    return pentasWithAvatars
  }

  const fetchDataAsync = (endpoint) => {
    return fetch(`${API_URL}/api/${endpoint}`) // Assuming you're using localhost
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      });
  };






  const convertDobToId = (dob) => {
    const { date, time } = convertGMTtoDateTime(dob);
    let d = date.split('-');
    let t = time.split(':')
    const id = `${d[0]}${d[1]}${d[2]}${t[0]}${t[1]}01`
    return id;
  }

  const getDesign1 = (dob, token) => {
    // setLoadingDesign(token)
    if (!dob) return;
    let ret = cachedDesigns[convertDobToId(dob)]
    return ret
  }


  const getDesign = (avatar) => {
    // setLoadingDesign(avatar.token)
    if (!avatar.dob) return;
    let ret = cachedDesigns[convertDobToId(avatar.dob)]
    return ret
  }

  const getPenta = (token, callback = () => void (0), callbackFail = () => void (0)) => {

    if (pentas) {
      let ret = pentas.filter(one => one.token === token)
      if (ret.length) {
        ret = ret[0];
      } else {
        callbackFail();
        return;
      }
      callback(ret);
    } else {
      _getPenta(token, callback)
    }


  }



  const fetchPenta = (pentaId, callback) => {
    fetch(`${API_URL}/api/penta/${pentaId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json();
      })
      .then(data => {
        callback(data)
      })
      .catch(error => {
        console.error("Error fetching: ", error);
      });
  }


  const fetchPentaAvatars = (pentaId, callback) => {
    fetch(`${API_URL}/api/penta/avatars/${pentaId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json();
      })
      .then(data => {
        callback(data)
        // cacheNewDesigns(data)
        // console.log('data = ', data);
      })
      .catch(error => {
        console.error("Error fetching: ", error);
      });
  }


  const fetchAllUsers = (callback) => {
    fetch(`${API_URL}/api/public/users/8`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json();
      })
      .then(data => {
        callback(data)
        // cacheNewDesigns(data)
        // console.log('data = ', data);
      })
      .catch(error => {
        console.error("Error fetching: ", error);
      });
  }


  const cacheNewDesigns = (data = [], dontEraseCache) => {
    // if (!dontEraseCache) setCachedDesigns({})
    // let dobs = ''
    // data.forEach((one, ind, array) => {
    //   if (one.dob) {
    //     const { date, time, timezone = 'GMT' } = { ...convertGMTtoDateTime(one['dob']) }
    //     dobs += `${date}|${time}|${timezone}${ind < array.length - 1 ? ',' : ''}`
    //     // getDesign(date, time, timezone, 'London', newDesign => cacheDesign(one['dob'], newDesign))
    //   }
    // })
    // // console.log('dobs = ', dobs);
    // _getDesignArray(dobs, (data) => {
    //   if (data) {
    //     setCachedDesigns((prevCache = {}) => ({ ...prevCache, ...processDesigns(data) }))
    //   }
    //   setUpdatingAva(false)
    // })
  }


  const processDesigns = (array = []) => {
    const ret = {}
    array.forEach(one => {
      ret[one['id']] = { ...one }
    })
    return ret
  }








  const loadFriends = () => fetchData('me/friends', (followings) => {
    followings = parseDesignInPersonas(followings)
    setFriends(followings)
  })
  const loadFollowings = (callback) => fetchData('me/followings', (followings) => {
    followings = parseDesignInPersonas(followings)
    setFollowings(followings)
    callback(followings)
  })


  const loadAvatars = (callback = () => void (0)) => fetchData('me/avatars', (avatars) => {
    setAvatars(processAvatars(avatars))
    callback(avatars)
  })



  const loadPentas = () => fetchData('me/pentas', setPentasList)
  const loadGroups = () => fetchData('me/groups', setGroups)


  const setIsMe = ({ user, avatar }) => {
    let avatarToken = avatar ? avatar.token : ''
    if (avatarToken) {
      _setIsMe(avatarToken, fetchData('me', (me) => {
        let newMe = processMe(me)
        // console.log('newMe = ', newMe);
        setMe(newMe)
      }))
    } else { console.log('No avatar token') }
  }



  const updateAvatar = (newData, callback = () => void (0)) => {
    console.log('newData = ', newData);
    if (newData.dob) {
      setAvatars((prevAvatars = []) => {
        // const newAvatars = JSON.parse(JSON.stringify(prevAvatars))
        const newAvatars = []
        prevAvatars = prevAvatars.forEach(one => {
          one = JSON.parse(JSON.stringify(one))
          // one.avatar.design = (one.avatar.design && one.avatar.design['meta']) ? parseNewDesign(one.design) : default_design
          if (one.token !== newData.token) {
            newAvatars.push(one)
          } else {
            one.avatar.dob = null;
            newAvatars.push(one)
          }
        })
        return newAvatars
      })
      // cacheNewDesigns([newData], 'dontEraseCache')
    }

    const afterUpdate = (newReceivedData) => {
      if (!newData.dob) {
        setUpdatingAva(false)
      }
      if (newData.token === me.avatar.token) {
        // fetchData('me', (me) => setMe(processMe(me)));
        const newMe = JSON.parse(JSON.stringify(me))
        newMe.avatar = newReceivedData;
        setMe(processMe(newMe))
        const newU = {}
        newU[me.avatar.token] = newMe;
        setCachedUsers(prev => ({ ...prev, ...newU }))
      }
      loadAvatars(() => setUpdatingAva(false))
    }

    if (newData.token && newData) {
      setUpdatingAva(newData.token)
      _updateAvatar(newData, (newAvatar) => {
        // data.design = parseNewDesign(newAvatar.design)
        newAvatar = parseAvatar(newAvatar)
        afterUpdate(newAvatar)
        callback(newAvatar)
      })
      // if (newData.dob) {
      // cacheNewDesigns([newData], 'dontEraseCache')
      // }
    }

  }


  const createAvatar = (avatarData = {}, callback = () => void (0)) => {

    setCreatingAva(true)
    // callback(0)

    const fetchCallback = (newAvatar) => {
      newAvatar.dob = null;
      newAvatar = processAvatars([newAvatar])
      setAvatars((prevAvatars = []) => [...prevAvatars, ...newAvatar])
      callback(newAvatar[0].avatar)
      setCreatingAva(false)
    }
    _createAvatar(avatarData, fetchCallback)
  }



  const createGroup = (groupData = {}, callback = () => void (0)) => {
    // setCreatingAva(true)
    const fetchCallback = (newGroup) => {
      setGroups((prev = []) => [...prev, newGroup])
      callback(newGroup)
      setCreatingAva(false)
    }
    _groupCreate(groupData, fetchCallback)
  }

  const updateGroup = (groupData = {
    token: '',
    name: '',
    description: ''
  }, callback = () => void (0)) => {
    // setCreatingAva(true)
    const fetchCallback = (groupData) => {
      setGroups((prev = []) => {
        const newG = JSON.parse(JSON.stringify(prev))
        const index = groups.findIndex(group => group.token === groupData.token)
        newG[index] = {
          ...newG[index],
          ...groupData
        }
        return newG
      })
    }
    fetchCallback(groupData)
    _groupUpdate(groupData, callback)
  }

  const getGroups = (callback = () => void (0)) => {
    // setCreatingAva(true)
    const fetchCallback = (newGroups = []) => {
      setGroups([...newGroups])
      callback(newGroups)
      // setCreatingAva(false)
    }
    _getGroups(fetchCallback)
  }


  const deleteGroup = (token = '', callback = () => void (0)) => {
    // setCreatingAva(true)
    const fetchCallback = (token) => {
      setGroups(prev => [...prev.filter(group => group.token !== token)])
    }
    fetchCallback(token)
    _groupDelete(token)
  }

  const leaveGroup = (token = '', callback = () => void (0)) => {
    // setCreatingAva(true)
    const fetchCallback = (token) => {
      setGroups(prev => [...prev.filter(group => group.token !== token)])
    }
    fetchCallback(token)
    _groupLeave(token)
  }


  const createPenta = (members = [], callback = () => void (0)) => {

  }

  const createPentaWithMe = (callback = () => void (0)) => {
    setCreatingPenta(true)
    callback(0)
    const fetchCallback = (newPenta) => {
      console.log('newPenta = ', newPenta);
      // newAvatar.dob = null;
      // newAvatar = processAvatars([newAvatar])
      newPenta.members = newPenta.members ? parseDesignInPersonas(newPenta.members) : [];
      // newPenta.members.push(me)

      // addToPenta(newPenta.token, me.avatar.token)

      setPentas((prevPentas = []) => [...prevPentas, newPenta])
      callback()
      setCreatingPenta(false)
    }

    _createPenta(
      {
        avatars: [
          me.avatar.token
        ]
      }
      , fetchCallback)
  }

  const deleteAvatar = ({ avatar }, callback = () => void (0)) => {
    setDeletingAvatar((prev) => [...prev, avatar.token])
    _deleteAvatar(avatar.token, () => {
      callback();
      loadAvatars(null, () => setDeletingAvatar((prev) => prev.filter(token => token !== avatar.token)));
    })
  }

  const deletePenta = ({ penta }) => {
    setDeletingPenta((prev) => [...prev, penta.token])
    const newPentas = JSON.parse(JSON.stringify(pentas)).filter(one => one.token !== penta.token);
    setPentas(newPentas)
    updateLocalSummary('pentas', newPentas)
    _deletePenta(penta.token, () => {
      loadPentas(null, () => {
        setDeletingPenta((prev) => prev.filter(token => token !== penta.token))
        loadPentas()
      });
    })
  }

  const addToPenta = (pentaId = '', newAvatarId = '') => {
    if (!pentaId || !newAvatarId) {
      console.log('😞 no Penta Id or avatar ID: ', ' pentaId = ', pentaId, 'avatarId = ', newAvatarId)
      return;
    }
    const newPentaCallback = (newPenta) => {
      const newPentas = JSON.parse(JSON.stringify(pentas));
      const pentaIndex = newPentas.findIndex(one => one.token === newPenta.token)

      newPenta = {
        ...newPenta,
        'members': newPenta.members.map(one => {
          const newU = {
            ...one,
            avatar: {
              ...one.avatar,
              isMe: one.user && one.user.token === me.user.token,
            },
          }
          if (one.user) {
            newU.user = {
              ...one.user,
              isMe: one.user.token === me.user.token,
            }
          } else {
            newU.user = null
          }
          return newU
        })
      }

      if (pentaIndex < 0) {
        newPenta.members = parseDesignInPersonas(newPenta.members)
        newPentas.push(newPenta)
      }
      else {
        newPenta.members = parseDesignInPersonas(newPenta.members).reverse()
        newPentas[pentaIndex] = newPenta;
      }
      setPentas(newPentas)
    }
    _addToPenta(pentaId, newAvatarId, newPentaCallback)
  }

  const deleteFromPenta = (pentaId, avatarId) => {
    if (!pentaId || !avatarId) {
      console.log('😞 no Penta Id or avatar ID: ', ' pentaId = ', pentaId, 'avatarId = ', avatarId)
      return;
    }
    const afterDeleteCallback = (newPenta) => {
      const newPentas = JSON.parse(JSON.stringify(pentas));
      newPenta.members = parseDesignInPersonas(newPenta.members)
      const pentaIndex = newPentas.findIndex(one => one.token === newPenta.token)
      if (pentaIndex < 0) {
        newPentas.push(newPenta)
      }
      else {
        newPenta.members = newPenta.members.reverse()
        newPentas[pentaIndex] = newPenta;
      }
      setPentas(newPentas)
    }
    _deleteFromPenta(pentaId, avatarId, afterDeleteCallback)
  }







  const getUserSocial = async (userId, what, len) => {
    try {
      // Assuming fetchData returns a promise. If fetchData uses callbacks,
      // you would need to wrap it in a new Promise as shown below.
      const newUser = await fetchDataPromise(`user/${userId}`);

      let processedUser = newUser;
      if (me.user.token === userId) {
        processedUser = processMe(newUser);
      }

      const newU = { [userId]: processedUser };

      if (what && processedUser[what]) {
        return len ? processedUser[what].length : processedUser[what]
      }

      return processedUser;
    } catch (error) {
      console.error("Error fetching user:", error);
      // Handle error (e.g., by re-throwing it or returning a default value)
      throw error; // Or return null;
    }
  };

  const updateMyCity = (cityId, callback = () => void (0)) => {
    setUpdatingAva(me.user.token)
    _updateMyCity(cityId, (mewMe) => {
      setMe(processMe(mewMe))
      setUpdatingAva()
      callback()
    })
  }



  const getUser = (userId, callback = () => void (0)) => {
    const fetch = () => {
      fetchData(`user/${userId}`, (newUser) => {
        if (me.user.token === userId) {
          newUser = processMe(newUser)
        }
        callback(newUser)
      })
    }
    fetch()
  }

  const fetchUser = (userId, callback = () => void (0), refetch = false) => {

    const fetch = () => {
      fetchData(`user/${userId}`, (newUser) => {
        const newU = {}
        if (me.user.token === userId) {
          newUser = processMe(newUser)
        }
        newU[userId] = newUser;

        if (refetch) {
          setCachedUsers(prev => ({ ...prev, ...newU }))
        }
        callback(newUser)
      })
    }

    if (cachedUsers[userId] && !refetch) {
      callback(cachedUsers[userId])
      // console.log('cachedUsers[userId] = ', cachedUsers[userId]);
      // if (!cachedUsers[userId].followings) fetch()

    } else {
      fetch()
    }

  }

  const getAvatar = (avatarId) => {
    let allAvatars = [...avatars]
    // const allAvatarsDobs = {}
    // allAvatars.forEach(one => {
    //   allAvatarsDobs[one.avatar.token] = one.avatar
    // })
    // console.log('allAvatarsDobs[avatarId] = ', allAvatarsDobs[avatarId]);
    // return allAvatarsDobs[avatarId]
    allAvatars = allAvatars.filter(one => one.avatar.token === avatarId)
    return allAvatars.length ? allAvatars[0]['avatar'] : null
  }



  const fetchDataPromise = (what) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/api/${what}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          console.error("Error fetching: ", error);
          reject(error);
        });
    });
  };



  const fetchData = (what, callback = () => void (0), callbackError = () => void (0)) => {
    fetch(`${API_URL}/api/${what}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json();
      })
      .then(data => {
        callback(data)
      })
      .catch(error => {
        callbackError()
        console.error("Error fetching: ", error);
      });
  }


  const processSummary = (sum) => {
    let newSum = JSON.parse(JSON.stringify(sum));


    //me
    newSum.me.avatar.design = newSum.me.avatar.design['meta'] ? parseNewDesign(newSum.me.avatar.design) : newSum.me.avatar.design

    newSum.avatars = newSum.avatars.map(one => ({
      ...one,
      design: one.design['meta'] ? parseNewDesign(one.design) : one.design
    }))


    newSum['followers'] = parseDesignInPersonas(newSum['followers'])
    newSum['followings'] = parseDesignInPersonas(newSum['followings'])

    newSum.pentas = newSum.pentas.map(onePenta => ({
      ...onePenta,
      members: parseDesignInPersonas(onePenta.members)
    }))




    newSum.cached = true;


    // setLocal('summary', newSum)
    setFetchingSummary(false)

    return newSum
  }




  useEffect(() => {
    // console.log('🔴🔴 rendering = ');

    // if (OFFLINE) {
    //   setMe(processMe(summary.me))
    // } else {
    //   // fetchData('me/summary', (meData) => setSummary());
    //   // fetchData('me', (meData) => setMe(processMe(meData)));
    // }

    if ((summary && summary.cached) || !summary && !fetchingSummary) {
      setFetchingSummary(true)
      console.log('🔴 summary = ', summary);
      // console.log('fetchingSummary = ', fetchingSummary);
      fetchData('me/summary', (sum) => setSummary(processSummary(sum)), () => setSummary(0));
    }
  }, []);

  useEffect(() => {
    if (summary) {
      setMe(processMe(summary.me, summary))
    }
  }, [summary]); // Depend on `me` to avoid refetching if already set

  // useEffect(() => {
  //   if (!me) { // Only fetch if `me` is not already set
  //     if (OFFLINE) {
  //       setMe(processMe(summary.me))
  //     } else {
  //       fetchData('me/summary', (meData) => setSummary());
  //       // fetchData('me', (meData) => setMe(processMe(meData)));
  //     }
  //   }
  // }, [me]);

  useEffect(() => {
    if (me && summary) {
      // loadFriends()
      // loadAvatars()
      // loadPentas()
      loadGroups()

      // setFriends(summary.friends)
      setFollowings(summary.followings)
      setFollowers(summary.followers)

      const cacheUsers = (arr => {
        let usersObj = {}
        arr.forEach(one => usersObj[one.user.token] = one)
        return usersObj;
      })

      const cached = {}
      cached[summary.me.user.token] = processMe(summary.me, summary)
      setCachedUsers({
        ...cached,
        ...cacheUsers(summary.followings),
        ...cacheUsers(summary.followers)
      })
      setAvatars(processAvatars(summary.avatars))
      setPentasList(summary.pentas)
      getGroups()

    }
  }, [me, summary]);



  const checkIfFollow = (token) => {
    let arr = [...followings];
    arr = arr.filter(one => one.user.token === token)
    return arr.length ? true : false;
  }


  const unfollow = ({ user, avatar }, callback = () => void (0)) => {
    setFollowingUser([...followings, user.token])
    _unfollow(user.token,
      () => {
        loadFollowings(() => setFollowingUser(prev => prev.filter(one => one !== user.token)));
        callback()
      })
  }

  const follow = ({ user, avatar }, callback = () => void (0)) => {
    setFollowingUser([...followings, user.token])
    _follow(user.token,
      () => {
        loadFollowings(() => setFollowingUser(prev => prev.filter(one => one !== user.token)));
        callback()
      })
  }

  useEffect(() => {

    //AUTOFREIND
    const friendToken = getLocal('invited_by') ? getLocal('invited_by') : '';
    if (me && followings && friendToken) {
      follow(friendToken)
      // localStorage.setItem('invited_by', '')
      _invitedBy(friendToken)
      localStorage.setItem('invited_by', '')
    }

    //SETME

    const clearImport = () => localStorage.setItem('import_avatar', null)

    const import_avatar = getLocal('import_avatar') ? getLocal('import_avatar') : '';
    if (me
      && !me.avatar.city_id
      && import_avatar
      && import_avatar['dob']
      && import_avatar['city_id']
    ) {

      import_avatar['city_id'] = parseInt(import_avatar['city_id'])

      // if (friendToken) {
      //   import_avatar['invited_by'] = friendToken
      // }

      updateAvatar({
        ...import_avatar,
        'token': me.avatar.token
      }, clearImport)
    }



  }, [me])






  useEffect(() => {
    // console.log('😝')
    // console.log('me = ', me)
    // console.log('myCity = ', myCity)
    // console.log('friends = ', friends)
    // console.log('followings = ', followings)
    // console.log('followers = ', followers)
    // console.log('avatars = ', avatars)
    // console.log('pentas = ', pentas)
    // console.log('groups = ', groups)
    // console.log('cachedDesigns = ', cachedDesigns)
    // console.log('updatingAva = ', updatingAva)
    // console.log('creatingAva = ', creatingAva)
    // console.log('creatingPenta = ', creatingPenta)
    // console.log('loadingDesign = ', loadingDesign)
    // console.log('deletingAvatar = ', deletingAvatar)
    // console.log('followingUser = ', followingUser)
    // console.log('cachedUsers = ', cachedUsers)
    // console.log('geoPeople = ', geoPeople)
    // console.log('myCities = ', myCities)
  }, [
    // me,
    // myCity,
    // friends,
    // followings,
    // followers,
    // avatars,
    // pentas,
    // groups,
    // cachedDesigns,
    // updatingAva,
    // creatingAva,
    // creatingPenta,
    // loadingDesign,
    // deletingAvatar,
    // followingUser,
    // cachedUsers,
    // geoPeople,
    // myCities
  ])



  return (
    <SocialContext.Provider
      value={{
        me,
        myCity,
        friends,
        followings,
        followers,
        avatars,
        pentas,
        groups,
        cachedDesigns,
        updatingAva,
        creatingAva,
        creatingPenta,
        loadingDesign,
        deletingAvatar,
        followingUser,
        cachedUsers,
        geoPeople,
        myCities,
        publicUser,
        x: {
          fetchDataAsync,
          updateMyCity,
          getCityPeople,
          unfollow,
          follow,
          fetchPentaAvatars,
          getDesign,
          setIsMe,
          getAvatar,
          getUser,
          getUserSocial,
          fetchUser,
          updateAvatar,
          updatePenta: _updatePenta,
          setUpdatingAva,
          createAvatar,
          deleteAvatar,
          createPenta,
          createPentaWithMe,
          checkIfFollow,
          addCityHistory,
          fetchAllUsers,
          getPenta,
          fetchPenta,
          addToPenta,
          deletePenta,
          deleteFromPenta,
          setPublicUser,
          getGroups,
          createGroup,
          deleteGroup,
          leaveGroup,
          updateGroup
        }
      }}
    >
      {/* {children} */}

      {summary !== 0 && me && avatars && pentas && followings && followers && children}
      {summary !== 0 && !me &&
        <>
          <PreloaderHD />
        </>
      }
      {/* {summary === 0 && <><Ad1 /></>} */}
      {summary === 0 && children}
    </SocialContext.Provider>
  )
}


const SocialConsumer = SocialContext.Consumer;

export { SocialConsumer, SocialContext, SocialProvider };

