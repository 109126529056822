import React from 'react'

export function Icon({ children, size, button, style, className }) {
  return (
    <span className={className} style={{ ...style, transform: button ? 'translateX(-0.2em)' : 'none' }}>
      <span className={`t_icon ${button ? `t_icon--button` : ``} ${size ? `t_icon--${size}` : ''}`}>{children}</span>
    </span>
  )
}

