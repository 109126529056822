import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { CSSplanet, CSSplanetLine, GatePlanet, Planet } from '../Edu/Gates';
import { findGateInUser, prepareGates } from '../utilsDesign';
import GateNumber from './GateNumber';
import { theme } from './Bodygraph';



export const GateAllActivations = React.memo(({
  gateId,
  design,
  label,
  planets,
  hexagram = false,
  number = false,
  planetGlyph = true,
  planetName = false,
  inLine = false,
  inColor = false,
  inTone = false,
  color = false,
  tone = false,
  showAdvancedForSunEarth = false,
  showAdvancedForNode = false,
  owner
}) => {

  const context = { ...useContext(LangContext) };
  const $ui = context.$ui;
  const $planet = context.$planet;
  const $gate = context.$gate;
  const designGates = design ? design['gates'] : [];
  const gates = designGates.filter(one => one.gate === gateId)


  const gateActivations = prepareGates(design.gates)

  let prsDes;
  const prsD = prepareGates(design.gates, 'des')[gateId]
  const prsP = prepareGates(design.gates, 'prs')[gateId]

  if (prsD && prsP) prsDes = 'prsdes'
  if (prsD && !prsP) prsDes = 'des'
  if (!prsD && prsP) prsDes = 'prs'

  const showAdv = (p) => {
    if ((p === 'Sun' || p === 'Earth') && showAdvancedForSunEarth) return true;
    if ((p === 'North_Node' || p === 'South_Node') && showAdvancedForNode) return true;
    return false;
  };

  return (<>




    {Boolean(findGateInUser(gates, gateId).length) &&

      <div className="_fv">



        <div className="x_gate_title0">



          {number && <>
            <GateNumber design={design} gateId={gateId} />
          </>}

          {hexagram && <div className={`x_gate_title_hex x_gate_title_hex--${prsDes}`}>
            {$gate(gateId, 'hexagram')}
          </div>}




          {!inLine && findGateInUser(gates, gateId, label).map((oneGate, ind) => {

            const planet = oneGate.planet;
            return (
              <CSSplanetLine
                key={`gate_${oneGate['planet']}_${ind}_brief`}
              >
                <CSSplanet label={oneGate['label']}

                >
                  <Planet
                    label={oneGate['label']}
                    planet={oneGate['planet']}
                    shown={true}
                    gate={oneGate}
                    titleShown={true}

                  />.{`${oneGate['line']}`}

                </CSSplanet>

                {planets && <>

                  <div className="x_planet_title" style={{ color: oneGate['label'] === 'des' ? theme.designColor : '' }}>
                    {$planet(planet, 'title')}
                  </div>

                  <div className=" _fv _fgap04 _f100">
                    <div></div>
                    <div className="">
                      {$planet(planet, 'description')}
                    </div>
                  </div>

                </>}

              </CSSplanetLine>


            );
          })}

          {inLine &&
            <div className="x_gate_info">
              {findGateInUser(gates, gateId).map((oneGate, ind) => {
                return (<CSSplanetLine
                  key={`gate_${oneGate['planet']}_${ind}_line`}>
                  <GatePlanet
                    gate={oneGate}
                    design={design}
                    inLine={inLine}
                    color={color || showAdv(oneGate['planet'])}
                    tone={tone || showAdv(oneGate['planet'])} />
                </CSSplanetLine>);
              })}
            </div>}




        </div>

      </div>

    }


  </>
  );
});


