import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { drawType } from '../utilsDesign';

export const Background = React.memo(({
  user,
  design,
  activePage,
  aura = true,
  style,
  className,
  children
}) => {
  // const context = { ...useContext(Context), ...useContext(StateContext) };

  let { userId } = useParams();

  user = user ? user : userId;

  const [designInner, setDesignInner] = useState(design)

  useEffect(() => {
    setDesignInner(design)
  }, [design])



  return (
    <>


      <div className={`x_edu_bg ${className} ${designInner && drawType(designInner.type, 'class')}`}
        style={{ ...style }}
      >
        {/* {Boolean(children) && <div style={{ zIndex: 1 }}>{children}</div>} */}
        {aura && <div className={`x_edu_aurabg ${designInner && drawType(designInner.type, 'class')} ${className ? className : ''} ${activePage ? `page--${activePage}` : ''}`} />}
      </div>

    </>
  )
})