import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { WithVersion } from './VersionToggler';



export function SocialChoose({
  handle,
  handle0,
  handleHover,
  active = [],
  options = [],
  view = 'full'
}) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  return (<>

    {view === 'full' && <div className="x_panel_sub0">
      <div className="x_panel_sub_inner">
      {options.map(one => one.withVersion ? <WithVersion key={`menu_${one.id}`}><div
        className={active.includes(one.id) ? "x_panel_sub1 active" : "x_panel_sub1"}
        onClick={() => handle(one.handleValue)}
      >
        <div className="x_panel_sub_icon">
          <div className="t_icon">
            <div>{one.icon}</div>
          </div>
        </div>
        <div className="x_panel_sub_text">{$ui(one.title)}</div>
      </div></WithVersion> : <div
        key={`menu_${one.id}`}
        className={active.includes(one.id) ? "x_panel_sub1 active" : "x_panel_sub1"}
        onClick={() => handle(one.handleValue)}
      >
        <div className="x_panel_sub_icon">
          <div className="t_icon">
            <div>{one.icon}</div>
          </div>
        </div>
        <div className="x_panel_sub_text">{$ui(one.title)}</div>
      </div>

      )}
      </div>
    </div>}

    {view === 'left' && <div className="x_choose_open0"
    // onClick={() => handle0()}
    >


      {options.map(one => one.withVersion ? <WithVersion key={`menu_${one.id}`}><div className="x_choose_open_item"
        onClick={() => handle(one.handleValue)}
        onMouseEnter={() => handleHover(one.handleValue)}
      >
        <div className={active.includes(one.id) ? "x_choose_open active" : "x_choose_open"}>
          <div className="t_icon t_icon--60">
            {one.icon}
          </div>
        </div>
        <div className="x_choose_open_label inact">
          {$ui(one.title)}
        </div>
      </div></WithVersion>


        : <div className="x_choose_open_item"
          key={`menu_${one.id}`}
          onClick={() => handle(one.handleValue)}
          onMouseEnter={() => handleHover(one.handleValue)}
        >
          <div className={active.includes(one.id) ? "x_choose_open active" : "x_choose_open"}>
            <div className="t_icon t_icon--60">
              {one.icon}
            </div>
          </div>
          <div className="x_choose_open_label inact">
            {$ui(one.title)}
          </div>
        </div>

      )}

    </div>}

  </>
  );
}

function SocialChoosePlain({ handle, active = [] }) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  return (
    <div className="x_panel_sub0">

      <div className={active.includes('world') ? "x_panel_sub1 active" : "x_panel_sub1"}
        onClick={() => handle(['world'])}
      >
        <div className="x_panel_sub_icon">
          <div className="t_icon">
            <div>travel_explore</div>
          </div>
        </div>
        <div className="x_panel_sub_text">{$ui('people_city')}</div>
      </div>

      <div className={active.includes('people') ? "x_panel_sub1 active" : "x_panel_sub1"}
        onClick={() => handle(['people', 'followers'])}
      >
        <div className="x_panel_sub_icon">
          <div className="t_icon">
            <div>group</div>
          </div>
        </div>
        <div className="x_panel_sub_text">{$ui('people_people')}</div>
      </div>


        <div className={active.includes('celebs') ? "x_panel_sub1 active" : "x_panel_sub1"}
          onClick={() => handle(['celebs'])}
        >
          <div className="x_panel_sub_icon">
            <div className="t_icon">
              <div>star</div>
            </div>
          </div>
          <div className="x_panel_sub_text">{$ui('people_celebs')}</div>
        </div>


      <div className={active.includes('bodygraphs') ? "x_panel_sub1 active" : "x_panel_sub1"}
        onClick={() => handle(['bodygraphs'])}
      >
        <div className="x_panel_sub_icon">
          <div className="t_icon">
            <div>person_search</div>
          </div>
        </div>
        <div className="x_panel_sub_text">{$ui('people_bodygraphs')}</div>
      </div>


        <div className={active.includes('pentas') ? "x_panel_sub1 active" : "x_panel_sub1"}
          onClick={() => handle(['pentas'])}
        >
          <div className="x_panel_sub_icon">
            <div className="t_icon">
              <div>diversity_3</div>
            </div>
          </div>
        <div className="x_panel_sub_text">{$ui('people_composite')}</div>
        </div>


    </div>
  )
}

