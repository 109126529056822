import React, { useState } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

export const TestCity = () => {
  const [selectedToken, setSelectedToken] = useState('los');

  const handleChange = (e) => {
    setSelectedToken(e.target.value);
  };

  const handleCitySearch = async () => {
    const url = `${API_URL}/api/geo/cities?search=/${selectedToken}/`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        // If you need to send a body with the POST request, include it here
        // body: JSON.stringify({ key: 'value' }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      // Handle success, e.g., showing a success message
    } catch (error) {
      console.error('Error:', error.message);
      // Handle error, e.g., showing an error message
    }
  };

  return (
    <div>
      <h3>/api/geo/cities?search=XXX</h3>
      <input tepe='text' value={selectedToken} onChange={handleChange} placeholder='token'></input>
      <button onClick={handleCitySearch}>Search City</button>


      <div>
      </div>


    </div>
  );
};


