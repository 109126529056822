import { parseDesignInPersonas } from './utilsDesign';
const API_URL = process.env.REACT_APP_API_URL;

export const _getAdminUsersList = async () => {
  const url = `${API_URL}/admin/api/users/list`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    let data = await response.json();
    data = parseDesignInPersonas(data);
    console.log('Success:', data);
    return data; // Return the data instead of using a callback
  } catch (error) {
    console.error('Error:', error.message);
    throw error; // Rethrow the error to be handled by the calling code
  }
};



export const _publishDigest = async (
  digestToken = '',
  recepients,
  callback = () => void (0)
) => {
  const url = `${API_URL}/admin/api/skynet/publish/`;

  try {

    const sendData = {
      "_id": digestToken
    }
    if (recepients && recepients.length) {
      sendData['users'] = recepients;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendData),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};

export const _previewDigest = async (
  digestToken = '',
  callback = () => void (0)
) => {
  const url = `${API_URL}/admin/api/skynet/preview/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "_id": digestToken
      }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};