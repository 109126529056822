import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';

const API_URL = process.env.REACT_APP_API_URL;

export const ImageUpload = ({ user,
  avatar,
  handleUpdate = () => void (0),
  resetAvatar = () => void (0),
  ghost
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (selectedFile) {
      handleSubmit()
    }
  }, [selectedFile])

  const handleSubmit = async (event) => {
    // event.preventDefault();
    resetAvatar()
    const formData = new FormData();
    formData.append('UploadForm[imageFile]', selectedFile);

    try {
      const response = await fetch(`${API_URL}/api/user/image`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('File uploaded successfully', data);
        handleUpdate()
      } else {
        console.error('Error uploading file', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };

  return (
    <form >
      <FileInputWrapper>
        <FileInput
          type="file"
          accept="image/jpeg,image/png"
          onChange={handleFileChange}
          id="file-upload"
        />
        <FileInputLabel htmlFor="file-upload" className={!avatar.image ? 'x_button' : 'x_button x_button--ghost'}>
          {!avatar.image ? $ui('user_upload_image') : $ui('user_change_image')}
        </FileInputLabel>
      </FileInputWrapper>
      {/* {Boolean(selectedFile) && <Button ghost={ghost} type="submit" onClick={() => handleSubmit()}>{$ui('user_upload_image')}</Button>} */}
    </form>
  );
};


const FileInputWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const FileInput = styled.input`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  /* display: inline-block;
  padding: 0.5em 1em;
  border: 2px solid #007bff;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  } */
`;