import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { channels_dictionary } from '../../static/channels_dictionary';
import { PopInfo } from '../PopInfo';
import { CenterInfo } from '../UiKit/CenterInfo';
import { prepareChannels } from '../utilsDesign';
import { getPentaDesign } from '../utilsPenta';
import { ChannelInfo } from './ChannelInfo';
import { StudyGate } from './StudyGate';

export function Study({ avatar, penta }) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $center = context.$center;
  const $ui = context.$ui;
  const $gate = context.$gate;

  const navigate = useNavigate();
  const location = useLocation();

  const isStudy = location.pathname.includes('study');

  const { what } = useParams();

  const infoType = what ? what.split(':')[0] : null;
  let infoValue = what ? what.split(':')[1] : null;

  // console.log('infoType = ', infoType);
  // console.log('infoValue = ', infoValue);

  let design = !penta ? JSON.parse(JSON.stringify(avatar.design)) : getPentaDesign(penta)

  design = {
    ...design,
    channels: prepareChannels(design.gates)
  }

  const handleCloseInfoPop = () => {
    document.body.classList.remove('noscroll');
    context.setState.setHighlighted()
    context.setState.setSearch([])

    const removeStudySegments = (pathname) => {
      const segments = pathname.split('/study')
      return segments.length > 1 ? segments[0] : null
    };
    const currentPath = location.pathname;
    const newPath = removeStudySegments(currentPath);
    const queryParams = location.search ? location.search : '';
    if (newPath) navigate(`${newPath}${queryParams}`);
  };


  const handleClose = () => {
    // navigate('../')
    handleCloseInfoPop()
  }
  const channelProps = isStudy
    ? context.languageReady ? { ...context.lang.channels[infoValue] } : channels_dictionary.filter(one => one.id === infoValue)[0]
    : {}

  return (
    <>

      <PopInfo
        active={isStudy}
        id={`${what}_${infoValue}`}
        addClassName={isStudy ? 'active' : 'inactive'}
        type='study'
        // title={`${$center(infoValue, 'title')}`}
        title={`${what ? $center(infoValue, 'title') : ''}`}
        // title={`${$ui('pop_study_title')}: ${what ? $center(infoValue, 'title') : ''}`}
        icon='info'
        handleClose={() => handleClose()}
      >


        {Boolean(infoType) &&

          <div className="x_info_c _f100">

            {isStudy && infoType === 'center' &&
              <CenterInfo
                center={infoValue}
                design={design}
              />}


            {isStudy && infoType === 'channel'
              && <>
                <ChannelInfo
                  design={design}
                // gates={design.gates}
                  {...channelProps}
                />
              </>}

            {isStudy && infoType === 'gate'
              && <>
              <StudyGate
                design={design}
                gateId={infoValue}
              />
              </>}

          </div>
        }
      </PopInfo>
    </>
  )
}



