import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  padding: 0.5em 1em 0.5em 0.8em;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  flex: 1;
  row-gap: 0.2em;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  /* text-transform: uppercase; */
  font-size: 1.2em;
  font-weight: 600;
  &._active {
    background-color: #1900ff;
    color: #fff;
    border-color: #1900ff;
    /* font-weight: bold; */
  }
`;

export function AdminMenu({ active = 'users', style = {} }) {
  return (
    <div className='_f _fgap2' style={{
      padding: '1em 1em',
      background: '#fff',
      ...style
    }}>
      <h1 style={{ fontSize: '2em' }}>🔧 Admin</h1>
      <div className='_f _fgap1'>
        <StyledLink to="/admin/" className={active === 'users' ? '_active' : ''}  >👥 Users</StyledLink>
        <StyledLink to="/admin/skynet" className={active === 'skynet' ? '_active' : ''}>🌐 Skynet</StyledLink>
        <StyledLink to="/admin/texts" className={active === 'texts' ? '_active' : ''} >💬 Texts</StyledLink>
        <StyledLink to="/admin/videos" className={active === 'videos' ? '_active' : ''} >🎬 Videos</StyledLink>
      </div>
    </div>
  )
}