import React, { useState } from 'react';
import { Background } from '../UiKit/Background';
import { Preview } from '../UiKit/Preview';
import { getPentaDesign } from '../utilsPenta';
import { PentaBodyPlanets } from './PentaBodyPlanets';


export function PentaBody({
  planets,
  preview = 'comp',
  penta = {},
  parentContainer,
  className = 'x_bodygraph_body',
  handleClick,
  setPreview,
  aura,
  numbers
}) {

  const [temp, setTemp] = useState()


  return (
    <>

      {Boolean(handleClick) &&
        <div style={{ zIndex: 100 }}
          className="x_choose_hover" onClick={handleClick}>
        </div>
      }



      <div
        className={className}
        onClick={handleClick}
      >



        {planets && <PentaBodyPlanets
          setPreview={(previewId) => {
            setPreview(previewId)
            setTemp(previewId)
          }}
          penta={penta}
          preview={preview}
          label='des'
        />}

        <div
          className={(penta.members[0] && preview === 0)
            ? `x_body_comp_preview inact active`
            : "x_body_comp_preview inact"}
        >
          <Preview
            numbers={true}
            parentContainer={parentContainer}
            design={penta.members[0].avatar.design}
          />
        </div>

        {penta.members[1] && <div
          className={(penta.members[1] && preview === 1)
            ? `x_body_comp_preview inact active`
            : "x_body_comp_preview inact "}
        >
          <Preview
            numbers={true}
            parentContainer={parentContainer}
            design={penta.members[1].avatar.design}
          />
        </div>}


        <Preview
          // planets={true}
          numbers={numbers}
          design={getPentaDesign(penta)}
          aura={false}
          // width={60}
          // height={80}
          parentContainer={parentContainer}
        />


        {planets && <PentaBodyPlanets
          setPreview={(previewId) => {
            setPreview(previewId)
            setTemp(previewId)
          }}
          penta={penta}
          preview={preview}
          label='prs'
        />}


      </div>

      {aura && <Background
        design={getPentaDesign(penta)}
      />}
    </>
  )
}



