import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { _getInvitations, _requestIntoGroup } from '../utilsGroup';
import { Button } from './Button';
import { EditName } from './EditName';
import { Icon } from './Icon';
import { PopoverH } from './TooltipH';


export function SocialTabs({ active = 'all', handleChange = () => void (0) }) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [activeTab, setActiveTab] = useState(active)

  useEffect(() => {
    // if (active !== 'all' &&  context.groups.findIndex(group => group.token === active) < 0) {
    //   setActiveTab('all')
    //   handleChange('all')
    // }
    if (active && active !== activeTab) {
      setActiveTab(active)
      handleChange(active)
    }
  }, [active, context.groups])


  return (
    <>
      <div className="x_choose_folders0">
        <div className="x_choose_folders x_choose_folders--v">

          <Tab name={'All'} token={'all'} handleChange={handleChange} active={activeTab === 'all' ? true : false} />

          {context.groups.map(one => {
            return <Tab key={one.token} active={activeTab === one.token ? true : false} handleChange={handleChange} {...one} />
          })}

          <div className="x_choose_folders_create _f">

            <PopoverH
              title={<EditName
                name={''}
                placeholder={$ui('group_set_name_placeholder')}
                placement='bottom'
                handleUpdate={(newName) => {
                  context.x.createGroup({
                    'name': newName,
                    'description': ''
                  })
                }}
              />}>
              <div >
                <Button ghost icon='add'></Button>
              </div>
            </PopoverH>

            <GroupInvitations />

          </div>


        </div>
      </div>

    </>
  )
}


function GroupInvitations() {
  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [groupInvitations, setGroupInvitations] = useState([])
  useEffect(() => {
    _getInvitations(setGroupInvitations)
  }, [])

  const accept = (token) => {
    _requestIntoGroup(token, () => _getInvitations(setGroupInvitations))
  }

  return (<>

    {Boolean(groupInvitations.length) &&

      <PopoverH
        title={<>
          <div className="_fv _fgap06">
            <h3>{$ui('group_invited_to')}</h3>
            {groupInvitations.map(one => {
              return (<div key={one.token} className='_f'>
                <div className="_fv _fcc">
                  <Icon>group</Icon>
                </div>
                <div className="_f _fcc">
                  <b>{one.name}</b>{Boolean(one.description) && <span> | {one.description}</span>}
                </div>
                <div className="_fv _fcc">
                  <Button sm ghost icon='open_in_new' onClick={() => navigate(`/g/${one.token}`)}>{$ui('group_open')}</Button>
                </div>
                <div className="_fv _fcc">
                  <Button sm onClick={() => accept(one.token)}>{$ui('group_accept')}</Button>
                </div>

              </div>)
            })}
          </div>
        </>}>
        <div >
          <Button ghost icon='mail'>{groupInvitations.length}</Button>
        </div>
      </PopoverH >

    }
  </>)
}




export default function Tab({ name, active, token, handleChange = () => void (0) }) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  return (
    <div className="x_choose_folders_tab0" onClick={() => handleChange(token)}>
      <div className={`x_choose_folders_tab1 ${active ? 'active' : ''}`}>
        <svg width={20} height={56} viewBox="0 0 20 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3052 7.83977C11.2836 3.26724 15.324 0 20 0V0V56H0L10.3052 7.83977Z" fill="#FEF9F4" />
        </svg>
      </div>
      <div className={`x_choose_folders_tab ellipsis _fcc ${active ? 'active' : ''}`}>
        {name}
      </div>
      <div className={`x_choose_folders_tab1 ${active ? 'active' : ''}`}>
        <svg width={20} height={56} viewBox="0 0 20 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.69478 7.83977C8.71636 3.26724 4.67603 0 0 0V0V56H20L9.69478 7.83977Z" fill="#FEF9F4" />
        </svg>
      </div>
    </div>

  )
}
