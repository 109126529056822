import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { Pop } from './UiKit/Pop';
import { Videos } from './UiKit/Videos';

// YouTube component that uses the IFrame Player API
const YouTube = ({ video = {} }) => {
  const playerRef = useRef(null);
  const { youtubeId, start_time, end_time } = video;

  useEffect(() => {
    const loadYouTubeIframeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    const onYouTubeIframeAPIReady = () => {
      if (window.YT && playerRef.current) {
        playerRef.current = new window.YT.Player(`youtube-player-${youtubeId}`, {
          videoId: youtubeId,
          playerVars: {
            autoplay: 1,
            rel: 0,
            start: start_time,
            end: end_time
          },
          events: {
            onReady: (event) => {
              event.target.seekTo(start_time);
              event.target.playVideo();
            }
          }
        });
      }
    };

    loadYouTubeIframeAPI();

    if (window.YT) {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      if (playerRef.current && playerRef.current.destroy) {
        playerRef.current.destroy();
      }
    };
  }, [youtubeId, start_time]);

  return (
    <div className="x_pop_video_c">
      <div className="x_pop_video_embed0">
      <div
        id={`youtube-player-${youtubeId}`}
        ref={playerRef}
        className='x_pop_video_embed'
      // style={{ width: '400px', height: '300px' }}
        />
      </div>
    </div>
  );
};

// VideoPop component
export function VideoPop() {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const location = useLocation();
  const [videoId, setVideoId] = useState(null);
  const [rel, setRel] = useState(null);
  const [value, setValue] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const video = params.get('video');
    const related = params.get('rel');
    const relValue = params.get('value');
    const tit = params.get('title');
    setVideoId(video);
    setRel(related);
    setValue(relValue);
    setTitle(tit);
  }, [location]);

  const handleClose = () => {
    setVideoId(null);
    window.history.replaceState(null, '', window.location.pathname + window.location.search);
  };

  const video = context.getVideoById(videoId);

  return (
    <>
      {Boolean(videoId) && <Pop
        type='video'
        title={<>{title ? <div className="mobile_v_no">{title}</div> : <div className="mobile_v_no">{video?.comment}</div>}</>}
        icon='videocam'
        handleClose={handleClose}
        addContent={<div className="x_c_width">
          <div className="x_videos00">
            <VideoInfo video={video} />

            <Videos relType={rel} relValue={value} active={videoId} />
          </div>
        </div>
        }
      >
        <YouTube video={video} />
      </Pop>}
    </>
  );
}


const VideoInfo = ({ video }) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  return (
    <div className="m_b_extra0">
      <div className="x_pop_video_i0">
        <div className="x_pop_video_i">
          <div className="x_pop_video_i_title">{video.comment}</div>
          {/* <div className="x_choose_user_actionable">
            <div className="x_choose_user_info">
              <div className="x_choose_user_nick">
                <div>maiik</div>
              </div>
            </div>
          </div> */}
        </div>
        <a href={`https://www.youtube.com/watch?v=${video.youtubeId}`} target="_blank" className="x_f_social0 w-inline-block">
          <div className="x_f_social_ico">
            <div className="code-embed w-embed">
              <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M28.4566 9.96702C28.1576 8.79927 27.2765 7.87942 26.158 7.56729C24.1305 7 16.0001 7 16.0001 7C16.0001 7 7.86952 7 5.8421 7.56729C4.72351 7.87942 3.8424 8.79927 3.5434 9.96702C3 12.0836 3 16.5 3 16.5C3 16.5 3 20.9165 3.5434 23.0331C3.8424 24.2008 4.72351 25.1206 5.8421 25.4327C7.86952 26 16.0001 26 16.0001 26C16.0001 26 24.1305 26 26.158 25.4327C27.2765 25.1206 28.1576 24.2008 28.4566 23.0331C29 20.9165 29 16.5 29 16.5C29 16.5 29 12.0836 28.4566 9.96702ZM13.4 20.5714L20.1543 16.5L13.4 12.4286V20.5714Z" fill="currentColor" />
              </svg>
            </div>
          </div>
          <div className="x_f_social_name">
            <div>{$ui('video_watch_on_youtube')}</div>
          </div>
        </a>
      </div>
    </div>

  )
}