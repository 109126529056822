import React from 'react'

export default function TimeTicks({ hoursPlus = 1, dob }) {
  // Parse the dob to get the central hour and minute
  const centralDate = new Date(dob);
  const centralHour = centralDate.getHours();
  const centralMinute = centralDate.getMinutes();

  // Calculate the total number of ticks (always 1 minute intervals)
  const totalTicks = hoursPlus * 2 * 60;

  // Generate an array of ticks
  const ticks = Array.from({ length: totalTicks }, (_, index) => {
    // Calculate the actual minute this tick represents
    const tickTotalMinutes = (index + centralHour * 60 + centralMinute - hoursPlus * 60 + 1440) % 1440;
    const tickMinute = tickTotalMinutes % 60;

    // Determine the classes for the tick
    const isTenthTick = tickMinute % 10 === 0;
    const isFifthTick = tickMinute % 5 === 0;

    return (
      <div
        key={index}
        className={`x_time_tick ${isTenthTick ? 'x_time_tick--10' : ''} ${isFifthTick && !isTenthTick ? 'x_time_tick--5' : ''} ${!isFifthTick && !isTenthTick ? 'x_time_tick--0' : ''}`}
      />
    );
  });

  return (
    <div
      className={`x_time_uncertain_time0 ${hoursPlus >= 5 ? 'x_time_uncertain_time0--large-range' : ''}`}
      style={{
        opacity: 0.7,
        marginTop: '-0.3em',
        // fontSize: '0.6em'
      }}
    >
      {ticks}
    </div>
  )
}
