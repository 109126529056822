import React, { useContext, useEffect, useRef, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Avatar } from '../UiKit/Avatar';
import { Button } from '../UiKit/Button';
import { City } from '../UiKit/City';
import { ImageUpload } from '../UiKit/ImageUpload';
import { Preloader } from '../UiKit/kit';
import { _getCity } from '../utilsGeo';

export const MeChecklist = React.memo(({ children, user = {}, avatar = {}, addClassName = '' }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [title, setTitle] = useState(avatar.name ? avatar.name : '');
  const [image, setImage] = useState(avatar.image ? avatar.image : '');
  const [cityId, setCityId] = useState(user ? user.city_id : null);
  const [avatarInner, setAvatarInner] = useState(avatar);
  const [uploading, setUploading] = useState(false);

  const [cityData, setCityData] = useState(user.aggr.city);

  useEffect(() => {
    if (cityId && !cityData) {
      _getCity(cityId, setCityData)
    }
  }, [cityId])

  const updateImage = () => {
    // if (user.image !== image) {
    //   setImage(user.image)
    //   setAvatarInner(prev => ({
    //     ...prev,
    //     'image': user.image
    //   }))
    //   // setImage(avatar.image)
    // }
    window.location.reload();
    // handleSave()
    setUploading(false)

    if (avatar.image !== image) {
      setImage(avatar.image)
      setAvatarInner(prev => ({
        ...prev,
        'image': avatar.image
      }))
      setUploading(false)
      // setImage(avatar.image)
    }
  }

  useEffect(() => {
    if (avatar.image !== avatarInner.image ||
      avatar.name !== avatarInner.name
    ) {
      setAvatarInner(avatar)
      setUploading(false)
    }
  }, [avatar])

  useEffect(() => {
    if (cityData) {
      console.log('cityData = ', cityData);
    }
  }, [cityData])

  const inputName = useRef(null);





  const handleSave = () => {
    if (cityId) {
      context.x.updateMyCity(cityId)
    }
    if ((title && title !== avatarInner.name) || (image || image !== avatarInner.image)) {
      context.x.updateAvatar({
        'token': avatarInner.token,
        'name': title,
        'image': image,
        // 'privateNote': privateNote,
      })
    }
    // handleClose()
  };

  return (
    <>
      {avatarInner && user && <>

        <div className={`x_user_checklist0 ${addClassName}`} style={{ position: 'relative' }}>

          {children}

          <div className={`x_user_checklist_li ${avatarInner.image ? 'x_user_checklist_li--checked' : ''}`}>
            {Boolean(avatarInner.image) && <div className="x_user_check x_user_check--checked">
              <div className="x_user_check_checked">
                <div className="t_icon"><div>check_circle</div></div></div>
              {$ui('user_set_image')}
            </div>}
            {!avatarInner.image && <div className="x_user_check x_user_check--unchecked">
              <div className="x_user_check_unchecked">
                <div className="t_icon"><div>radio_button_unchecked</div></div></div>
              {$ui('user_set_image')}
            </div>}

            <div className={`x_user_check0 ${avatarInner.image ? 'x_user_check0--checked' : ''}`}>

              {/* <div className="x_edu_h3">{$ui('user_image')}</div> */}

              <div className="_f _f100">

                <div className="_f _f100 _fgap1 _fm">
                  <div className="x_calc_result_ava">
                    {uploading && <Preloader style={{ position: 'absolute' }} />}
                    <Avatar
                      style={{ fontSize: '3em' }}
                      avatar={avatarInner}
                    />
                  </div>
                  {/* <b><AvatarName avatar={avatar} /></b> */}

                  <ImageUpload
                    avatar={avatarInner}
                    resetAvatar={() => setUploading(true)}
                    handleUpdate={updateImage}
                    ghost={avatarInner.image ? true : false}
                  ></ImageUpload>
                </div>

              </div>
            </div>
          </div>


          <div className={`x_user_checklist_li ${avatar.name ? 'x_user_checklist_li--checked' : ''}`}>
            {Boolean(avatarInner.name) && <div className="x_user_check x_user_check--checked">
              <div className="x_user_check_checked">
                <div className="t_icon"><div>check_circle</div></div></div>
              {$ui('user_set_publicname_title')}
            </div>}
            {!avatar.name && <div className="x_user_check x_user_check--unchecked">
              <div className="x_user_check_unchecked"><div className="t_icon"><div>radio_button_unchecked</div></div></div>
              {$ui('user_set_publicname_title')}
            </div>}
            <div className={`x_user_check0 ${avatar.name ? 'x_user_check0--checked' : ''}`}>
              <div className="x_calc_form_line">

                {/* <div className="x_edu_h3">{$ui('user_publicname_label')}</div> */}

                <div className="_f">

                  <div className="x_calc_form0 _f100">
                    <input
                      ref={inputName}
                      // placeholder={title}
                      type="text"
                      className="x_input x_input--name"
                      onFocus={(e) => e.target.select()}
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />

                    <div className="x_input_right">
                      <div className="x_input_action" onClick={() => {
                        setTitle(avatar.name)
                        inputName.current.focus()
                      }}>
                        <div className="t_icon t_icon--40">
                          edit
                        </div>
                      </div>
                    </div>

                  </div>
                  {(!title || title !== avatar.name) && <div className="_fr">
                    <Button
                      icon={!context.updatingAva ? 'check' : 'pending'}
                      disabled={!title || context.updatingAva || title === avatar.name}
                      inactive={!title || title === avatar.name}
                      onClick={() =>
                        !(context.updatingAva || title === avatar.name) && handleSave()
                      }
                    >
                      {/* {!context.updatingAva && <div>
                              {$ui('process_save')}
                            </div>} */}
                      {/* {context.updatingAva && <div>{$ui('process_saving')}...</div>} */}
                    </Button>
                  </div>}

                </div>
              </div>
            </div>
          </div>


          <div className={`x_user_checklist_li ${user.city_id ? 'x_user_checklist_li--checked' : ''}`}>
            {Boolean(user.city_id) && <div className="x_user_check x_user_check--checked">
              <div className="x_user_check_checked"><div className="t_icon"><div>check_circle</div></div></div>
              {$ui('user_set_public_city')}
            </div>}
            {!Boolean(user.city_id) && <div className="x_user_check x_user_check--unchecked">
              <div className="x_user_check_unchecked"><div className="t_icon"><div>radio_button_unchecked</div></div></div>
              {$ui('user_set_public_city')}
            </div>}

            <div className={`x_user_check0 ${user && user.city_id ? 'x_user_check0--checked' : ''}`}>
              {/* <div className="x_edu_h3">{$ui('user_city_title')}</div> */}
              <div className="x_calc_form0 _f100">



                {user && user.isMe && <div className="x_calc_form_line">

                  <div className="x_calc_form0">
                    {user && !user.city_id && <div className="x_noti0" />}
                    <City
                      locateMe={true}
                      city={user ? user.aggr.city : context.myCity}
                      className='x_input--cityprofile'
                      show={['city', 'province', 'country']}
                      placeholder={$ui('user_city_placeholder')}
                      value={cityId}
                      handleCityId={(id) => { setCityId(id) }}
                      handleCityObj={(city) => {
                        if (city && city.id) {
                          setCityData(city)
                          setCityId(city.id)
                          context.x.updateMyCity(city.id)
                        }
                      }}
                    />
                  </div>
                </div>}

                {(!cityId || cityId !== user.city_id) && <div className="_fr">
                  <Button
                    icon={!context.updatingAva ? 'check' : 'pending'}
                    disabled={!cityId || context.updatingAva || cityId === user.city_id}
                    inactive={!cityId || cityId === user.city_id}
                    onClick={() =>
                      (cityId && !(context.updatingAva || cityId === user.city_id) && !context.updatingAva) && handleSave()
                    }
                  >
                  </Button>


                </div>
                }
              </div>

              {$ui('user_city_placeholder_note')}

            </div>
          </div>

        </div>
      </>}
    </>
  )
})


