export const default_design = {
  id: 'dummy',
  type: '',
  auth: '',
  gates: [],
  variables: {
    left_down: null,
    left_up: null,
    right_down: null,
    right_up: null
  },
  inc_cross: [],
  inc_cross_typ: '',
  profile: [],
  split: '',
  active_chakras: [],

  des: '',
  prs: '',

  design: [],
  personality: [],
  meta: {
    design: '',
    personality: ''
  }

};

export const default_avatar = {
  "my": true,
  "name": "",
  "image": "",
  "gender": "",
  "dob": "",
  "dob_start": "",
  "dob_end": "",
  "city_id": 0,
  "aggr": {},
  "token": "dummy",
  "created": "2024-07-17T06:17:43+00:00",
  "updated": "2024-07-17T06:18:50+00:00",
  "design": default_design
};