import React from 'react'

export default function Soon({ title, id, activeLink }) {
  return (

    <div className="x_edu_100">
      <div className="x_edu_type">
        <div className="x_edu_content_title x_edu_content_title--nosticky">
          <h1 className=" x_edu_content_h--type">"{title}" page is in development</h1>

          {/* <div className="x_edu_vote0">
            <div className="x_button x_button--vote">
              <div>Invest 1 token</div>
            </div>
            <div className="x_edu_vote_descr">
              <div>Use your tokens to invest into the development of "{title}".</div>
            </div>
          </div> */}


        </div>
      </div>
    </div>



  )
}
