import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;


export const getGeoLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export const showGeoPosition = (position) => {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;
  console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

  // You can now use the latitude and longitude for your app's functionality
};

const showGeoError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      alert("User denied the request for Geolocation.");
      break;
    case error.POSITION_UNAVAILABLE:
      alert("Location information is unavailable.");
      break;
    case error.TIMEOUT:
      alert("The request to get user location timed out.");
      break;
    case error.UNKNOWN_ERROR:
      alert("An unknown error occurred.");
      break;
  }
};



export const fetchNearestCities = async (latitude, longitude) => {
  try {
    const response = await fetch(`${API_URL}/api/geo/close?lat=${latitude}&lon=${longitude}`);
    if (!response.ok) {
      throw new Error('Failed to fetch city data');
    }
    const data = await response.json();
    return data; // Return the city data directly
  } catch (error) {
    message.error("Failed to fetch city data");
    console.error("Error fetching city data:", error);
    return []; // Return an empty array in case of error
  }
};

export const checkProvince = (input) => {
  if (!input) return '';

  const firstChar = input.charAt(0);

  if (/^\d/.test(firstChar)) {
    return '';
  } else if (/^[a-zA-Z]/.test(firstChar)) {
    return input;
  } else {
    return '';
  }
};




export const handleCitySearch = async (selectedToken = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/geo/cities?search=${selectedToken}`;
  // const url = `https://hd.cafe/api/geo/cities?search=${selectedToken}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();
    console.log('Success:', data);
    callback(data)
  } catch (error) {
    console.error('Error:', error.message);
  }
};

export const handleCitySearchHD = async (cityName = '') => {
  const url = `https://hd.cafe/api/geo/cities?search=${cityName}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    return data;  // Return the data directly

  } catch (error) {
    console.error('Error:', error.message);
    return [];  // Return an empty array or handle the error as needed
  }
};



export const _getCity = async (cityId, handleData = () => void (0)) => {
  // dateTimeToId(date, time, null, setOutput)
  // calculateDesign(date, time, { city: city, title: title, timezone: timezone }, setOutput)

  const url = `${API_URL}/api/geo/city/${cityId}`;
  // const url = `https://hd.cafe/api/geo/city/${cityId}`;

  // console.log('url = ', url);
  fetch(url)
    .then((response) => response.json())
    .then((dat) => {
      handleData(dat)
    })
    .catch(error => {
      console.error("Error fetching: ", error);
    });

}



export const _updateMyCity = async (newCityId = '', callback = () => void (0)) => {
  const url = `${API_URL}/api/me/update`;

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Include any other headers you need, such as authorization
      },
      // If you need to send a body with the POST request, include it here
      body: JSON.stringify({ city_id: newCityId }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    callback && callback(data)
    // Handle success, e.g., showing a success message
  } catch (error) {
    console.error('Error:', error.message);
    // Handle error, e.g., showing an error message
  }
};