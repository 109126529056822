import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { default_design } from '../static/default_design';
import { designs_default1 } from '../static/dummy_data';
import { Preview } from './UiKit/Preview';



export function Ad1({
  children,
  nameShown,
  typ,
  profile,
  personId,
  obscure,
  nameObs,
  image,
  size,
  types = ['G', 'MG', 'M', 'P', 'R'],
  lines = [1, 2, 3, 4, 5, 6],
}) {

  const context = { ...useContext(LangContext) }
  const $ui = context.$ui;

  const data = designs_default1;




  const sizes = {
    sm: {
      width: 36,
      height: 64,
    },
    full: {
      width: 100,
      height: 140,
    },
  };

  size = size ? 'full' : 'sm';




  const [i, setI] = useState()
  // let oneDef = data[i]
  // const [one, setOne] = useState(oneDef)


  // useEffect(() => {
  //   const max = Object.keys(data).length;
  //   // Set up an interval to cycle through data
  //   const interval = setInterval(() => {
  //     setI((currentI) => {
  //       // console.log('currentI = ', currentI);
  //       // If we reach the end of the data, reset i to 0, otherwise increment i
  //       return currentI < max - 1 ? currentI + 1 : 0;
  //     });
  //   }, 100); // Change 3000 to however many milliseconds you want each item to be displayed

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(interval);
  // }, [data.length])


  const location = useLocation();

  const removeLanguageParam = () => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    params.delete('ln');
    const newHash = params.toString();
    window.history.replaceState(null, '', `${location.pathname}${location.search}${newHash ? `#${newHash}` : ''}`);
  };

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove the leading '#'
    const languageParam = params.get('ln');
    if (languageParam !== context.language && context.languages.includes(languageParam)) {
      context.setLanguage(languageParam)
    }
    removeLanguageParam()
  }, [location]);


  return (
    <>


      <div
        className="under_construction under_construction--ad"
        style={{ padding: '10% 0 0 0' }}
      >
        <div className="x_ad_gate">
          <div className="x_logo">
            HD

            <div className="hd_logo_img w-embed"><svg className="hd_logo_img" width={144} height={144} viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_i_1937_131685)">
                <path fillRule="evenodd" clipRule="evenodd" d="M114.08 116.785C104.469 126.436 93.5503 132.418 77.415 133.744C57.1046 135.412 40.4598 128.818 28.6506 117.417C16.958 106.128 10.3408 90.4651 9.18382 74.3604C8.02513 58.2319 12.3158 41.3259 22.8697 27.491C34.5713 12.1515 49.7652 3.64379 72.4859 0.0995063C76.3685 -0.506146 80.3679 1.69035 80.973 5.57662C81.578 9.46289 78.9211 13.1043 75.0386 13.71C55.6176 16.7395 42.3792 25.3859 34.1793 36.135C25.9145 46.9692 22.4447 60.3644 23.3768 73.3388C24.3106 86.3371 29.6354 98.5786 38.5293 107.165C47.3066 115.64 59.8928 120.892 76.2508 119.548C89.1997 118.484 98.1022 113.648 103.938 107.328C109.847 100.928 113.033 92.5464 113.646 83.803C114.918 65.6558 105.355 49.228 91.1035 45.9336C77.7393 42.8444 67.738 45.3296 61.243 50.0882C54.7344 54.8568 50.9568 62.4612 50.9568 71.3159C50.9568 77.69 52.7721 82.8116 55.8891 86.2575C58.8987 89.5846 63.8241 92.1331 71.7545 92.1331C77.3031 92.1331 80.9073 90.59 83.0456 88.6441C85.1073 86.7679 86.5311 83.8673 86.5311 79.5332C86.5311 76.131 85.5309 74.2695 84.5976 73.2327C83.5976 72.1217 82.1752 71.409 80.5658 71.171C78.8786 70.9215 77.49 71.2669 76.7892 71.6575C76.7423 71.6837 76.7023 71.7071 76.6684 71.7278C76.4552 75.4693 73.3566 78.4376 69.5653 78.4376C65.6359 78.4376 62.4505 75.2491 62.4505 71.3159C62.4505 65.5316 65.9293 61.4078 69.8666 59.2134C73.6809 57.0874 78.3124 56.4399 82.6454 57.0806C87.0562 57.7329 91.9572 59.7415 95.4723 63.6465C99.0541 67.6257 100.945 73.6227 100.945 80.0811C100.945 87.7991 98.0809 94.2114 92.6179 99.1829C87.2316 104.085 79.89 106.376 71.7545 106.376C60.5296 106.376 51.4987 102.625 45.3408 95.8174C39.2904 89.1287 36.7271 80.2808 36.7271 71.3159C36.7271 58.2579 42.404 46.2397 52.8384 38.5948C63.2863 30.94 77.7162 28.2209 94.3053 32.0556C119.553 40.0903 129.404 62.4986 127.841 84.7997C127.036 96.2804 123.753 107.073 114.08 116.785Z" fill="#005FDD" />
                <path fillRule="evenodd" clipRule="evenodd" d="M114.08 116.785C104.469 126.436 93.5503 132.418 77.415 133.744C57.1046 135.412 40.4598 128.818 28.6506 117.417C16.958 106.128 10.3408 90.4651 9.18382 74.3604C8.02513 58.2319 12.3158 41.3259 22.8697 27.491C34.5713 12.1515 49.7652 3.64379 72.4859 0.0995063C76.3685 -0.506146 80.3679 1.69035 80.973 5.57662C81.578 9.46289 78.9211 13.1043 75.0386 13.71C55.6176 16.7395 42.3792 25.3859 34.1793 36.135C25.9145 46.9692 22.4447 60.3644 23.3768 73.3388C24.3106 86.3371 29.6354 98.5786 38.5293 107.165C47.3066 115.64 59.8928 120.892 76.2508 119.548C89.1997 118.484 98.1022 113.648 103.938 107.328C109.847 100.928 113.033 92.5464 113.646 83.803C114.918 65.6558 105.355 49.228 91.1035 45.9336C77.7393 42.8444 67.738 45.3296 61.243 50.0882C54.7344 54.8568 50.9568 62.4612 50.9568 71.3159C50.9568 77.69 52.7721 82.8116 55.8891 86.2575C58.8987 89.5846 63.8241 92.1331 71.7545 92.1331C77.3031 92.1331 80.9073 90.59 83.0456 88.6441C85.1073 86.7679 86.5311 83.8673 86.5311 79.5332C86.5311 76.131 85.5309 74.2695 84.5976 73.2327C83.5976 72.1217 82.1752 71.409 80.5658 71.171C78.8786 70.9215 77.49 71.2669 76.7892 71.6575C76.7423 71.6837 76.7023 71.7071 76.6684 71.7278C76.4552 75.4693 73.3566 78.4376 69.5653 78.4376C65.6359 78.4376 62.4505 75.2491 62.4505 71.3159C62.4505 65.5316 65.9293 61.4078 69.8666 59.2134C73.6809 57.0874 78.3124 56.4399 82.6454 57.0806C87.0562 57.7329 91.9572 59.7415 95.4723 63.6465C99.0541 67.6257 100.945 73.6227 100.945 80.0811C100.945 87.7991 98.0809 94.2114 92.6179 99.1829C87.2316 104.085 79.89 106.376 71.7545 106.376C60.5296 106.376 51.4987 102.625 45.3408 95.8174C39.2904 89.1287 36.7271 80.2808 36.7271 71.3159C36.7271 58.2579 42.404 46.2397 52.8384 38.5948C63.2863 30.94 77.7162 28.2209 94.3053 32.0556C119.553 40.0903 129.404 62.4986 127.841 84.7997C127.036 96.2804 123.753 107.073 114.08 116.785Z" fill="url(#paint0_linear_1937_131685)" />
              </g>
              <path d="M76.4075 71.3013L76.1841 71.4379L76.1692 71.6994C75.971 75.1782 73.0895 77.9376 69.5653 77.9376C65.9125 77.9376 62.9505 74.9734 62.9505 71.3159C62.9505 65.757 66.2885 61.7801 70.11 59.6501C73.8147 57.5853 78.3321 56.9483 82.5723 57.5753C86.899 58.2151 91.6821 60.1832 95.1007 63.981C98.5779 67.8439 100.445 73.7088 100.445 80.0811C100.445 87.6604 97.6388 93.9377 92.2814 98.8131C87.0094 103.611 79.7994 105.876 71.7545 105.876C60.6451 105.876 51.7587 102.167 45.7116 95.482C39.7644 88.9073 37.2271 80.1903 37.2271 71.3159C37.2271 58.4051 42.8376 46.5419 53.1339 38.9982C63.4385 31.4483 77.7054 28.7364 94.1729 32.5382C119.131 40.4903 128.893 62.6351 127.342 84.7647C126.543 96.1614 123.292 106.828 113.726 116.432C104.191 126.006 93.3809 131.93 77.3741 133.245C57.2026 134.903 40.7006 128.356 28.9979 117.057C17.4037 105.863 10.8317 90.3204 9.68254 74.3246C8.53179 58.3066 12.7937 41.5238 23.2672 27.7943C34.8776 12.5744 49.9468 4.12151 72.563 0.593532C76.2498 0.0184102 79.9274 2.11144 80.4789 5.65354C81.0416 9.26744 78.5708 12.6529 74.9615 13.2159C55.4243 16.2636 42.0666 24.9714 33.7818 35.8318C25.4367 46.7712 21.9381 60.2896 22.8781 73.3747C23.8198 86.4819 29.1899 98.8435 38.182 107.525C47.0658 116.102 59.7946 121.402 76.2918 120.046C89.3529 118.973 98.3763 114.088 104.306 107.667C110.303 101.172 113.525 92.6775 114.145 83.8379C115.427 65.5406 105.79 48.8153 91.2161 45.4465C77.738 42.3309 67.5811 44.8247 60.9475 49.6849C54.3012 54.5544 50.4568 62.3135 50.4568 71.3159C50.4568 77.7814 52.2986 83.0335 55.5183 86.5929C58.6381 90.0419 63.7075 92.6331 71.7545 92.6331C77.3948 92.6331 81.1301 91.0633 83.3821 89.0139C85.5627 87.0295 87.0311 83.9869 87.0311 79.5332C87.0311 76.0231 85.9951 74.0378 84.9692 72.8982C83.8813 71.6896 82.3477 70.9291 80.639 70.6764C78.8579 70.413 77.3554 70.7695 76.5458 71.2208C76.4934 71.25 76.4476 71.2768 76.4075 71.3013Z" stroke="#0017C0" strokeOpacity="0.56" strokeLinecap="round" />
              <defs>
                <filter id="filter0_i_1937_131685" x={9} y={0} width={119} height={134} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation={1} />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.152941 0 0 0 0 0.733333 0 0 0 0.8 0" />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1937_131685" />
                </filter>
                <linearGradient id="paint0_linear_1937_131685" x1="68.4906" y1="-0.449386" x2="68.4906" y2={134} gradientUnits="userSpaceOnUse">
                  <stop stopColor="#008FF4" />
                  <stop offset={1} stopColor="#0043E4" />
                </linearGradient>
              </defs>
            </svg></div>

            Cafe</div>
        </div>
        <div className="x_ad_gate2">
          {$ui('landing_slogan')}
        </div>

        <div className="x_add_calltoaction">

          <br />




          <a href='/auth0/login' className="x_ad_cta" style={{ position: 'relative' }}>
            {$ui('landing_enter')}
          </a>




        </div>


        <div className="x_ad_languages0">
          <a className={`x_ad_lang ${context.language === 'en' ? 'active' : ''}`}
            // href='#ln=en'
            onClick={() => context.setLanguage('en')}
          >
            <div>English</div>
          </a>
          <a className={`x_ad_lang ${context.language === 'ru' ? 'active' : ''}`}
            // href='#ln=ru'
            onClick={() => context.setLanguage('ru')}
          >
            <div>По-русски</div>
          </a>
        </div>




        {/* <Link to='/start' style={{ textDecoration: 'none', color: 'black' }} >Beta version is coming soon</Link> */}


        {/* <div className="x_ad_testimoniials">
          <div className="x_ad_test1">
            <div className="x_ad_stars">
              <div>★★★★★</div>
            </div>
            <div>Gate55! That's an interesting name for a Human Design learning app! <span className="x_ad_test1_span">Gate 55 is associated with the Gate of Spirit</span>, which represents the energy of faith, trust, and surrender. </div>
            <div>This gate is all about trusting in the process of life and having faith in oneself and the universe.</div>
            <div className="x_ad_test1_auth">
              <div>Chat GPT4</div>
            </div>
          </div>
          <div className="x_ad_test1">
            <div className="x_ad_stars">
              <div>★★★★★</div>
            </div>
            <div>Gate55 reflects <span className="x_ad_test1_span">the energy of deep spiritual transformation and awakening</span>, the energy of the divine, and suggests the idea of transcending the limitations of the ego and accessing a higher level of consciousness.</div>
            <div className="x_ad_test1_auth">
              <div>Chat GPT4</div>
            </div>
          </div>
          <div className="x_ad_test1">
            <div className="x_ad_stars">
              <div>★★★★★</div>
            </div>
            <div>Gate55 could also serve as a reminder of the importance of <span className="x_ad_test1_span">having faith in their own unique journey</span> and trusting in the unfolding of their Human Design chart.</div>
            <Link to='/1' className="x_ad_test1_auth">
              <div>Chat GPT4</div>
            </Link>
          </div>
        </div> */}


      </div>




      {/* <div className={`x_choose_one ${ind === parseInt(personId) ? 'active' : ''} ${size === 'full' ? 'x_choose_one--v' : ''} `} */}
      <div
        style={{
          flexDirection: 'column',
          opacity: 0.5,
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >

        <div
          className='_fv _fcc'
          style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
          <Preview
          // focus={'centers'}
          design={default_design}
        // {...narrative[activeLink]['body']}
        // blur={true}
        // opacity={0.6}

          />

          {/* <div className="x_choose_bodygraph"> */}
          {/* <Preview
            // width={600}
            // height={700}
            // design={one}
            design={data[Object.keys(data)[i]]}
            // lines={false}
            // body={false}
            aura={false}
            // size={true}
            bw={true} /> */}


          {/* <div className="x_choose_one_typep">
          <div className="x_choose_type">{drawType(one['type'], 'short')}</div>
          <div className="x_choose_profile">
            {drawProfile(one['profile'])}
          </div>
        </div> */}
        </div>

      </div>







    </>
  );
}
