import React, { useContext } from 'react';
import { MessageContext } from '../../contexts/messageContext';
import { SocialContext } from '../../contexts/socialContext';
import Chats from './Chats';

export function WithChats({ children }) {

  return (
    <>


        <WithChatInner />

        {children}

    </>
  )
}


const WithChatInner = ({ }) => {
  const context = { ...useContext(SocialContext) };
  const messageContext = { ...useContext(MessageContext) }
  return (
    <>
      {Boolean(context.me) && Boolean(messageContext.currentChat) && <Chats />}
    </>
  )
}