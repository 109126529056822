import React, { useContext, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { ListAvatars } from './ListAvatars';
import { ListAvatarsLayout } from './ListAvatarsLayout';
import celebs_categories from '../../static/celebs_categories.json';


export function Celebs({ customHandler, fullSrceen }) {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const $celebType = context.$celebType;


  const [category, setCategory] = useState('all');

  const expanded = fullSrceen || context.state.peoplePanel;

  return (
    <>



      {Boolean(context.celebsData.length) && <ListAvatarsLayout
        icon='star'
        title={$ui('people_celebs')}
        listId='celebs'
        quantity={context.celebsData.length}
        className={expanded ? 'v' : ''}
        celeb={true}
        fullSrceen={fullSrceen}
      >

        {expanded && <div className="x_panel_sub_sub_inner"
        >
          <div
            className={`x_panel_sub_sub1 ${category === 'all' ? 'active' : ''}`}
            key={'all'}
            onClick={() => setCategory('all')}
          >
            <div className="x_panel_sub_sub_icon">
              <div className="t_icon">star</div>
            </div>
            <div className="x_panel_sub_sub_text">{$ui('people_celebs_all')}</div>

            <div className="x_choose_menu_counter">
              {context.celebsData.length}
            </div>


          </div>
          {Object.keys(celebs_categories)
            // .filter(oneId => context.celebsData.some(one => one.type.includes(oneId)))
            .map(oneId => <div
              className={`x_panel_sub_sub1 ${category === oneId ? 'active' : ''}`}
              key={oneId}
              onClick={() => setCategory(oneId)}
            >
              <div className="x_panel_sub_sub_icon">
              <div className="t_icon">
                {celebs_categories[oneId].icon}
              </div>
            </div>
              <div className="x_panel_sub_sub_text">{$celebType(oneId)}</div>

              <div className="x_choose_menu_counter">
                {context.celebsData.filter(one => one.type.includes(oneId)).length}
              </div>


          </div>)}
        </div>}

        <ListAvatars
          customHandler={customHandler}
          listId='celebs'
          array={context.celebsData.filter(one => one.type.includes(category) || category === 'all')}
          actions={['share']}
          celeb={true}
          fullSrceen={fullSrceen}
        />
      </ListAvatarsLayout>}
    </>
  );
}
