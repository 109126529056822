import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { BodyArrow } from '../UiKit/BodyArrows';
import { Variants } from '../UiKit/Variants';
import {
  getDesignNodeBase,
  getDesignNodeColor,
  getDesignNodeTone,
  getDesignSunBase,
  getDesignSunColor,
  getDesignSunTone,
  getMars,
  getMoon,
  getPersNodeBase,
  getPersNodeColor,
  getPersNodeTone,
  getPersSunBase,
  getPersSunColor,
  getPersSunTone
} from '../utilsDesign';

export const VariablesAdv = React.memo(({ id, design }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $vars = context.$vars;



  const options = [
    {
      mode: 'phs',
      title: `${$vars('label_diet')}: ${$vars(`${'diet'}_${getDesignSunColor(design)}`)}`,
      varName: 'diet',
      getColor: getDesignSunColor,
      getTone: getDesignSunTone,
      getBase: getDesignSunBase,
      transference: true,
      label: 'des',
      children: <BodyArrow
        design={design}
        variables={design['variables']}
        arrowsB1={true}
        size='sm'
      />
    },
    {
      mode: 'phs',
      title: `${$vars('label_env')}: ${$vars(`${'env'}_${getDesignNodeColor(design)}`)}`,
      varName: 'env',
      getColor: getDesignNodeColor,
      getTone: getDesignNodeTone,
      getBase: getDesignNodeBase,
      transference: true,
      label: 'des',
      children: <BodyArrow
        design={design}
        variables={design['variables']}
        arrowsB2={true}
        size='sm'
      />
    },
    {
      mode: 'phs',
      title: `${$vars('label_motivation')}: ${$vars(`${'motivation'}_${getPersSunColor(design)}`)}`,
      varName: 'motivation',
      getColor: getPersSunColor,
      getTone: getPersSunTone,
      getBase: getPersSunBase,
      transference: true,
      label: 'prs',
      children: <BodyArrow
        design={design}
        variables={design['variables']}
        arrowsP1={true}
        size='sm'
      />
    },
    {
      mode: 'phs',
      title: `${$vars('label_perspective')}: ${$vars(`${'perspective'}_${getPersNodeColor(design)}`)}`,
      varName: 'perspective',
      getColor: getPersNodeColor,
      getTone: getPersNodeTone,
      getBase: getPersNodeBase,
      transference: true,
      label: 'prs',
      children: <BodyArrow
        design={design}
        variables={design['variables']}
        arrowsP2={true}
        size='sm'
      />
    },
    {
      mode: 'mars',
      title: `${$vars('label_trauma')}: ${$vars(`${'trauma'}_${getMars(design)}`)}`,
      varName: 'trauma',
      getLine: getMars,
      transference: false,
    },
    {
      mode: 'moon',
      title: `${$vars('label_love')}: ${$vars(`${'love'}_${getMoon(design)}`)}`,
      varName: 'love',
      getLine: getMoon,
      transference: false,
    },
  ]




  return (
    <>

      <Variants
        design={design}
        options={options}
        mode='phs'
      />


      <Variants
        mode='mars'
        design={design}
        options={[
          {
            title: 'label_trauma',
            varName: 'trauma',
            getLine: getMars,
            transference: false,
            label: 'des',
          },
        ]} />

    </>
  )
})



