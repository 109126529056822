import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { MessageContext } from '../../contexts/messageContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Avatar } from '../UiKit/Avatar';
import { AvatarName } from '../UiKit/AvatarName';
import { CHANNEL_TOKEN_LENGTH, USER_TOKEN_LENGTH } from '../utilsDesign';

import { PentaContainer } from '../Penta/PentaContainer';
import { Persona } from '../Persona';
import { AvatarCity } from '../UiKit/AvatarCity';

export const ChatInfo = React.memo(({
  showInfo,
  setShowInfo,
  handleState
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext), ...useContext(MessageContext) }
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;
  const myId = context.me.user.token;

  const chatId = messageContext.currentChat;
  // const { chatId } = useParams();

  const [chatData, setChatData] = useState()
  const [to, setTo] = useState()
  const getMembers = (chatData = {}, leaveMe) => {
    if (leaveMe) {
      return chatData.members ? chatData.members : []
    } else {
      return chatData.members ? chatData.members.filter(one => one !== myId) : []
    }
  }
  const [channelData, setChannelData] = useState()

  useEffect(() => {
    //GET TO
    if (chatId
      && chatId.length === CHANNEL_TOKEN_LENGTH
      && messageContext.chatsList) {
      messageContext.getChannel(chatId, (channelData) => {
        setTo(getMembers(channelData)[0])
        setChannelData(channelData)
        console.log('getMembers(channelData)[0] = ', getMembers(channelData)[0]);
      })
    }
    if (chatId && chatId.length === USER_TOKEN_LENGTH
    ) {
      setTo(chatId)
    }
  }, [chatId, messageContext.chatsList])

  const navigate = useNavigate();


  const getPentaUrl = () => {
    if (!channelData) return null;
    let pentas = ''
    getMembers(channelData, true).forEach((oneId, ind, arr) => ind === arr.length - 1 ? pentas += oneId : pentas += `${oneId}-`)
    return `/p/${pentas}`
  }

  return (<>


    <div className={`m_b_info_z ${showInfo ? 'shown' : 'inactive'}`}
      onClick={() => setShowInfo(false)}
    />




    <div className={`m_b m_b--chats m_b--chats--info ${showInfo ? 'expanded' : ''}`}>

      {!showInfo && <div className="m_b_h"
        style={{
          position: 'absolute',
          top: '1.4em',
          right: '-2em'
        }}
      >
        <div className="x_pop_right">
          <div className="x_action"
            onClick={() => {
              messageContext.setCurrentChat()
              handleState()
            }}
          >
            <div className="t_icon t_icon--20">
              <div>close</div>
            </div>
          </div>
        </div>
      </div>}

      <div className="m_b_info_scroll_grad" />

      <div className="m_b_info_scroll">




        {Boolean(to) && messageContext.getMemberData(to) && <>

          <div className="x_chats_info0">

            <div className="x_chat_info_section">
              <Avatar
                avatar={messageContext.getMemberData(to).avatar}
              />
              <div className="x_choose_user_nick">
                <AvatarName avatar={messageContext.getMemberData(to).avatar} />
              </div>
              <div className="x_choose_user_2nd">
                <AvatarCity avatar={messageContext.getMemberData(to).avatar} />
              </div>
              {/* <div className="x_choose_user_2nd">
            <div>24 friends</div>
            <div>Los Angeles</div>
          </div> */}
            </div>
            <div className="x_chat_info_section">

              <Persona
                noClick={true}
                avatar={messageContext.getMemberData(to).avatar}
                user={messageContext.getMemberData(to).user}
                noInfo={true}
                addClassName='chat'
              />

              <div className="x_button x_button--ghost"
                onClick={() => {
                  messageContext.setCurrentChat()
                  navigate(`/u/${messageContext.getMemberData(to).user.token}`)
                }}
              >
                {$ui('chat_goto_profile')}
              </div>
            </div>


            {messageContext.getMemberData(to).avatar.design.type && <div className="x_chat_info_section">

              <div className="x_penta0 x_penta0--v">


                <PentaContainer
                  handleClick={() => {
                    messageContext.setCurrentChat()
                    navigate(getPentaUrl())
                  }}
                  penta={{
                    members: [
                      context.me,
                      messageContext.getMemberData(to)
                    ]
                  }}
                />

                {/* <PentaStats
                  penta={{
                    members: [
                      context.me,
                      messageContext.getMemberData(to)
                    ]
                  }}
                /> */}

              </div>

              {channelData && <div className="x_button x_button--ghost"
                onClick={() => {
                  messageContext.setCurrentChat()
                  navigate(getPentaUrl())
                }}
              >
                {$ui('chat_goto_penta')}
              </div>}

            </div>}




          </div>

        </>}


      </div>

    </div>

  </>
  )
})

