import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Txt } from '../UiKit/Txt';
import { Videos } from '../UiKit/Videos';
import { getCrossType } from '../utilsDesign';
import { LeanMore } from '../UiKit/LeanMore';

export const Tags = React.memo(({ id, design, style = {} }) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $profile = context.$profile;
  const $line = context.$line;
  const $vars = context.$vars;
  const $envs = context.$envs;
  const $type = context.$type;
  const $auths = context.$auths;


  const processTag = (tagsAsText) => {
    if (!tagsAsText) return []
    let tags = tagsAsText.split(',');
    tags = tags.map(oneTag => ({
      label: oneTag.split('#')[0],
      weight: oneTag.split('#')[1]
    }))
    // console.log('tags = ', tags);
    return tags
  }

  const profileId = `${design['profile'][0]}${design['profile'][1]}`;

  return (
    <>
      <div className="x_edu_content_inner  _fv _f100 _fgap2" style={{ ...style }}>


        <div className="x_edu_content_title  _f100">


          <div className="_f">
            <div className="x_edu_content_label">
              <div>{$ui('basic_label_type')}</div>
            </div>
          </div>

          <div className="_fv _fgap2 _f100">
            <div className="_fv _fgap1 _f100">

              <div className="_fv _fgap1 _f100">

                <h1 className="x_edu_content_h x_edu_content_h--2 x_edu_content_h--3">
                  <Txt noP={true}>{$type(design['type'], 'title_full')}</Txt>
                </h1>

                {Boolean($type(design['type'], 'tags')) && <TagCloud tagline={$type(design['type'], 'tags')} />}

                <div className="x_edu_content_desc">
                  <div className="w-richtext">
                    <Txt>{$type(design['type'], 'description')}</Txt>
                  </div>
                </div>

            </div>

          {/* <div className="x_edu_content_keywords">
            <div>Defines your:</div>
            <div className="x_edu_content_key">
            <div>strategy</div>
            </div>
            </div> */}

            </div>

            <Videos
              title={`${$ui('basic_label_type')}: ${$type(design['type'], 'title')}`}
              relType='types'
              relValue={design['type']}
            />


          </div>
        </div>



        <div className="x_edu_content_title  _f100">

          <div className="_fv _fgap1 _f100">

            <div className="_f">
              <div className="x_edu_content_label">
                <div>{$ui('basic_label_profile')}</div>
              </div>
            </div>

            <div className="_fv _fgap2 _f100">

              <div className="_fv">
                <h1 className="x_edu_content_h x_edu_content_h--2">
                  <span className="x_edu_content_h_italic">{$profile(profileId, 'profile')}</span> "{$profile(profileId, 'title')}"
                </h1>

                {/* {$profile(`${design['profile'][0]}${design['profile'][1]}`, 'tags')} */}

                {Boolean($profile(profileId, 'tags')) && <TagCloud tagline={$profile(profileId, 'tags')} />}


                <div className="x_edu_content_desc">
                  <Txt>{$profile(profileId, 'description')}</Txt>
                </div>
              </div>


              <div className="_fv">
                <div className="x_edu_content_h x_edu_content_h--3">
                  {getCrossType(design.profile) === 'right' && <>{$profile(profileId, 'profile')} {$ui('profile_personal')}</>}
                  {getCrossType(design.profile) === 'left' && <>{$profile(profileId, 'profile')} {$ui('profile_transpersonal')}</>}
                  {getCrossType(design.profile) === 'juxtaposition' && <>{$profile(profileId, 'profile')} {$ui('profile_juxtaposition')}</>}
                </div>
                <div className="x_edu_content_desc">
                  {getCrossType(design.profile) === 'right' && <Txt>{$ui('profile_personal', 'rich')}</Txt>}
                  {getCrossType(design.profile) === 'left' && <Txt>{$ui('profile_transpersonal', 'rich')}</Txt>}
                  {getCrossType(design.profile) === 'juxtaposition' && <Txt>{$ui('profile_juxtaposition', 'rich')}</Txt>}
                </div>
              </div>

              {/* TODO: add lean more */}
              {/* <LeanMore /> */}


              <Videos
                title={`${$ui('basic_label_profile')}: ${$profile(profileId, 'profile')} "${$profile(profileId, 'title')}"`}
                relType='profiles'
                relValue={profileId}
              />



            </div>


          </div>
        </div>



        <div className="x_edu_content_title _f100">

            <div className="_f">
              <div className="x_edu_content_label">
                {$ui('basic_label_authority')}
              </div>
            </div>

          <div className="_fv _fgap1 _f100">

            <h1 className="x_edu_content_h x_edu_content_h--2">
              <Txt>
                {$auths(design['auth'], 'title')}
              </Txt>
            </h1>

            {Boolean($auths(design['auth'], 'tags')) && <TagCloud tagline={$auths(design['auth'], 'tags')} />}

            <div className="x_edu_content_desc x_edu_content_desc--v">
              <div className="w-richtext">
                <Txt>
                  {$auths(design['auth'], 'description')}
                </Txt>
              </div>
            </div>


            {/* <div className="x_edu_content_keywords">
            <div>Defines your:</div>
            <div className="x_edu_content_key">
              <div>Inner Authority</div>
            </div>
            <div className="x_edu_content_key">
              <div>strategy</div>
            </div>
            </div> */}


            <Videos
              title={`${$ui('basic_label_authority')}: ${$auths(design['auth'], 'title')}`}
              relType='auths'
              relValue={design['auth']}
            />

          </div>

        </div>

      </div>







    </>
  )
})


export const TagCloud = React.memo(({ tagline = '' }) => {
  // console.log('tagline = ', tagline);
  return (
    <>
      <div className="tag-cloud">
        {tagline.split(',').map(oneTag => {
          const tag = oneTag.split('#')[0]
          const tagWeight = oneTag.split('#')[1]
          return (<div className={`x_tag x_tag--${tagWeight}`} key={`${tag}_${tagWeight}`}>
            {tag}
          </div>)
        })}

      </div>
    </>
  )
})

export const TagList = React.memo(({ tagline = '', className = '' }) => {
  return (
    <>

      {tagline.split(',').map((oneTag, ind, arr) => {
        const tag = oneTag.split('#')[0]
        const tagWeight = oneTag.split('#')[1]
        return (<span className={`x_keyword ${className}`} key={`${tag}_${tagWeight}`}>
          {tag}{ind < arr.length - 1 && ', '}
        </span>)
      })}

    </>
  )
})