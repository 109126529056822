import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { AdminMenu } from './AdminMenu';

//TODO: youtube api key
// const YOUTUBE_APIKEY = process.env.REACT_APP_YOUTUBE_APIKEY;

export function AdminVideos(props) {

  const { active = false, size = 'md', style = {} } = props;


  const navigate = useNavigate();
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };



  const location = useLocation();
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const video = params.get('video') ? params.get('video') : '';
    setVideoId(video);
  }, [location]);

  useEffect(() => {
    if (!active) setVideoId(active)
  }, [active]);

  const YouTubeEmbed = ({ video, size = '', setUnavailable }) => {
    const [isAvailable, setIsAvailable] = useState(true);
    const thumbnailUrl = `https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`;

    // useEffect(() => {
    //   const checkVideoAvailability = async () => {
    //     try {
    //       const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${video.youtubeId}&key=${YOUTUBE_APIKEY}&part=status`);
    //       const data = await response.json();

    //       if (data.items.length === 0 || data.items[0].status.privacyStatus === 'private') {
    //         setIsAvailable(false);
    //         setUnavailable([...unavailable, video.youtubeId]);
    //       }
    //     } catch (error) {
    //       console.error('Error checking video availability:', error);
    //       setIsAvailable(false);
    //     }
    //   };

    //   checkVideoAvailability();
    // }, [video.youtubeId]);

    return (
      <div
        className={`x_videos_item0 ${size ? `x_videos_item0--${size}` : ''}`}
        style={{
          position: 'relative',
          height: '10em',
        }}
      >
        {isAvailable ? (
          <>
            <div
              className={`x_hover x_hover--sm ${active && videoId === video._id ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                const url = `https://www.youtube.com/watch?v=${video.youtubeId}`;
                window.open(url, '_blank');
              }}
            />
            <div className={`x_mobile_tap ${active && videoId === video._id ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                const url = `https://www.youtube.com/watch?v=${video.youtubeId}`;
                window.open(url, '_blank');
              }}
            />
            <div className={`x_videos_item ${size ? `x_videos_item--${size}` : ''}`}>
              <div className="x_video_play"
                style={{ backgroundImage: `url(${thumbnailUrl})` }}
              >
                <div className="x_video_play_icon">
                  <div className="t_icon">
                    <div>play_circle</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`x_videos_item_title ${size ? `x_videos_item_title--${size}` : ''}`}>{video['comment']}</div>
          </>
        ) : (
          <div className="x_video_unavailable _fv _fcc"
            style={{
              height: '100%',
              background: 'yellow',
            }}
            onClick={(e) => {
              e.stopPropagation();
              const url = `https://www.youtube.com/watch?v=${video.youtubeId}`;
              window.open(url, '_blank');
            }}
          >
            <b>Unavailable</b>
            <div>{video.youtubeId}</div>
          </div>
        )}
      </div>
    );
  };

  const videosLocale = (videos = [], language, field = '') => videos
    .filter(one => {
      return one.locale.includes(language)
    })

    .map(one => ({
      ...one,
      comment: one[`comment-${language}`]
    }))
  const videosByField = (videos = [], field = '') => videos
    .filter(one => {
      return field ? one[field].includes(field) : !one[field].length
    })

  const fields = [
    'types',
    'profiles',
    'lines',
    'channels',
    'gates',
    'auths',
    'planets',
    'variables',
    'centers',
    'diet',
    'env',
    'motivation',
    'perspective',
    'trauma',
    'love',
  ]

  const [byField, setByField] = useState(false);
  const [unavailable, setUnavailable] = useState([]);

  return (
    <>

      <AdminMenu active="videos" style={{ position: 'absolute', top: '0', width: '100%', zIndex: '1000' }} />




      <div
        className='_fv'
        style={{
          position: 'relative',
          // minHeight: '100vh',
          padding: '8em 2em 6em 2em',
          background: '#efefef',
          minHeight: '100vh',
        }}>

        <div className='_f _fgap2'>
          <h1>🎬 Videos</h1>

          <h2 className='_f'>
            <a
              className="x_button"
              href='https://airtable.com/appIJalAF64UStr7E/tblArZC58Mc61SB5I/viwsyCIOkirIqYCUU?blocks=hide'
              target='_blank'>
              Go to Airtable
            </a>
          </h2>

          {/* <h2 className='_f _fgap2'>
            Unavailable videos: {unavailable.length}
          </h2> */}

        </div>


        <div className="_f _fgap4">
          {['ru', 'en'].map(language => {
            return (
              <>
                <div className="_fv _fgrow _fgap2" style={{ width: '50%', padding: '3em 0' }}>
                  <h1 className='_f _fm'>{language}
                    <div className="x_choose_menu_counter">
                      {videosLocale(context.videos, language).length}
                    </div>

                  </h1>


                  {Boolean(videosLocale(context.videos, language).length) &&
                    <div
                      className={`_f _fgap2 _fwrap`}
                    >

                      {videosLocale(context.videos, language).map((video, index) => (
                        <YouTubeEmbed
                          key={index} video={video} size={size}
                          unavailable={unavailable}
                          setUnavailable={setUnavailable}
                        />
                      ))}


                    </div>

                  }

                </div>

              </>
            )
          })}
        </div>
      </div>


    </>
  )
}
