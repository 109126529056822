import React, { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../contexts/langContext';
import { MessageContext } from '../../contexts/messageContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Chat } from './Chat';
import { ChatInfo } from './ChatInfo';
import { ChatsList } from './ChatsList';

function Chats({
  handleStateAdd = () => void (0),
}) {

  useEffect(() => {
    document.body.classList.add('noscroll');
    return () => {
      document.body.classList.remove('noscroll');
    };
  }, []);


  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext), ...useContext(MessageContext) }
  const messageContext = { ...useContext(MessageContext) }
  const $ui = context.$ui;

  const handleState = (url) => {
    messageContext.setCurrentChat(url)
    handleStateAdd(url)
  }

  const [showChats, setShowChats] = useState(false)
  const [showInfo, setShowInfo] = useState(false)




  return (
    <div className="m0 m0--chats">
      <div className="m_z"
        onClick={() => handleState()}
      />
      <div className="m_b m_b--chats">

        <div className="x_mess0">


          <ChatsList
            showChats={showChats}
            setShowChats={setShowChats}
            handleState={handleState}
          />



          <Chat
            showChats={showChats}
            setShowChats={setShowChats}
            handleState={handleState}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
          />


          <ChatInfo
            handleState={handleState}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
          />



        </div>
      </div>


      {/* {messageContext.updating && (
        <Preloader0 style={{ position: 'fixed', flexDirection: 'row', bottom: 0, height: '3em', zIndex: 1000 }}>
          <Preloader />
        </Preloader0>
      )} */}

    </div>

  )
}

export default Chats
