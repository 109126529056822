
export const gates_types = {
  "1": ["projected"],
  "2": ["generated"],
  "3": ["generated"],
  "4": ["projected"],
  "5": ["generated"],
  "6": ["generated"],
  "7": ["projected"],
  "8": ["projected"],
  "9": ["generated"],
  "10": ["generated", "projected"],
  "11": ["generated"],
  "12": ["manifested"],
  "13": ["projected"],
  "14": ["generated"],
  "15": ["generated"],
  "16": ["manifested"],
  "17": ["generated"],
  "18": ["manifested"],
  "19": ["projected"],
  "20": ["projected", "manifested", "generated"],
  "21": ["manifested"],
  "22": ["manifested"],
  "23": ["projected"],
  "24": ["projected"],
  "25": ["projected"],
  "26": ["projected"],
  "27": ["generated"],
  "28": ["generated"],
  "29": ["generated"],
  "30": ["projected"],
  "31": ["projected"],
  "32": ["projected"],
  "33": ["generated"],
  "34": ["manifested", "generated"],
  "35": ["manifested"],
  "36": ["manifested"],
  "37": ["projected"],
  "38": ["projected"],
  "39": ["projected"],
  "40": ["projected"],
  "41": ["projected"],
  "42": ["generated"],
  "43": ["projected"],
  "44": ["projected"],
  "45": ["manifested"],
  "46": ["generated"],
  "47": ["projected"],
  "48": ["manifested"],
  "49": ["projected"],
  "50": ["manifested"],
  "51": ["projected"],
  "52": ["generated"],
  "53": ["generated"],
  "54": ["projected"],
  "55": ["projected"],
  "56": ["generated"],
  "57": ["manifested", "generated"],
  "58": ["projected"],
  "59": ["generated"],
  "60": ["projected"],
  "61": ["projected"],
  "62": ["projected"],
  "63": ["projected"],
  "64": ["projected"]
}


export const gates_dictionary = {
  "1": { "center": "GC", "gate": 1, "title": "The Creative", "description": "The Gate of Self-Expression", "corresponding_gate": 8, "hexagram": "䷀", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["1"], },
  "2": { "center": "GC", "gate": 2, "title": "The Receptive", "description": "The Gate of Higher Knowledge", "corresponding_gate": 1, "hexagram": "䷁", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["2"], },
  "3": { "center": "SL", "gate": 3, "title": "The Difficulty at the Beginning", "description": "The Gate of Ordering", "corresponding_gate": 60, "hexagram": "䷂", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["3"], },
  "4": { "center": "AA", "gate": 4, "title": "The Answer", "description": "The Gate of Formulization", "corresponding_gate": 63, "hexagram": "䷃", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["4"], },
  "5": { "center": "SL", "gate": 5, "title": "The Waiting", "description": "The Gate of Fixed Rhythms", "corresponding_gate": 15, "hexagram": "䷄", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["5"], },
  "6": { "center": "SP", "gate": 6, "title": "Conflict", "description": "The Gate of Friction", "corresponding_gate": 59, "hexagram": "䷅", "stream": ["protect"], "circuit": ["tribal"], "type": gates_types["6"], },
  "7": { "center": "GC", "gate": 7, "title": "The Role of the Self", "description": "The Gate of the Army", "corresponding_gate": 31, "hexagram": "䷆", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["7"], },
  "8": { "center": "TT", "gate": 8, "title": "Contribution", "description": "The Gate of Externalization", "corresponding_gate": 1, "hexagram": "䷇", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["8"], },
  "9": { "center": "SL", "gate": 9, "title": "The Focus", "description": "The Gate of Focus", "corresponding_gate": 52, "hexagram": "䷈", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["9"], },
  "10": { "center": "GC", "gate": 10, "title": "Behavioral Flexibility", "description": "The Gate of Self-Love", "corresponding_gate": 57, "hexagram": "䷉", "stream": ["integration"], "circuit": ["individual"], "type": gates_types["10"], },
  "11": { "center": "AA", "gate": 11, "title": "Ideas", "description": "The Gate of Ideas", "corresponding_gate": 12, "hexagram": "䷊", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["11"], },
  "12": { "center": "TT", "gate": 12, "title": "Standstill", "description": "The Gate of Caution", "corresponding_gate": 11, "hexagram": "䷋", "stream": ["protect"], "circuit": ["individual"], "type": gates_types["12"], },
  "13": { "center": "GC", "gate": 13, "title": "The Listener", "description": "The Gate of Narrative", "corresponding_gate": 7, "hexagram": "䷌", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["13"], },
  "14": { "center": "SL", "gate": 14, "title": "Power Skills", "description": "The Gate of Power Skills", "corresponding_gate": 2, "hexagram": "䷍", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["14"], },
  "15": { "center": "GC", "gate": 15, "title": "Extremes", "description": "The Gate of Extremes", "corresponding_gate": 5, "hexagram": "䷎", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["15"], },
  "16": { "center": "TT", "gate": 16, "title": "Skills", "description": "The Gate of Enthusiasm", "corresponding_gate": 48, "hexagram": "䷏", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["16"], },
  "17": { "center": "AA", "gate": 17, "title": "Opinions", "description": "The Gate of Opinion", "corresponding_gate": 18, "hexagram": "䷐", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["17"], },
  "18": { "center": "SN", "gate": 18, "title": "Correcting", "description": "The Gate of Correction", "corresponding_gate": 17, "hexagram": "䷑", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["18"], },
  "19": { "center": "RT", "gate": 19, "title": "Approach", "description": "The Gate of Wanting", "corresponding_gate": 33, "hexagram": "䷒", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["19"], },
  "20": { "center": "TT", "gate": 20, "title": "The Now", "description": "The Gate of the Now", "corresponding_gate": 34, "hexagram": "䷓", "stream": ["knowledge", "integration"], "circuit": ["individual"], "type": gates_types["20"], },
  "21": { "center": "HT", "gate": 21, "title": "Control", "description": "The Gate of Hunter", "corresponding_gate": 61, "hexagram": "䷔", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["21"], },
  "22": { "center": "SP", "gate": 22, "title": "Openness", "description": "The Gate of Grace", "corresponding_gate": 12, "hexagram": "䷕", "stream": ["protect"], "circuit": ["individual"], "type": gates_types["22"], },
  "23": { "center": "TT", "gate": 23, "title": "Assimilation", "description": "The Gate of Assimilation", "corresponding_gate": 43, "hexagram": "䷖", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["23"], },
  "24": { "center": "AA", "gate": 24, "title": "Rationalization", "description": "The Gate of Rationalization", "corresponding_gate": 2, "hexagram": "䷗", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["24"], },
  "25": { "center": "GC", "gate": 25, "title": "The Spirit of Self", "description": "The Gate of Innocence", "corresponding_gate": 46, "hexagram": "䷘", "stream": ["center"], "circuit": ["individual"], "type": gates_types["25"], },
  "26": { "center": "HT", "gate": 26, "title": "The Taming Power of the Great", "description": "The Gate of the Egoist", "corresponding_gate": 45, "hexagram": "䷙", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["26"], },
  "27": { "center": "SL", "gate": 27, "title": "The Nourisher", "description": "The Gate of Caring", "corresponding_gate": 28, "hexagram": "䷚", "stream": ["protect"], "circuit": ["tribal"], "type": gates_types["27"], },
  "28": { "center": "SN", "gate": 28, "title": "The Game Player", "description": "The Gate of the Game Player", "corresponding_gate": 27, "hexagram": "䷛", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["28"], },
  "29": { "center": "SL", "gate": 29, "title": "The Abysmal", "description": "The Gate of Commitment", "corresponding_gate": 46, "hexagram": "䷜", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["29"], },
  "30": { "center": "SP", "gate": 30, "title": "The Clinging Fire", "description": "The Gate of Feelings", "corresponding_gate": 29, "hexagram": "䷝", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["30"], },
  "31": { "center": "TT", "gate": 31, "title": "The Influence", "description": "The Gate of Influence", "corresponding_gate": 7, "hexagram": "䷞", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["31"], },
  "32": { "center": "SN", "gate": 32, "title": "Continuity", "description": "The Gate of Endurance", "corresponding_gate": 54, "hexagram": "䷟", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["32"], },
  "33": { "center": "TT", "gate": 33, "title": "Retreat", "description": "The Gate of Privacy", "corresponding_gate": 19, "hexagram": "䷠", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["33"], },
  "34": { "center": "SL", "gate": 34, "title": "The Power of the Great", "description": "The Gate of Power", "corresponding_gate": 20, "hexagram": "䷡", "stream": ["integration"], "circuit": ["individual"], "type": gates_types["34"], },
  "35": { "center": "TT", "gate": 35, "title": "The Progress", "description": "The Gate of Change", "corresponding_gate": 36, "hexagram": "䷢", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["35"], },
  "36": { "center": "SP", "gate": 36, "title": "Crisis", "description": "The Gate of Crisis", "corresponding_gate": 35, "hexagram": "䷣", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["36"], },
  "37": { "center": "SP", "gate": 37, "title": "The Family", "description": "The Gate of Family", "corresponding_gate": 40, "hexagram": "䷤", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["37"], },
  "38": { "center": "RT", "gate": 38, "title": "The Fighter", "description": "The Gate of the Fighter", "corresponding_gate": 39, "hexagram": "䷥", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["38"], },
  "39": { "center": "RT", "gate": 39, "title": "Providence", "description": "The Gate of Provocation", "corresponding_gate": 38, "hexagram": "䷦", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["39"], },
  "40": { "center": "HT", "gate": 40, "title": "Aloneness", "description": "The Gate of Solitude", "corresponding_gate": 37, "hexagram": "䷧", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["40"], },
  "41": { "center": "RT", "gate": 41, "title": "Contraction", "description": "The Gate of Contraction", "corresponding_gate": 30, "hexagram": "䷨", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["41"], },
  "42": { "center": "SL", "gate": 42, "title": "Increase", "description": "The Gate of Growth", "corresponding_gate": 31, "hexagram": "䷩", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["42"], },
  "43": { "center": "AA", "gate": 43, "title": "Breakthrough", "description": "The Gate of Insight", "corresponding_gate": 23, "hexagram": "䷪", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["43"], },
  "44": { "center": "SN", "gate": 44, "title": "The Coming to Meet", "description": "The Gate of the Lover", "corresponding_gate": 26, "hexagram": "䷫", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["44"], },
  "45": { "center": "TT", "gate": 45, "title": "The Gatherer", "description": "The Gate of the Gatherer", "corresponding_gate": 26, "hexagram": "䷬", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["45"], },
  "46": { "center": "GC", "gate": 46, "title": "The Jars", "description": "The Gate of Determination", "corresponding_gate": 25, "hexagram": "䷭", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["46"], },
  "47": { "center": "AA", "gate": 47, "title": "The Oppression", "description": "The Gate of Realization", "corresponding_gate": 22, "hexagram": "䷮", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["47"], },
  "48": { "center": "TT", "gate": 48, "title": "The Well", "description": "The Gate of Depth", "corresponding_gate": 16, "hexagram": "䷯", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["48"], },
  "49": { "center": "SP", "gate": 49, "title": "The Revolution", "description": "The Gate of Principles", "corresponding_gate": 30, "hexagram": "䷰", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["49"], },
  "50": { "center": "SN", "gate": 50, "title": "The Cauldron", "description": "The Gate of Values", "corresponding_gate": 27, "hexagram": "䷱", "stream": ["protect"], "circuit": ["tribal"], "type": gates_types["50"], },
  "51": { "center": "HT", "gate": 51, "title": "The Arousing", "description": "The Gate of Initiation", "corresponding_gate": 57, "hexagram": "䷲", "stream": ["center"], "circuit": ["individual"], "type": gates_types["51"], },
  "52": { "center": "RT", "gate": 52, "title": "The Keeping Still", "description": "The Gate of Silence", "corresponding_gate": 9, "hexagram": "䷳", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["52"], },
  "53": { "center": "RT", "gate": 53, "title": "Development", "description": "The Gate of Beginnings", "corresponding_gate": 13, "hexagram": "䷴", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["53"], },
  "54": { "center": "RT", "gate": 54, "title": "The Marrying Maiden", "description": "The Gate of Ambition", "corresponding_gate": 32, "hexagram": "䷵", "stream": ["ego"], "circuit": ["tribal"], "type": gates_types["54"], },
  "55": { "center": "SP", "gate": 55, "title": "The Abundance", "description": "The Gate of Spirit", "corresponding_gate": 22, "hexagram": "䷶", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["55"], },
  "56": { "center": "TT", "gate": 56, "title": "The Wanderer", "description": "The Gate of Stimulation", "corresponding_gate": 50, "hexagram": "䷷", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["56"], },
  "57": { "center": "SN", "gate": 57, "title": "The Gentle", "description": "The Gate of Intuition", "corresponding_gate": 20, "hexagram": "䷸", "stream": ["integration"], "circuit": ["individual"], "type": gates_types["57"], },
  "58": { "center": "RT", "gate": 58, "title": "The Joyous", "description": "The Gate of the Joyous Lake", "corresponding_gate": 18, "hexagram": "䷹", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["58"], },
  "59": { "center": "SL", "gate": 59, "title": "The Dispersion", "description": "The Gate of Emoting", "corresponding_gate": 6, "hexagram": "䷺", "stream": ["protect"], "circuit": ["tribal"], "type": gates_types["59"], },
  "60": { "center": "RT", "gate": 60, "title": "The Limitation", "description": "The Gate of Acceptance", "corresponding_gate": 3, "hexagram": "䷻", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["60"], },
  "61": { "center": "HD", "gate": 61, "title": "Inner Truth", "description": "The Gate of Mystery", "corresponding_gate": 21, "hexagram": "䷼", "stream": ["knowledge"], "circuit": ["individual"], "type": gates_types["61"], },
  "62": { "center": "TT", "gate": 62, "title": "The Preponderance of the Small", "description": "The Gate of Detail", "corresponding_gate": 63, "hexagram": "䷽", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["62"], },
  "63": { "center": "HD", "gate": 63, "title": "Doubt", "description": "The Gate of Doubt", "corresponding_gate": 4, "hexagram": "䷾", "stream": ["realize"], "circuit": ["collective"], "type": gates_types["63"], },
  "64": { "center": "HD", "gate": 64, "title": "Before Completion", "description": "The Gate of Confusion", "corresponding_gate": 14, "hexagram": "䷿", "stream": ["sense"], "circuit": ["collective"], "type": gates_types["64"], }
}






export const gates_circuits = {
  "1": { "circuit": ["individual"] },
  "2": { "circuit": ["individual"] },
  "3": { "circuit": ["individual"] },
  "4": { "circuit": ["collective"] },
  "5": { "circuit": ["collective"] },
  "6": { "circuit": ["tribal"] },
  "7": { "circuit": ["collective"] },
  "8": { "circuit": ["individual"] },
  "9": { "circuit": ["collective"] },
  "10": { "circuit": ["individual"] },
  "11": { "circuit": ["collective"] },
  "12": { "circuit": ["individual"] },
  "13": { "circuit": ["collective"] },
  "14": { "circuit": ["individual"] },
  "15": { "circuit": ["collective"] },
  "16": { "circuit": ["collective"] },
  "17": { "circuit": ["collective"] },
  "18": { "circuit": ["collective"] },
  "19": { "circuit": ["tribal"] },
  "20": { "circuit": ["individual"] },
  "21": { "circuit": ["tribal"] },
  "22": { "circuit": ["individual"] },
  "23": { "circuit": ["individual"] },
  "24": { "circuit": ["individual"] },
  "25": { "circuit": ["individual"] },
  "26": { "circuit": ["tribal"] },
  "27": { "circuit": ["tribal"] },
  "28": { "circuit": ["individual"] },
  "29": { "circuit": ["collective"] },
  "30": { "circuit": ["collective"] },
  "31": { "circuit": ["collective"] },
  "32": { "circuit": ["tribal"] },
  "33": { "circuit": ["collective"] },
  "34": { "circuit": ["individual"] },
  "35": { "circuit": ["collective"] },
  "36": { "circuit": ["collective"] },
  "37": { "circuit": ["tribal"] },
  "38": { "circuit": ["individual"] },
  "39": { "circuit": ["individual"] },
  "40": { "circuit": ["tribal"] },
  "41": { "circuit": ["collective"] },
  "42": { "circuit": ["collective"] },
  "43": { "circuit": ["individual"] },
  "44": { "circuit": ["tribal"] },
  "45": { "circuit": ["tribal"] },
  "46": { "circuit": ["collective"] },
  "47": { "circuit": ["collective"] },
  "48": { "circuit": ["collective"] },
  "49": { "circuit": ["tribal"] },
  "50": { "circuit": ["tribal"] },
  "51": { "circuit": ["individual"] },
  "52": { "circuit": ["collective"] },
  "53": { "circuit": ["collective"] },
  "54": { "circuit": ["tribal"] },
  "55": { "circuit": ["individual"] },
  "56": { "circuit": ["collective"] },
  "57": { "circuit": ["individual"] },
  "58": { "circuit": ["collective"] },
  "59": { "circuit": ["tribal"] },
  "60": { "circuit": ["individual"] },
  "61": { "circuit": ["individual"] },
  "62": { "circuit": ["collective"] },
  "63": { "circuit": ["collective"] },
  "64": { "circuit": ["collective"] }
}



