export const numbers_selects = `<svg width="600" height="800" viewBox="0 0 600 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  fill="gateSelect64"  fill-rule="evenodd" clip-rule="evenodd" d="M283 126H275V115.064C272.037 113.592 270 110.534 270 107C270 102.029 274.029 98 279 98C283.971 98 288 102.029 288 107C288 110.534 285.963 113.592 283 115.064V126Z"/>
<path  fill="gateSelect63"  fill-rule="evenodd" clip-rule="evenodd" d="M324 126H316V115.064C313.037 113.592 311 110.534 311 107C311 102.029 315.029 98 320 98C324.971 98 329 102.029 329 107C329 110.534 326.963 113.592 324 115.064V126Z"/>
<path  fill="gateSelect62"  fill-rule="evenodd" clip-rule="evenodd" d="M283 247H275V257.936C272.037 259.408 270 262.466 270 266C270 270.971 274.03 275 279 275C283.971 275 288 270.971 288 266C288 262.466 285.964 259.408 283 257.936V247Z"/>
<path  fill="gateSelect61"  fill-rule="evenodd" clip-rule="evenodd" d="M303 126H295V115.064C292.037 113.592 290 110.534 290 107C290 102.029 294.029 98 299 98C303.971 98 308 102.029 308 107C308 110.534 305.963 113.592 303 115.064V126Z"/>
<path  fill="gateSelect60"  fill-rule="evenodd" clip-rule="evenodd" d="M303 665H295V675.936C292.036 677.408 290 680.466 290 684C290 688.971 294.029 693 299 693C303.97 693 308 688.971 308 684C308 680.466 305.963 677.408 303 675.936V665Z"/>
<path  fill="gateSelect59"  fill-rule="evenodd" clip-rule="evenodd" d="M340.322 621.843C339.273 624.292 337.15 626.253 334.377 626.996C329.576 628.283 324.641 625.433 323.354 620.632C322.068 615.831 324.917 610.896 329.718 609.61C333.757 608.527 337.891 610.372 339.868 613.844L353.376 613.826L353.386 621.826L340.322 621.843Z"/>
<path  fill="gateSelect58"  fill-rule="evenodd" clip-rule="evenodd" d="M254.194 743.258C255.25 741.095 257.165 739.357 259.648 738.597C264.401 737.141 269.434 739.814 270.89 744.566C272.345 749.319 269.673 754.352 264.92 755.808C260.66 757.112 256.175 755.1 254.242 751.246C248.205 751.567 245.68 750.977 240.072 749.521L241.852 741.956C247.594 743.27 249.265 743.345 254.194 743.258Z"/>
<path  fill="gateSelect57"  fill-rule="evenodd" clip-rule="evenodd" d="M116.899 551.075C119.033 552.188 120.718 554.149 121.411 556.652C122.739 561.442 119.931 566.401 115.141 567.728C110.351 569.056 105.392 566.248 104.065 561.458C102.875 557.164 105.008 552.735 108.912 550.907C108.755 544.863 109.412 542.355 111.018 536.788L118.533 538.771C117.065 544.476 116.945 546.144 116.899 551.075Z"/>
<path  fill="gateSelect56"  fill-rule="evenodd" clip-rule="evenodd" d="M324 247H316V257.936C313.037 259.408 311 262.466 311 266C311 270.971 315.03 275 320 275C324.971 275 329 270.971 329 266C329 262.466 326.964 259.408 324 257.936V247Z"/>
<path  fill="gateSelect55"  fill-rule="evenodd" clip-rule="evenodd" d="M477.487 629.061C475.785 627.359 474.766 624.982 474.855 622.386C475.026 617.419 479.192 613.53 484.16 613.701C489.127 613.872 493.016 618.038 492.845 623.005C492.691 627.458 489.329 631.044 485.055 631.616C483.393 637.429 482.013 639.624 478.811 644.453L472.238 640.307C475.349 635.306 475.964 633.75 477.487 629.061Z"/>
<path  fill="gateSelect54"  fill-rule="evenodd" clip-rule="evenodd" d="M257.525 698.962C258.58 696.799 260.495 695.062 262.979 694.301C267.731 692.845 272.764 695.518 274.22 700.271C275.675 705.023 273.003 710.056 268.25 711.512C263.99 712.817 259.505 710.804 257.572 706.95C251.535 707.271 249.01 706.681 243.402 705.225L245.182 697.66C250.924 698.974 252.595 699.049 257.525 698.962Z"/>
<path  fill="gateSelect53"  fill-rule="evenodd" clip-rule="evenodd" d="M283 665H275V675.936C272.036 677.408 270 680.466 270 684C270 688.971 274.029 693 279 693C283.97 693 288 688.971 288 684C288 680.466 285.963 677.408 283 675.936V665Z"/>
<path  fill="gateSelect52"  fill-rule="evenodd" clip-rule="evenodd" d="M324 665H316V675.936C313.036 677.408 311 680.466 311 684C311 688.971 315.029 693 320 693C324.97 693 329 688.971 329 684C329 680.466 326.963 677.408 324 675.936V665Z"/>
<path  fill="gateSelect51"  fill-rule="evenodd" clip-rule="evenodd" d="M388.681 475.95C388.547 476.142 388.42 476.34 388.299 476.545C385.782 480.831 387.215 486.346 391.501 488.864C395.786 491.382 401.302 489.949 403.819 485.663C406.337 481.377 404.904 475.862 400.618 473.344C398.986 472.385 397.176 472 395.421 472.126L395.468 472.099L387.573 458.183L380.614 462.131L388.509 476.047L388.681 475.95Z"/>
<path  fill="gateSelect50"  fill-rule="evenodd" clip-rule="evenodd" d="M171.412 604.715L174.781 597.459L164.862 592.854C164.774 589.546 162.858 586.411 159.653 584.923C155.145 582.83 149.793 584.788 147.7 589.297C145.607 593.805 147.565 599.157 152.073 601.25C155.278 602.738 158.91 602.178 161.493 600.111L171.412 604.715Z"/>
<path  fill="gateSelect49"  fill-rule="evenodd" clip-rule="evenodd" d="M456.871 615.31C455.169 613.608 454.15 611.231 454.239 608.635C454.41 603.668 458.576 599.779 463.543 599.95C468.511 600.121 472.399 604.287 472.229 609.254C472.075 613.707 468.712 617.293 464.439 617.865C462.777 623.678 461.397 625.873 458.195 630.702L451.621 626.556C454.733 621.555 455.348 619.999 456.871 615.31Z"/>
<path  fill="gateSelect48"  fill-rule="evenodd" clip-rule="evenodd" d="M97.8992 540.075C100.034 541.188 101.718 543.149 102.412 545.652C103.739 550.442 100.932 555.401 96.1415 556.728C91.3514 558.056 86.3924 555.248 85.0653 550.458C83.8757 546.164 86.0081 541.735 89.9129 539.907C89.7551 533.863 90.4128 531.355 92.019 525.788L99.5332 527.771C98.0652 533.476 97.9451 535.144 97.8992 540.075Z"/>
<path  fill="gateSelect47"  fill-rule="evenodd" clip-rule="evenodd" d="M275 128H283V138.936C285.963 140.408 288 143.466 288 147C288 151.971 283.971 156 279 156C274.029 156 270 151.971 270 147C270 143.466 272.037 140.408 275 138.936V128Z"/>
<path  fill="gateSelect46"  fill-rule="evenodd" clip-rule="evenodd" d="M324 493H316V468.064C313.037 466.592 311 463.534 311 460C311 455.029 315.029 451 320 451C324.971 451 329 455.029 329 460C329 463.534 326.963 466.592 324 468.064V493Z"/>
<path  fill="gateSelect45"  fill-rule="evenodd" clip-rule="evenodd" d="M340.658 335.73C339.072 338.293 336.235 340 333 340C328.029 340 324 335.971 324 331C324 326.029 328.029 322 333 322C336.553 322 339.625 324.059 341.089 327.049C345.431 328.41 348.139 331.283 351.001 334.322C351.303 334.642 351.607 334.965 351.915 335.288L345.919 340.706C344.615 339.547 343.601 338.545 342.737 337.69C341.957 336.919 341.299 336.268 340.658 335.73Z"/>
<path  fill="gateSelect44"  fill-rule="evenodd" clip-rule="evenodd" d="M140.936 570.695C141.753 572.959 141.665 575.544 140.481 577.855C138.215 582.279 132.791 584.028 128.368 581.762C123.944 579.496 122.195 574.072 124.461 569.649C126.492 565.683 131.06 563.867 135.172 565.165C139.147 560.61 141.329 559.21 146.28 556.2L150.468 562.747C145.525 565.951 144.308 567.098 140.936 570.695Z"/>
<path  fill="gateSelect43"  fill-rule="evenodd" clip-rule="evenodd" d="M303 249H295V235V224.064C292.037 222.592 290 219.534 290 216C290 211.029 294.029 207 299 207C303.971 207 308 211.029 308 216C308 219.534 305.963 222.592 303 224.064V235V249Z"/>
<path  fill="gateSelect42"  fill-rule="evenodd" clip-rule="evenodd" d="M283 654H275V643.064C272.037 641.592 270 638.534 270 635C270 630.029 274.029 626 279 626C283.971 626 288 630.029 288 635C288 638.534 285.963 641.592 283 643.064V654Z"/>
<path  fill="gateSelect41"  fill-rule="evenodd" clip-rule="evenodd" d="M344.3 743.258C343.245 741.095 341.329 739.357 338.846 738.597C334.094 737.141 329.061 739.814 327.605 744.566C326.149 749.319 328.822 754.352 333.575 755.808C337.835 757.112 342.32 755.1 344.252 751.246C350.29 751.567 352.814 750.977 358.423 749.521L356.643 741.956C350.901 743.27 349.23 743.345 344.3 743.258Z"/>
<path  fill="gateSelect40"  fill-rule="evenodd" clip-rule="evenodd" d="M438.657 524.521C438.441 524.612 438.22 524.695 437.994 524.77C433.277 526.336 428.183 523.78 426.618 519.063C425.052 514.345 427.607 509.252 432.325 507.686C437.042 506.121 442.136 508.676 443.701 513.393C444.297 515.19 444.296 517.04 443.805 518.73L443.841 518.69L455.798 529.321L450.483 535.3L438.526 524.669L438.657 524.521Z"/>
<path  fill="gateSelect39"  fill-rule="evenodd" clip-rule="evenodd" d="M342.636 721.11C341.58 718.947 339.665 717.209 337.182 716.449C332.429 714.993 327.396 717.666 325.94 722.419C324.485 727.171 327.157 732.204 331.91 733.66C336.17 734.965 340.656 732.952 342.588 729.098C348.625 729.419 351.15 728.829 356.758 727.373L354.978 719.808C349.236 721.122 347.565 721.197 342.636 721.11Z"/>
<path  fill="gateSelect38"  fill-rule="evenodd" clip-rule="evenodd" d="M255.859 721.11C256.915 718.947 258.83 717.209 261.314 716.449C266.066 714.993 271.099 717.666 272.555 722.419C274.01 727.171 271.338 732.204 266.585 733.66C262.325 734.965 257.84 732.952 255.907 729.098C249.87 729.419 247.345 728.829 241.737 727.373L243.517 719.808C249.259 721.122 250.93 721.197 255.859 721.11Z"/>
<path  fill="gateSelect37"  fill-rule="evenodd" clip-rule="evenodd" d="M460.59 562.969C458.719 564.483 457.456 566.74 457.272 569.33C456.921 574.289 460.656 578.593 465.614 578.944C470.572 579.295 474.876 575.561 475.227 570.603C475.542 566.158 472.574 562.239 468.385 561.222C467.341 555.267 466.2 552.939 463.522 547.801L456.55 551.234C459.119 556.534 459.568 558.146 460.59 562.969Z"/>
<path  fill="gateSelect36"  fill-rule="evenodd" clip-rule="evenodd" d="M502.122 537.858C499.894 538.771 498.036 540.569 497.116 542.998C495.354 547.645 497.694 552.841 502.342 554.603C506.989 556.365 512.185 554.025 513.947 549.377C515.526 545.211 513.81 540.604 510.09 538.425C510.802 532.422 510.378 529.864 509.29 524.173L501.625 525.457C502.563 531.272 502.529 532.945 502.122 537.858Z"/>
<path  fill="gateSelect35"  fill-rule="evenodd" clip-rule="evenodd" d="M340.787 291.516C339.229 294.197 336.325 296 333 296C328.029 296 324 291.971 324 287C324 282.029 328.029 278 333 278C336.724 278 339.92 280.262 341.289 283.487L352.553 285.384L351.224 293.273L340.787 291.516Z"/>
<path  fill="gateSelect34"  fill-rule="evenodd" clip-rule="evenodd" d="M254.612 587.649C255.423 590.643 257.761 593.136 260.973 593.996C265.774 595.283 270.709 592.433 271.995 587.632C273.282 582.831 270.433 577.896 265.631 576.61C262.034 575.646 258.361 577.004 256.205 579.765L244.834 578.682L244.075 586.646L254.612 587.649Z"/>
<path  fill="gateSelect33"  fill-rule="evenodd" clip-rule="evenodd" d="M324 360H316V349.064C313.037 347.592 311 344.534 311 341C311 336.029 315.029 332 320 332C324.971 332 329 336.029 329 341C329 344.534 326.963 347.592 324 349.064V360Z"/>
<path  fill="gateSelect32"  fill-rule="evenodd" clip-rule="evenodd" d="M141.739 616.057C143.441 614.355 144.46 611.978 144.371 609.382C144.2 604.415 140.034 600.526 135.066 600.697C130.099 600.868 126.21 605.034 126.381 610.002C126.535 614.454 129.898 618.04 134.171 618.612C135.833 624.425 137.213 626.62 140.415 631.449L146.989 627.303C143.877 622.302 143.262 620.746 141.739 616.057Z"/>
<path  fill="gateSelect31"  fill-rule="evenodd" clip-rule="evenodd" d="M283 360H275V349.064C272.037 347.592 270 344.534 270 341C270 336.029 274.029 332 279 332C283.971 332 288 336.029 288 341C288 344.534 285.963 347.592 283 349.064V360Z"/>
<path  fill="gateSelect30"  fill-rule="evenodd" clip-rule="evenodd" d="M497.103 638.812C495.401 637.109 494.382 634.733 494.472 632.137C494.643 627.17 498.808 623.281 503.776 623.452C508.743 623.623 512.632 627.789 512.461 632.756C512.308 637.209 508.945 640.795 504.672 641.367C503.009 647.18 501.629 649.375 498.427 654.204L491.854 650.058C494.965 645.057 495.581 643.501 497.103 638.812Z"/>
<path  fill="gateSelect29"  fill-rule="evenodd" clip-rule="evenodd" d="M324 543H316V553.936C313.037 555.408 311 558.466 311 562C311 566.971 315.029 571 320 571C324.971 571 329 566.971 329 562C329 558.466 326.963 555.408 324 553.936V543Z"/>
<path  fill="gateSelect28"  fill-rule="evenodd" clip-rule="evenodd" d="M119.806 628.808C121.508 627.106 122.527 624.729 122.438 622.133C122.267 617.166 118.101 613.277 113.134 613.448C108.166 613.619 104.278 617.785 104.449 622.752C104.602 627.205 107.965 630.791 112.238 631.363C113.901 637.176 115.28 639.371 118.483 644.2L125.056 640.054C121.944 635.053 121.329 633.497 119.806 628.808Z"/>
<path  fill="gateSelect27"  fill-rule="evenodd" clip-rule="evenodd" d="M243 622L243 614L253.935 614C255.408 611.037 258.466 609 262 609C266.97 609 271 613.029 271 618C271 622.971 266.97 627 262 627C258.466 627 255.408 624.963 253.935 622L243 622Z"/>
<path  fill="gateSelect26"  fill-rule="evenodd" clip-rule="evenodd" d="M366.588 510.476C366.695 510.685 366.811 510.89 366.936 511.093C369.544 515.324 375.088 516.64 379.32 514.032C383.551 511.424 384.867 505.88 382.259 501.648C379.651 497.417 374.107 496.101 369.875 498.709C368.264 499.702 367.076 501.121 366.366 502.731L366.364 502.677L350.371 503.137L350.601 511.134L366.594 510.674L366.588 510.476Z"/>
<path  fill="gateSelect25"  fill-rule="evenodd" clip-rule="evenodd" d="M352.973 432.707C352.991 432.474 353 432.238 353 432C353 427.029 348.971 423 344 423C339.03 423 335 427.029 335 432C335 436.971 339.03 441 344 441C345.893 441 347.649 440.416 349.099 439.418L349.072 439.464L362.928 447.464L366.928 440.536L353.072 432.536L352.973 432.707Z"/>
<path  fill="gateSelect24"  fill-rule="evenodd" clip-rule="evenodd" d="M295 128H303V138.936C305.963 140.408 308 143.466 308 147C308 151.971 303.971 156 299 156C294.029 156 290 151.971 290 147C290 143.466 292.037 140.408 295 138.936V128Z"/>
<path  fill="gateSelect23"  fill-rule="evenodd" clip-rule="evenodd" d="M303 247H295V257.936C292.037 259.408 290 262.466 290 266C290 270.971 294.03 275 299 275C303.971 275 308 270.971 308 266C308 262.466 305.964 259.408 303 257.936V247Z"/>
<path  fill="gateSelect22"  fill-rule="evenodd" clip-rule="evenodd" d="M479.588 549.859C477.516 551.083 475.937 553.131 475.377 555.667C474.306 560.521 477.372 565.324 482.225 566.396C487.079 567.468 491.882 564.402 492.954 559.548C493.915 555.197 491.551 550.887 487.555 549.269C487.392 543.225 486.603 540.755 484.704 535.281L477.305 537.659C479.073 543.278 479.282 544.938 479.588 549.859Z"/>
<path  fill="gateSelect21"  fill-rule="evenodd" clip-rule="evenodd" d="M405.467 457.626C405.272 457.754 405.079 457.891 404.891 458.037C400.958 461.076 400.234 466.728 403.274 470.661C406.314 474.594 411.966 475.318 415.899 472.278C419.832 469.239 420.556 463.586 417.516 459.654C416.358 458.156 414.822 457.124 413.146 456.587L413.2 456.58L411.056 440.725L403.128 441.796L405.272 457.652L405.467 457.626Z"/>
<path  fill="gateSelect20"  fill-rule="evenodd" clip-rule="evenodd" d="M255.831 314.442C257.475 316.604 260.074 318 263 318C267.971 318 272 313.971 272 309C272 304.029 267.971 300 263 300C258.714 300 255.127 302.996 254.221 307.009L253.959 306.559C247.78 309.014 245.77 310.6 241.126 314.473L245.965 320.554C250.333 317.122 251.897 316.232 255.831 314.442Z"/>
<path  fill="gateSelect19"  fill-rule="evenodd" clip-rule="evenodd" d="M340.971 698.962C339.915 696.799 338 695.062 335.517 694.301C330.764 692.845 325.731 695.518 324.275 700.271C322.82 705.023 325.492 710.056 330.245 711.512C334.505 712.817 338.991 710.804 340.923 706.95C346.96 707.271 349.485 706.681 355.093 705.225L353.313 697.66C347.571 698.974 345.9 699.049 340.971 698.962Z"/>
<path  fill="gateSelect18"  fill-rule="evenodd" clip-rule="evenodd" d="M99.8733 639.559C101.575 637.857 102.594 635.48 102.505 632.884C102.334 627.917 98.1684 624.028 93.2007 624.199C88.2331 624.37 84.3446 628.536 84.5156 633.503C84.6689 637.956 88.0318 641.542 92.305 642.114C93.9676 647.927 95.3473 650.122 98.5496 654.951L105.123 650.805C102.011 645.804 101.396 644.248 99.8733 639.559Z"/>
<path  fill="gateSelect17"  fill-rule="evenodd" clip-rule="evenodd" d="M283 222H275V197.064C272.037 195.592 270 192.534 270 189C270 184.029 274.029 180 279 180C283.971 180 288 184.029 288 189C288 192.534 285.963 195.592 283 197.064V222Z"/>
<path  fill="gateSelect16"  fill-rule="evenodd" clip-rule="evenodd" d="M256.42 292.14C258.063 293.9 260.403 295 263 295C267.971 295 272 290.971 272 286C272 281.029 267.971 277 263 277C258.545 277 254.846 280.238 254.127 284.489C248.26 285.95 246.019 287.254 241.083 290.288L245 297C250.106 294.062 251.681 293.501 256.42 292.14Z"/>
<path  fill="gateSelect15"  fill-rule="evenodd" clip-rule="evenodd" d="M283 493H275V468.064C272.037 466.592 270 463.534 270 460C270 455.029 274.029 451 279 451C283.971 451 288 455.029 288 460C288 463.534 285.963 466.592 283 468.064V493Z"/>
<path  fill="gateSelect14"  fill-rule="evenodd" clip-rule="evenodd" d="M303 544H295V554.936C292.037 556.408 290 559.466 290 563C290 567.971 294.029 572 299 572C303.971 572 308 567.971 308 563C308 559.466 305.963 556.408 303 554.936V544Z"/>
<path  fill="gateSelect13"  fill-rule="evenodd" clip-rule="evenodd" d="M324 382H316V392.936C313.037 394.408 311 397.466 311 401C311 405.971 315.029 410 320 410C324.971 410 329 405.971 329 401C329 397.466 326.963 394.408 324 392.936V382Z"/>
<path  fill="gateSelect12"  fill-rule="evenodd" clip-rule="evenodd" d="M340.076 314.561C338.429 316.655 335.871 318 333 318C328.029 318 324 313.971 324 309C324 304.029 328.029 300 333 300C337.182 300 340.697 302.852 341.708 306.717L354.76 310.196L352.7 317.926L340.076 314.561Z"/>
<path  fill="gateSelect11"  fill-rule="evenodd" clip-rule="evenodd" d="M324 222H316V208V197.064C313.037 195.592 311 192.534 311 189C311 184.029 315.029 180 320 180C324.971 180 329 184.029 329 189C329 192.534 326.963 195.592 324 197.064V208V222Z"/>
<path  fill="gateSelect10"  fill-rule="evenodd" clip-rule="evenodd" d="M235 428L235 436L245.936 436C247.408 438.963 250.466 441 254 441C258.971 441 263 436.971 263 432C263 427.029 258.971 423 254 423C250.466 423 247.408 425.037 245.936 428L235 428Z"/>
<path  fill="gateSelect9"  fill-rule="evenodd" clip-rule="evenodd" d="M324 654H316V643.064C313.037 641.592 311 638.534 311 635C311 630.029 315.029 626 320 626C324.971 626 329 630.029 329 635C329 638.534 326.963 641.592 324 643.064V654Z"/>
<path  fill="gateSelect8"  fill-rule="evenodd" clip-rule="evenodd" d="M304 360H296V349.064C293.037 347.592 291 344.534 291 341C291 336.029 295.029 332 300 332C304.971 332 309 336.029 309 341C309 344.534 306.963 347.592 304 349.064V360Z"/>
<path  fill="gateSelect7"  fill-rule="evenodd" clip-rule="evenodd" d="M283 382H275V392.936C272.037 394.408 270 397.466 270 401C270 405.971 274.029 410 279 410C283.971 410 288 405.971 288 401C288 397.466 285.963 394.408 283 392.936V382Z"/>
<path  fill="gateSelect6"  fill-rule="evenodd" clip-rule="evenodd" d="M429.997 591.657C429.749 589.262 430.456 586.775 432.162 584.817C435.426 581.068 441.111 580.675 444.86 583.94C448.608 587.204 449.001 592.889 445.737 596.637C442.811 599.997 437.94 600.661 434.262 598.411C429.307 601.876 426.852 602.71 421.322 604.44L418.833 597.078C424.401 595.157 425.859 594.337 429.997 591.657Z"/>
<path  fill="gateSelect5"  fill-rule="evenodd" clip-rule="evenodd" d="M283 544H275V554.936C272.037 556.408 270 559.466 270 563C270 567.971 274.029 572 279 572C283.971 572 288 567.971 288 563C288 559.466 285.963 556.408 283 554.936V544Z"/>
<path  fill="gateSelect4"  fill-rule="evenodd" clip-rule="evenodd" d="M316 128H324V138.936C326.963 140.408 329 143.466 329 147C329 151.971 324.971 156 320 156C315.029 156 311 151.971 311 147C311 143.466 313.037 140.408 316 138.936V128Z"/>
<path  fill="gateSelect3"  fill-rule="evenodd" clip-rule="evenodd" d="M303 654H295V643.064C292.037 641.592 290 638.534 290 635C290 630.029 294.029 626 299 626C303.971 626 308 630.029 308 635C308 638.534 305.963 641.592 303 643.064V654Z"/>
<path  fill="gateSelect2"  fill-rule="evenodd" clip-rule="evenodd" d="M303 498H295V487.064C292.037 485.592 290 482.534 290 479C290 474.029 294.029 470 299 470C303.971 470 308 474.029 308 479C308 482.534 305.963 485.592 303 487.064V498Z"/>
<path  fill="gateSelect1"  fill-rule="evenodd" clip-rule="evenodd" d="M304 368H296V378.936C293.037 380.408 291 383.466 291 387C291 391.971 295.029 396 300 396C304.971 396 309 391.971 309 387C309 383.466 306.963 380.408 304 378.936V368Z"/>
</svg>`
