import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { DropDown } from './DropDown';
import { Switch } from './Switch';


export function DesignToggles(
  {
    prs,
    des,
    advanced,
    numbers,
    hints,
    planets,
    arrows,
    setNumbers,
    setHints,
    setPlanets,
    setArrows,
    setPrs,
    setAdvanced,
    setDes,
    editAvailable = false
  }
) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;


  let options = [

    {
      id: 'hints',
      handle: setHints,
      component: <Switch
        size='xs'
        label={$ui('edu_help_bodygraph')}
        active={hints}
        handle={setHints}
      />
    },
    {
      id: 'numbers',
      handle: setNumbers,
      component: <Switch
        size='xs'
        label={$ui('toggle_numbers')}
        active={numbers}
        handle={setNumbers}
      />
    },
    {
      id: 'planets',
      handle: setPlanets,
      component:
        <Switch
          size='xs'
          label={$ui('toggle_planets')}
          active={planets}
          handle={setPlanets}
        />
    },
    {
      id: 'advanced',
      handle: setAdvanced,
      component: <Switch
        size='xs'
        label={$ui('toggle_advanced')}
        active={advanced}
        handle={setAdvanced}
      />
    },
    // {
    //   id: 'arrows',
    //   handle: setArrows,
    //   component:
    //     <Switch
    //       size='xs'
    //       label={$ui('toggle_arrows')}
    //       active={arrows}
    //       handle={setArrows}
    //     />
    // },
    // {
    //   id: 'personality',
    //   handle: setPrs,
    //   component:
    //     <Switch
    //       size='xs'
    //       label={$ui('toggle_personality')}
    //       active={prs}
    //       handle={setPrs}
    //     />
    // },
    // {
    //   id: 'body',
    //   handle: setDes,
    //   component:
    //     <Switch
    //       size='xs'
    //       label={$ui('toggle_design')}
    //       active={des}
    //       handle={setDes}
    //     />
    // },
  ]

  // let options = [...optionsBasic, {
  //   id: 'edit',
  //   icon: 'edit',
  //   // handle: setDes,
  //   component:
  //     <div onClick={() => context.setState.setCalcShown(true)}>
  //       {$ui('but_edit_avatar_dob')}
  //     </div>
  // }]

  let edit = <div onClick={() => context.setState.setCalcShown(true)} className="x_toggles_li __calcshown"><div className="t_icon t_icon--20"><div>edit</div></div>
    <div>{$ui('but_edit_avatar_dob')}</div>
  </div>
  let editLocked = <div className="x_toggles_li x_menu_li--locked"><div className="t_icon t_icon--20"><div>lock</div></div>
    <div>{$ui('but_edit_avatar_dob')}</div>
  </div>


  let addElements = editAvailable ? edit : editLocked



  // console.log('options = ', options);

  return (<>

    {/* {editAvailable ? edit : editLocked} */}

    {/* <div className="x_toggles_li" onClick={() => context.setState.setCalcShown(true)} >
      <div className="t_icon t_icon--20">
        edit
      </div>
      <div className="x_body_settings_label">{$ui('but_edit_avatar_dob')}</div>
    </div> */}

    <DropDown
      id='bodysettings'
      options={options}
      // addElements={addElements}
      // addElementsFirst={true}
      // addElementsCloseOnClick={true}
    >
      <div className="x_toggles_li">
        <div className="t_icon t_icon--20">
          tune
        </div>
        <div className="t_icon">
          <div>expand_more</div>
        </div>
        {/* <div className="x_body_settings_label">{$ui('bodygraph_view')}</div> */}
      </div>

    </DropDown>






  </>
  )
}

