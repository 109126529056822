import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { MessageContext } from '../../contexts/messageContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { Avatar } from '../UiKit/Avatar';
import { AvatarName } from '../UiKit/AvatarName';

export const ChatHead = ({
  userData,
  right,
  handleShowChats = () => void (0),
  handleState = () => void (0),
  showInfo,
  setShowInfo
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext), ...useContext(MessageContext) };
  const $ui = context.$ui;



  return (<>

    <div className="m_b_h_chats">
      <div className="x_choose_section_header x_choose_section_header--chats">

        {handleShowChats && <div className="x_chats_showall"
          onClick={() => handleShowChats()}
        ><div className="x_button x_button--ghost"><div className="x_button_icon x_button_icon--solo"><div className="t_icon t_icon--20"><div>list</div></div></div></div></div>}


        <div className="x_choose_section_title x_choose_section_title--chats">
          <div className="x_chat_header_ava">
            {userData && <Avatar
              avatar={userData.avatar}
              style={{ fontSize: '1.2em' }} />}
            {/* <div className="x_user_online" /> */}
          </div>
          <div className='ellipsis'
            style={{ maxWidth: '40vw' }}
          >{userData && <AvatarName avatar={userData.avatar} />}</div>
        </div>
        <div className="x_choose_section_header_right">

          {right}


          <div className="mobile_vh_yes">

            <div className="_f">
              <div className="x_button x_button--ghost"
                onClick={() => setShowInfo(true)}
              >
                <div className="x_button_icon x_button_icon--solo">
                  <div className="t_icon t_icon--20">
                    <div>info</div>
                  </div>
                </div>
              </div>

              <div className="x_button x_button--ghost"
                onClick={() => handleState(null)}
              >
                <div className="x_button_icon x_button_icon--solo">
                  <div className="t_icon t_icon--20">
                    <div>close</div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </>
  );
};
