export const replacements = {
  '<': '&lt;',
  '>': '&gt;',
  '&': '&amp;',
  '"': '&quot;',
  "'": '&#39;',
  // Additional special symbols to sanitize
  '`': '&#96;',   // Grave accent/backtick
  '!': '&#33;',   // Exclamation mark
  '@': '&#64;',   // At sign
  '#': '&#35;',   // Hash/pound sign
  '$': '&#36;',   // Dollar sign
  '%': '&#37;',   // Percent sign
  '^': '&#94;',   // Caret
  '*': '&#42;',   // Asterisk
  '(': '&#40;',   // Left parenthesis
  ')': '&#41;',   // Right parenthesis
  '{': '&#123;',  // Left curly brace
  '}': '&#125;',  // Right curly brace
  '[': '&#91;',   // Left square bracket
  ']': '&#93;',   // Right square bracket
  '\\': '&#92;',  // Backslash
  '|': '&#124;',  // Vertical bar/pipe
  ';': '&#59;',   // Semicolon
  ':': '&#58;',   // Colon
  ',': '&#44;',   // Comma
  '.': '&#46;',   // Period/full stop
  '/': '&#47;',   // Slash
  '?': '&#63;',   // Question mark
  '~': '&#126;',  // Tilde
  '-': '&#45;',   // Hyphen-minus
  '_': '&#95;',   // Underscore
  '=': '&#61;',   // Equals sign
  '+': '&#43;',   // Plus sign
};