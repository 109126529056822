import {
  drawTimeFromArray,
  drawDateFromArray,
  convertGMTtoDateTime,
  _getUser
} from './utilsDesign';
import celebs_categories from '../static/celebs_categories.json';






export const getListIcon = (quantity = 1) => {
  if (quantity === 1) return 'person';
  if (quantity === 2) return 'group';
  if (quantity >= 3 || quantity < 5) return 'diversity_3';
  if (quantity > 5) return 'groups';
}



export const getPersonUrl = (city, dob, timezone, title, photoURL, privateNote) => {
  let url = dob
    ? `${city ? `city=${city}&` : ''}date=${convertGMTtoDateTime(dob)['date']}&time=${convertGMTtoDateTime(dob)['time']}&timezone=${timezone}${title ? `&title=${title}` : ''}${photoURL ? `&img=${photoURL}` : ''
    }${privateNote ? `&note=${privateNote}` : ''
    }`
    : '';
  url = url.replaceAll(' ', '%20')
  console.log('url = ', url);
  return url;
}


export const setLocal = (what, value) => {
  try {
    // Only stringify if the value is an object or array, otherwise store as-is
    const itemToStore = (typeof value === 'object') ? JSON.stringify(value) : value;
    localStorage.setItem(what, itemToStore);
  } catch (error) {
    console.error(`Error setting localStorage item '${what}':`, error);
  }
};

export const getLocal = (what) => {
  try {
    let item = localStorage.getItem(what);
    if (item && (item.startsWith('{') || item.startsWith('['))) {
      return JSON.parse(item);
    }
    if (item && item.startsWith('"') && item.endsWith('"')) {
      return item.slice(1, -1);
    }
    return item;
  } catch (error) {
    console.error(`Error getting localStorage item '${what}':`, error);
    return null;
  }
};


export const getUrl = (user, me, language) => {
  // let tz = context.me.avatar.aggr.city.timezone;
  // tz = tz.replaceAll('/', '%2F')
  // let url = `https://hd.cafe/public/?timezone=${tz}&dob=${context.me.avatar.dob}&city_id=${context.me.avatar.city_id}&city=${context.me.avatar.aggr.city.city}&title=${context.me.avatar.name}&invited_by=${context.me.user.token}&ln=${context.language}`
  // url = url.replaceAll(' ', '%20')
  const url = `https://hd.cafe/u/${user.token}${language ? `&ln=${language}` : ''}`
  return url;
}

export const getPrivateUrl = (avatar, me, language, copy) => {
  let tz = avatar.aggr.city.timezone;
  tz = tz.replaceAll('/', '%2F')
  let url = `https://hd.cafe/public/?timezone=${tz}&dob=${avatar.dob}&city_id=${avatar.city_id}&city=${avatar.aggr.city.city}&title=${avatar.name}${copy ? `&copy=true` : ''}${me ? `&invited_by=${me.user.token}` : ''}${language ? `#ln=${language}` : ''}`
  url = url.replaceAll(' ', '%20')
  return url;
}

export const fetchUsers = (userIds, callback, callbackError) => {
  const membersData = {}
  const promises = userIds.map(userId => {
    return new Promise((resolve, reject) => {
      _getUser(
        userId,
        userData => {
          membersData[userId] = userData;
          resolve();
        },
        error => {
          console.error(`Error fetching data for user ${userId}:`, error);
          resolve(); // Resolve even on error to ensure Promise.all completes
        }
      );
    });
  });

  Promise.all(promises)
    .then(() => {
      callback(membersData);
    })
    .catch(error => {
      console.error('Error fetching all users data:', error);
      callbackError()
    });
};




// const calculatePercentages = (typ) => {
//   let total = context['data'].filter(one => one['design'] && one['design']['type']).length
//   let q = context['data'].filter(one => one['design'] && one['design']['type'] === typ).length
//   let percentage = (q / total) * 100;
//   percentage = percentage.toFixed(0)
//   return percentage;
// }

// const calculatePercentageByChannel = (gates) => {
//   let friendsWithDesign = context['data'].filter(one => one['design'] && one['design']['type'])
//   let total = friendsWithDesign.length
//   let q = countByChannel(gates, friendsWithDesign)
//   let percentage = (q / total) * 100;
//   percentage = percentage.toFixed(0)
//   return percentage;
// }


// export const calculatePercentByCenter = (center = '', array = []) => {
//   let total = array.filter(one => one['design'] && one['design']['type']).length
//   let q = array.filter(one => one['design'] && one['design']['type'] === typ).length
//   let percentage = (q / total) * 100;
//   percentage = percentage.toFixed(0)
//   return percentage;
// }
