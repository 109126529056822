import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';

export const UserDropDown = React.memo(({
  listId,
  actions = [],
  avatar = {},
  user = {},
  penta = {},
  addComponentFirst,
  addComponent
}) => {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) }
  const $ui = context.$ui;
  const navigate = useNavigate();

  const allActions = {
    edit: {
      title: $ui('but_edit_profile_short'),
      icon: 'edit',
      action: ({ user, avatar }) => navigate(`/u/${avatar.token}/info#p=bodygraphs`)
    },
    share: {
      title: $ui('but_public_link_short'),
      icon: 'share',
      action: ({ user, avatar }) => navigate(`/u/${user.token}/info#p=people`)
    },
    // setMe: {
    //   'title': 'Set Me',
    //   'icon': 'person',
    //   'action': context.x.setIsMe,
    // },
    // invite: {
    //   'title': 'Invite',
    //   'icon': 'person',
    //   'action': () => void (0),
    // },
    // share: {
    //   'title': 'Share',
    //   'icon': 'share',
    //   'action': () => void (0),
    // },
    delete: {
      'title': $ui('but_body_delete_short'),
      'icon': 'delete',
      'action': ({ avatar }) => {
        if (!avatar.isMe) {
          context.x.deleteAvatar({ avatar: avatar })
          context.setState.setShowHistory({ avatar: avatar }, true)
        }
      },
    },
    deleteFromHistory: {
      'title': $ui('but_body_delete_history_short'),
      'icon': 'hide_source',
      'action': ({ avatar }) => {
        context.setState.setShowHistory({ avatar: avatar }, true)
      },
    },
    deletePenta: {
      'title': $ui('but_body_delete_short'),
      'icon': 'delete',
      'action': context.x.deletePenta,
    },
    addToPenta: {
      'title': $ui('penta_add_people'),
      'icon': 'person_add',
    },
    follow: {
      'title': ({ user }) => context.x.checkIfFollow(user.token) ? $ui('but_unfollow') : $ui('but_follow'),
      'icon': ({ user }) => context.x.checkIfFollow(user.token) ? 'person_remove' : 'person_add',
      'action': ({ user }) => context.x.checkIfFollow(user.token) ? context.x.unfollow : context.x.follow,
    }
  }


  const checkIsMe = (actions) => {
    if (avatar && (avatar.isMe || !avatar.my)) {
      return actions.filter(one => one !== 'delete')
    }
    return actions
  }


  const [shown, setShown] = useState(false);
  const dropdownRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the component
      if (shown && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShown(false);
      }
    };

    const handleMouseMove = (event) => {
      // Check if the mouse is outside the dropdown
      if (shown && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [shown]);



  return (
    <div
      ref={dropdownRef}
      className={shown ? "x_choose_one_menu0 active" : "x_choose_one_menu0"}
      onClick={(e) => {
        e.stopPropagation()
        setShown(!shown)
      }}
    >
      <div className="x_choose_one_menu_ico">
        <div className="t_icon t_icon--20">
          <div>more_vert</div>
        </div>
      </div>
      <div className={shown ? "x_choose_one_menu_drop active" : "x_choose_one_menu_drop"}>
        <div className="x_choose_one_menu_ul">

          {addComponentFirst}

          {checkIsMe(actions).map((one, ind) => {
            if (!allActions[one]) return;
            return <div
              key={`${listId}_${avatar.token}_${one}`}
              className="x_choose_one_menu_li w-inline-block"
              onClick={() => {
                allActions[one].action({ avatar: avatar, user: user, penta: penta })
              }}
            >
              <div className="t_icon t_icon--20">
                {typeof allActions[one].icon === 'function' ? allActions[one].icon({ user: user }) : allActions[one].icon}
              </div>
              {typeof allActions[one].title === 'function' ? allActions[one].title({ user: user }) : allActions[one].title}
            </div>
          })}

          {addComponent}
          {/* <a href="#" className="x_choose_one_menu_li w-inline-block"
            onClick={() => {
              context.setState.showPeople(false)

            }}
          >
            <div className="t_icon t_icon--20">
              <div>edit</div>
            </div>
            <div>Edit</div>
          </a> */}


          {/* <a href="#" className="x_choose_one_menu_li w-inline-block">
            <div className="t_icon t_icon--20">
              <div>people</div>
            </div>
            <div>Invite</div>
          </a> */}
          {/* <a href="#" className="x_choose_one_menu_li w-inline-block">
            <div className="t_icon t_icon--20">
              <div>share</div>
            </div>
            <div>Share</div>
          </a> */}


          {/* {actions.map(one => {
            return (<div className="x_choose_one_menu_li x_choose_one_menu_li--2 w-inline-block"
              onClick={(e) => {
                // context.handles.deleteUser(userId)
                one.action(avatar.token)
              }}
            >
              <div className="t_icon t_icon--20">
                <div>delete</div>
              </div>
              {one.title}
            </div>)
          })} */}


          {/* {userId === context['user']['userData']['id'] && <a href="#" className="x_choose_one_menu_li x_choose_one_menu_li--2 w-inline-block">
            This is you
          </a>} */}
        </div>
      </div>
    </div>
  )
})