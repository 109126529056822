import React, { useContext } from 'react';
import { LangContext } from '../../contexts/langContext';
import { SocialContext } from '../../contexts/socialContext';
import { StateContext } from '../../contexts/stateContext';
import { planets_dictionary } from '../../static/planets_dictionary';
import { PlanetPop } from '../Edu/LinesTable';
import { findGateByPlanet } from '../utilsDesign';


export const Planet = React.memo(({
  interactive = true,
  label = 'des',
  planet,
  titleShown = false,
  // gate,
  design,
  colorShown = false
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $planet = context.$planet;

  const gate = findGateByPlanet(design.gates, planet, label);
  // console.log('gate = ', gate);
  // console.log('gate2 = ', gate2);

  return (<>

    <div className={`x_bodygraph_planet  ${interactive ? 'x_bodygraph_planet--hoverable' : ''} x_bodygraph_planet--${label} ${planet === 'Sun' ? 'x_bodygraph_planet--sun' : ''}`}>



      <div className="x_profile_planet_lines0">

        {colorShown && Boolean(gate) && [1, 2, 3, 4, 5, 6].reverse().map((oneColor, ind) => {
          return <div key={`${label}_${planet}_${oneColor}`} className={oneColor === gate.color ? "x_profile_planet_line x_profile_planet_line--active" : "x_profile_planet_line"}>
            {oneColor}
          </div>;
        })}

      </div>


      {Boolean(gate) && <div className='x_profile_planet0'>
        <PlanetPop planet={planet} gate={gate} design={design}>

        </PlanetPop>
      </div>}

      <div className={`x_planet ${planet === 'Sun' ? " x_planet--sun" : ''} ${planet.includes('Node') ? " x_planet--node" : ''}`}
        alt={planets_dictionary[planet]['title']}
      >
        {$planet(planet, 'glyph')}
      </div>

      {titleShown && <div className="x_planet_title">
        {/* {planets_dictionary[planet]['title']} */}
        {$planet(planet, 'short')}
      </div>}



    </div>




  </>
  );
});





export const PlanetWithPop = React.memo(({
  label = 'des',
  planet,
  titleShown = false,
  // gate,
  design,
  colorShown = false
}) => {
  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ = context.$;
  const $ui = context.$ui;
  const $planet = context.$planet;

  const gate = findGateByPlanet(design.gates, planet, label);
  // console.log('gate2 = ', gate2);

  return (<>

    <div className={`x_profile_planet  x_profile_planet--${label} ${planet === 'Sun' ? 'x_profile_planet--sun' : ''}`}>



      <div className="x_profile_planet_lines0">

        {colorShown && [1, 2, 3, 4, 5, 6].reverse().map((oneColor, ind) => {
          return <div key={`${label}_${planet}_${oneColor}`} className={oneColor === gate.color ? "x_profile_planet_line x_profile_planet_line--active" : "x_profile_planet_line"}>
            {oneColor}
          </div>;
        })}

      </div>


      <div className='x_profile_planet0'>
        <PlanetPop planet={planet} gate={gate} design={design}>

        </PlanetPop>
      </div>

      <div className={`x_planet ${planet === 'Sun' ? " x_planet--sun" : ''} ${planet.includes('Node') ? " x_planet--node" : ''}`}
        alt={planets_dictionary[planet]['title']}
      >
        {$planet(planet, 'glyph')}
      </div>

      {titleShown && <div className="x_planet_title">
        {/* {planets_dictionary[planet]['title']} */}
        {$planet(planet, 'short')}
      </div>}



    </div>




  </>
  );
});
