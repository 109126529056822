import React, { useContext, useState } from 'react';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';

export function SocialVIewSettings({ filter, setFilter }) {

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const [view, setView] = useState(false)


  const handleMouseEnter = () => {
    if (!view) setView(true);
  };

  const handleMouseLeave = () => {
    if (view) setView(false);
  };





  return (
    <>


      <div className="x_choose_filters0"
        // onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >


        <div className={view ? "x_filter_li active" : "x_filter_li"}
          onClick={() => setView(prev => !prev)}
        >

          <div className={filter ? "x_filter_single on" : "x_filter_single"}>
            <div className="x_filter_1">
              <div className="x_panel_sub_icon">
                <div className="t_icon t_icon--20">
                  <div>category</div>
                </div>
              </div>
              {!filter ? $ui('view_options_grouping') : $ui(`people_sort_by_${filter}`)}
            </div>
          </div>

          {/* <div className={context.state.hideNames ? "x_filter_single on" : "x_filter_single"}>
            <div className="x_filter_1">
              <div className="x_panel_sub_icon">
                <div className="t_icon t_icon--20">
                  <div>visibility_off</div>
                </div>
              </div>
              {$ui('view_options_privacy_hidenames')}
            </div>
          </div> */}

          <div className="x_panel_sub_icon">
            <div className="t_icon t_icon--40">
              <div>more_vert</div>
            </div>
          </div>

        </div>



        <div className="x_choose_filters_pop0">


          <div className={view ? "x_choose_filters_pop expanded" : "x_choose_filters_pop"}>
            <div className="x_settings_pop">
              <div className="x_settings_pop_section">
                <div className="x_settings_pop_section_title">
                  {$ui('view_options_grouping')}
                </div>
                <div className="x_settings_pop_ul">


                  <div className="x_settings_li">
                    <div
                      className={filter ? "x_settings_action" : "x_settings_action"}
                      onClick={(prev) => setFilter(filter ? '' : 'types')}
                    >
                      <div className="t_icon">
                        <div>category</div>
                      </div>
                      <div>
                        <div className="x_choose_filter_title">
                          {$ui('view_options_grouping')}
                        </div>
                      </div>
                      <div className="x_settings_right">

                        <div className={`x_settings_check ${filter ? 'active' : ''}`}>
                          <div className="t_icon t_icon--20">
                            {filter ? <div>toggle_on</div> : <div>toggle_off</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={filter ? "x_settings_pop_ul_sub expanded" : "x_settings_pop_ul_sub"}>

                    <div className="x_settings_li">
                      <div className={filter === 'types' ? "x_settings_action active" : "x_settings_action"}
                      onClick={() => setFilter('types')}
                    >
                      <div className="t_icon">
                        <div>group</div>
                      </div>
                      <div>
                        <div className="x_choose_filter_title">
                          {$ui('people_sort_by_types')}
                        </div>
                      </div>
                        <div className="x_settings_right">
                          <div className={`x_settings_check ${filter === 'types' ? 'active' : ''}`}>
                            <div className="t_icon t_icon--20">
                              <div>{filter === 'types' ? <>radio_button_checked</> : <>radio_button_unchecked</>}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                    <div className="x_settings_li">
                      <div className={filter === 'profiles' ? "x_settings_action active" : "x_settings_action"}
                      onClick={() => setFilter('profiles')}
                    >
                      <div className="t_icon">
                        group
                      </div>
                      <div>
                        <div className="x_choose_filter_title">
                          {$ui('people_sort_by_profiles')}
                        </div>
                      </div>
                      <div className="x_settings_right">
                        <div className={`x_settings_check ${filter === 'profiles' ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          <div>{filter === 'profiles' ? <>radio_button_checked</> : <>radio_button_unchecked</>}</div>
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>

                  </div>


                </div>

              </div>




              {/* <div className="x_settings_pop_section">
                <div className="x_settings_pop_section_title">
                  {$ui('view_options_privacy')}
                </div>
                <div className="x_settings_pop_ul">


                  <div className={context.state.hideNames ? "x_settings_action active" : "x_settings_action"}
                    onClick={() => context.setState.setHideNames((prev) => !prev)}
                  >
                    <div className="t_icon">
                      person_off
                    </div>
                    <div className="x_choose_filter_title">
                      {$ui('view_options_privacy_hidenames')}
                    </div>
                    <div className="x_settings_right">
                      <div className="t_icon t_icon--20">
                        {context.state.hideNames ? <div>toggle_on</div> : <div>toggle_off</div>}
                      </div>
                    </div>
                  </div>


                </div>
              </div> */}



          </div>
        </div>
      </div>



    </>
  )
}