const ln = 'en'

export const nodes_up = `<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.3646 13.1686C26.1433 13.9473 26.146 15.2089 25.3707 15.9909L25.2466 16.1161C24.4665 16.903 23.1955 16.9057 22.412 16.1223L16.0469 9.7571L16.0469 27C16.0469 27.5523 15.5992 28 15.0469 28L12.7259 28C12.1736 28 11.7259 27.5523 11.7259 27L11.7259 9.7571L5.37039 16.123C4.58834 16.9064 3.31876 16.9055 2.53775 16.1211L2.40811 15.9909C1.62994 15.2094 1.63131 13.9454 2.41116 13.1655L12.4721 3.10455C13.2532 2.32351 14.5195 2.3235 15.3006 3.10455L25.3646 13.1686Z" fill="currentColor"/>
</svg>`
export const nodes_dn = `<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.3646 16.8314C26.1433 16.0527 26.146 14.7911 25.3707 14.0091L25.2466 13.8839C24.4665 13.097 23.1955 13.0943 22.412 13.8777L16.0469 20.2429L16.0469 3C16.0469 2.44771 15.5992 2 15.0469 2L12.7259 2C12.1736 2 11.7259 2.44772 11.7259 3L11.7259 20.2429L5.37039 13.877C4.58834 13.0936 3.31876 13.0945 2.53775 13.8789L2.40811 14.0091C1.62994 14.7906 1.63131 16.0546 2.41116 16.8345L12.4721 26.8954C13.2532 27.6765 14.5195 27.6765 15.3006 26.8954L25.3646 16.8314Z" fill="currentColor"/>
</svg>`

export const variables_dictionary = {
  'left_up': {
    'title': {
      'en': 'Digestion',
      'ru': 'Питание'
    },
    'description': {
      'en': 'How we take in, or digest, the world around us, and how we set routines.',
      'ru': ''
    },
    'left': {
      'title': {
        'en': 'Active',
        'ru': 'Активное'
      },
      'description': {
        'en': 'Consistency and structure in your daily routines are helpful for you. You thrive with a predictable and consistent schedule, and feel freed up to take on the day when you know what to expect ahead of time. You’re also someone who probably can eat the same thing over and over and be totally ok with it. Lean in to creating schedules and routines for yourself, this is supportive for you. You’re also able to take in and absorb lots of information very well.',
        'ru': ''
      },
    },
    'right': {
      'title': {
        'en': 'Passive',
        'ru': 'Пассивное'
      },
      'description': {
        'en': 'A structured, predictable routine or schedule isn’t necessary for you. You can change things up on a daily basis, and allow for more fluidity in your schedule so you can approach things with a more go-with-the-flow attitude. You like to get creative with what you eat so let yourself try out lots of different things. You take in information in a broader, more generalized manner, so take your time with the way you learn and take things in to allow yourself to fully absorb it.',
        'ru': ''
      },
    },
  },
  'left_down': {
    'title': {
      'en': 'Environment',
      'ru': 'Среда обитания'
    },
    'description': {
      'en': 'How we prefer to exist in physical spaces and the consistency with which we approach disciplines or routines in our lives.',
      'ru': ''
    },
    'left': {
      'title': {
        'en': 'Observed',
        'ru': 'Активное'
      },
      'description': {
        'en': 'You thrive in a consistent environment. It probably feels good for you to sleep, eat, and work in the same places every day. When you go somewhere new, you need time to really familiarize yourself with your surroundings and feel comfortable in them before moving on to something new. Allow yourself to enjoy the predictability of frequenting the same spots and sticking with what works, since this will likely feel best to you.',
        'ru': ''
      },
    },
    'right': {
      'title': {
        'en': 'Observer of ',
        'ru': 'Пассивное'
      },
      'description': {
        'en': 'You enjoy variety and flexibility with where you spend your time. It’s good for you to travel around and spend time in different environments, switching up where you work, and the way you do things on a daily basis. You can still have discipline in your daily schedules and routines, but don’t be too rigid with it. Allow movement and freedom for yourself, trying out different places and experiences to see what feels good for you.',
        'ru': ''
      },
    },
  },
  'right_up': {
    'title': {
      'en': 'Awareness',
      'ru': 'Ум'
    },
    'description': {
      'en': 'How we learn, take in, and then recall information',
      'ru': ''
    },
    'left': {
      'title': {
        'en': 'Strategic',
        'ru': 'Стратегический'
      },
      'description': {
        'en': 'Details, specifics, and getting granular with how you take in information is what works best for you. It’s good for you to dive in and really immerse yourself with the facts as this is how you best remember things, and then are able to quickly recall and share them with others too. Staying heads-down and focused on what’s in front of you is your go-to approach to learning.',
        'ru': ''
      },
    },
    'right': {
      'title': {
        'en': 'Receptive',
        'ru': 'Восприимчивый'
      },
      'description': {
        'en': '“Big-picture” learning is how you typically approach things. You look to zoom out and see things as a whole, and approach learning in a way that’s more fluid and free-flowing, not needing to get very detailed or rigid with the way you take in information. You have a gift for seeing the bigger picture and general themes that connect in a way that not everyone else can. Let go of the more traditional methods of education and learn things in a way that takes in all the angles and lets you create meaning and insight out of it.',
        'ru': ''
      },
    },
  },
  'right_down': {
    'title': {
      'en': 'Perspective',
      'ru': 'Взгляд'
    },
    'description': {
      'en': 'Represents how we’re designed to view the world, and our approach to manifesting our goals and intentions.',
      'ru': ''
    },
    'left': {
      'title': {
        'en': 'Focused',
        'ru': 'Сфокусированный'
      },
      'description': {
        'en': 'Specific Manifestor. Setting specific goals and laying out a detailed plan is your jam. Release any shame or judgment you might have for being INCREDIBLY SPECIFIC with what you want in life. Making plans and spelling out your goals and visions is how they come to you, so don’t be afraid of speaking your crystal clear intentions out into the Universe. Incorporating strategy and using business plans and specific goal-setting will likely be beneficial to you.',
        'ru': ''
      },
    },
    'right': {
      'title': {
        'en': 'Peripheral',
        'ru': 'Рассеянный'
      },
      'description': {
        'en': 'Non-Specific Manifestor. The details of your goals or plans aren\'t as important to you. Instead it’s good for you to focus on the feeling behind achieving or receiving those goals. How do you want to feel in your career? Home? Daily life? Having a general idea of what you’re wanting is enough, you can release any pressure to come up with a detailed plan or roadmap of how you’ll get there, and know that just creating a general intention of what you want is enough!',
        'ru': ''
      },
    },
  },
}




const arrowTags = {
  'left': {
    'en': [
      'Logical',
      'Structure',
      'Analytical',
      'Competitive',
      'Objective',
      'Results-oriented',
      'Urgency',
      'Head-focused'
    ],
    'ru': []
  },
  'right': {
    'en': [
      'Values',
      'Intuition',
      'Openness',
      'Collaborative',
      'Subjective',
      'Process-oriented',
      'Patience',
      'Heart-focused'
    ],
    'ru': []
  },
}


export const getVariable = (varname, what = 'title', direction, fullname) => {

  let ret = direction ? `${variables_dictionary[varname][direction][what][ln]}` : variables_dictionary[varname][what][ln]
  if (fullname) ret += ' ' + variables_dictionary[varname]['title'][ln]
  return ret
}

export const getPRR = (design) => {
  if (!design['variables']) return;
  let variables = design['variables'];
  let ret = 'P'
  ret += variables['right_up'] === 'left' ? 'L' : 'R';
  ret += variables['right_down'] === 'left' ? 'L' : 'R';
  ret += 'D'
  ret += variables['left_up'] === 'left' ? 'L' : 'R';
  ret += variables['left_down'] === 'left' ? 'L' : 'R';
  return ret
}