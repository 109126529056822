import { Segmented } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LangContext } from '../../contexts/langContext';
import { StateContext } from '../../contexts/stateContext';
import { gates_circuits, gates_dictionary } from '../../static/gates_dictionary';
import { handleGateClick } from '../utilsDesign';
import { Button } from './Button';
import GateNumber from './GateNumber';
import { PopoverH } from './TooltipH';


export function Search({ design }) {
  const context = { ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const [value, setValue] = useState('');

  const validat = (v) => {

    // if (!/^\d+$/.test(v)) {
    //   return '';
    // }

    const numericValue = Number(v);

    if (v == 0) {
      return ''
    }
    if (numericValue < 1 || numericValue > 64) {
      if (v.slice(0, 1) < 7) {
        return parseInt(v.slice(0, 1))
      } else {
        return '';
      }
    }
    if (v.length < 3) {
      return parseInt(v);
    } else {
      v = v.slice(0, 2);
      return parseInt(v);
    }
  }
  const verifyValue = (v) => {
    v = v.toString()
    if (v.split(',').length > 1) {
      // && v.split(',')[v.split(',').length - 1]
      return v.split(',')
        .filter(one => validat(one))
        .map(one => parseInt(one))
    } else {
      return [validat(v.split(',')[0])]
    }

  };

  useEffect(() => {
    if (value) {
      context.setState.setSearch([...verifyValue(value)])
      console.log('value = ', value);
    } else {
      context.setState.setSearch([])
    }
  }, [value])

  return (
    <>
      <PopoverH title={<><GatesList design={design} /></>} placement='bottomLeft'>
      <Wrapper className="x_highlightgate0">
        <Icon className="x_highlight_icon"><div className="t_icon t_icon--40">search</div></Icon>
        <SearchInput className={`x_input x_input--highlightgate ${value ? 'active' : ''}`} type='text' value={value}
          // placeholder={$ui('bodygraph_search')}
          placeholder={''}
          // onChange={(e) => setValue([...verifyValue(e.target.value)])}
          onChange={(e) => {
            let v = e.target.value
            setValue(v.split(',').length > 1 ? v : validat(v))
          }}
        ></SearchInput>
        {Boolean(value) && <div onClick={() => setValue('')} className="x_highlight_clear"><div className="t_icon t_icon--40">close</div></div>}
      </Wrapper>
      </PopoverH>
    </>
  )

}


const GatesList = ({ design }) => {

  const context = { ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;
  const location = useLocation();
  const navigate = useNavigate();
  const handleHover = (gateId) => {
    context.setState.setSearch([gateId])
  };
  const handleHoverCircuit = (circuitId, circuit) => {
    context.setState.setSearch([...circuit])
    setHovered(circuitId)
  };
  const handleClick = (gateId) => context.setState.setSearch([gateId]);
  const isStudy = location.pathname.includes('study');
  const { what } = useParams();
  const infoType = what ? what.split(':')[0] : null;
  let infoValue = what ? what.split(':')[1] : null;

  const [current, setCurrent] = useState('gates');

  const [hovered, setHovered] = useState();

  const getCircuits = () => {
    const circuits = {};
    ['individual', 'collective', 'tribal'].forEach(oneCircuit => {
      circuits[oneCircuit] = Object.keys(gates_circuits).filter(oneGate => {
        return gates_circuits[oneGate].circuit[0] === oneCircuit;
      }).map(gate => parseInt(gate, 10)); // Convert to integers here
    });
    return circuits;
  };

  const getStreams = () => {
    const streams = {};

    ['knowledge', 'realize', 'protect', 'integration', 'sense', 'ego'].forEach(oneStream => {
      streams[oneStream] = Object.keys(gates_dictionary).filter(oneGate => {
        return gates_dictionary[oneGate].stream.includes(oneStream);
      }).map(gate => parseInt(gate)); // Convert gate numbers to integers
    });

    return streams;
  };

  return (<>
    <div className="_fv">
      <Segmented
        options={[{
          label: $ui('gates'),
          value: 'gates'
        }, {
          label: $ui('circuits'),
          value: 'circuits'
        }, {
          label: $ui('streams'),
          value: 'streams'
        }]}
        onChange={(value) => {
          setCurrent(value)
          console.log(value); // string
        }}
      />


      {current === 'circuits' && <div className='_fv'>
        {Object.keys(getCircuits()).map(oneCircuitId => {
          const oneCircuit = getCircuits()[oneCircuitId];
          return <div>
            <Button
              style={{ padding: '0.4em 0.6em' }}
              ghost={hovered !== oneCircuitId}
              onMouseEnter={(e) => {
                e.stopPropagation();
                handleHoverCircuit(oneCircuitId, oneCircuit);
              }}
              onMouseOut={(e) => {
                e.stopPropagation();
                handleHover();
                setHovered()
                !isStudy && handleHover();
              }}
            >
              <div className=' _fl _f100 _fm'>
                <div className="x_tap"
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    handleHoverCircuit(oneCircuitId, oneCircuit);
                  }}
                  onMouseOut={(e) => {
                    e.stopPropagation();
                    handleHover();
                    setHovered()
                    !isStudy && handleHover();
                  }}
                />
                <div className="_fgrow">
                  {$ui(oneCircuitId)}
                </div>
                <div className="_f _fwrap _fgrow _fgap02 inact _fr" style={{ maxWidth: '12em' }}>
                  {oneCircuit.map(one => {
                    one = parseInt(one);
                    return (<GateNumber
                      key={`gate_${one}`}
                      design={design}
                      gateId={one}
                      style={{ fontSize: '0.6em', width: '2em', height: '2em', cursor: 'pointer' }}
                    ></GateNumber>)
                  })}
                </div>
              </div>

            </Button>
          </div>
        })}</div>}

      {current === 'streams' && <div className='_fv'>
        {Object.keys(getStreams()).map(oneCircuitId => {
          const oneCircuit = getStreams()[oneCircuitId];
          return <div>
            <Button
              style={{ padding: '0.4em 0.6em' }}
              ghost={hovered !== oneCircuitId}
            >
              <div className='_fwrap _fl _f100 _fm'>
                <div className="x_tap"
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    handleHoverCircuit(oneCircuitId, oneCircuit);
                  }}
                  onMouseOut={(e) => {
                    e.stopPropagation();
                    handleHover();
                    setHovered()
                    !isStudy && handleHover();
                  }}
                />
                <div className="_fgrow">
                  {$ui(oneCircuitId)}
                </div>
                <div className="_f _fwrap _fgrow _fgap02 inact _fr" style={{ maxWidth: '12em' }}>
                  {oneCircuit.map(one => {
                    one = parseInt(one);
                    return (<GateNumber
                      key={`gate_${one}`}
                      design={design}
                      gateId={one}
                      style={{ fontSize: '0.6em', width: '2em', height: '2em', cursor: 'pointer' }}
                    ></GateNumber>)
                  })}
                </div>
              </div>
            </Button>
          </div>
        })}</div>}


      {current === 'gates' && <div className="_f _fwrap _fgap02" style={{ width: '22em' }}>
      {Object.keys(gates_dictionary).map(one => {
        one = parseInt(one);
        return (<GateNumber
          key={`gate_${one}`}
          design={design}
          gateId={one}
          style={{ width: '2em', cursor: 'pointer' }}
          className={`x_gate_title_num0--hoverable ${(isStudy && infoType === 'gate' && infoValue == one)
            || (isStudy && infoType === 'channel' && infoValue.split('-').includes(one.toString()))
            ? 'active' : ''
            }`
          }
          // className='x_gate_title_num0--hoverable'
          onMouseEnter={(e) => {
            e.stopPropagation();
            handleHover(one);
          }} i
          onMouseOut={(e) => {
            e.stopPropagation();
            !isStudy && handleHover();
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(one);
            handleGateClick(one, location, navigate);
          }}
        ></GateNumber>)
      })}
      </div>}

    </div>
  </>)
}

const SearchInput = styled.input`
  width: 4.4em;
  padding-left: 0.8em !important;
  z-index:0;
  &:focus {
    padding-left: 0.8em;
    z-index:1;
  }
`;

const Wrapper = styled.div`
  position: relative;
  align-items: center;
`;


const Icon = styled.div`
  left:0.6em;
  z-index:0;
`;

