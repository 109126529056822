import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LangContext } from '../contexts/langContext';
import { SocialContext } from '../contexts/socialContext';
import { StateContext } from '../contexts/stateContext';
import { _getSettings, _setSettings } from './utilsUser';

export function Settings(props) {
  const { children } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const context = { ...useContext(SocialContext), ...useContext(StateContext), ...useContext(LangContext) };
  const $ui = context.$ui;

  const { useravatarId } = useParams();


  // useEffect(() => {
  //   if (useravatarId !== context.me.user.token) {
  //     navigate('/')
  //   }
  // }, [])


  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await _getSettings();
        console.log('data = ', data);
        setSettings(data);
      } catch (error) {
        console.log('error = ', error);
      }
    };
    fetchSettings();
  }, []);


  const updateSettings = (key, val) => {
    setSettings(prev => {
      const newSett = JSON.parse(JSON.stringify(prev))
      newSett[key] = val;

      _setSettings(newSett)

      return newSett;
    })
  }


  return (
    <>


      <div className="m_b_c_scroll">
        <div className="m_b_cols">


          <div className="m_b_form0">
            <div className="m_b_form">
              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('language')}</div>
              </div>
              <div className="x_calc_form_line">

                <div className="x_settings_li">
                  <div className={`x_settings_action ${context.language === 'en' ? 'active' : ''}`}
                    onClick={() => {
                      context.setLanguage('en')
                    }}
                  >
                    <div className="t_icon t_icon--40">
                      <div>language</div>
                    </div>
                    <div>
                      <div className="x_choose_filter_title">English</div>
                    </div>
                    <div className="x_settings_right">
                      <div className={`x_settings_check ${context.language === 'en' ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          {context.language === 'en' ? 'radio_button_checked' : 'radio_button_unchecked'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="x_settings_li">
                  <div className={`x_settings_action ${context.language === 'ru' ? 'active' : ''}`}
                    onClick={() => {
                      context.setLanguage('ru')
                    }}
                  >
                    <div className="t_icon t_icon--40">
                      language
                    </div>
                    <div>
                      <div className="x_choose_filter_title">Русcкий</div>
                    </div>
                    <div className="x_settings_right">

                      <div className={`x_settings_check ${context.language === 'ru' ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          {context.language === 'ru' ? 'radio_button_checked' : 'radio_button_unchecked'}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div className="m_b_form">
              <div className="x_calc_form_line">
                <div className="x_calc_form_title">{$ui('settings_notifications_title')}</div>
              </div>
              <div className="x_calc_form_line">

                <div className="x_settings_li">
                  <div className={`x_settings_action ${settings.setting_email_unread_messages ? 'active' : ''}`}
                    onClick={() => {
                      updateSettings('setting_email_unread_messages', !settings.setting_email_unread_messages)
                    }}
                  >
                    <div className="t_icon t_icon--40">
                      <div>notifications</div>
                    </div>
                    <div>
                      <div className="x_choose_filter_title">{$ui('settings_notifications_messages')}</div>
                    </div>
                    <div className="x_settings_right">
                      <div className={`x_settings_check ${settings.setting_email_unread_messages ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          {settings.setting_email_unread_messages ? 'toggle_on' : 'toggle_off'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="x_settings_li">
                  <div className={`x_settings_action ${settings.setting_email_new_follower ? 'active' : ''}`}
                    onClick={() => {
                      updateSettings('setting_email_new_follower', !settings.setting_email_new_follower)
                    }}
                  >
                    <div className="t_icon t_icon--40">
                      group_add
                    </div>
                    <div>
                      <div className="x_choose_filter_title">{$ui('settings_notifications_followers')}</div>
                    </div>
                    <div className="x_settings_right">

                      <div className={`x_settings_check ${settings.setting_email_new_follower ? 'active' : ''}`}>
                        <div className="t_icon t_icon--20">
                          {settings.setting_email_new_follower ? 'toggle_on' : 'toggle_off'}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>


        </div>
      </div>


    </>
  )
}